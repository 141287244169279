import { createSlice } from '@reduxjs/toolkit';
import { Oauth2User } from 'utils/auth';

const STORE_NAME = 'authentication';

export interface Authentication {
  tenantId: string | undefined;
  authenticated: boolean;
  authenticateduser: Oauth2User | undefined;
  sub: string | undefined;
  username: string | undefined;
  roles: string[];
}
const tenantFromStorage =
  typeof localStorage === 'undefined'
    ? undefined
    : localStorage?.getItem('tenantId');

const initialState = {
  tenantId: tenantFromStorage ?? undefined,
  authenticated: false,
  authenticateduser: undefined,
  sub: undefined,
  username: undefined,
  roles: [],
} as Authentication;

export const authenticationSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    updateAuthentication: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const { updateAuthentication } = authenticationSlice.actions;

export default authenticationSlice.reducer;
