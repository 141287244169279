import { IContainer } from 'interfaces/general';

import Timer from './Options.timer';

import * as Shared from '../Shared.styles';
import * as S from './Options.styles';
import SessionSelector from './Options.sessionSelector';
import { useSessionContext } from 'pages/admin/Trade/context';
import { useEffect, useState } from 'react';
import useSSE, { AutoTraderEvent } from 'hooks/useSSE';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { EventType } from 'services/StructuredLogging/events';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { pickSelectedParticipant } from 'redux/states/miscellaneous.state';
import { AppDispatch } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchParticipantById,
  updateParticipantAutoSubmit,
} from 'redux/states/participants.state';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppContext } from 'App/AppProvider';

const Options = ({ className }: IContainer) => {
  const dispatch = useDispatch<AppDispatch>();
  const { logEvent } = useAppContext();
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const { isAutoSubmitOn } = useSessionContext();
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [sourceUri, setSourceUri] = useState<string | undefined>(undefined);
  const sseInit = {
    event: 'MarketParticipantUpdatedEvent',
    type: EventType.AUTOTRADER,
    sourceURL: sourceUri,
  };
  const { data } = useSSE(sseInit);

  const onChangeSwitchHandler = (e: any) => {
    if (selectedParticipant && selectedParticipant?.id)
      dispatch(
        updateParticipantAutoSubmit({
          participantId: selectedParticipant?.id,
          autoSubmitStatus: e.target.checked,
        })
      )
        .then(unwrapResult)
        .then((result) => {
          const { autoSubmitStatus, duration } = result as {
            autoSubmitStatus: boolean;
            duration: number;
          };
          if (logEvent) {
            logEvent({
              eventTime: new Date(),
              eventName: Event.TOGGLEDAUTOSUBMIT,
              payload: {
                autoSubmit: autoSubmitStatus,
              },
              timeToComplete: duration,
              category: Category.TRADEDASHBOARD_PAGE,
              requestStatus: 200,
            });
          }
        });

    setAutoSubmit(e.target.checked);
  };

  useEffect(() => {
    setAutoSubmit(isAutoSubmitOn);
  }, [isAutoSubmitOn]);

  useEffect(() => {
    getAuditSource();
  }, []);

  const getAuditSource = async () => {
    const auditUri = await AutoTraderApi.getAutoTraderSSEURL();
    setSourceUri(auditUri);
  };

  useEffect(() => {
    if (data) {
      const { targets } = data as AutoTraderEvent;
      if (targets.length) {
        const participantId = targets[0];
        dispatch(fetchParticipantById(participantId));
      }
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Shared.Options className={className}>
      <Shared.FocusedOptionItem>
        <Shared.OptionItem>
          <Shared.Title>Current interval:</Shared.Title>
          <S.SessionSelectorWrapper>
            <SessionSelector />
          </S.SessionSelectorWrapper>
        </Shared.OptionItem>
        <Shared.OptionItem>
          <Shared.Title>Closing in:</Shared.Title> <Timer />
        </Shared.OptionItem>
      </Shared.FocusedOptionItem>
      <Shared.OptionItem>
        <Shared.Title>Autosubmit:</Shared.Title>
        <Shared.AutosubmitSwitch
          data-testid='toggle_autosubmit'
          checked={autoSubmit}
          onClick={onChangeSwitchHandler}
        />
      </Shared.OptionItem>
    </Shared.Options>
  );
};

export default Options;
