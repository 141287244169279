import { Announcement } from '@mui/icons-material';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { Participant } from 'api/models/Participant.model';
import React from 'react';
import styles from './Portfolio.module.scss';

interface Props {
  participants: Participant[];
  handleSelector:
    | ((event: SelectChangeEvent<string>, child: React.ReactNode) => void)
    | undefined;
  selectedParticipant: Participant | undefined;
}
const { container, portfolioSelector, advisor } = styles;
const ParticipantDropdown = ({
  participants,
  handleSelector,
  selectedParticipant,
}: Props) => {
  const executeSelector = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    if (handleSelector) handleSelector(event, child);
  };

  return (
    <div className={portfolioSelector}>
      <div className={container}>
        <FormControl fullWidth size='small' data-testid='topbar_participants'>
          <InputLabel id='portfolio-input'>Participants</InputLabel>
          <Select
            labelId='participant-selector'
            id='participant-selector'
            value={
              selectedParticipant ? selectedParticipant.name : 'NO_SELECTED'
            }
            label='Participant'
            onChange={executeSelector}
          >
            {!participants.length && (
              <MenuItem key={'NO_SELECTED'} value={'NO_SELECTED'}>
                NONE
              </MenuItem>
            )}
            {Array.isArray(participants) &&
              participants.map((participant) => (
                <MenuItem key={participant.id} value={participant.name}>
                  {participant.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
      {participants.length === 0 && (
        <Tooltip
          title={
            <React.Fragment>
              <Typography color='inherit'>
                You have no participants created yet
              </Typography>
              {"Please create one participant's page"}
            </React.Fragment>
          }
        >
          <Announcement className={advisor} />
        </Tooltip>
      )}
    </div>
  );
};

export default ParticipantDropdown;
