import axiosRoot, { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  EFFECTIVE_OPERATING_PLAN_M15,
  BIDS_OFFERS,
  MATCHED_TRADES,
  OPERATING_PLAN_OVERRIDES,
  FORMULATED_OPERATING_PLAN_M15,
  OPTIMIZED_OPERATING_PLAN_M15,
  RESOURCE_CREATE,
  TRADE_STACK,
  OPERATING_PLAN_M15,
  AUTOTRADER_SSE,
  AUDIT_SSE,
  LOSS_REPORT,
} from './utils/constants';

import { PowerResource } from './models/PowerResource.model';

import { CreateTenantDto, TenantDto } from './models/Tenant.model';
import { CreateUserDto, UserDto, UserPreferenceDto } from './models/User.model';
import {
  ApiKeyCreatedDto,
  ApiKeyCreateRequestDto,
  ApiKeyDeletionCountDto,
  ApiKeyResponseDto,
} from './models/ApiKey.model';
import {
  IOperatingPlanM15Resources,
  IOperatingPlanOverrides,
} from 'interfaces/resource';
import { Portfolio } from './models/Portfolio.model';
import { TDateISO } from 'types/data';
import { Participant, Resources } from './models/Participant.model';
import { BidsOffers } from './models/BidsOffers.model';
import { TradeStack } from './models/TradeStack.model';
import { SourceSink } from './models/SourceSink.model';
import { Meter } from './models/Meter.model';
import { MatchedTrade } from './models/MatchedTrade.model';
import {
  IApiConfig,
  ITenantAuthConfigBase,
  Oauth2AuthorizationServerContext,
  OAuth2SessionManager,
} from '../utils/auth';
import { authDebug } from '../utils/log';
import { Logger } from 'services/StructuredLogging/logger';
import { EResolution } from 'components/molecules/RangeFilterTab/constants';
import { MeterStatus } from './models/MeterStatus.model';
import MonthEndLosses from './models/MonthEndLosses.model';
const axios = axiosRoot.create();

axios.interceptors.request.use(
  (config) => {
    const newConfig: any = { ...config };
    newConfig.metadata = { startTime: new Date().getTime() };
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    const newRes: any = { ...response };
    newRes.config.metadata.endTime = new Date().getTime();
    newRes.duration =
      newRes.config.metadata.endTime - newRes.config.metadata.startTime;
    return newRes;
  },
  (error) => {
    const newError = { ...error };
    newError.config.metadata.endTime = new Date().getTime();
    newError.duration =
      newError.config.metadata.endTime - newError.config.metadata.startTime;
    return Promise.reject(newError);
  }
);

export interface IApiTimeInterval {
  startAt?: string | TDateISO;
  stopAt?: string | TDateISO;
  timeResolution?: string | EResolution;
}

export interface IMTradesZones {
  outputTimeZone?: string;
}

interface IOperatingPlanRequest extends IApiTimeInterval {
  marketParticipantId?: string;
}

interface IOperatingPlanOverridesRequest extends IOperatingPlanRequest {}

export interface IOperatingPlanM15Request extends IOperatingPlanRequest {}

export interface IBidsOffersRequest extends IApiTimeInterval {
  marketParticipantId?: string;
}
export interface IMatchedTradesRequest extends IApiTimeInterval {
  marketParticipantId?: string;
}

function assetPath(assetName: string) {
  if (process.env.NODE_ENV === 'production') {
    return (process.env.PUBLIC_URL ?? '') + '/' + assetName;
  } else {
    return '/' + assetName;
  }
}

export function apiConfigPath(): string {
  return assetPath('apiConfig.json');
}

function fakeBidsOffersNew(): string {
  return assetPath('fakeBidsOffersNew.json');
}

export class OAuth2FederatedSignInWrapper {
  constructor(private asContext: Oauth2AuthorizationServerContext) {}

  async federatedSignIn(): Promise<void> {
    await this.asContext.redirectToIdentityProvider();
  }
}

export class OAuth2Wrapper {
  private constructor(
    private apiConfig: IApiConfig,
    private auth: OAuth2SessionManager
  ) {}

  static async loadApiConfig(): Promise<IApiConfig> {
    authDebug('loading apiConfig.json');
    const response: AxiosResponse = await axios.get(apiConfigPath());
    const apiConfig = response.data;
    if (apiConfig === undefined) {
      throw new Error('Failed to load api config');
    }
    const baseUrlOverride = process.env.REACT_APP_API_BASE_URL;
    if (baseUrlOverride) {
      apiConfig.baseUrl = baseUrlOverride;
    }
    apiConfig.redirectSignIn = window.location.origin + '/login';
    apiConfig.redirectSignOut = apiConfig.redirectSignIn;
    return apiConfig;
  }

  async getApiConfig(): Promise<IApiConfig> {
    return this.apiConfig;
  }

  getAuth(): OAuth2SessionManager {
    return this.auth;
  }

  async getCurrentAuthenticatedUser(): Promise<any> {
    try {
      const auth = this.getAuth();
      return await auth.currentAuthenticatedUser();
    } catch (e: any) {
      return undefined;
    }
  }

  static async configureTenant(
    apiConfig: IApiConfig,
    tenantId: string
  ): Promise<Oauth2AuthorizationServerContext> {
    authDebug('searching for auth config for tenant', tenantId);
    const tenantAuthConfig: ITenantAuthConfigBase | undefined =
      apiConfig.tenants.find(
        (tenant: ITenantAuthConfigBase) => tenant.tenantId === tenantId
      );
    if (tenantAuthConfig === undefined) {
      throw new Error('No auth config exists for tenant ' + tenantId);
    } else if (
      tenantAuthConfig.issuer === undefined ||
      tenantAuthConfig.clientId === undefined
    ) {
      throw new Error('Invalid auth config for tenant ' + tenantId);
    } else {
      return await Oauth2AuthorizationServerContext.of({
        ...tenantAuthConfig,
        redirectSignIn: `${apiConfig.redirectSignIn}?tenantId=${tenantId}`,
        redirectSignOut: apiConfig.redirectSignOut,
      });
    }
  }

  async signOut() {
    await this.auth.signOut();
    sessionStorage.clear();
  }

  addOnSignOut(onSignOut: () => Promise<void>) {
    if (this.auth === undefined) {
      throw new Error('auth not defined');
    }
    this.auth.addOnSignOut(onSignOut);
  }

  static async ofTenant(
    tenantId: string
  ): Promise<OAuth2Wrapper | OAuth2FederatedSignInWrapper> {
    const apiConfig = await OAuth2Wrapper.loadApiConfig();
    const asContext = await OAuth2Wrapper.configureTenant(apiConfig, tenantId);
    let sessionManager: OAuth2SessionManager | undefined =
      await OAuth2SessionManager.of(asContext);
    if (sessionManager !== undefined) {
      return new OAuth2Wrapper(apiConfig, sessionManager);
    } else {
      return new OAuth2FederatedSignInWrapper(asContext);
    }
  }
}

export interface IAutoTraderUsersApi {
  getUsers: () => Promise<UserDto[]>;
  patchUser: (user: UserDto) => Promise<UserDto>;
}

interface IAutoTraderApiCsm extends IAutoTraderUsersApi {
  getTenants: () => Promise<TenantDto[]>;
  switchTenantStatus: (status: boolean, tentant: string) => Promise<TenantDto>;
  createTenant: (tenant: CreateTenantDto) => Promise<TenantDto>;
}

export interface IAutoTraderApi extends IAutoTraderUsersApi {
  postLog: (logger: Logger) => Promise<any>;
  getPortfolios: () => Promise<Portfolio[]>;
  postPortfolios: (portfolios: Portfolio) => Promise<any>;
  getParticipants: () => Promise<Participant[]>;
  getParticipantById: (participantId: string) => Promise<Participant>;
  getParticipantByIdFull: (participantId: string) => Promise<any>;
  validateParticipantName: (name: string) => Promise<any>;
  postParticipants: (participant: Participant) => Promise<any>;
  createUser: (user: CreateUserDto) => Promise<UserDto>;
  getResources: () => Promise<any>;
  getResourcesFromParticipant: (participantId: string) => Promise<any>;
  getTimes: (participantId: string) => Promise<any>;
  postResources: (data: PowerResource) => Promise<any>;
  getSourcesAndSinks: () => Promise<any>;
  getBidsOffers: () => Promise<any>;
  getBidsOffersFake: () => Promise<any>;
  getBidsOffersNew: (data: IBidsOffersRequest) => Promise<any>;
  getOperatingPlanOverrides: (
    data: IOperatingPlanOverridesRequest
  ) => Promise<any>;
  createUpdateOperatingPlanOverrides: (
    data: IOperatingPlanOverrides,
    type: string
  ) => Promise<any>;
  getOperatingPlanM15: (data: IOperatingPlanM15Request) => Promise<any>;
  getFormulatedOperatingPlanM15: (
    data: IOperatingPlanM15Request
  ) => Promise<any>;
  getEffectiveOperatingPlanM15: (
    data: IOperatingPlanM15Request
  ) => Promise<any>;
  getOptmizedOperatingPlanM15: (data: IOperatingPlanM15Request) => Promise<any>;
  putResources: (data: PowerResource) => Promise<any>;
  createApiKey: (
    data: ApiKeyCreateRequestDto
  ) => Promise<AxiosResponse<ApiKeyCreatedDto>>;
  deleteApiKey: (apiKeyId: string) => Promise<ApiKeyDeletionCountDto>;
  getApiKeys: () => Promise<ApiKeyResponseDto[]>;
  getMeters: () => Promise<Meter[]>;
  postMeters: (meters: Meter) => Promise<any>;
  getMatchedTrades: (data: IMatchedTradesRequest) => Promise<any>;
  validateParticipantAutoSubmitOn: (participantId: string) => Promise<any>;
  updateParticipantAutoSubmit: (
    participantId: string,
    autosubmit: boolean
  ) => Promise<any>;
  getUserPreferences: () => Promise<any>;
  saveUserPreferences: (data: UserPreferenceDto) => Promise<any>;
}

class AutoTraderApiOauthParent implements IAutoTraderUsersApi {
  constructor(private oAuth2Wrapper: OAuth2Wrapper | undefined) {}

  setOauth2Wrapper(oAuth2Wrapper: OAuth2Wrapper | undefined) {
    this.oAuth2Wrapper = oAuth2Wrapper;
  }

  private static async getToken(auth: OAuth2SessionManager) {
    const session = await auth.currentSession();
    return session.getAccessToken();
  }

  async getAxiosConfig(): Promise<AxiosRequestConfig> {
    authDebug('getting axios config');
    if (this.oAuth2Wrapper === undefined) {
      throw new Error(
        'Cannot use AutoTraderApi until oAuth2Wrapper is configured'
      );
    }
    const auth = this.oAuth2Wrapper.getAuth();
    const token: string = await AutoTraderApiOauthParent.getToken(auth);
    const apiConfig: IApiConfig = await this.oAuth2Wrapper.getApiConfig();
    return {
      baseURL: apiConfig.baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      validateStatus: (status) => true,
    };
  }

  async getUsers(): Promise<UserDto[]> {
    const response = await axios.get('/v1/users', await this.getAxiosConfig());
    return response.data as UserDto[];
  }

  async patchUser(user: UserDto): Promise<UserDto> {
    const response = await axios.put(
      '/v1/users',
      user,
      await this.getAxiosConfig()
    );
    return response.data as UserDto;
  }
}

class AutoTraderApiCsmOauth
  extends AutoTraderApiOauthParent
  implements IAutoTraderApiCsm
{
  async createTenant(tenant: CreateTenantDto): Promise<TenantDto> {
    const response = await axios.post(
      '/v1/tenants',
      tenant,
      await this.getAxiosConfig()
    );
    return response.data as TenantDto;
  }

  async getTenants(): Promise<TenantDto[]> {
    const response = await axios.get(
      '/v1/tenants?activeOnly=false',
      await this.getAxiosConfig()
    );
    return response.data as TenantDto[];
  }

  async switchTenantStatus(
    status: boolean,
    tenant: string
  ): Promise<TenantDto> {
    const response = await axios.patch(
      `/v1/tenants/${tenant}?activate=${status}`,
      null,
      await this.getAxiosConfig()
    );
    return response.data as TenantDto;
  }
}

export class AutoTraderApiOauth
  extends AutoTraderApiOauthParent
  implements IAutoTraderApi
{
  async postLog(logger: Logger): Promise<any> {
    const response = await axios.post(
      '/v1/events',
      logger,
      await this.getAxiosConfig()
    );
    return response.data;
  }

  async getAuditSSEURL(
    startDate: string,
    endDate: string,
    events: string[]
  ): Promise<string> {
    const config = await this.getAxiosConfig();
    const search = new URLSearchParams();
    if (config && config.headers)
      search.set(
        'token',
        config.headers.Authorization.replace('Bearer', '').trim()
      );

    search.set('startDate', startDate);
    search.set('endDate', endDate);
    search.set('eventNames', events.join(','));
    return `${
      config.baseURL?.split('/autotrader')[0]
    }${AUDIT_SSE}?${search.toString()}`;
  }

  async getAutoTraderSSEURL(): Promise<string> {
    const config = await this.getAxiosConfig();
    return `${
      config.baseURL
    }${AUTOTRADER_SSE}?token=${config?.headers?.Authorization.replace(
      'Bearer',
      ''
    ).trim()}`;
  }

  async createUser(user: CreateUserDto): Promise<UserDto> {
    const response = await axios.post(
      '/v1/users',
      user,
      await this.getAxiosConfig()
    );
    return response.data as UserDto;
  }

  async postPortfolios(portfolio: Portfolio): Promise<any> {
    return await axios.post(
      '/v1/registration/portfolios',
      portfolio,
      await this.getAxiosConfig()
    );
  }

  async getPortfolios(): Promise<Portfolio[]> {
    const response = await axios.get(
      '/v1/registration/portfolios',
      await this.getAxiosConfig()
    );
    return response.data as Portfolio[];
  }

  async getSourcesAndSinks() {
    const response = await axios.get(
      '/v1/setup/locations',
      await this.getAxiosConfig()
    );
    return response.data as SourceSink[];
  }

  async postParticipants(participant: Participant): Promise<any> {
    return await axios.post(
      '/v1/registration/participants',
      participant,
      await this.getAxiosConfig()
    );
  }

  async validateParticipantName(name: string): Promise<any> {
    const response = await axios.head(
      `/v1/registration/market/participants/names/${name}`,
      await this.getAxiosConfig()
    );
    return response.status;
  }

  async getParticipants(): Promise<Participant[]> {
    if (process.env.NODE_ENV === 'production') {
      const response = await axios.get(
        '/v1/registration/participants',
        await this.getAxiosConfig()
      );
      return response.data as Participant[];
    } else {
      const response = await axios.get(
        '/v1/registration/participants',
        await this.getAxiosConfig()
      );
      return response.data as Participant[];
    }
  }

  async getParticipantById(participantId?: string): Promise<Participant> {
    const response = await axios.get(
      `/v1/registration/participants/${participantId}`,
      await this.getAxiosConfig()
    );
    return response.data as Participant;
  }

  async getLossReport(
    participantId: string,
    fromRangeDateTime?: string,
    toRangeDateTime?: string
  ): Promise<MonthEndLosses[] | any> {
    const baseUrl = `/v1${LOSS_REPORT}`;
    const startAtParam = fromRangeDateTime && `?startAt=${fromRangeDateTime}`;
    const stopAtParam = toRangeDateTime && `&stopAt=${toRangeDateTime}`;
    const url = `${baseUrl}/${participantId}${
      startAtParam ? startAtParam : ''
    }${stopAtParam ? stopAtParam : ''}`;
    const response = await axios.get(url, await this.getAxiosConfig());

    return response.data as MonthEndLosses[];
  }

  async getParticipantByIdFull(participantId?: string): Promise<any> {
    return await axios.get(
      `/v1/registration/participants/${participantId}`,
      await this.getAxiosConfig()
    );
  }

  async getResourcesWithSinkAndSourcesByParticipant(
    participantId: string
  ): Promise<Resources[]> {
    const response = await axios.get(
      `/v1/registration/participants/${participantId}`,
      await this.getAxiosConfig()
    );
    const participant = response.data as Participant;
    return participant.resources;
  }

  async getResources() {
    return axios.get<PowerResource[]>(
      '/v1/settings/resources',
      await this.getAxiosConfig()
    );
  }

  async getResourcesFromParticipant(participantId?: string): Promise<any> {
    return await axios.get(
      `/v1/settings/resources/participants/${participantId}`,
      await this.getAxiosConfig()
    );
  }

  async getTimes(participantId: string): Promise<any> {
    const response = await axios.get(
      `/v1/registration/market/participants/times/${participantId}`,
      await this.getAxiosConfig()
    );
    return response.data;
  }

  async postResources(data: PowerResource) {
    const response = await axios.post(
      `/v1${RESOURCE_CREATE}`,
      data,
      await this.getAxiosConfig()
    );
    return response;
  }

  async putResources(data: PowerResource) {
    return axios.put<PowerResource>(
      `/v1${RESOURCE_CREATE}`,
      data,
      await this.getAxiosConfig()
    );
  }

  async getBidsOffers(data?: IBidsOffersRequest) {
    const { marketParticipantId, startAt, stopAt } = data || {};
    const baseUrl = `/v1${BIDS_OFFERS}`;
    const startAtParam = startAt && `?startAt=${startAt}`;
    const stopAtParam = stopAt && `&stopAt=${stopAt}`;
    const url = `${baseUrl}/${marketParticipantId}${
      startAtParam ? startAtParam : '?startAt=2000-01-01T00:00:00Z'
    }${stopAtParam ? stopAtParam : ''}`;

    if (!marketParticipantId) return;

    return axios.get<BidsOffers[]>(url, await this.getAxiosConfig());
  }

  async getBidsOffersNew(data?: IBidsOffersRequest) {
    const { marketParticipantId, startAt, stopAt } = data || {};
    const baseUrl = `/v1${TRADE_STACK}`;
    const startAtParam = startAt && `?startAt=${startAt}`;
    const stopAtParam = stopAt && `&stopAt=${stopAt}`;
    const url = `${baseUrl}/${marketParticipantId}${
      startAtParam ? startAtParam : '?startAt=2000-01-01T00:00:00Z'
    }${stopAtParam ? stopAtParam : ''}`;

    if (!marketParticipantId) return;

    return axios.get<TradeStack[]>(url, await this.getAxiosConfig());
  }

  async getBidsOffersFake() {
    const url = fakeBidsOffersNew();
    return axios.get<BidsOffers[]>(url);
  }

  async getOperatingPlanOverrides(data?: IOperatingPlanOverridesRequest) {
    let url = `/v1/operating-plan/overrides/participants/${data?.marketParticipantId}`;
    if (data?.startAt || data?.stopAt) {
      const queryParams = new URLSearchParams({
        startAt: data?.startAt ?? '',
        stopAt: data?.stopAt ?? '',
      });
      url += `?${queryParams.toString()}`;
    }
    return await axios.get(url, await this.getAxiosConfig());
  }

  async createUpdateOperatingPlanOverrides(
    data: IOperatingPlanOverrides,
    type: string
  ) {
    const url = `/v1${OPERATING_PLAN_OVERRIDES}/${type}`;
    return axios.post(url, data, await this.getAxiosConfig());
  }

  async deleteOperatingPlanOverrides(overrideId: string) {
    const url = `/v1${OPERATING_PLAN_OVERRIDES}/${overrideId}`;
    return axios.delete(url, await this.getAxiosConfig());
  }

  async getOperatingPlanM15(
    data: IOperatingPlanM15Request,
    planTypeUrl?: string
  ) {
    const { marketParticipantId, startAt, stopAt } = data;
    const baseUrl = `/v1${planTypeUrl ?? OPERATING_PLAN_M15}`;
    const params = `startAt=${startAt?.replace(
      '+00:00',
      ''
    )}&stopAt=${stopAt?.replace('+00:00', '')}`;
    const url = `${baseUrl}/participants/${marketParticipantId}?${params}`;
    return axios.get<IOperatingPlanM15Resources>(
      url,
      await this.getAxiosConfig()
    );
  }

  async getFormulatedOperatingPlanM15(data: IOperatingPlanM15Request) {
    return this.getOperatingPlanM15(data, FORMULATED_OPERATING_PLAN_M15);
  }

  async getEffectiveOperatingPlanM15(data: IOperatingPlanM15Request) {
    return this.getOperatingPlanM15(data, EFFECTIVE_OPERATING_PLAN_M15);
  }

  async getOptmizedOperatingPlanM15(data: IOperatingPlanM15Request) {
    return this.getOperatingPlanM15(data, OPTIMIZED_OPERATING_PLAN_M15);
  }

  async createApiKey(
    data: ApiKeyCreateRequestDto
  ): Promise<AxiosResponse<ApiKeyCreatedDto>> {
    return axios.post('/v1/apiKeys', data, await this.getAxiosConfig());
  }

  async activateApiKey(keyToActivate: string, status: boolean) {
    return axios.patch(
      `/v1/apiKeys/${keyToActivate}?activate=${status}`,
      null,
      await this.getAxiosConfig()
    );
  }

  async deleteApiKey(apiKeyId: string): Promise<ApiKeyDeletionCountDto> {
    const response = await axios.delete(
      '/v1/apiKeys/' + apiKeyId,
      await this.getAxiosConfig()
    );
    return response.data as ApiKeyDeletionCountDto;
  }

  async getApiKeys(): Promise<ApiKeyResponseDto[]> {
    const response = await axios.get(
      '/v1/apiKeys',
      await this.getAxiosConfig()
    );
    return response.data as ApiKeyResponseDto[];
  }

  async getMeters(): Promise<Meter[]> {
    if (process.env.NODE_ENV === 'production') {
      const response = await axios.get(
        '/v1/telemetry/meters',
        await this.getAxiosConfig()
      );
      return response.data as Meter[];
    } else {
      const response = await axios.get(
        '/v1/telemetry/meters',
        await this.getAxiosConfig()
      );
      return response.data as Meter[];
    }
  }

  async getMetersStatus(): Promise<MeterStatus[]> {
    if (process.env.NODE_ENV === 'production') {
      const response = await axios.get(
        '/v1/telemetry/meters/statuses',
        await this.getAxiosConfig()
      );
      return response.data as MeterStatus[];
    } else {
      const response = await axios.get(
        '/v1/telemetry/meters/statuses',
        await this.getAxiosConfig()
      );
      return response.data as MeterStatus[];
    }
  }

  async postMeters(meters: Meter): Promise<any> {
    return await axios.post(
      '/v1/telemetry/meters',
      meters,
      await this.getAxiosConfig()
    );
  }

  async getMatchedTrades(data?: IMatchedTradesRequest & IMTradesZones) {
    const {
      marketParticipantId,
      startAt,
      stopAt,
      timeResolution,
      outputTimeZone,
    } = data || {};
    const baseUrl = `/v1${MATCHED_TRADES}`;

    const params = `${startAt ? `startAt=${startAt}` : ''}${
      stopAt ? `&stopAt=${stopAt}` : ''
    }${timeResolution ? `&timeResolution=${timeResolution}` : ''}${
      outputTimeZone ? `&outputTimeZone=${outputTimeZone}` : ''
    }`;
    const url = `${baseUrl}/${marketParticipantId}?${params.toString()}`;
    if (!marketParticipantId) return;
    return axios.get<MatchedTrade[]>(url, await this.getAxiosConfig());
  }

  async validateParticipantAutoSubmitOn(participantId: string): Promise<any> {
    const response = await axios.head(
      `/v1/market/auto-submit/participants/${participantId}`,
      await this.getAxiosConfig()
    );
    return response.status;
  }

  async updateParticipantAutoSubmit(
    participantId: string,
    autosubmit: boolean
  ) {
    if (autosubmit) {
      return axios.put(
        `/v1/market/auto-submit/participants/${participantId}`,
        {},
        await this.getAxiosConfig()
      );
    } else {
      return axios.delete(
        `/v1/market/auto-submit/participants/${participantId}`,
        await this.getAxiosConfig()
      );
    }
  }

  async getUserPreferences() {
    const response = await axios.get<UserPreferenceDto>(
      '/v1/preferences',
      await this.getAxiosConfig()
    );

    return response.data;
  }

  async saveUserPreferences(
    data: UserPreferenceDto
  ): Promise<UserPreferenceDto> {
    return axios.post('/v1/preferences', data, await this.getAxiosConfig());
  }
}

export const AutoTraderApi = new AutoTraderApiOauth(undefined);
export const AutoTraderApiCsm = new AutoTraderApiCsmOauth(undefined);
