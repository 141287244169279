import { ICellRendererParams } from 'ag-grid-community';
import { roundValue } from 'utils/general';
import { getImbalanceClassName } from './helpers';
import * as S from './M15.styles';
import M15Tooltip from './M15.tooltip';

export interface IImbalanceRendererProps extends ICellRendererParams {
  transformedDataValue: {
    energy: any;
    generation: any;
    load: any;
    ancilliaryValues: any;
  };
}

type TNegativeAncillaries = [string, number];

const M15ImbalanceRenderer = (props: IImbalanceRendererProps) => {
  const { transformedDataValue } = props;
  const { energy, generation, load, ...ancilliaryValues } =
    transformedDataValue || {};

  const energyValue = (energy && roundValue(energy)) || 0;
  const className = getImbalanceClassName(energyValue);

  const negativeAncillaries = Object.entries(ancilliaryValues)?.filter(
    (item: any) => item[1] < 0
  ) as TNegativeAncillaries[];

  const tooltipContent = (
    <S.TooltipContent>
      <h3>Ancillary service warning</h3>
      <table>
        <tbody>
          {negativeAncillaries?.map((acillary: TNegativeAncillaries) => {
            return (
              <tr key={`td-${acillary[0]}-${acillary[1]}`}>
                <td>{acillary[0]}</td>
                <td>{acillary[1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </S.TooltipContent>
  );

  const isAncilliaryNegative = !!negativeAncillaries?.length;

  return (
    <M15Tooltip
      disableHoverListener={!isAncilliaryNegative}
      title={tooltipContent}
      placement='right'
      arrow
    >
      <S.EnergyBox>
        <span className={`imbalanceColorCodeLabel_${className}`}>
          {energyValue}
        </span>
        <S.EnergyWarning show={isAncilliaryNegative} />
      </S.EnergyBox>
    </M15Tooltip>
  );
};

export default M15ImbalanceRenderer;
