import { GridReadyEvent } from 'ag-grid-community';
import CellRender from 'components/molecules/CellRender/CellRender';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FolioSkeleton from '../GridSkeleton/GridSkeleton';
import styles from '../ResourcesList/ResourcesList.module.scss';
import loosStyles from './LossPage.module.scss';
import * as S from '../OperatingPlan/OperatingPlan.styles';
import { auditColumnDefs } from './constants';
import DateRangeSelector from '../DateRangeSelector';
import { keepLocalTimezone } from 'utils/dateTime';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { useDateRangeSelector } from 'components/admin/DateRangeSelector';
import { Button, Skeleton } from '@mui/material';
import MonthEndLosses from 'api/models/MonthEndLosses.model';
import {
  ALL,
  MONTHS_12_FILTER,
  MONTHS_3_FILTER,
  MONTH_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from 'react-router-dom';
import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';
import { getTimeIntervalSetup } from '../Performance/helpers';
import { ITimeInterval } from 'components/molecules/RangeFilterTab/types';

import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppStore } from 'redux/store';
import { fetchLosses } from 'redux/states/loss.state';
import { FULFILLED } from 'redux/constants';

const INTERVALS = [ALL, MONTH_FILTER, MONTHS_3_FILTER, MONTHS_12_FILTER];

const LossPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { status, losses } = useSelector((store: AppStore) => store.losses);
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { intervalPreferences } = usePreferences();
  const { pathname } = useLocation();
  const [isCustomRangeActive, setIsCustomRangeActive] =
    useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const { fromRangeDateTime, toRangeDateTime, handleDateRangeSelector } =
    useDateRangeSelector(selectedTimeZone as TTimeZone);
  const [lossReport, setLossReport] = useState<MonthEndLosses[] | undefined>(
    []
  );

  const { handleRangeFilterChange, selectedRangeFilterTab } =
    useRangeFilterTabs(MONTHS_12_FILTER);

  const { resourcesPageResources, resourcesWrapperResources } = styles;
  const { matchedtradesoptions, toolButton, intervalOptions } = loosStyles;

  const [loadingLogs, setLoadingLogs] = useState<boolean>(false);

  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      editable: true,
      cellEditorPopup: false,
      wrapText: true,
      cellClass: 'ag-cell-aligned-center',
      headerClass: 'ag-header-cell-aligned-center',
    };
  }, []);

  const switchFilter = () => {
    setIsCustomRangeActive(!isCustomRangeActive);
  };

  const dateFromFilterTab = useCallback(
    (selectedFilter: ITimeInterval) => {
      const now = ZonedDateTime.now(selectedTimeZone as TTimeZone);
      if (selectedFilter.key === ALL.key) {
        setStartTime(now.add(-30, 'years').toIsoString());
        setEndTime(now.add(30, 'years').toIsoString());
      } else {
        const timeIntervalSetup = getTimeIntervalSetup(selectedFilter, now);
        const { startAt: fromDate, stopAt: toDate } = timeIntervalSetup;
        setStartTime(fromDate);
        setEndTime(toDate);
      }
    },
    [setStartTime, setEndTime, selectedTimeZone]
  );

  const dateFromRange = useCallback(
    (timeZone: TTimeZone) => {
      const fromDate = ZonedDateTime.parseIso(
        fromRangeDateTime,
        timeZone
      ).toIsoString();
      const toDate = ZonedDateTime.parseIso(
        toRangeDateTime,
        timeZone
      ).toIsoString();
      setStartTime(fromDate);
      setEndTime(toDate);
    },
    [setStartTime, setEndTime, fromRangeDateTime, toRangeDateTime]
  );

  const onGridParticipantsReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(70);
    gridEvent.api.sizeColumnsToFit();
  };

  const TITLE = `Loss Reports for ${selectedParticipant?.registration?.accountNumber}`;

  const fetchLossReports = useCallback(
    (fromRangeDateTime?: string, toRangeDateTime?: string) => {
      if (selectedParticipant.id)
        dispatch(
          fetchLosses({
            marketParticipantId: selectedParticipant.id,
            startAt: fromRangeDateTime,
            stopAt: toRangeDateTime,
          })
        );
    },
    [selectedParticipant, dispatch]
  );

  useEffect(() => {
    if (status === FULFILLED) {
      setLossReport(losses);
      setLoadingLogs(false);
    }
  }, [status, losses, setLossReport, setLoadingLogs]);

  const onRefreshData = () => {
    setLoadingLogs(true);
    if (selectedParticipant?.id) {
      fetchLossReports(startTime, endTime);
    }
  };

  useEffect(() => {
    setLoadingLogs(true);
    if (selectedParticipant?.id) {
      fetchLossReports(startTime, endTime);
    }
  }, [
    startTime,
    endTime,
    fetchLossReports,
    selectedParticipant,
    setLoadingLogs,
  ]);

  useEffect(() => {
    if (selectedTimeZone && selectedRangeFilterTab) {
      dateFromFilterTab(selectedRangeFilterTab);
    }
  }, [
    selectedRangeFilterTab.key,
    selectedRangeFilterTab,
    selectedTimeZone,
    dateFromFilterTab,
  ]);

  useEffect(() => {
    if (selectedTimeZone) {
      dateFromRange(selectedTimeZone);
    }
  }, [fromRangeDateTime, toRangeDateTime, selectedTimeZone, dateFromRange]);

  useEffect(() => {
    const intervalPreference = intervalPreferences?.find(
      (interval) => interval.page === pathname
    );
    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'BASIC'
    ) {
      setIsCustomRangeActive(false);
    }

    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'CUSTOM'
    ) {
      setIsCustomRangeActive(true);
    }
  }, [intervalPreferences, pathname]);
  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <S.PageTitle>
          {selectedParticipant?.id ? (
            TITLE
          ) : (
            <Skeleton height={50} width={800} />
          )}
        </S.PageTitle>
        <FolioSkeleton
          fullHeight
          onClickReset={onRefreshData}
          defaultDefs={defaultColDef}
          loading={loadingLogs}
          onGridReady={onGridParticipantsReadyHandler}
          canEdit={false}
          canAdd={false}
          canReset
          components={{ CellRender: CellRender }}
          columnDefs={auditColumnDefs}
          data={lossReport}
          pagination={true}
          toolExtra={
            <div className={matchedtradesoptions}>
              {isCustomRangeActive ? (
                <DateRangeSelector
                  disableTime
                  startDate={keepLocalTimezone(
                    fromRangeDateTime,
                    selectedTimeZone as TTimeZone
                  )}
                  endDate={keepLocalTimezone(
                    toRangeDateTime,
                    selectedTimeZone as TTimeZone
                  )}
                  handleDateRangeSelector={handleDateRangeSelector}
                  timezone={selectedTimeZone as TTimeZone}
                />
              ) : (
                <RangeFilterTab
                  tab={selectedRangeFilterTab.key}
                  handleChange={handleRangeFilterChange}
                  timeIntervals={INTERVALS}
                />
              )}
              <div className={intervalOptions}>
                <Button
                  className={toolButton}
                  variant='contained'
                  size='medium'
                  onClick={switchFilter}
                >
                  {!isCustomRangeActive ? 'Custom Range' : 'Basic Range'}
                </Button>
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default LossPage;
