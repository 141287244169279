import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import AppPages from 'pages/admin';

import LogoutPage from 'pages/LogoutPage/LogoutPage';
import {
  APP_BASE_URL,
  LOGIN,
  LOGOUT,
  NOT_PERMISSIONS,
  ROOT,
  SUPPORT_URL_AUTH_GUIDE,
  SUPPORT_URL_RELEASE_NOTES,
  SUPPORT_URL_USER_GUIDE,
  TENANTS_REQUESTOR,
  TRADE_DASHBOARD,
  TRADE_URL,
} from 'components/admin/Sidebar/menu.config';
import UserGuidePage from 'pages/admin/Support/UserGuidePage';
import NotFound from 'components/admin/NotFound/Index';
import TenantRequestor from 'pages/TenantRequestor/TenantRequestor';
import { useEffect } from 'react';
import { blankToUndefined } from 'utils/general';
import NotPermissions from 'components/admin/NotPermissions/Index';
import { allAvailableRoles } from './constants';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';
import { isEmpty } from 'lodash';

const Routes = (): JSX.Element => {
  const { tenantId, authenticated, roles } = useSelector(
    (store: AppStore) => store.authentication
  );
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const tenant = blankToUndefined(new URLSearchParams(search).get('id'));
    if (tenant) {
      history.push(`${TENANTS_REQUESTOR}?id=${tenant}`);
    }
  }, [history, search]);

  useEffect(() => {
    if (authenticated && !isEmpty(roles)) {
      const hasPermissions = roles?.some((role) =>
        allAvailableRoles.includes(role)
      );

      if (!hasPermissions) {
        history.push(NOT_PERMISSIONS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles, authenticated]);

  const guides = () => [
    <Route
      key={SUPPORT_URL_RELEASE_NOTES}
      exact
      path={`${SUPPORT_URL_RELEASE_NOTES}`}
    >
      <UserGuidePage guide={process.env.REACT_APP_RELEASE_NOTES_URL} />
    </Route>,
    <Route
      key={SUPPORT_URL_AUTH_GUIDE}
      exact
      path={`${SUPPORT_URL_AUTH_GUIDE}`}
      render={() => (
        <UserGuidePage guide={process.env.REACT_APP_AUTH_GUIDE_URL} />
      )}
    />,
  ];
  return (
    <Switch>
      {tenantId === undefined &&
        guides().concat([
          <Route
            key={TENANTS_REQUESTOR}
            exact
            path={TENANTS_REQUESTOR}
            component={TenantRequestor}
          />,
          <Route key={ROOT} exact path={'*'}>
            <Redirect to={TENANTS_REQUESTOR} />
          </Route>,
        ])}
      {authenticated === false &&
        guides().concat([
          <Route
            key={TENANTS_REQUESTOR}
            exact
            path={TENANTS_REQUESTOR}
            component={TenantRequestor}
          />,
        ])}
      {!!authenticated &&
        guides().concat([
          <Route key={ROOT} exact path={ROOT}>
            <Redirect to={LOGIN} />
          </Route>,
          <Route key={TENANTS_REQUESTOR} exact path={TENANTS_REQUESTOR}>
            <Redirect to={LOGIN} />
          </Route>,
          <Route key={LOGIN} exact path={LOGIN}>
            <Redirect to={`${APP_BASE_URL}${TRADE_URL}${TRADE_DASHBOARD}`} />
          </Route>,
          <Route key={APP_BASE_URL} path={APP_BASE_URL} component={AppPages} />,
          <Route
            key={SUPPORT_URL_USER_GUIDE}
            path={SUPPORT_URL_USER_GUIDE}
            render={() => (
              <UserGuidePage guide={process.env.REACT_APP_USER_GUIDE_URL} />
            )}
          />,
          <Route key={LOGOUT} exact path={LOGOUT} component={LogoutPage} />,
          <Route
            key={NOT_PERMISSIONS}
            path={NOT_PERMISSIONS}
            component={NotPermissions}
          />,
          <Route key={'404'} path={'*'} component={NotFound} />,
        ])}
    </Switch>
  );
};

export default Routes;
