export const CREATING = 'CREATING';
export const EDITING = 'EDITING';
export const VIEWING = 'VIEWING';
export const LOAD = 'LOAD';
export const GENERATING_UNIT = 'GEN_UNIT';
export const POWER_IMPORT = 'POWER_IMPORT';
export const POWER_EXPORT = 'POWER_EXPORT';
export const CONTINUE = 'Continue';
export const BACK = 'Back';
export const ADD = 'Add Resource';
export const breadcrumbsify = (bread: string) =>
  bread.replaceAll('/', '').replaceAll('-', ' ');

// export const tranform = <T>(resource: T<IFieldsFormat<K>>) => {
//   let transformed: Record<string, any> = {};
//   Object.keys(resource).forEach((key) => {
//     resource[key as keyof FormikValues].value;
//     transformed[key] = value;
//   });
//   return transformed;
// };
