import { AutoTraderApi } from 'api/AutoTraderAPI';
import React, { createContext, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import BreadcrumbsBar from '../BreadcrumbsBar';
import style from './AddUser.module.scss';
import { IUserValues } from './Forms/CreateUser/IUserValues';
import { CreateUserDto } from '../../../api/models/User.model';
import { useSnackbar, VariantType } from 'notistack';

interface IAddUserContext {
  resourceToAdd: string;
  setResourceToAdd?: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode[];
  back: Function;
  addUser: Function;
}

const AddUserContext = createContext<Partial<IAddUserContext>>({});

export const useAddUserContext = () => useContext(AddUserContext);

type Props = {
  children?: React.ReactNode;
};

const AddUserContextProvider = ({ children }: Props) => {
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { addResources } = style;

  const back = () => history.goBack();

  const addUser = async (resource: IUserValues) => {
    let resourceObj: CreateUserDto = tranform(resource);
    resourceObj.roles = [...resourceObj.roles, 'SEEM_MARKET'];
    try {
      const response = await AutoTraderApi.createUser(resourceObj);
      if (!response.name) {
        throw response;
      }
      pushNotification('success', 'User successfully created');
      history.goBack();
    } catch (error) {
      const {
        error: { message },
      } = error as {
        apiVersion: string;
        error: { code: number; message: string };
      };
      pushNotification('error', `Error has occurred creating user: ${message}`);
    }
  };

  const pushNotification = (variant: VariantType, message: string) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const tranform = (resource: IUserValues) => {
    let transformed: Record<string, any> = {};
    Object.keys(resource).forEach((key) => {
      const keyName = key as keyof IUserValues;
      transformed[keyName] = resource[keyName]?.value;
    });
    return transformed as CreateUserDto;
  };
  return (
    <AddUserContext.Provider value={{ addUser, back }}>
      <div className={addResources}>
        <BreadcrumbsBar path={`${path}`} />
        {children}
      </div>
    </AddUserContext.Provider>
  );
};

export { AddUserContextProvider };
