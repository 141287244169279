import { Dictionary, EntityState, configureStore } from '@reduxjs/toolkit';
import { Participant, Resources } from 'api/models/Participant.model';
import { Miscellaneous } from 'api/models/Miscellaneous.model';
import { Preferences } from 'api/models/Preferences.model';
import { PowerResource } from 'api/models/PowerResource.model';
import { Meter } from 'api/models/Meter.model';
import { MeterStatus } from 'api/models/MeterStatus.model';
import { AuditLog } from 'api/models/AuditLog.model';
import { Authentication } from './states/authentication.state';

import OverridesReducer, {
  OperatingPlanOverrides,
} from './states/overrides.state';
import ParticipantReducer from './states/participants.state';
import MiscellaneousReducer from './states/miscellaneous.state';
import PreferencesReducer from './states/preferences.state';
import ResourceReducer from './states/resources.state';
import AuditLogsReducer from './states/auditlogs.state';
import MetersReducer from './states/meters.state';
import LossesReducer, { LossState } from './states/loss.state';
import AuthenticationReducer from './states/authentication.state';
import BidsOffersReducer, { TradeStackState } from './states/bidsoffers.state';
import OperatingPlansReducer, {
  OperatingPlans,
} from './states/operatingplans.state';
import MatchedTradesReducer, {
  MatchedTradesState,
} from './states/matchedtrades.state';
import SourcesAndSinksReducer, {
  SourcesAndSinkState,
} from './states/sourcesandsinks.state';

export interface AppStore {
  participant: EntityState<Participant> & { status: string };
  losses: LossState;
  matchedtrades: MatchedTradesState;
  auditlogs: EntityState<AuditLog> & { status: string };
  resource: EntityState<PowerResource> & {
    resourcesStatus: string;
    sourcesSinksStatus: string;
    sourcesSinks: Dictionary<Resources>;
  };
  meters: EntityState<Meter> & {
    resourcesStatus: string;
    metersStatus: string;
    meterStatusesStatus: string;
    resources: Dictionary<PowerResource>;
    meterStatuses: Dictionary<MeterStatus>;
  };
  sourcesandsink: SourcesAndSinkState & { status: string };
  miscellaneous: Miscellaneous;
  preferences: Preferences & { status: string };
  authentication: Authentication;
  operatingplans: OperatingPlans;
  overrides: OperatingPlanOverrides & { status: string };
  bidsoffers: TradeStackState;
}

const store = configureStore({
  reducer: {
    participant: ParticipantReducer,
    matchedtrades: MatchedTradesReducer,
    auditlogs: AuditLogsReducer,
    resource: ResourceReducer,
    sourcesandsink: SourcesAndSinksReducer,
    miscellaneous: MiscellaneousReducer,
    preferences: PreferencesReducer,
    meters: MetersReducer,
    authentication: AuthenticationReducer,
    operatingplans: OperatingPlansReducer,
    overrides: OverridesReducer,
    bidsoffers: BidsOffersReducer,
    losses: LossesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
