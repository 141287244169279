import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from 'react-router-dom';
import {
  ICustomIntervalPreference,
  IIntervalPreference,
} from 'api/models/User.model';
import { isEmpty } from 'lodash';

export type THandleDateRangeSelector<T> = (fromDate: T, toDate: T) => void;

type TDateTime = string;
interface TUseDateRangeSelector {
  fromRangeDateTime: TDateTime;
  toRangeDateTime: TDateTime;
  setFromDateTime: Dispatch<SetStateAction<string>>;
  setToDateTime: Dispatch<SetStateAction<string>>;
  handleDateRangeSelector: THandleDateRangeSelector<string>;
}

const useDateRangeSelector = (
  selectedTimeZone: TTimeZone,
  startTimeToSubtract = 60,
  endTimeToAdd = 20,
  intervalDate?: ZonedDateTime
): TUseDateRangeSelector => {
  const { intervalPreferences, setPreference } = usePreferences();
  const { pathname } = useLocation();
  const [fromRangeDateTime, setFromDateTime] = useState<TDateTime>(
    ZonedDateTime.now(selectedTimeZone || ZonedDateTime.defaultTimeZone())
      .subtract(startTimeToSubtract, 'minutes')
      .startOf('minutes')
      .toIsoString()
  );

  const [toRangeDateTime, setToDateTime] = useState<TDateTime>(
    isEmpty(intervalDate)
      ? ZonedDateTime.now(selectedTimeZone || ZonedDateTime.defaultTimeZone())
          .add(endTimeToAdd, 'minutes')
          .startOf('minutes')
          .toIsoString()
      : intervalDate
          .add(endTimeToAdd, 'minutes')
          .startOf('minutes')
          .toIsoString()
  );

  const dateSetter = useCallback(
    (from: string, to: string) => {
      if (from && to) {
        const definedIntervalPreference = intervalPreferences?.find(
          (interval) => interval.page === pathname
        );
        if (definedIntervalPreference) {
          const startTime = from;
          const endTime = to;
          const intervalPreference: IIntervalPreference = {
            intervalType: 'CUSTOM',
            interval: { startTime, endTime },
            page: pathname,
          };
          const filteredIntervals = intervalPreferences?.filter(
            (interval) => interval.page !== pathname
          );
          filteredIntervals?.push(intervalPreference);
          setPreference({ intervalPreference: filteredIntervals });
        } else {
          const startTime = from;
          const endTime = to;
          const notDefinedUnitPreference: IIntervalPreference = {
            intervalType: 'CUSTOM',
            interval: { startTime, endTime },
            page: pathname,
          };
          setPreference({ intervalPreference: [notDefinedUnitPreference] });
        }
      }
    },
    [intervalPreferences, pathname, setPreference]
  );

  const handleDateRangeSelector = useCallback(
    (fromDate, toDate) => {
      if (selectedTimeZone) {
        const fromDateTimeFormated = ZonedDateTime.parseIso(
          fromDate as string,
          selectedTimeZone
        ).toString();
        const toDateTimeFormated = ZonedDateTime.parseIso(
          toDate as string,
          selectedTimeZone
        ).toString();
        dateSetter(fromDateTimeFormated, toDateTimeFormated);
        setFromDateTime(fromDate);
        setToDateTime(toDate);
      }
    },
    [selectedTimeZone, dateSetter]
  );

  useEffect(() => {
    const intervalPreference = intervalPreferences?.find(
      (interval) => interval.page === pathname
    );
    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'CUSTOM' &&
      selectedTimeZone
    ) {
      const { startTime, endTime } =
        intervalPreference.interval as ICustomIntervalPreference;
      const fromDateTimeFormated = ZonedDateTime.parseIso(
        startTime as string,
        selectedTimeZone
      ).toString();
      const toDateTimeFormated = ZonedDateTime.parseIso(
        endTime as string,
        selectedTimeZone
      ).toString();
      setFromDateTime(fromDateTimeFormated);
      setToDateTime(toDateTimeFormated);
    }
  }, [intervalPreferences, selectedTimeZone, pathname]);

  return {
    fromRangeDateTime,
    toRangeDateTime,
    setFromDateTime,
    setToDateTime,
    handleDateRangeSelector,
  };
};

export default useDateRangeSelector;
