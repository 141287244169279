import { Participant, RegistrationSeem } from 'api/models/Participant.model';
import CellEditor from 'components/molecules/CellEditor/CellEditor';
import DateTimeEditor from 'components/molecules/DateEditor/DateTimeEditor';
import PercentEditor from 'components/molecules/PercentEditor/PercentEditor';
import SelectEditor from 'components/molecules/SelectEditor/SelectEditor';
import { AGSort } from 'interfaces/dataGrid';
import {
  currencyInDollarsFormatter,
  generateToken,
  percentFormatter,
  timeExportHeaderValueGetter,
  timeExportValueGetter,
} from 'utils/general';

export const SEEM = 'SEEM';
export const TEA = 'TEA_SEEM';

//balance handling
export const ALWAYS = 'ALWAYS';
export const IF_REQUIRED = 'IF_REQUIRED';
export const NO = 'NO';

//bid offer strategy type
export const BID = 'BID';
export const OFFER = 'OFFER';
export const BOTH = 'BOTH';

export const initialValues = (hourStart: string, hourEnd: string) => {
  return {
    id: { value: generateToken(), error: undefined, touched: undefined },
    name: { value: 'MPT_01', error: undefined, touched: undefined },
    marketType: { value: 'SEEM', error: undefined, touched: undefined },
    startAt: {
      value: hourStart,
      error: undefined,
      touched: undefined,
    },
    stopAt: {
      value: hourEnd,
      error: undefined,
      touched: undefined,
    },
    tradeFinalizeDeadline: { value: '0', error: undefined, touched: undefined },

    bidQuantityWithheldFromFront: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    bidMaximumQuantityAllowed: {
      value: null,
      error: undefined,
      touched: undefined,
    },
    bidQuantityWithheldFromBack: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    bidCeilingPricedAtFrontQuantity: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    bidDiscount: { value: '0', error: undefined, touched: undefined },
    bidMinDiscountPercent: { value: '0', error: undefined, touched: undefined },
    bidMaxDiscountPercent: { value: '0', error: undefined, touched: undefined },

    offerMaximumQuantityAllowed: {
      value: null,
      error: undefined,
      touched: undefined,
    },
    offerQuantityWithheldFromFront: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    offerQuantityWithheldFromBack: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    offerFloorPricedAtFrontQuantity: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    offerPremium: { value: '0', error: undefined, touched: undefined },
    offerMinPremiumPercent: {
      value: '0',
      error: undefined,
      touched: undefined,
    },
    offerMaxPremiumPercent: {
      value: '0',
      error: undefined,
      touched: undefined,
    },

    autoSubmit: { value: true, error: undefined, touched: undefined },
    balanceRequired: { value: true, error: undefined, touched: undefined },
    balancerHandling: { value: 'ALWAYS', error: undefined, touched: undefined },
    balanceTolerance: { value: '5.0', error: undefined, touched: undefined },
    accountNumber: {
      value: 'ACC_01',
      error: undefined,
      touched: undefined,
    },
    blockSize: {
      value: '24',
      error: undefined,
      touched: undefined,
    },
    trader: {
      value: 'TraderName ',
      error: undefined,
      touched: undefined,
    },
    primaryKey: {
      value: 'PRIMARY_APIKEY',
      error: undefined,
      touched: undefined,
    },
    primaryExpiration: {
      value: hourEnd,
      error: undefined,
      touched: undefined,
    },
    secondaryKey: {
      value: 'SECONDARY_APIKEY',
      error: undefined,
      touched: undefined,
    },
    secondaryExpiration: {
      value: hourEnd,
      error: undefined,
      touched: undefined,
    },
    newRecord: true,
  };
};

export const participantTransformer = (participants: Participant[]) => {
  const hourEnd = '2099-12-01';
  return participants.map(
    ({
      id,
      name,
      marketType,
      startAt,
      stopAt,
      tradeFinalizeDeadline,
      autoSubmit,
      balanceRequired,
      balancerHandling,
      balanceTolerance,
      blockSize,
      registration,
      resources,
      strategy,
    }) => {
      const registrationObj = registration as RegistrationSeem;
      return {
        id,
        name,
        marketType,
        startAt,
        stopAt,
        tradeFinalizeDeadline: tradeFinalizeDeadline?.toString(),
        strategy,
        autoSubmit,
        balanceRequired,
        balancerHandling,
        balanceTolerance: balanceTolerance.toString(),
        blockSize: blockSize.toString(),
        resources: resources,
        accountNumber: registration?.accountNumber,

        trader: registrationObj?.trader,
        primaryKey: registrationObj?.primaryKey,
        primaryExpiration: registrationObj?.primaryExpiration || hourEnd,
        secondaryKey: registrationObj?.secondaryKey,
        secondaryExpiration: registrationObj?.secondaryExpiration || hourEnd,
      };
    }
  );
};

export const participantColumnDefs = [
  {
    headerName: 'Name',
    field: 'name',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    minWidth: 160,
    pinned: 'left',
    sort: AGSort.DESC,
  },
  {
    headerName: 'Market \n Type',
    field: 'marketType',
    minWidth: 80,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'selector',
      options: [{ value: SEEM, label: 'SEEM' }],
    },
    cellEditorParams: {
      options: [{ value: SEEM, label: 'SEEM' }],
    },
  },
  {
    headerName: 'Start At',
    field: 'startAt',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: DateTimeEditor,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Stop At',
    field: 'stopAt',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: DateTimeEditor,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Finalize \n Deadline \n (Minutes)',
    field: 'tradeFinalizeDeadline',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Auto \n Submit',
    field: 'autoSubmit',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'radio',
    },
    cellEditorParams: {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  },
  {
    headerName: 'Balance \n Required',
    field: 'balanceRequired',
    minWidth: 100,
    maxWidth: 100,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'radio',
    },
    cellEditorParams: {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  },
  {
    headerName: 'Submit \n Optimized Plan',
    field: 'balancerHandling',
    minWidth: 100,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'selector',
      options: [
        { value: ALWAYS, label: 'Always' },
        { value: IF_REQUIRED, label: 'If required' },
        { value: NO, label: 'No' },
      ],
    },
    cellEditorParams: {
      options: [
        { value: ALWAYS, label: 'Always' },
        { value: IF_REQUIRED, label: 'If required' },
        { value: NO, label: 'No' },
      ],
    },
  },
  {
    headerName: 'Balance \n Tolerance \n (MW)',
    field: 'balanceTolerance',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Block \n Size \n (MW)',
    field: 'blockSize',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Account \n Number',
    field: 'accountNumber',
    minWidth: 130,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Trader',
    field: 'trader',
    minWidth: 180,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Primary \n Api Key',
    field: 'primaryKey',
    minWidth: 120,
    maxWidth: 120,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'password',
    },
  },
  {
    headerName: 'Primary Api Key \n Expiration Date',
    field: 'primaryExpiration',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: DateTimeEditor,
    cellRendererParams: {
      type: 'datetime',
    },
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Secondary \n Api Key',
    field: 'secondaryKey',
    minWidth: 120,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'password',
    },
  },
  {
    headerName: 'Secondary Api Key \n Expiration Date',
    field: 'secondaryExpiration',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: DateTimeEditor,
    cellRendererParams: {
      type: 'datetime',
    },
    cellRenderer: 'CellRender',
  },

  {
    headerName: 'Bid Maximum Quantity Allowed',
    field: 'bidMaximumQuantityAllowed',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Bid Quantity Withheld From Front',
    field: 'bidQuantityWithheldFromFront',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Bid Quantity Withheld From Back',
    field: 'bidQuantityWithheldFromBack',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Bid Ceiling Priced At Front Quantity',
    field: 'bidCeilingPricedAtFrontQuantity',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Bid Discount',
    field: 'bidDiscount',
    cellEditor: CellEditor,
    valueFormatter: currencyInDollarsFormatter,
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Bid Min Discount Percent',
    field: 'bidMinDiscountPercent',
    cellEditor: PercentEditor,
    valueFormatter: percentFormatter,
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Bid Max Discount Percent',
    field: 'bidMaxDiscountPercent',
    cellEditor: PercentEditor,
    valueFormatter: percentFormatter,
    suppressColumnsToolPanel: true,
    hide: true,
  },

  {
    headerName: 'Offer Maximum Quantity Allowed',
    field: 'offerMaximumQuantityAllowed',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Offer Quantity Withheld From Front',
    field: 'offerQuantityWithheldFromFront',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Offer Quantity Withheld From Back',
    field: 'offerQuantityWithheldFromBack',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Offer Floor Priced At Front Quantity',
    field: 'offerFloorPricedAtFrontQuantity',
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Offer Premium',
    field: 'offerPremium',
    cellEditor: CellEditor,
    valueFormatter: currencyInDollarsFormatter,
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Offer Min Premium Percent',
    field: 'offerMinPremiumPercent',
    cellEditor: PercentEditor,
    valueFormatter: percentFormatter,
    suppressColumnsToolPanel: true,
    hide: true,
  },
  {
    headerName: 'Offer Max Premium Percent',
    field: 'offerMaxPremiumPercent',
    cellEditor: PercentEditor,
    valueFormatter: percentFormatter,
    suppressColumnsToolPanel: true,
    hide: true,
  },
];
