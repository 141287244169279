import BidsOffersDetails from 'components/admin/BidsOffers/BidsOffersDetails';
import NotFound from 'components/admin/NotFound/Index';
import PageInfoCard from 'components/admin/PageInfoCard/PageInfoCard';
import {
  TRADE_STRATEGY_ENDPOINT,
  TRADE_BIDSOFFERS_ENDPOINT,
  TRADE_DASHBOARD,
  TRADE_POSITION_ENDPOINT,
} from 'components/admin/Sidebar/menu.config';
import TradeDashboard from 'components/admin/TradeDashboard/TradeDashboard';
import TradeStrategyPage from 'components/admin/TradeStrategy/TradeStrategyPage';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { Route, Switch } from 'react-router-dom';
import { SessionProvider } from './context';

const themeMap = {
  light: 'public/assets/css/compact-light-theme.css',
  dark: 'public/assets/css/compact-dark-theme.css',
};

function index() {
  return (
    <Switch>
      <Route exact path={`*${TRADE_DASHBOARD}`}>
        <ThemeSwitcherProvider defaultTheme='light' themeMap={themeMap}>
          <SessionProvider>
            <TradeDashboard />
          </SessionProvider>
        </ThemeSwitcherProvider>
      </Route>
      <Route exact path={`*${TRADE_STRATEGY_ENDPOINT}`}>
        <ThemeSwitcherProvider defaultTheme='light' themeMap={themeMap}>
          <SessionProvider>
            <TradeStrategyPage />
          </SessionProvider>
        </ThemeSwitcherProvider>
      </Route>
      <Route exact path={`*${TRADE_BIDSOFFERS_ENDPOINT}`}>
        <ThemeSwitcherProvider defaultTheme='light' themeMap={themeMap}>
          <SessionProvider>
            <BidsOffersDetails />
          </SessionProvider>
        </ThemeSwitcherProvider>
      </Route>
      <Route exact path={`*${TRADE_POSITION_ENDPOINT}`}>
        <PageInfoCard message='Full details of what we expect to occur at each resource, and where we expect it to be,at ech 15-minute step' />
      </Route>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}

export default index;
