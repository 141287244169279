import { useCallback, useEffect, useMemo, useState } from 'react';
import { ZonedDateTime } from '@pci/pci-ui-library';

import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import {
  EResolution,
  HOURS_12_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import { useSessionContext } from 'pages/admin/Trade/context';
import {
  DAILY_FORMAT,
  DATE_TIME_FORMAT,
  MONTH_FORMAT,
  WEEKLY_FORMAT,
} from 'constants/dateTime';
import { IIndexable } from 'interfaces/general';
import { MatchedTrade } from 'api/models/MatchedTrade.model';

import PerformanceHeader from './Performance.header';
import PerformanceChart from './Performance.chart';
import { getTimeIntervalSetup, sortTotalMarginDesc } from './helpers';

import * as S from './Peformance.styles';
import { MVP_TIMEZONES } from 'constants/general';
import {
  pickSelectedParticipant,
  pickSelectedTimezone,
  updateMiscellaneous,
} from 'redux/states/miscellaneous.state';

import { fetchMatchedTrades } from 'redux/states/matchedtrades.state';

import { useDispatch, useSelector } from 'react-redux';
import RootStore, { AppDispatch, AppStore } from 'redux/store';
import { FULFILLED } from 'redux/constants';
import {
  savePreferences,
  toPreferenceDto,
  updatePreferences,
} from 'redux/states/preferences.state';
import { useAppContext } from 'App/AppProvider';

export interface IPerformanceChartData {
  id: string;
  bids: number;
  offers: number;
}
export interface IPerformanceChartDataWithTotal extends IPerformanceChartData {
  total: number;
}

export interface IPerformanceChartsData {
  counterparty: IPerformanceChartDataWithTotal[];
  resource: IPerformanceChartDataWithTotal[];
  energy: IPerformanceChartData[];
  value: IPerformanceChartData[];
}

interface IRawTimedChart extends IIndexable {
  '11/09/2022 12:30'?: IPerformanceChartData;
}

interface IRawResourceChart extends IIndexable {
  CCGT_650?: IPerformanceChartDataWithTotal;
}

interface IRawCounterPartyChart extends IIndexable {
  'Other Company'?: IPerformanceChartDataWithTotal;
}

interface IRawChartsData {
  counterparty: IRawCounterPartyChart;
  resource: IRawResourceChart;
  energy: IRawTimedChart;
  value: IRawTimedChart;
}

const Performance = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { pushNotification } = useAppContext();
  const { status, matchedtrades } = useSelector(
    (store: AppStore) => store.matchedtrades
  );
  const { handleRangeFilterChange, selectedRangeFilterTab } =
    useRangeFilterTabs(HOURS_12_FILTER);
  const { timeZone } = useSessionContext();
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);

  const [chartsData, setChartsData] = useState<IPerformanceChartsData>();
  const timeIntervalSetup = useMemo(
    () =>
      getTimeIntervalSetup(
        selectedRangeFilterTab,
        ZonedDateTime.now(selectedTimeZone as typeof timeZone)
      ),
    [selectedRangeFilterTab, selectedTimeZone]
  );

  const fetchTrades = useCallback(() => {
    const now = ZonedDateTime.now(selectedTimeZone as typeof timeZone);
    const { startAt, stopAt, timeResolution } = getTimeIntervalSetup(
      selectedRangeFilterTab,
      now
    );
    const timezone = MVP_TIMEZONES.filter(
      (timeZone) => timeZone.zone === selectedTimeZone
    )[0];
    const requestProps = {
      marketParticipantId: selectedParticipant?.id,
      startAt: encodeURIComponent(startAt),
      stopAt: encodeURIComponent(stopAt),
      timeResolution: EResolution[timeResolution as keyof typeof EResolution],
      outputTimeZone: timezone.identifier,
    };
    dispatch(fetchMatchedTrades(requestProps));
  }, [
    selectedTimeZone,
    selectedRangeFilterTab,
    selectedParticipant?.id,
    dispatch,
  ]);

  useEffect(() => {
    fetchTrades();
  }, [timeIntervalSetup, fetchTrades]);

  useEffect(() => {
    if (status === FULFILLED && matchedtrades?.length) {
      const bidsOffersChartsReducer = (
        result: IRawChartsData,
        bidsOffers: MatchedTrade
      ) => {
        const {
          counterparty,
          grossMargin,
          matchedQuantity,
          resourceIdentifier,
          tradeInterval,
          type,
        } = bidsOffers;
        const zonedTime = ZonedDateTime.parseIso(
          tradeInterval,
          selectedTimeZone as typeof timeZone
        );
        const time: string =
          timeIntervalSetup.timeResolution === EResolution.NONE
            ? zonedTime.format(DATE_TIME_FORMAT)
            : timeIntervalSetup.timeResolution === EResolution.DAILY
            ? zonedTime.format(DAILY_FORMAT)
            : timeIntervalSetup.timeResolution === EResolution.MONTHLY
            ? zonedTime.format(MONTH_FORMAT)
            : timeIntervalSetup.timeResolution === EResolution.WEEKLY
            ? zonedTime.format(WEEKLY_FORMAT)
            : zonedTime.format(DATE_TIME_FORMAT);

        if (!result?.resource) result.resource = {};
        if (!result?.counterparty) result.counterparty = {};
        if (!result?.energy) result.energy = {};
        if (!result?.value) result.value = {};

        if (!result.energy?.[time]) {
          result.energy[time] = {
            id: time,
            bids: 0,
            offers: 0,
          };
        }

        if (!result.value?.[time]) {
          result.value[time] = {
            id: time,
            bids: 0,
            offers: 0,
          };
        }

        if (!result.counterparty?.[counterparty]) {
          result.counterparty[counterparty] = {
            id: counterparty,
            bids: 0,
            offers: 0,
          };
        }

        if (!result.resource?.[resourceIdentifier]) {
          result.resource[resourceIdentifier] = {
            id: resourceIdentifier,
            bids: 0,
            offers: 0,
          };
        }

        if (type === 'BID') {
          result.energy[time].bids += matchedQuantity;
          result.value[time].bids += grossMargin;
          result.resource[resourceIdentifier].bids += grossMargin;
          result.counterparty[counterparty].bids += grossMargin;
        } else {
          result.energy[time].offers += matchedQuantity;
          result.value[time].offers += grossMargin;
          result.resource[resourceIdentifier].offers += grossMargin;
          result.counterparty[counterparty].offers += grossMargin;
        }

        result.resource[resourceIdentifier].total =
          result.resource[resourceIdentifier].bids +
          result.resource[resourceIdentifier].offers;
        result.counterparty[counterparty].total =
          result.counterparty[counterparty].bids +
          result.counterparty[counterparty].offers;

        return result;
      };
      const roughtData = matchedtrades.reduce(
        bidsOffersChartsReducer,
        {} as any
      );
      const cleanData: IPerformanceChartsData = {
        energy: Object.values(roughtData.energy).map((energy) => {
          const { id, bids, offers } = energy as IPerformanceChartData;

          return { id, bids: bids / 4, offers: offers / 4 };
        }),
        value: Object.values(roughtData.value),
        counterparty: Object.values(roughtData.counterparty),
        resource: Object.values(roughtData.resource),
      };

      cleanData.counterparty.sort(sortTotalMarginDesc);
      cleanData.resource.sort(sortTotalMarginDesc);

      setChartsData(cleanData);
    }

    if (status === FULFILLED && matchedtrades?.length === 0) {
      const cleanData: IPerformanceChartsData = {
        energy: [],
        value: [],
        counterparty: [],
        resource: [],
      };
      setChartsData(cleanData);
    }
    // eslint-disable-next-line
  }, [status, matchedtrades]);

  useEffect(() => {
    const now = ZonedDateTime.now(selectedTimeZone as typeof timeZone);
    const { startAt, stopAt } = getTimeIntervalSetup(
      selectedRangeFilterTab,
      now
    );
    const startZoned = ZonedDateTime.parseIso(
      startAt,
      selectedTimeZone as typeof timeZone
    );
    const stopZoned = ZonedDateTime.parseIso(
      stopAt,
      selectedTimeZone as typeof timeZone
    );
    const diff = stopZoned.diff(startZoned, 'weeks');
    if (selectedParticipant && window.document.readyState === 'complete') {
      if (diff >= 2) {
        const { marketType } = selectedParticipant;
        if (marketType === 'SEEM' && pushNotification) {
          const seemTimezone = MVP_TIMEZONES.find(
            (timezone) => timezone.label === 'UTC-5'
          );
          dispatch(updatePreferences({ timezone: seemTimezone?.zone }));
          const transformedPreferencesOld = toPreferenceDto(
            RootStore.getState()
          );
          dispatch(savePreferences(transformedPreferencesOld));
          dispatch(
            updateMiscellaneous({
              lockedTimezone: true,
            })
          );

          pushNotification(
            'info',
            'The timezone is set up according to the selected participant market type.'
          );
        }
      } else {
        dispatch(updateMiscellaneous({ lockedTimezone: false }));
      }
    }

    return () => {
      dispatch(updateMiscellaneous({ lockedTimezone: false }));
    };
  }, [
    selectedRangeFilterTab,
    dispatch,
    pushNotification,
    selectedParticipant,
    selectedTimeZone,
  ]);

  return (
    <S.Performance>
      <PerformanceHeader
        selectedRangeFilterTab={selectedRangeFilterTab}
        handleRangeFilterChange={handleRangeFilterChange}
      />
      <S.Main>
        <S.ChartHolder>
          <PerformanceChart
            data={chartsData?.value}
            isTimeBased
            chartConfig={timeIntervalSetup.chartSetup}
            title='Total Gross Margin'
          />
        </S.ChartHolder>
        <S.ChartHolder>
          <PerformanceChart
            isHorizontal
            data={chartsData?.counterparty}
            title='Margin by CounterParty'
          />
        </S.ChartHolder>
        <S.ChartHolder>
          <PerformanceChart
            data={chartsData?.energy}
            isTimeBased
            chartConfig={timeIntervalSetup.chartSetup}
            title='Total MWh'
          />
        </S.ChartHolder>
        <S.ChartHolder>
          <PerformanceChart
            isHorizontal
            data={chartsData?.resource}
            title='Margin by Resource'
          />
        </S.ChartHolder>
      </S.Main>
    </S.Performance>
  );
};

export default Performance;
