import React, { useCallback, useEffect, useMemo, useState } from 'react';

import './resources-list.scss';
import styles from './ResourcesList.module.scss';
import {
  GridApi,
  GridReadyEvent,
  RowEditingStoppedEvent,
  RowEditingStartedEvent,
} from 'ag-grid-community';
import { useFormik } from 'formik';
import { IAdditions, ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import { PowerResource } from 'api/models/PowerResource.model';
import useQueue, { CREATE, UPDATE } from 'hooks/useQueue';
import { AutoTraderApi } from 'api/AutoTraderAPI';

import CellRender from 'components/molecules/CellRender/CellRender';
import { IResourcesValues } from './IResourcesValues';
import { resourcesSchema } from './resourcesSchema';
import FolioSkeleton from '../GridSkeleton/GridSkeleton';
import ResourcesTab from './ResourcesTab';
import { useAppContext } from 'App/AppProvider';
import SourceSinkRender from 'components/molecules/SourceSinkRender/SourceSinkRender';
import { removeEmpty } from 'utils/general';
import { Event, EventType } from 'services/StructuredLogging/events';
import * as S from '../OperatingPlan/OperatingPlan.styles';
import { Participant, Resources } from 'api/models/Participant.model';
import useRequestManager from 'hooks/useRequestManager';
import { ERequestManager } from 'enums/requestManager';
import { compact, isEmpty } from 'lodash';
import { Category } from 'services/StructuredLogging/categories';
import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { initialValues, resourcesColumnDefs } from './constants';
import {
  fetchResourcesFromParticipant,
  fetchResourcesWithSinkAndSources,
  resourceUpsert,
  selectFullResourcesByUnit,
  sourceSinkAssociationUpsert,
} from 'redux/states/resources.state';
import RootStore, { AppDispatch, AppStore } from 'redux/store';
import useUnitsTab from 'hooks/useUnitsTab';
import { FULFILLED } from 'redux/constants';
import useSSE, { AutoTraderEvent } from 'hooks/useSSE';

const ResourcesList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { resourcesStatus, sourcesSinksStatus } = useSelector(
    (store: AppStore) => store.resource
  );
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);

  const { selectedUnitTypeTab, handleOnChangeUnitTypeTab } = useUnitsTab();

  const { resourcesPageResources, resourcesWrapperResources } = styles;

  const [sourceUri, setSourceUri] = useState<string | undefined>(undefined);
  const [additions, setAdditions] = useState<IAdditions>({
    key: '',
    additionsIds: [],
  });
  const sseInit = useMemo(() => {
    return {
      event: 'MarketParticipantUpdatedEvent',
      type: EventType.AUTOTRADER,
      sourceURL: sourceUri,
    };
  }, [sourceUri]);
  const { data } = useSSE(sseInit);

  const { pushNotification, logEvent } = useAppContext();
  const {
    enqueue,
    execute,
    result,
    status: requestManagerStatus,
  } = useRequestManager();

  const {
    enqueue: enqueueAssociate,
    execute: executeAssociate,
    result: resultAssociate,
    status: requestManagerStatusAssociate,
  } = useRequestManager();
  const queueHook = useQueue<PowerResource, 'resourceIdentifier'>(
    'resourceIdentifier'
  );
  const initialValue = initialValues(selectedUnitTypeTab.key);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [loading, setLoading] = useState<boolean>(true);
  const [editingMode, setEditingMode] = useState<boolean>(true);

  const [oldEditing, setOldEditing] = useState<PowerResource>();
  const [rowData, setRowData] = useState<PowerResource[] | undefined>(
    undefined
  );
  const [associations, setAssociations] = useState<any[]>([]);

  const [changedData, setChangedData] = useState<PowerResource[] | any[]>([]);
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: () => {},
    validate: (values: IResourcesValues) => {
      let errors = resourcesSchema(values);

      if (gridApi) {
        const instances =
          gridApi.getCellEditorInstances() as ICellEditorImperativeHandle[];

        if (instances.length > 0) {
          instances.forEach((instance) => {
            if (typeof instance.validateField === 'function') {
              const field = instance.columnField();
              const errored = new Map(Object.entries(errors)).get(field)?.error;
              instance.validateField(errored);
            }
          });
        }
      }
      return errors;
    },
  });

  const { setInQueue, isInQueue, update, cleanQueue, getQueue } = queueHook;

  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      editable: true,
      cellEditorPopup: false,
    };
  }, []);

  const getResources = useCallback(async () => {
    try {
      if (selectedParticipant && selectedParticipant.id) {
        const resourcesWithSS = selectFullResourcesByUnit(
          RootStore.getState(),
          selectedUnitTypeTab.key
        );
        setTimeout(() => {
          setRowData(resourcesWithSS);
          setLoading(false);
          gridApi?.refreshCells();
          gridApi?.refreshHeader();
        }, 0);
      }
    } catch (error: any) {}
    setEditingMode(false);
  }, [selectedParticipant, selectedUnitTypeTab.key, gridApi]);

  useEffect(() => {
    onClickReset();
    getResources();
    // eslint-disable-next-line
  }, [selectedTimeZone]);

  useEffect(() => {
    if (
      requestManagerStatus === ERequestManager.COMPLETED &&
      pushNotification
    ) {
      const { suceeded, clientErrored, serverErrored } = result;
      const associateResource: Resources[] = [];
      let created = 0;
      let updated = 0;
      if (!isEmpty(suceeded)) {
        suceeded.forEach((record) => {
          const {
            data,
            config: { method },
            duration,
            status,
          }: any = record;
          if (method === 'put') {
            updated = updated + 1;
            dispatch(resourceUpsert(data));
            if (logEvent) {
              logEvent({
                eventTime: new Date(),
                eventName: Event.MODIFIEDRESOURCE,
                payload: { ...data },
                timeToComplete: duration,
                category: Category.RESOURCE_PAGE,
                requestStatus: status,
              });
            }
          }
          if (method === 'post') {
            created = created + 1;
            dispatch(resourceUpsert(data));
            if (logEvent) {
              logEvent({
                eventTime: new Date(),
                eventName: Event.ADDEDRESOURCE,
                payload: { ...data },
                timeToComplete: duration,
                category: Category.RESOURCE_PAGE,
                requestStatus: status,
              });
            }
          }
          const resource: PowerResource = data;
          associateResource.push(
            associations.filter(
              (record) =>
                record.resourceIdentifier === resource.resourceIdentifier
            )[0]
          );
        });
      }
      if (!isEmpty(clientErrored)) {
        clientErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
          } = record;
          pushNotification('error', `${message}`);
        });
      }
      if (!isEmpty(serverErrored)) {
        serverErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
            config: { data },
          } = record;
          const resource: PowerResource = JSON.parse(data);
          if (message.includes('io.ebean.DuplicateKeyException')) {
            associateResource.push(
              associations.filter(
                (record) =>
                  record.resourceIdentifier === resource.resourceIdentifier
              )[0]
            );
          } else {
            pushNotification('error', `${message}`);
          }
        });
      }

      if (created) pushNotification('success', `${created} Rows was created`);
      if (updated) pushNotification('info', `${updated} Rows was updated`);

      if (!isEmpty(associateResource) && selectedParticipant) {
        const resources = selectedParticipant.resources
          ? [...selectedParticipant.resources, ...associateResource]
          : associateResource;
        enqueueAssociate(
          AutoTraderApi.postParticipants({
            ...selectedParticipant,
            resources,
          })
        );
      }
      executeAssociate();
    }
    // eslint-disable-next-line
  }, [requestManagerStatus]);

  useEffect(() => {
    if (
      requestManagerStatusAssociate === ERequestManager.COMPLETED &&
      pushNotification
    ) {
      const { suceeded, clientErrored, serverErrored } = resultAssociate;
      let associatedSuccessful: any[] = [];
      if (!isEmpty(suceeded)) {
        suceeded.forEach((record) => {
          const { data } = record;
          const participant: Participant = data;
          associatedSuccessful = participant.resources.filter(
            (resources) => resources.resourceIdentifier !== undefined
          );
          dispatch(sourceSinkAssociationUpsert(associatedSuccessful));
        });
      }
      if (!isEmpty(clientErrored)) {
        clientErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
          } = record;
          pushNotification('error', `${message}`);
        });
      }
      if (!isEmpty(serverErrored)) {
        serverErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
            // config: { method, data },
          } = record;
          pushNotification('error', `${message}`);
        });
      }
      if (associatedSuccessful.length)
        pushNotification(
          'success',
          `${compact(
            associatedSuccessful.map(
              (association) => association.resourceIdentifier
            )
          ).join(', ')} was associated successfully`
        );
      triggerReset();
    }
    // eslint-disable-next-line
  }, [requestManagerStatusAssociate]);

  const onGridReadyHandler = (gridEvent: GridReadyEvent) => {
    return setGridApi(gridEvent.api);
  };

  const onRowEditingStartedHandler = (e: RowEditingStartedEvent) => {
    const instances =
      gridApi?.getCellEditorInstances() as ICellEditorImperativeHandle[];

    if (!e.data.newRecord) {
      instances.forEach((instance) => {
        if (instance.columnField() === 'resourceIdentifier') {
          if (typeof instance.setEditable === 'function') {
            instance.setEditable(true);
          }
        }
      });
    }

    const {
      resourceIdentifier,
      resourceType,
      startAt,
      stopAt,
      quickStart,
      fuelType,
      cycleType,
      name,
      plant,
      area,
      line,
      category,
      owner,
      source,
      sink,
    } = e.data;

    setOldEditing({
      resourceIdentifier: resourceIdentifier,
      resourceType: resourceType,
      startAt: startAt,
      stopAt: stopAt,
      quickStart: quickStart,
      fuelType: fuelType,
      cycleType: cycleType,
      name: name,
      plant: plant,
      area: area,
      line: line,
      category: category,
      owner: owner,
      source: source,
      sink: sink ? sink : '',
    } as PowerResource);
  };

  const onRowEditingStoppedHandler = (e: RowEditingStoppedEvent) => {
    // post data to API

    const data = e.data as PowerResource;
    const key = data.resourceIdentifier;

    if (JSON.stringify(oldEditing) !== JSON.stringify(data)) {
      if (!data.newRecord) {
        // UPDATE
        if (isInQueue(key)) {
          // IF is already on the queue
          update(key, data, UPDATE);
        } else {
          setInQueue(data, UPDATE);
        }
      } else {
        if (isInQueue(key)) {
          // UPDATE CREATED
          update(key, data, CREATE);
        } else {
          // CREATE
          setInQueue(data, CREATE);
        }
      }
    }
  };

  const toggleEditing = useCallback(
    (enable: boolean) => {
      if (gridApi && !enable) {
        gridApi.stopEditing();
      }
      setEditingMode(enable);
    },
    [gridApi]
  );

  const onClickEnableEditing = () => {
    toggleEditing(true);
  };

  const onClickSaveButton = async () => {
    const queue = getQueue();
    const created = [...queue.filter((element) => element.status === CREATE)];

    if (created.length) {
      created.forEach(({ data }) => {
        const { newRecord, source, sink, ...resourceData } = data;
        let outStartAt: string;
        let outStopAt: string;
        const { startAt, stopAt, ...rest } = removeEmpty(resourceData);

        if (typeof startAt === 'object') {
          const startAtString = startAt as unknown as {
            value: string;
            error: undefined;
            touched: undefined;
          };
          outStartAt = startAtString.value;
        } else {
          outStartAt = startAt;
        }
        if (typeof stopAt === 'object') {
          const stopAtString = stopAt as unknown as {
            value: string;
            error: undefined;
            touched: undefined;
          };
          outStopAt = stopAtString.value;
        } else {
          outStopAt = stopAt;
        }
        const clearOutput = {
          ...rest,
          startAt: outStartAt,
          stopAt: outStopAt,
        };
        enqueue(AutoTraderApi.postResources(clearOutput));
        if (selectedParticipant) {
          associateResource({
            ...data,
            startAt: outStartAt,
            stopAt: outStopAt,
          });
        }
      });
    }

    const updated = [...queue.filter((element) => element.status === UPDATE)];

    if (updated.length) {
      updated.forEach(({ data }) => {
        const { source, sink, ...resourceData } = data;
        enqueue(AutoTraderApi.putResources(removeEmpty(resourceData)));
        if (selectedParticipant) {
          associateResource(data);
        }
      });
    }
    execute();
  };

  const associateResource = (powerResources: PowerResource) => {
    const { source, sink, ...resourceData } = powerResources;
    setAssociations((current) => [
      ...current,
      {
        resourceIdentifier: resourceData.resourceIdentifier,
        startAt: resourceData.startAt,
        stopAt: resourceData.stopAt,
        source: source !== '' ? source : undefined,
        sink: sink !== '' ? sink : undefined,
      },
    ]);
  };

  const onClickReset = useCallback(() => {
    toggleEditing(false);
    cleanQueue();
    setChangedData([]);
    setAdditions({
      key: '',
      additionsIds: [],
    });
    getResources();
    // eslint-disable-next-line
  }, []);

  const onClickAddRow = () => {
    if (gridApi) {
      const {
        resourceIdentifier: { value },
      } = initialValue;
      const addition = {
        key: 'resourceIdentifier',
        additionsIds: [...additions.additionsIds, value],
      };
      setAdditions(addition);

      gridApi.stopEditing();
      gridApi?.applyTransaction({ add: [initialValue], addIndex: 0 });

      toggleEditing(true);
      setChangedData([...changedData, 'add']);
    }
  };

  const triggerReset = () => {
    onClickReset();
    getResources();
  };

  useEffect(() => {
    void triggerReset();
    // eslint-disable-next-line
  }, [selectedUnitTypeTab.key]);

  useEffect(() => {
    if (resourcesStatus === FULFILLED && sourcesSinksStatus === FULFILLED) {
      if (logEvent) {
        logEvent({
          eventTime: new Date(),
          eventName: Event.VIEWEDRESOURCESPAGE,
          category: Category.RESOURCE_PAGE,
        });
      }

      getResources();
    }
  }, [resourcesStatus, sourcesSinksStatus, getResources, logEvent]);

  const fetchResources = useCallback(
    (selectedParticipantId: string) => {
      onClickReset();
      dispatch(fetchResourcesFromParticipant(selectedParticipantId));
      dispatch(fetchResourcesWithSinkAndSources(selectedParticipantId));
    },
    [dispatch, onClickReset]
  );

  useEffect(() => {
    getAuditSource();
    if (selectedParticipant && selectedParticipant.id) {
      fetchResources(selectedParticipant.id);
    }
  }, [selectedParticipant, fetchResources]);

  useEffect(() => {
    if (data) {
      const { targets } = data as AutoTraderEvent;
      if (targets.length) {
        const participantId = targets[0];
        if (participantId === selectedParticipant.id) {
          fetchResources(selectedParticipant.id);
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  const getAuditSource = async () => {
    const auditUri = await AutoTraderApi.getAutoTraderSSEURL();
    setSourceUri(auditUri);
  };

  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <S.PageTitle data-testid='title'>Resources</S.PageTitle>
        <FolioSkeleton
          fullHeight
          canSave
          additions={additions}
          context={{
            formik: formik,
            queueHook: queueHook,
          }}
          toolExtra={
            <ResourcesTab
              tab={selectedUnitTypeTab.key}
              handleChange={handleOnChangeUnitTypeTab}
            />
          }
          canEdit
          canAdd={selectedParticipant !== undefined}
          onRowEditingStopped={onRowEditingStoppedHandler}
          onRowEditingStarted={onRowEditingStartedHandler}
          onClickSaveButton={onClickSaveButton}
          onClickAddRow={onClickAddRow}
          onClickReset={onClickReset}
          onClickEnableEditing={onClickEnableEditing}
          defaultDefs={defaultColDef}
          loading={loading}
          onGridReady={onGridReadyHandler}
          editMode={editingMode}
          components={{
            CellRender: CellRender,
            SourceSinkRender: SourceSinkRender,
          }}
          columnDefs={resourcesColumnDefs}
          data={rowData}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default ResourcesList;
