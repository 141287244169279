import {
  Dictionary,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { Resources } from 'api/models/Participant.model';
import { PowerResource } from 'api/models/PowerResource.model';
import { keyBy, pickBy } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import { AppStore } from 'redux/store';

const STORE_NAME = 'resouce';

const resourceAdapter = createEntityAdapter<PowerResource>({
  selectId: (resource) => resource.resourceIdentifier,
  sortComparer: (a, b) => a.resourceType.localeCompare(b.resourceType),
});

const initialState = resourceAdapter.getInitialState({
  resourcesStatus: IDLE,
  sourcesSinksStatus: IDLE,
  sourcesSinks: {} as Dictionary<Resources>,
});

export const fetchResourcesFromParticipant = createAsyncThunk(
  `${STORE_NAME}/fetchResourcesFromParticipant`,
  async (participantId: string) => {
    const { status, data } = await AutoTraderApi.getResourcesFromParticipant(
      participantId
    );
    if (status >= 200 && status <= 400) {
      return data as PowerResource[];
    } else {
      return [];
    }
  }
);

export const fetchResourcesWithSinkAndSources = createAsyncThunk(
  `${STORE_NAME}/fetchResourcesWithSinkAndSources`,
  async (participantId: string) => {
    const response =
      await AutoTraderApi.getResourcesWithSinkAndSourcesByParticipant(
        participantId
      );

    return keyBy(response, 'resourceIdentifier');
  }
);

export const resourceSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    resourceUpsert: (state, action) => {
      resourceAdapter.upsertOne(state, action.payload);
      state.resourcesStatus = FULFILLED;
    },
    sourceSinkAssociationUpsert: (state, action) => {
      const association = keyBy(action.payload, 'resourceIdentifier');

      state.sourcesSinks = { ...state.sourcesSinks, ...association };
      state.resourcesStatus = FULFILLED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchResourcesFromParticipant.pending, (state) => {
        state.resourcesStatus = LOADING;
      })
      .addCase(fetchResourcesFromParticipant.fulfilled, (state, action) => {
        resourceAdapter.setAll(state, action.payload);
        state.resourcesStatus = FULFILLED;
      })
      .addCase(fetchResourcesWithSinkAndSources.pending, (state) => {
        state.sourcesSinksStatus = LOADING;
      })
      .addCase(fetchResourcesWithSinkAndSources.fulfilled, (state, action) => {
        state.sourcesSinks = action.payload;
        state.sourcesSinksStatus = FULFILLED;
      });
  },
});

export const { resourceUpsert, sourceSinkAssociationUpsert } =
  resourceSlice.actions;

const selectResources = (state: AppStore) => state.resource.entities;

const selectSourcesSinksByResource = (
  state: AppStore,
  resourceIdentifier: string
) => state.resource.sourcesSinks[resourceIdentifier];

export const selectResourcesEntitiesByUnit = (
  state: AppStore,
  selectedUnit: string
) => {
  return pickBy<PowerResource>(
    selectResources(state) as any,
    (resource) => resource.resourceType === selectedUnit
  );
};

export const selectFullResourcesByUnit = (
  state: AppStore,
  selectedUnit: string
) => {
  const resourceDictionary = pickBy<PowerResource>(
    selectResources(state) as any,
    (resource) => resource.resourceType === selectedUnit
  );

  return Object.entries(resourceDictionary).map(
    ([resourceIdentifier, resource]) => {
      const { source, sink } = selectSourcesSinksByResource(
        state,
        resourceIdentifier
      ) as Resources;
      return { ...resource, source, sink };
    }
  );
};

export default resourceSlice.reducer;
