import * as am4core from '@amcharts/amcharts4/core';
import { PCI_STANDARD_SPACING } from '@pci/pci-ui-library';
import { ETheme } from 'enums/styles';

export const DEFAULT_THEME: ETheme = ETheme.Light;

// Note: These PCI_ prefixed constants are taken from the underlying
// pci-ui-library values. In the long term, these should be removed and the
// values should be directly imported from pci-ui-library. As of this writing
// 2021-09-22, those values are not exposed in the pci-ui-library yet.
export const PCI_TEXT_COLOUR_DARK = 'rgba(255, 255, 255, 0.65)';
export const PCI_TEXT_COLOUR_LIGHT = 'rgba(0, 0, 0, 0.85)';

export const BLACK_COLOUR = '#000000';
export const WHITE_COLOUR = '#ffffff';
export const DEFAULT_FONT_COLOR = '#0E0E2C';

export const HIGHLIGHT_COLOUR = '#188fff';
export const ERROR_COLOR = '#ff4d4f';
export const FAILURE_COLOR = ERROR_COLOR;
export const SUCCESS_COLOUR = '#7fcfd7';
export const WARNING_COLOUR = '#faad14';
export const ROW_HIGHLIGHT_COLOUR = '#85c4ff';

export const BID_COLOUR_DARK = '#2ad062';
export const BID_COLOUR_LIGHT = '#2ad062';
export const OFFER_COLOUR_DARK = '#384bd9';
export const OFFER_COLOUR_LIGHT = '#384bd9';
export const CENTRAL_COLOUR = '#69798C';

export const BID_STRATEGY_FRONT_COLOUR_DARK = '#c26223';
export const BID_STRATEGY_FRONT_COLOUR_LIGHT = '#c26223';
export const OFFER_STRATEGY_FRONT_COLOUR_DARK = '#213955';
export const OFFER_STRATEGY_FRONT_COLOUR_LIGHT = '#531dab';
export const BID_STRATEGY_BACK_COLOUR_DARK = '#87cdff';
export const BID_STRATEGY_BACK_COLOUR_LIGHT = '#87cdff';
export const OFFER_STRATEGY_BACK_COLOUR_DARK = '#87cdff';
export const OFFER_STRATEGY_BACK_COLOUR_LIGHT = '#87cdff';

export const EDITABLE_BACKGROUND_DARK = 'rgba(0, 0, 0, 0.31)';
export const EDITABLE_BACKGROUND_LIGHT = 'rgba(255, 255, 255, 0.71)';

export const SELECTED_EDITED_ROW_COLOUR = '#c19e7b';
export const SELECTED_ROW_COLOUR = '#72a5f8';
export const EDITED_ROW_COLOUR = '#e89a3c';
export const ADDED_ROW_COLOUR = '#e0529c';

export const NOTIFICATION_INFO_COLOUR = '#009700';
export const NOTIFICATION_WARNING_COLOUR = '#e76f14';
export const NOTIFICATION_ERROR_COLOUR = '#de1314';

// am chart colors
export const PCI_TEXT_COLOR_DARK = am4core.color(PCI_TEXT_COLOUR_DARK);
export const PCI_TEXT_COLOR_LIGHT = am4core.color(PCI_TEXT_COLOUR_LIGHT);

export const INPUT_HEIGHT_VALUE = 28;
export const ICON_BUTTON_SIZE_VALUE = INPUT_HEIGHT_VALUE;
export const BUTTON_ICON_DIMENSION_VALUE = ICON_BUTTON_SIZE_VALUE / 2;
export const CELL_PADDING_VALUE = 2;
export const HEADER_CELL_MIN_HEIGHT_VALUE = 58 + CELL_PADDING_VALUE * 2;

export const COLUMN_STICKY_LEFT_OFFSET_Z_INDEX = 200;
export const COLUMN_STICKY_RIGHT_OFFSET_Z_INDEX = 400;
export const INPUT_EXPAND_OVER_Z_INDEX = 500;

export const SEEM_NOTIFICATION_WIDTH_VALUE = 346;

export const ERROR_MESSAGE_MAX_WIDTH = '238px';
export const ERROR_MESSAGE_MIN_HEIGHT = '20px';

export const TAB_BAR_HEIGHT_VALUE = 44;
export const TAB_BAR_HEIGHT = `${TAB_BAR_HEIGHT_VALUE}px`;

export const TAB_LAYOUT_CALC_HEIGHT = `100vh - ${PCI_STANDARD_SPACING} - ${TAB_BAR_HEIGHT} - ${PCI_STANDARD_SPACING} - ${PCI_STANDARD_SPACING}`;

export const BUTTON_ICON_DIMENSIONS = `
  height: ${BUTTON_ICON_DIMENSION_VALUE}px;
  width: ${BUTTON_ICON_DIMENSION_VALUE}px;
`;

export const LABEL_HEIGHT = '26px';
export const LABEL_STYLES = `
  font-size: 16px;
  height: ${LABEL_HEIGHT};
  white-space: nowrap;
`;

export const CONTENT_HEADER_CELL_CLASS_NAME = 'ant-tag-geekblue';

export const PURCHASE_ROW_CLASS_NAME = 'ant-tag-red';
export const SALE_ROW_CLASS_NAME = 'ant-tag-green';
export const OFFER_HEADER_CELL_CLASS_NAME = 'ant-tag-purple';
export const BID_HEADER_CELL_CLASS_NAME = 'ant-tag-orange';
