import App from 'App/App';
import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import './lang/i18n';
import './scss/app.scss';
import { ThemeProvider, createTheme } from '@mui/material';

const THEME = createTheme({
  palette: {
    primary: {
      main: '#213955',
    },
  },
  typography: { fontFamily: 'Lato', fontSize: 14 },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={THEME}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
