export enum EActionState {
  NoAction = 'NO_ACTION',
  Actioning = 'ACTIONING',
  Failed = 'FAILED',
  Succeeded = 'SUCCEEDED',
}

export enum EAPIState {
  Error = 'error',
  Fulfilled = 'fulfilled',
  Idle = 'idle',
  Pending = 'pending',
}

export enum SourceSink {
  SOURCE = 'SOURCE',
  SINK = 'SINK',
  SOURCE_SINK = 'SOURCE_SINK',
}

export enum EOrientation {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}
