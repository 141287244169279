import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { IIntervalPreference } from 'api/models/User.model';
import { IFilter } from 'components/molecules/RangeFilterTab/constants';
import usePreferences from 'hooks/usePreferences';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  intervals: IFilter[];
  handleIntervalSelected: (event: SelectChangeEvent<IFilter | string>) => void;
  selectedInterval: IFilter | undefined;
  inputTitle?: string;
}

export default function IntervalSelector({
  handleIntervalSelected,
  intervals,
  selectedInterval,
  inputTitle = 'Interval:',
}: Props) {
  const { intervalPreferences, setPreference } = usePreferences();
  const { pathname } = useLocation();
  useEffect(() => {
    if (selectedInterval) {
      const intervalPreference = intervalPreferences?.find(
        (interval) => interval.page === pathname
      );
      if (intervalPreference) {
        const intervalPreference: IIntervalPreference = {
          intervalType: 'BASIC',
          interval: selectedInterval.key,
          page: pathname,
        };
        const filteredIntervals = intervalPreferences?.filter(
          (interval) => interval.page !== pathname
        );
        filteredIntervals?.push(intervalPreference);
        setPreference({
          intervalPreference: filteredIntervals,
        });
      } else {
        const notDefinedUnitPreference: IIntervalPreference = {
          intervalType: 'BASIC',
          interval: selectedInterval.key,
          page: pathname,
        };
        setPreference({ intervalPreference: [notDefinedUnitPreference] });
      }
    }

    // eslint-disable-next-line
  }, [selectedInterval]);
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id='interval-input'>{inputTitle}</InputLabel>
      <Select
        labelId='interval-selector'
        id='interval-selector'
        value={selectedInterval ? selectedInterval.key : ''}
        label='Interval'
        onChange={handleIntervalSelected}
      >
        {Array.isArray(intervals) &&
          intervals.map((interval, index) => (
            <MenuItem key={index} value={interval.key}>
              {interval.fullDescription}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
