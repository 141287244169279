import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TextField } from '@mui/material';

import * as S from './PercentEditor.styles';
import { ICellEditorImperativeHandle } from 'interfaces/dataGrid';

type TRef = {};

type TProps = {
  [field: string]: any;
};

const PercentEditor = React.forwardRef<TRef, TProps>((props, ref) => {
  const {
    context: { formik },
    eGridCell: { offsetHeight },
  } = props;

  const [value, setValue] = useState('');
  const [edition, setEdition] = useState<string | null>(null);
  const [field, setField] = useState(props.colDef.field);
  const [error, setError] = useState<string | null>();
  const [editable, setEditable] = useState<boolean>(false);

  const refInput = useRef<React.RefObject<HTMLInputElement>>(null);

  useEffect(() => {
    if (typeof props.value === 'string') {
      setValue(props.value);
    } else if (props.value && typeof props.value === 'object') {
      setValue(props.value.value);
    } else {
      setValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.current?.focus();
    }
    setField(props.colDef.field);
  }, [props.colDef.field]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, (): ICellEditorImperativeHandle => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return edition ? (parseInt(edition) / 100).toString() : value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any cell that has an error
        return !!error;
      },

      columnField: () => field,
      validateField: (error) => setError(error),
      setEditable: (editable) => setEditable(editable),
      setValue: (value) => setValue(value),
    };
  });

  return (
    <S.PercentEditor cellHeight={offsetHeight}>
      <TextField
        error={!!error}
        disabled={editable}
        inputRef={refInput}
        helperText={error}
        id={`TextField-${props.colDef.field}`}
        value={`${edition === null ? parseFloat(value) * 100 : edition}`}
        onChange={(e) => {
          const fieldName = props.colDef.field;
          const fieldValues = formik.values[props.colDef.field];
          if (fieldName) {
            formik.setFieldValue(fieldName, {
              ...fieldValues,
              value: e.target.value,
            });
          }
          setEdition(e.target.value);
        }}
      />
    </S.PercentEditor>
  );
});

export default PercentEditor;
