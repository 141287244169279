import DataGrid, { IDataGrid } from 'components/molecules/DataGrid/DataGrid';
import { currencyInDollarsFormatter } from 'utils/general';
import { bidsOffersColumnDef } from './helpers';
import { EGridType } from 'enums/bidOffer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSessionContext } from 'pages/admin/Trade/context';
import { IBids, IBidsOffersFiltered, IOffers } from 'interfaces/bidOffer';
import { AgGridReact } from 'ag-grid-react';

import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppStore } from 'redux/store';
import { fetchTradeStack } from 'redux/states/bidsoffers.state';
import { FULFILLED } from 'redux/constants';

interface IDashboardGrid extends IDataGrid {
  type?: EGridType;
}

const DashboardGrid = ({
  type = EGridType.Bids,
  defaultColDef,
  ...props
}: IDashboardGrid) => {
  const { sessionObj } = useSessionContext();
  const dispatch = useDispatch<AppDispatch>();
  const { tradeStack, tradeStackStatus } = useSelector(
    (store: AppStore) => store.bidsoffers
  );
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const [bidsOffers, setBidsOffers] = useState<IBidsOffersFiltered>();
  const [loadingBidsOffers, setLoadingBidsOffers] = useState<boolean>(true);
  const [statusMessage, setStatusMessage] = useState('No records found');
  const gridRef = useRef<AgGridReact>(null);

  const getBidsOffers = useCallback(() => {
    try {
      if (tradeStack) {
        if (tradeStack.length >= 1) {
          let bidList = tradeStack[0].bids.map((bid) => {
            const newBid: IBids = Object.assign({
              resourceIdentifier: bid.resourceIdentifier,
              planQuantity: bid.planQuantity,
              planCost: bid.planCost,
              tradeQuantity: bid.tradeQuantity,
              tradePrice: bid.tradePrice,
              location: bid.location,
            });
            return newBid;
          });

          let offerList = tradeStack[0].offers.map((offer) => {
            const newOffer: IOffers = Object.assign({
              resourceIdentifier: offer.resourceIdentifier,
              planQuantity: offer.planQuantity,
              planCost: offer.planCost,
              tradeQuantity: offer.tradeQuantity,
              tradePrice: offer.tradePrice,
              location: offer.location,
            });
            return newOffer;
          });

          const mergedBidsOffers: IBidsOffersFiltered = Object.assign({
            bids: bidList,
            offers: offerList,
          });

          setStatusMessage(tradeStack[0].statusMessage);
          setBidsOffers(mergedBidsOffers);
        } else {
          setStatusMessage('No records found');
          setBidsOffers(undefined);
        }
      } else {
        setStatusMessage('No records found');
        setBidsOffers(undefined);
      }
      setLoadingBidsOffers(false);
    } catch (error: any) {
      // pushNotification('error', `Error has occured retrieving bids and offers list`);
      setLoadingBidsOffers(false);
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.refreshCells();
        gridRef.current.api.refreshHeader();
      }
    }
  }, [tradeStack]);

  useEffect(() => {
    if (tradeStackStatus === FULFILLED) {
      getBidsOffers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeStackStatus]);

  const fetchTrades = useCallback(() => {
    setLoadingBidsOffers(true);
    if (selectedParticipant?.id) {
      const request = {
        marketParticipantId: selectedParticipant.id,
        startAt: sessionObj.toIsoString().replace('+', '-'),
      };
      dispatch(fetchTradeStack(request));
    }
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells();
      gridRef.current.api.refreshHeader();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipant?.id, sessionObj, selectedTimeZone]);

  useEffect(() => {
    if (selectedParticipant?.id) {
      setBidsOffers(undefined);
      fetchTrades();
    }
  }, [selectedParticipant?.id, selectedTimeZone, fetchTrades, setBidsOffers]);

  const customDefaultColDef = {
    editable: false,
    sortable: true,
    menuTabs: [],
    wrapText: true,
    filterable: false,
    floatingFilter: false,
    flex: 1,
    autoHeight: true,
    ...defaultColDef,
  };

  const columnDefVariation =
    type === EGridType.Offers
      ? bidsOffersColumnDef.offers
      : bidsOffersColumnDef.bids;

  const columnDefs = [
    {
      headerName: 'Resource',
      cellClass: 'ag-header-cell-aligned-left',
      field: 'resourceIdentifier',
      filter: 'agTextColumnFilter',
      minWidth: 80,
    },
    {
      filter: 'agTextColumnFilter',
      minWidth: 160,
      maxWidth: 160,
      ...columnDefVariation,
    },
    {
      headerName: 'Plan Quantity',
      cellClass: 'ag-cell-aligned-center',
      headerClass: 'ag-header-cell-aligned-center',
      field: 'planQuantity',
      filter: 'agNumberColumnFilter',
      width: 130,
      maxWidth: 130,
    },
    {
      headerName: 'Plan Cost',
      cellClass: 'ag-cell-aligned-center',
      headerClass: 'ag-header-cell-aligned-center',
      field: 'planCost',
      filter: 'agNumberColumnFilter',
      minWidth: 130,
      valueFormatter: currencyInDollarsFormatter,
    },
    {
      headerName: 'Trade Quantity',
      cellClass: 'ag-cell-aligned-center',
      headerClass: 'ag-header-cell-aligned-center',
      field: 'tradeQuantity',
      filter: 'agNumberColumnFilter',
      width: 130,
      maxWidth: 130,
    },
    {
      headerName: 'Trade Price',
      cellClass: 'ag-cell-aligned-center',
      headerClass: 'ag-header-cell-aligned-center',
      field: 'tradePrice',
      filter: 'agNumberColumnFilter',
      minWidth: 130,
      valueFormatter: currencyInDollarsFormatter,
    },
  ];

  return (
    <DataGrid
      ref={gridRef}
      data={bidsOffers?.[type] || []}
      columnDefs={columnDefs}
      defaultColDef={customDefaultColDef}
      isLoading={loadingBidsOffers}
      overlayNoRowsTemplate={statusMessage}
      pagination={true}
      paginationAutoPageSize={true}
      {...props}
    />
  );
};

export default DashboardGrid;
