import {
  CellValueChangedEvent,
  GridApi,
  GridReadyEvent,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  TextCellEditor,
} from 'ag-grid-community';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { useAppContext } from 'App/AppProvider';
import { AxiosError } from 'axios';
import CellRender from 'components/molecules/CellRender/CellRender';
import { useFormik } from 'formik';
import useQueue, { CREATE, DELETE, UPDATE } from 'hooks/useQueue';
import { IAdditions, ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import { memo, useCallback, useEffect, useState } from 'react';
import FolioSkeleton from '../GridSkeleton/GridSkeleton';
import { tradeStrategySchema } from './tradeStrategySchema';
import styles from '../ResourcesList/ResourcesList.module.scss';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { ITradeStrategyValues } from './ITradeStrategyValues';
import './tradeStrategy.scss';
import { ITradeStrategy } from 'api/models/TradeStrategy.model';
import { useSnackbar, VariantType } from 'notistack';
import { isEmpty } from 'lodash';
import { IIndexable } from 'interfaces/general';
import { GENERATING_UNIT } from '../AddResources/constant';
import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';
import {
  PARTICIPANT,
  RESOURCE,
} from 'components/molecules/RangeFilterTab/constants';
import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import * as S from 'components/admin/OperatingPlan/OperatingPlan.styles';
import { pickSelectedParticipant } from 'redux/states/miscellaneous.state';

import { columnsDef, initialNewRowValue } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import RootStore, { AppDispatch, AppStore } from 'redux/store';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import {
  fetchResourcesFromParticipant,
  selectResourcesEntitiesByUnit,
} from 'redux/states/resources.state';
import {
  assignResource,
  fetchParticipantById,
} from 'redux/states/participants.state';
import { Participant, Resources } from 'api/models/Participant.model';

interface ITradeStrategyQueue {
  data: ITradeStrategy;
  status: string;
}

type TPushNotification = (variant: VariantType, message: string) => void;

const TradeStrategyPage = () => {
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const { resourcesStatus } = useSelector((store: AppStore) => store.resource);
  const [additions, setAdditions] = useState<IAdditions>({
    key: '',
    additionsIds: [],
  });
  const { status: participantStatus } = useSelector(
    (store: AppStore) => store.participant
  );
  const { logEvent } = useAppContext();
  const dispatch = useDispatch<AppDispatch>();

  const [gridApi, setGridApi] = useState<GridApi>();

  const { resourcesPageResources, resourcesWrapperResources, toolBarOptions } =
    styles;

  const { enqueueSnackbar } = useSnackbar();

  const [editingMode, setEditingMode] = useState<boolean>(false);
  const [colDef, setColDef] = useState<any[]>([]);
  const [oldEditing, setOldEditing] = useState<ITradeStrategy>();
  const [rowData, setRowData] = useState<ITradeStrategy[] | undefined>(
    undefined
  );

  const { handleRangeFilterChange, selectedRangeFilterTab } =
    useRangeFilterTabs(PARTICIPANT);

  const getParticipantHeaderName = useCallback(() => {
    return selectedRangeFilterTab &&
      selectedRangeFilterTab.key === PARTICIPANT.key
      ? 'Market Participant'
      : 'Resource Identifier';
  }, [selectedRangeFilterTab]);

  const [newRowValue, setNewRowValue] = useState(initialNewRowValue);

  const formik = useFormik({
    initialValues: initialNewRowValue,
    onSubmit: () => {},
    validate: (values: ITradeStrategyValues) => {
      let errors = tradeStrategySchema(values);

      if (gridApi) {
        const instances =
          gridApi.getCellEditorInstances() as ICellEditorImperativeHandle[];

        if (instances.length > 0) {
          instances.forEach((instance) => {
            if (typeof instance.validateField === 'function') {
              const field = instance.columnField();
              const errored = new Map(Object.entries(errors)).get(field)?.error;
              instance.validateField(errored);
            }
          });
        }
      }
      return errors;
    },
  });

  const queueHook = useQueue<ITradeStrategy, 'resourceIdentifier'>(
    'resourceIdentifier'
  );

  const handleCreateUpdateDeleteTradeStrategy = useCallback((data: any) => {
    AutoTraderApi.postParticipants.bind(AutoTraderApi);
    return AutoTraderApi.postParticipants(data);
  }, []);

  useEffect(() => {
    const pickSelectedEntities = selectResourcesEntitiesByUnit(
      RootStore.getState(),
      GENERATING_UNIT
    );
    if (resourcesStatus === FULFILLED && !isEmpty(pickSelectedEntities)) {
      const resourcesIdentifiers = Object.keys(pickSelectedEntities);
      const sortResources = (a: string, b: string) => a.localeCompare(b);

      const newColDef = [...columnsDef(getParticipantHeaderName)];
      setColDef(() => {
        if (newColDef[0].cellEditorParams?.options) {
          const resources = [
            ...resourcesIdentifiers
              .sort(sortResources)
              .map((resourceIdentifier: string) => ({
                value: resourceIdentifier,
                label: resourceIdentifier,
              })),
          ];

          const transformed = [
            ...resources,
            {
              value: selectedParticipant?.name,
              label: selectedParticipant?.name,
            },
          ];

          newColDef[0].cellEditorParams.options = transformed;
          newColDef[0].headerName = getParticipantHeaderName();
          setNewRowValue((oldRowValue) => {
            oldRowValue.resourceIdentifier = resources?.[0] as any;
            return oldRowValue;
          });
        }
        return newColDef;
      });
    }
    // eslint-disable-next-line
  }, [resourcesStatus, setColDef, getParticipantHeaderName]);

  useEffect(() => {
    if (gridApi && colDef) gridApi.setColumnDefs(colDef);
  }, [colDef, gridApi]);

  const evaluateBidsOffers = (maxQTYAllowed: number) => {
    return isNaN(maxQTYAllowed) || maxQTYAllowed ? true : false;
  };

  const loadStrategy = useCallback(
    (participant: Participant) => {
      if (participant.resources) {
        const strategyObj: any = participant.resources
          .filter((item: any) => item.strategy!)
          .map((resource: any) => {
            return {
              resourceIdentifier: resource.resourceIdentifier,
              isBid: evaluateBidsOffers(
                resource.strategy?.bidStrategy.maximumQuantityAllowed
              ),
              isOffer: evaluateBidsOffers(
                resource.strategy?.offerStrategy.maximumQuantityAllowed
              ),
              offerMaximumQuantityAllowed:
                resource.strategy?.offerStrategy.maximumQuantityAllowed?.toString() ||
                null,
              offerQuantityWithheldFromFront:
                resource.strategy?.offerStrategy.quantityWithheldFromFront.toString() ||
                null,
              offerQuantityWithheldFromBack:
                resource.strategy?.offerStrategy.quantityWithheldFromBack?.toString() ||
                null,
              offerFloorPricedAtFrontQuantity:
                resource.strategy?.offerStrategy.floorPricedAtFrontQuantity?.toString() ||
                null,
              offerPremium:
                resource.strategy?.offerStrategy.premium?.toString() || null,
              offerMinPremiumPercent:
                resource.strategy?.offerStrategy.minPremiumPercent?.toString() ||
                null,
              offerMaxPremiumPercent:
                resource.strategy?.offerStrategy.maxPremiumPercent?.toString() ||
                null,
              bidMaximumQuantityAllowed:
                resource.strategy?.bidStrategy.maximumQuantityAllowed?.toString() ||
                null,
              bidQuantityWithheldFromFront:
                resource.strategy?.bidStrategy.quantityWithheldFromFront?.toString() ||
                null,
              bidQuantityWithheldFromBack:
                resource.strategy?.bidStrategy.quantityWithheldFromBack?.toString() ||
                null,
              bidCeilingPricedAtFrontQuantity:
                resource.strategy?.bidStrategy.ceilingPricedAtFrontQuantity?.toString() ||
                null,
              bidDiscount:
                resource.strategy?.bidStrategy.discount?.toString() || null,
              bidMinDiscountPercent:
                resource.strategy?.bidStrategy.minDiscountPercent?.toString() ||
                null,
              bidMaxDiscountPercent:
                resource.strategy?.bidStrategy.maxDiscountPercent?.toString() ||
                null,
            };
          });
        const participantStrategyObj = [
          {
            resourceIdentifier: participant?.name,
            isBid: evaluateBidsOffers(
              participant.strategy?.bidStrategy.maximumQuantityAllowed as number
            ),
            isOffer: evaluateBidsOffers(
              participant.strategy?.offerStrategy
                .maximumQuantityAllowed as number
            ),
            offerMaximumQuantityAllowed:
              participant.strategy?.offerStrategy.maximumQuantityAllowed?.toString() ||
              null,
            offerQuantityWithheldFromFront:
              participant.strategy?.offerStrategy.quantityWithheldFromFront.toString() ||
              null,
            offerQuantityWithheldFromBack:
              participant.strategy?.offerStrategy.quantityWithheldFromBack?.toString() ||
              null,
            offerFloorPricedAtFrontQuantity:
              participant.strategy?.offerStrategy.floorPricedAtFrontQuantity?.toString() ||
              null,
            offerPremium:
              participant.strategy?.offerStrategy.premium?.toString() || null,
            offerMinPremiumPercent:
              participant.strategy?.offerStrategy.minPremiumPercent?.toString() ||
              null,
            offerMaxPremiumPercent:
              participant.strategy?.offerStrategy.maxPremiumPercent?.toString() ||
              null,
            bidMaximumQuantityAllowed:
              participant.strategy?.bidStrategy.maximumQuantityAllowed?.toString() ||
              null,
            bidQuantityWithheldFromFront:
              participant.strategy?.bidStrategy.quantityWithheldFromFront?.toString() ||
              null,
            bidQuantityWithheldFromBack:
              participant.strategy?.bidStrategy.quantityWithheldFromBack?.toString() ||
              null,
            bidCeilingPricedAtFrontQuantity:
              participant.strategy?.bidStrategy.ceilingPricedAtFrontQuantity?.toString() ||
              null,
            bidDiscount:
              participant.strategy?.bidStrategy.discount?.toString() || null,
            bidMinDiscountPercent:
              participant.strategy?.bidStrategy.minDiscountPercent?.toString() ||
              null,
            bidMaxDiscountPercent:
              participant.strategy?.bidStrategy.maxDiscountPercent?.toString() ||
              null,
          },
        ];
        let transformed = undefined;
        switch (selectedRangeFilterTab.key) {
          case PARTICIPANT.key: {
            transformed = [...participantStrategyObj];
            break;
          }
          case RESOURCE.key: {
            transformed = [...strategyObj];
            break;
          }
          default:
            break;
        }
        setRowData(isEmpty(transformed) ? undefined : transformed);
      } else {
        setRowData(undefined);
      }
    },
    [setRowData, selectedRangeFilterTab.key]
  );

  useEffect(() => {
    if (selectedParticipant?.resources) {
      loadStrategy(selectedParticipant);
    }
  }, [selectedParticipant, selectedParticipant?.resources, loadStrategy]);

  useEffect(() => {
    if (selectedParticipant?.id) {
      if (
        participantStatus === FULFILLED &&
        selectedParticipant?.resources === undefined
      ) {
        dispatch(fetchParticipantById(selectedParticipant?.id));
        if (resourcesStatus === IDLE) {
          dispatch(fetchResourcesFromParticipant(selectedParticipant?.id));
        }
      }
      if (selectedParticipant.resources !== undefined) {
        dispatch(fetchResourcesFromParticipant(selectedParticipant?.id));
        dispatch(fetchParticipantById(selectedParticipant?.id));
      }
      loadStrategy(selectedParticipant);
    }
    // eslint-disable-next-line
  }, [selectedParticipant?.id, dispatch, loadStrategy]);

  const onGridReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(70);
    gridEvent.api.setGroupHeaderHeight(30);
    gridEvent.api.sizeColumnsToFit();
    return setGridApi(gridEvent.api);
  };

  const onRowEditingStartedHandler = (e: RowEditingStartedEvent) => {
    const instances =
      gridApi?.getCellEditorInstances() as ICellEditorImperativeHandle[];

    if (e.data.newRecord) {
      formik.setFieldValue('newRecord', true);
    } else {
      instances.forEach((instance) => {
        if (instance instanceof TextCellEditor) {
          return;
        }
        const isResourceIdentifier =
          instance.columnField() === 'resourceIdentifier';
        if (
          isResourceIdentifier &&
          typeof instance.setEditable === 'function'
        ) {
          instance.setEditable(true);
        }
      });
      formik.setFieldValue('newRecord', false);
    }
    setOldEditing({ ...e.data });
  };

  const onRowEditingStoppedHandler = (e: RowEditingStoppedEvent) => {
    const { isInQueue, update, setInQueue } = queueHook;
    // post data to API
    const data = e.data as ITradeStrategy;
    const key = data.resourceIdentifier;

    if (JSON.stringify(oldEditing) !== JSON.stringify(data)) {
      if (!data.newRecord) {
        // UPDATE
        if (isInQueue(key)) {
          // IF is already on the queue
          update(key, data, UPDATE);
        } else {
          setInQueue(data, UPDATE);
        }
      } else {
        if (isInQueue(key)) {
          // UPDATE CREATED
          update(key, data, CREATE);
        } else {
          // CREATE
          setInQueue(data, CREATE);
        }
      }
    }
  };

  const toggleEditing = useCallback(
    (enable: boolean) => {
      if (gridApi && !enable) {
        gridApi.stopEditing();
        // gridApi.setRowData([]);
      }
      setEditingMode(enable);
    },
    [gridApi]
  );

  const addRowOnClickHandler = useCallback(() => {
    if (gridApi) {
      const rowValues = { ...newRowValue };
      const {
        id: { value },
      } = rowValues;
      const addition = {
        key: 'id',
        additionsIds: [...additions.additionsIds, value],
      };
      setAdditions(addition);

      gridApi.stopEditing();
      gridApi?.applyTransaction({
        add: [rowValues],
        addIndex: 0,
      });

      toggleEditing(true);
      gridApi?.startEditingCell({
        rowIndex: 0,
        colKey: 'resourceIdentifier',
      });
    }
    // eslint-disable-next-line
  }, [gridApi, newRowValue]);

  useEffect(() => {
    if (queueHook.getLast()?.status === DELETE) {
      saveChangesOnClickHandler();
      resetGridOnClickHandler();
    }
    // eslint-disable-next-line
  }, [queueHook.getQueue().length]);

  const cleanTradeStrategyQueue = useCallback(() => {
    const { cleanQueue } = queueHook;
    cleanQueue();
  }, [queueHook]);

  const resetGridOnClickHandler = useCallback(() => {
    toggleEditing(false);
    cleanTradeStrategyQueue();
    setAdditions({
      key: '',
      additionsIds: [],
    });
    if (!editingMode) {
      setRowData(undefined);
      if (selectedParticipant?.resources) {
        loadStrategy(selectedParticipant);
      }
    }
    // eslint-disable-next-line
  }, [selectedParticipant?.resources, selectedRangeFilterTab.key, gridApi]);

  const onClickEnableEditing = useCallback(() => {
    toggleEditing(true);
  }, [toggleEditing]);

  const transformParticipantStrategyData = (newData: ITradeStrategyQueue[]) => {
    if (newData && newData.length!) {
      var bidMaximumQuantityAllowed = newData[0].data.bidMaximumQuantityAllowed;
      var offerMaximumQuantityAllowed =
        newData[0].data.offerMaximumQuantityAllowed;
      return {
        bidStrategy: {
          maximumQuantityAllowed: bidMaximumQuantityAllowed,
          quantityWithheldFromFront:
            newData[0].data.bidQuantityWithheldFromFront,
          quantityWithheldFromBack: newData[0].data.bidQuantityWithheldFromBack,
          ceilingPricedAtFrontQuantity:
            newData[0].data.bidCeilingPricedAtFrontQuantity,
          discount: newData[0].data.bidDiscount,
          minDiscountPercent: newData[0].data.bidMinDiscountPercent,
          maxDiscountPercent: newData[0].data.bidMaxDiscountPercent,
        },
        offerStrategy: {
          maximumQuantityAllowed: offerMaximumQuantityAllowed,
          quantityWithheldFromFront:
            newData[0].data.offerQuantityWithheldFromFront,
          quantityWithheldFromBack:
            newData[0].data.offerQuantityWithheldFromBack,
          floorPricedAtFrontQuantity:
            newData[0].data.offerFloorPricedAtFrontQuantity,
          premium: newData[0].data.offerPremium,
          minPremiumPercent: newData[0].data.offerMinPremiumPercent,
          maxPremiumPercent: newData[0].data.offerMaxPremiumPercent,
        },
      };
    }
    return undefined;
  };

  const transformResourcesData = (newItem: ITradeStrategy) => {
    const resource = selectedParticipant.resources?.find(
      (item: any) => item.resourceIdentifier === newItem.resourceIdentifier
    );

    var bidMaximumQuantityAllowed = newItem.bidMaximumQuantityAllowed;
    var offerMaximumQuantityAllowed = newItem.offerMaximumQuantityAllowed;

    const strategyObj = {
      bidStrategy: {
        maximumQuantityAllowed: bidMaximumQuantityAllowed,
        quantityWithheldFromFront: newItem.bidQuantityWithheldFromFront,
        quantityWithheldFromBack: newItem.bidQuantityWithheldFromBack,
        ceilingPricedAtFrontQuantity: newItem.bidCeilingPricedAtFrontQuantity,
        discount: newItem.bidDiscount,
        minDiscountPercent: newItem.bidMinDiscountPercent,
        maxDiscountPercent: newItem.bidMaxDiscountPercent,
      },
      offerStrategy: {
        maximumQuantityAllowed: offerMaximumQuantityAllowed,
        quantityWithheldFromFront: newItem.offerQuantityWithheldFromFront,
        quantityWithheldFromBack: newItem.offerQuantityWithheldFromBack,
        floorPricedAtFrontQuantity: newItem.offerFloorPricedAtFrontQuantity,
        premium: newItem.offerPremium,
        minPremiumPercent: newItem.offerMinPremiumPercent,
        maxPremiumPercent: newItem.offerMaxPremiumPercent,
      },
    };
    return { ...resource, strategy: strategyObj };
  };

  const saveCreatedTradeStrategy = async (
    tradeStrategyToSave: ITradeStrategy[] | IIndexable,
    pushNotification: TPushNotification
  ) => {
    try {
      const transformedData = tradeStrategyToSave
        .filter(
          (item: ITradeStrategyQueue) =>
            item.data.resourceIdentifier !== selectedParticipant?.name
        )
        .map((newItem: ITradeStrategyQueue) => {
          return transformResourcesData(newItem.data);
        });

      const newParticipantStrategy = tradeStrategyToSave.filter(
        (item: ITradeStrategyQueue) =>
          item.data.resourceIdentifier === selectedParticipant?.name
      );

      const participantStrategyObj = transformParticipantStrategyData(
        newParticipantStrategy
      );

      var newData = undefined;

      if (transformedData && transformedData.length!) {
        newData = {
          ...selectedParticipant,
          strategy: participantStrategyObj
            ? participantStrategyObj
            : selectedParticipant.strategy,
          resources: transformedData,
        };
      } else {
        newData = {
          ...selectedParticipant,
          strategy: participantStrategyObj
            ? participantStrategyObj
            : selectedParticipant.strategy,
        };
      }

      const response = await handleCreateUpdateDeleteTradeStrategy(newData);
      let success = 0;

      if (/20[0-9]/.test(response.status)) {
        const { id } = response.data as Participant;
        const resources = newData.resources as Resources[];
        resources.forEach((resource) => {
          if (id) dispatch(assignResource({ id, resource: resource }));
        });
        success++;
        if (logEvent) {
          logEvent({
            eventTime: new Date(),
            eventName: Event.ADDEDTRADESTRATEGY,
            payload: { ...response.data },
            timeToComplete: response.duration,
            category: Category.TRADESTRATEGY_PAGE,
            requestStatus: response.status,
          });
        }
      } else {
        pushNotification(
          'error',
          `An error has occurred on ${JSON.parse(response.config.data).id} ${
            response.data.error.message
          }`
        );
      }
      pushNotification('success', `${success} Rows was created`);
    } catch (error) {
      const { response, config } = error as AxiosError;
      if (response)
        pushNotification(
          'error',
          `An error has occurred on ${JSON.parse(config.data).id} ${
            response.data.error.message
          }`
        );
    }
  };

  const createdTradeStrategyHandler = async (
    createdTradeStrategy: ITradeStrategyQueue[],
    pushNotification: TPushNotification
  ) => {
    if (!createdTradeStrategy.length) return false;
    const tradeStrategyToSave = createdTradeStrategy;
    return saveCreatedTradeStrategy(tradeStrategyToSave, pushNotification);
  };

  const updatedTradeStrategyHandler = async (
    updatedTradeStrategy: ITradeStrategyQueue[],
    pushNotification: TPushNotification
  ) => {
    if (!updatedTradeStrategy.length) return false;

    const tradeStrategyToUpdate = updatedTradeStrategy;

    const transformedData = tradeStrategyToUpdate
      .filter(
        (item: ITradeStrategyQueue) =>
          item.data.resourceIdentifier !== selectedParticipant?.name
      )
      .map((newItem: ITradeStrategyQueue) => {
        return transformResourcesData(newItem.data);
      });

    const newParticipantStrategy = tradeStrategyToUpdate.filter(
      (item: ITradeStrategyQueue) =>
        item.data.resourceIdentifier === selectedParticipant?.name
    );

    const participantStrategyObj = transformParticipantStrategyData(
      newParticipantStrategy
    );

    var newData = undefined;

    if (transformedData && transformedData.length!) {
      newData = {
        ...selectedParticipant,
        strategy: participantStrategyObj
          ? participantStrategyObj
          : selectedParticipant.strategy,
        resources: transformedData,
      };
    } else {
      newData = {
        ...selectedParticipant,
        strategy: participantStrategyObj
          ? participantStrategyObj
          : selectedParticipant.strategy,
      };
    }

    try {
      const response = await handleCreateUpdateDeleteTradeStrategy(newData);
      let success = 0;

      if (response.status === 200 || response.status === 201) {
        const { id } = response.data as Participant;
        const resources = newData.resources as Resources[];
        resources.forEach((resource) => {
          if (id) dispatch(assignResource({ id, resource: resource }));
        });

        success++;
        if (logEvent) {
          logEvent({
            eventTime: new Date(),
            eventName: Event.MODIFIEDTRADESTRATEGY,
            payload: { ...response.data },
            timeToComplete: response.duration,
            category: Category.TRADESTRATEGY_PAGE,
            requestStatus: response.status,
          });
        }
      } else {
        pushNotification(
          'error',
          `An error has occurred on ${JSON.parse(response.config.data).id} ${
            response.data.error.message
          }`
        );
      }
      pushNotification('info', `${success} Rows was updated`);
    } catch (error) {
      const err = error as AxiosError;
      pushNotification('error', `${err.response?.data.error.message}`);
    }
  };

  const saveChangesOnClickHandler = async () => {
    const { getQueue } = queueHook;
    const tradeStrategyQueue = getQueue();

    const pushNotification = (variant: VariantType, message: string) => {
      enqueueSnackbar(message, { variant });
    };

    const tradeStrategyActions = tradeStrategyQueue.reduce(
      (
        acc: Record<string, ITradeStrategyQueue[]>,
        tradeStrategy: ITradeStrategyQueue
      ) => {
        if (tradeStrategy.status === CREATE) acc.create.push(tradeStrategy);
        if (tradeStrategy.status === UPDATE) acc.update.push(tradeStrategy);
        if (tradeStrategy.status === DELETE) acc.delete.push(tradeStrategy);
        return acc;
      },
      { create: [], update: [], delete: [] }
    );

    createdTradeStrategyHandler(tradeStrategyActions.create, pushNotification);
    updatedTradeStrategyHandler(tradeStrategyActions.update, pushNotification);

    toggleEditing(false);
    resetGridOnClickHandler();
  };

  useEffect(() => {
    if (logEvent && document.readyState === 'complete') {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDTRADESTRATEGYPAGE,
        category: Category.TRADESTRATEGY_PAGE,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleRowClass = (params: any) => {
    return params.data.resourceIdentifier === selectedParticipant?.name;
  };

  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent) => {
      var isBid = evaluateBidsOffers(params.data.bidMaximumQuantityAllowed);
      var isOffer = evaluateBidsOffers(params.data.offerMaximumQuantityAllowed);
      var changedData = [{ ...params.data, isBid, isOffer }];
      if (gridApi) {
        gridApi.applyTransaction({
          update: changedData,
        });
      }
    },
    [gridApi]
  );

  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <S.PageTitle data-testid='title'>Trade Strategy</S.PageTitle>
        <FolioSkeleton
          fullHeight
          canSave={true}
          canAdd={
            selectedParticipant !== undefined &&
            selectedRangeFilterTab.key === RESOURCE.key
          }
          canEdit={true}
          context={{
            formik: formik,
            queueHook: queueHook,
          }}
          additions={additions}
          onRowEditingStopped={onRowEditingStoppedHandler}
          onRowEditingStarted={onRowEditingStartedHandler}
          onClickSaveButton={saveChangesOnClickHandler}
          onClickAddRow={addRowOnClickHandler}
          onClickReset={resetGridOnClickHandler}
          onClickEnableEditing={onClickEnableEditing}
          //onClickRemoveButton={removeRowOnClickHandler}
          onCellValueChanged={onCellValueChanged}
          defaultDefs={{
            editable: true,
            flex: 1,
            suppressMenu: true,
            autoHeight: true,
            cellEditorPopup: false,
            wrapText: true,
          }}
          loading={participantStatus === LOADING}
          onGridReady={onGridReadyHandler}
          editMode={editingMode}
          components={{ CellRender: CellRender }}
          columnDefs={colDef}
          data={rowData}
          pagination={true}
          headerHeight={60}
          rowClassRules={{ 'participant-strategy': handleRowClass }}
          toolExtra={
            <div className={toolBarOptions}>
              <div>
                <RangeFilterTab
                  tab={selectedRangeFilterTab.key}
                  handleChange={handleRangeFilterChange}
                  timeIntervals={[PARTICIPANT, RESOURCE]}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default memo(TradeStrategyPage);
