import styled, { css } from 'styled-components';
import PlansGrid from './partials/Grid/Grid';

export const OperatingPlan = styled.div`
  width: 100%;
  height: inherit;
`;
export const Wrapper = styled.div`
  flex: 1 1 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;

  padding: 33px 33px 33px 16px;
  background-color: var(--page-background);
  position: relative;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  color: var(--primary);
  margin-top: 0;
`;

const commonGridStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;

  & > div {
    width: 100%;
    height: 100%;
  }

  .ag-root-wrapper {
    border-radius: 8px;
  }

  .ag-cell-wrapper {
    display: flex;
  }

  .ag-header-viewport .ag-header-cell {
    padding: 5px;
  }

  .ag-cell-pinned-group {
    padding-left: 20px;
  }

  .operating-plan-hours-header {
    color: #7e8c9d;
    background-color: #ffffff;
    border-right: 1px solid #babfc7 !important;
  }

  .ag-cell {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 1px;
  }

  .operating-plan-hours-header-error {
    color: #7e8c9d;
    background-color: #f2f5f7;
    background: linear-gradient(-135deg, #b31d3b 10px, #f2f5f7 0);
  }

  .operating-plan-hours-header-notes {
    color: #7e8c9d;
    background-color: #f2f5f7;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px 0;
      border-color: transparent #df943b transparent transparent;
      right: 0;
      top: 0;
      position: absolute;
    }
  }

  .operating-plan-hours-header-error-notes {
    color: #7e8c9d;
    background-color: #f2f5f7;
    background: linear-gradient(-135deg, #b31d3b 10px, #f2f5f7 0);
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 9px 9px 0;
      border-color: transparent #df943b transparent transparent;
      right: 0;
      top: 0;
      position: absolute;
    }
  }

  .operating-plan-header {
    background-color: #ffffff;
  }
`;

export const M15GridContainer = styled(PlansGrid)`
  ${commonGridStyle}

  .ag-floating-top {
    overflow-y: hidden !important;

    .ag-cell-last-left-pinned {
      border-right: 0 !important;
    }

    .ag-row {
      background: #cdd2d9;
      border: 0;
    }
  }

  .ag-root-wrapper {
    border-radius: 0 0 8px 8px;
  }

  .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
    flex: 0;
  }
`;

export const OverridesGridContainer = styled(PlansGrid)`
  ${commonGridStyle}

  .ag-root-wrapper {
    border-radius: 0 0 8px 8px;
  }

  .ag-pinned-right-header,
  .ag-horizontal-right-spacer {
    width: 0 !important;
    min-width: 0 !important;
  }

  .ag-pinned-right-cols-container {
    position: absolute !important;
    left: -50px;
    pointer-events: none;
  }

  .ag-pinned-right-cols-container * {
    pointer-events: initial;
  }

  .ag-pinned-right-cols-container .ag-cell {
    border: none !important;
  }

  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover),
  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
    width: 0 !important;
    padding: 0 !important;
    left: -50px;
  }

  .ag-cell-inline-editing {
    height: 60px;
  }

  &.pci-editing {
    border-top: 1px solid #2cb2be;

    .ag-pinned-right-cols-container {
      left: 0;
    }

    .ag-pinned-right-cols-container .ag-row.ag-row-hover {
      background: none;
      width: 10px;
      height: 10px;
      top: 0;
      left: -7px;
      transition: all 250ms ease-in-out;
    }

    .ag-row-hover {
      .action-button-close {
        width: 20px;
        display: flex;
        justify-content: center;
        position: relative;
        background: transparent;
        margin: 0;
        padding: 0;
        height: 20px;
      }
    }

    .ag-row-inline-editing .action-button-close {
      left: -30px !important;
    }
  }

  .ag-body-viewport {
    .ag-cell-wrapper,
    .ag-cell-inline-editing,
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      height: 100%;
      width: 100%;
    }
  }
`;
