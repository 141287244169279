import React from 'react';

const PageNotFound = () => {
  return (
    <div
      id='wrapper'
      style={{
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div>
        <h1 style={{ fontSize: '8rem' }}>404</h1>
      </div>
      <div id='info'>
        <h3>This page could not be found</h3>
      </div>
    </div>
  );
};

export default PageNotFound;
