import { useRef, useState } from 'react';

import BarChart, { IChartConfig } from 'components/molecules/Charts/XY.bar';
import { EOrientation } from 'enums/general';
import { uniqueId } from 'utils/general';

import * as S from './Peformance.styles';
import {
  IPerformanceChartData,
  IPerformanceChartDataWithTotal,
} from './Performance';

export interface IPerformanceChart {
  isHorizontal?: boolean;
  isTimeBased?: false;
  title: string;
  data?: IPerformanceChartDataWithTotal[];
  chartConfig?: IChartConfig;
}

interface IPerformanceChartTimeBased
  extends Omit<IPerformanceChart, 'isTimeBased' | 'data'> {
  isTimeBased: true;
  data?: IPerformanceChartData[];
}

const PerformanceChart = ({
  data,
  isHorizontal,
  isTimeBased,
  chartConfig,
  title,
}: IPerformanceChart | IPerformanceChartTimeBased) => {
  const [autoId] = useState(() => uniqueId('seem-performance-chart-'));
  const loaderRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <S.ChartLoader ref={loaderRef} />
      <BarChart
        style={{ height: '100%' }}
        data={data}
        fieldConfig={[
          { name: 'Offers', color: '#7FCFD7' },
          { name: 'Bids', color: '#213955' },
        ]}
        chartId={autoId}
        loaderRef={loaderRef}
        chartTitle={title}
        isTimeBased={isTimeBased}
        isStacked
        orientation={
          isHorizontal ? EOrientation.Horizontal : EOrientation.Vertical
        }
        chartConfig={chartConfig}
      />
    </>
  );
};

export default PerformanceChart;
