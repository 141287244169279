import { useState } from 'react';

interface IWorkerState {
  status: 'idle' | 'running' | 'complete';
  result: unknown;
}

const useWorker = (worker: Worker) => {
  const [workerStatus, setWorkerStatus] = useState<
    'idle' | 'running' | 'complete'
  >('idle');
  const [results, setResults] = useState<unknown>({});
  const instance = worker;

  instance.onmessage = (event) => {
    const { status, result } = event.data as IWorkerState;

    if (status.length) {
      setWorkerStatus(status);
    }
    if (result) {
      setResults(result);
    }
  };

  instance.onerror = (event) => {
    console.error(event.message);
  };

  return { instance, results, workerStatus };
};

export default useWorker;
