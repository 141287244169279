import { ICellRendererParams } from 'ag-grid-community';
import M15ImbalanceRenderer from './M15.imbalanceRenderer';

import * as S from './M15.styles';

export interface ImageCellRendererParams extends ICellRendererParams {
  rendererStatus: string;
  rendererMessage: string;
}

const M15CellRenderer = (props: ImageCellRendererParams) => {
  const {
    data: { type, resourceIdentifier },
  } = props;

  const customValueGetter = () => {
    if (props.data !== undefined) {
      type ObjectKeyParent = keyof typeof props.data;
      const fieldGroup = props.colDef?.field;
      const parentField = fieldGroup?.split('.')[0] as ObjectKeyParent;
      const dataField = fieldGroup?.split('.')[1] as ObjectKeyParent;
      const currentParentField = props.data[parentField];

      if (currentParentField === undefined) return;
      const currentDataSearch = currentParentField[dataField];
      return currentDataSearch;
    }
  };

  const transformedDataValue = customValueGetter();

  const isImbalance = type === 'Imbalance';

  if (isImbalance)
    return (
      <M15ImbalanceRenderer
        transformedDataValue={transformedDataValue}
        {...props}
      />
    );

  const isEnergy = type === 'energy' || resourceIdentifier === 'energy';

  if (!isEnergy) {
    return (
      <S.EnergyBox>
        <span className='energyColorCodeLabel_default'>
          {transformedDataValue?.num}
        </span>
      </S.EnergyBox>
    );
  }

  return (
    <S.EnergyBox>
      <span
        className={`energyColorCodeLabel_${
          transformedDataValue?.className || 'default'
        }`}
      >
        {transformedDataValue?.className === 'dash'
          ? '-'
          : transformedDataValue?.num}
      </span>
    </S.EnergyBox>
  );
};

export default M15CellRenderer;
