import {
  currencyInDollarsFormatter,
  timeExportHeaderValueGetter,
  timeExportValueGetter,
} from 'utils/general';
import BidsOffersStatusRender from '../BidsOffersStatusRender';
import { bidOfferStatusValueGetter } from '../helpers';

export const BIDS_OFFERS_DEF_COLS = [
  {
    headerName: 'Resource',
    field: 'resourceIdentifier',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
    pinned: 'left',
  },
  {
    headerName: 'Bid/Offer',
    field: 'bidOffer',
    minWidth: 50,
    maxWidth: 70,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Trade \n interval',
    field: 'tradeInterval',
    minWidth: 150,
    maxWidth: 150,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Status',
    field: 'data',
    minWidth: 150,
    maxWidth: 150,
    cellRenderer: BidsOffersStatusRender,
    valueGetter: bidOfferStatusValueGetter,
  },
  {
    headerName: 'Plan \n Quantity \n (MW)',
    field: 'planQuantity',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Plan \n Cost \n ($/MWh)',
    field: 'planCost',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Trade \n Quantity \n (MW)',
    field: 'tradeQuantity',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Trade \n Price \n ($/MWh)',
    field: 'tradePrice',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Sink',
    field: 'sink',
    minWidth: 90,
    maxWidth: 90,
  },
  {
    headerName: 'Source',
    field: 'source',
    minWidth: 90,
    maxWidth: 90,
  },
  {
    headerName: 'Id',
    field: 'id',
    hide: true,
  },
];
