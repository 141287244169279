import styled from 'styled-components';

export const RemoveRowButton = styled.button`
  border: 0;
  outline: 0;
  background: none;

  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  color: var(--secondary);
`;
