import { useCallback, useState, useRef, useEffect } from 'react';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { isValid } from 'date-fns';

import { switchLocalTimezone } from 'utils/dateTime';

import * as OptionsStyles from 'components/admin/TradeDashboard/partials/Options/Options.styles';

import * as S from './DateRangeSelector.styles';

type THoursOrDateView = 'day' | 'hours';

export type TDefaultTime = Date | ZonedDateTime | null | string;

interface IMinMaxDateTime {
  min?: Date;
  max?: Date;
}
interface Props {
  startDate: string;
  endDate: string;
  disable?: boolean;
  disableTime?: boolean;
  disableDate?: boolean;
  startMinMaxDateTime?: IMinMaxDateTime;
  endMinMaxDateTime?: IMinMaxDateTime;
  handleDateRangeSelector: (fromDateTime: string, toDateTime: string) => void;
  timezone: TTimeZone;
}

const DateRangeSelector = ({
  startDate,
  endDate,
  startMinMaxDateTime,
  endMinMaxDateTime,
  handleDateRangeSelector,
  timezone,
  disableTime = false,
  disableDate = false,
  disable = false,
}: Props) => {
  const ref: any = useRef(null);

  const [fromDateTime, setFromDateTime] = useState<TDefaultTime>(startDate);
  const [toDateTime, setToDateTime] = useState<TDefaultTime>(endDate);

  const [fromIsOpen, setFromIsOpen] = useState<boolean>(false);
  const [toIsOpen, setToIsOpen] = useState<boolean>(false);

  const [hoursOrDateView, setHoursOrDateView] =
    useState<THoursOrDateView>('day');

  const onChangeDateTimeHandler = (
    newDateTime: Date | string | ZonedDateTime | null
  ) => {
    if (newDateTime && isValid(newDateTime)) return newDateTime;
  };

  const onAcceptFromDateTimeHandler = (newAcceptDateTime: TDefaultTime) => {
    const startZonedDateTime = switchLocalTimezone(
      newAcceptDateTime as string,
      timezone
    );
    setFromDateTime(newAcceptDateTime);
    handleDateRangeSelector(
      startZonedDateTime,
      switchLocalTimezone(toDateTime as string, timezone)
    );
  };

  const onAcceptToDateTimeHandler = (newAcceptDateTime: TDefaultTime) => {
    const zonedDateTime = switchLocalTimezone(
      newAcceptDateTime as string,
      timezone
    );
    setToDateTime(newAcceptDateTime);
    handleDateRangeSelector(
      switchLocalTimezone(fromDateTime as string, timezone),
      zonedDateTime
    );
  };

  // eslint-disable-next-line
  const toggleFromOpenClose = () => {
    setFromIsOpen(!fromIsOpen);
  };

  // eslint-disable-next-line
  const toggleToOpenClose = () => {
    setToIsOpen(!toIsOpen);
  };

  const onChangeView = useCallback(
    (view: THoursOrDateView) => {
      if (!fromIsOpen || !toIsOpen) {
        setHoursOrDateView(view);
      }
    },
    [fromIsOpen, toIsOpen]
  );

  const renderFromPickerInput = useCallback(
    (params) => {
      const value = params?.inputProps?.value;
      let date: string = '';
      let time: string = '';

      if (value) {
        date = value.substr(0, 10);
        time = value.substr(11);
      }
      return (
        <OptionsStyles.SelectorButton
          onClick={toggleFromOpenClose}
          ref={params.inputRef}
        >
          {!disableDate && (
            <strong onClick={() => onChangeView('day')}>{date}</strong>
          )}
          {!disableTime && (
            <strong onClick={() => onChangeView('hours')}>{time}</strong>
          )}
          <CalendarTodayIcon
            onClick={() => onChangeView('day')}
            fontSize='small'
          />
        </OptionsStyles.SelectorButton>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeView, toggleFromOpenClose]
  );

  const renderToPickerInput = useCallback(
    (params) => {
      const value = params?.inputProps?.value;
      let date: string = '';
      let time: string = '';
      if (value) {
        date = value.substr(0, 10);
        time = value.substr(11);
      }
      return (
        <OptionsStyles.SelectorButton
          onClick={toggleToOpenClose}
          ref={params.inputRef}
        >
          {!disableDate && (
            <strong onClick={() => onChangeView('day')}>{date}</strong>
          )}
          {!disableTime && (
            <strong onClick={() => onChangeView('hours')}>{time}</strong>
          )}
          <CalendarTodayIcon
            onClick={() => onChangeView('day')}
            fontSize='small'
          />
        </OptionsStyles.SelectorButton>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeView, toggleToOpenClose]
  );

  useEffect(() => {
    if (timezone) {
      setFromDateTime(startDate);
      setToDateTime(endDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timezone, startDate, endDate]);

  const inputFormat = `${!disableDate && 'MM/dd/yyyy'} ${
    !disableTime && 'hh:mm a'
  }`;

  return (
    <S.FilterContainer ref={ref}>
      <S.FilterBox data-testid='interval_from'>
        <S.Label>From:</S.Label>
        {disableTime ? (
          <DesktopDatePicker
            value={fromDateTime}
            open={fromIsOpen}
            disabled={disable}
            onChange={onChangeDateTimeHandler}
            onAccept={onAcceptFromDateTimeHandler}
            onClose={toggleFromOpenClose}
            renderInput={renderFromPickerInput}
          />
        ) : (
          <DesktopDateTimePicker
            value={fromDateTime}
            minutesStep={15}
            disabled={disable}
            inputFormat={inputFormat}
            minDateTime={startMinMaxDateTime?.min || undefined}
            maxDateTime={startMinMaxDateTime?.max || undefined}
            openTo={hoursOrDateView}
            open={fromIsOpen}
            onChange={onChangeDateTimeHandler}
            onAccept={onAcceptFromDateTimeHandler}
            onClose={toggleFromOpenClose}
            renderInput={renderFromPickerInput}
          />
        )}
      </S.FilterBox>
      <S.FilterBox data-testid='interval_to'>
        <S.SecondLabel>To:</S.SecondLabel>
        {disableTime ? (
          <DesktopDatePicker
            value={toDateTime}
            disabled={disable}
            open={toIsOpen}
            onChange={onChangeDateTimeHandler}
            onAccept={onAcceptToDateTimeHandler}
            onClose={toggleToOpenClose}
            renderInput={renderToPickerInput}
          />
        ) : (
          <DesktopDateTimePicker
            value={toDateTime}
            disabled={disable}
            minutesStep={15}
            inputFormat={inputFormat}
            minDateTime={endMinMaxDateTime?.min || undefined}
            maxDateTime={endMinMaxDateTime?.max || undefined}
            openTo={hoursOrDateView}
            open={toIsOpen}
            onChange={onChangeDateTimeHandler}
            onAccept={onAcceptToDateTimeHandler}
            onClose={toggleToOpenClose}
            renderInput={renderToPickerInput}
          />
        )}
      </S.FilterBox>
    </S.FilterContainer>
  );
};

export default DateRangeSelector;
