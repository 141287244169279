import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import {
  GENERATING_UNIT,
  LOAD,
  POWER_EXPORT,
  POWER_IMPORT,
} from 'components/admin/AddResources/constant';
import { endOfDay, startOfDay } from 'date-fns';
import {
  IGeneratingUnitOperatingPlan,
  IOperatingPlansDefaultPowerResource,
} from 'interfaces/resource';
import { Dictionary, keyBy, omit } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';

const STORE_NAME = 'overrides';

export interface OperatingPlanOverrides {
  generatingUnitOperatingPlans: Dictionary<IGeneratingUnitOperatingPlan>;
  loadOperatingPlans: Dictionary<IOperatingPlansDefaultPowerResource>;
  powerExportOperatingPlans: Dictionary<IOperatingPlansDefaultPowerResource>;
  powerImportOperatingPlans: Dictionary<IOperatingPlansDefaultPowerResource>;
}

const initialState = {
  generatingUnitOperatingPlans: {},
  loadOperatingPlans: {},
  powerExportOperatingPlans: {},
  powerImportOperatingPlans: {},
  status: IDLE,
} as OperatingPlanOverrides & { status: string };

export const fetchOperatingPlanOverrides = createAsyncThunk(
  `${STORE_NAME}/fetchOperatingPlanOverrides`,
  async (marketParticipantId: string) => {
    const { data, status } = await AutoTraderApi.getOperatingPlanOverrides({
      marketParticipantId,
      startAt: startOfDay(new Date()).toISOString(),
      stopAt: endOfDay(new Date('2099-12-31')).toISOString(),
    });
    const {
      generatingUnitOperatingPlans: initialGenUnitOP,
      loadOperatingPlans: initialLoadOP,
      powerExportOperatingPlans: initialPowExpOP,
      powerImportOperatingPlans: initialPowImpOP,
    } = initialState;

    if (status >= 200 && status <= 400) {
      const {
        generatingUnitOperatingPlans,
        loadOperatingPlans,
        powerExportOperatingPlans,
        powerImportOperatingPlans,
      } = data;
      return {
        generatingUnitOperatingPlans: generatingUnitOperatingPlans
          ? keyBy(generatingUnitOperatingPlans, 'id')
          : initialGenUnitOP,
        loadOperatingPlans: loadOperatingPlans
          ? keyBy(loadOperatingPlans, 'id')
          : initialLoadOP,
        powerExportOperatingPlans: powerExportOperatingPlans
          ? keyBy(powerExportOperatingPlans, 'id')
          : initialPowExpOP,
        powerImportOperatingPlans: powerImportOperatingPlans
          ? keyBy(powerImportOperatingPlans, 'id')
          : initialPowImpOP,
      };
    } else {
      return initialState;
    }
  }
);

export const overridesSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    overridesUpsert: (state, action) => {
      const { data, type } = action.payload;
      return dataUpsert(data, type, state);
    },
    overridesDelete: (state, action) => {
      const { overrideId, type } = action.payload;
      return dataRemove(overrideId, type, state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOperatingPlanOverrides.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(fetchOperatingPlanOverrides.fulfilled, (_, action) => {
        return { status: FULFILLED, ...action.payload };
      });
  },
});

const dataRemove = (
  overrideId: string,
  type: string,
  overrideState: OperatingPlanOverrides & { status: string }
) => {
  switch (type) {
    case GENERATING_UNIT: {
      const { generatingUnitOperatingPlans } = overrideState;
      const newDictionary = omit(generatingUnitOperatingPlans, overrideId);
      const output = {
        ...overrideState,
        generatingUnitOperatingPlans: newDictionary,
      };
      return output;
    }
    case LOAD: {
      const { loadOperatingPlans } = overrideState;
      const newDictionary = omit(loadOperatingPlans, overrideId);
      const output = {
        ...overrideState,
        loadOperatingPlans: newDictionary,
      };
      return output;
    }
    case POWER_IMPORT: {
      const { powerImportOperatingPlans } = overrideState;
      const newDictionary = omit(powerImportOperatingPlans, overrideId);
      const output = {
        ...overrideState,
        powerImportOperatingPlans: newDictionary,
      };
      return output;
    }
    case POWER_EXPORT: {
      const { powerExportOperatingPlans } = overrideState;
      const newDictionary = omit(powerExportOperatingPlans, overrideId);
      const output = {
        ...overrideState,
        powerExportOperatingPlans: newDictionary,
      };
      return output;
    }

    default: {
      return overrideState;
    }
  }
};

const dataUpsert = (
  data: IGeneratingUnitOperatingPlan | IOperatingPlansDefaultPowerResource,
  type: string,
  overrideState: OperatingPlanOverrides & { status: string }
) => {
  switch (type) {
    case GENERATING_UNIT: {
      const { generatingUnitOperatingPlans } = overrideState;
      const newDictionary = keyBy<IGeneratingUnitOperatingPlan>(
        [
          ...Object.values(generatingUnitOperatingPlans),
          data as IGeneratingUnitOperatingPlan,
        ],
        'id'
      );
      const output = {
        ...overrideState,
        generatingUnitOperatingPlans: newDictionary,
      };
      return output;
    }
    case LOAD: {
      const { loadOperatingPlans } = overrideState;
      const newDictionary = keyBy(
        [...Object.values(loadOperatingPlans), data],
        'id'
      );
      const output = {
        ...overrideState,
        loadOperatingPlans: newDictionary,
      };
      return output;
    }
    case POWER_IMPORT: {
      const { powerImportOperatingPlans } = overrideState;
      const newDictionary = keyBy(
        [...Object.values(powerImportOperatingPlans), data],
        'id'
      );
      const output = {
        ...overrideState,
        powerImportOperatingPlans: newDictionary,
      };
      return output;
    }
    case POWER_EXPORT: {
      const { powerExportOperatingPlans } = overrideState;
      const newDictionary = keyBy(
        [...Object.values(powerExportOperatingPlans), data],
        'id'
      );
      const output = {
        ...overrideState,
        powerExportOperatingPlans: newDictionary,
      };
      return output;
    }

    default: {
      return overrideState;
    }
  }
};

export const selectPlan = (plan: OperatingPlanOverrides) => {
  return {
    generatingUnitOperatingPlans: Object.values(
      plan.generatingUnitOperatingPlans
    ),
    loadOperatingPlans: Object.values(plan.loadOperatingPlans),
    powerExportOperatingPlans: Object.values(plan.powerExportOperatingPlans),
    powerImportOperatingPlans: Object.values(plan.powerImportOperatingPlans),
  };
};

export const selectOverridesByUnit = (
  overrides: OperatingPlanOverrides,
  unit: string
) => {
  switch (unit) {
    case GENERATING_UNIT: {
      return Object.values(overrides.generatingUnitOperatingPlans);
    }
    case LOAD: {
      return Object.values(overrides.loadOperatingPlans);
    }
    case POWER_IMPORT: {
      return Object.values(overrides.powerImportOperatingPlans);
    }
    case POWER_EXPORT: {
      return Object.values(overrides.powerExportOperatingPlans);
    }

    default:
      return undefined;
  }
};

export const { overridesUpsert, overridesDelete } = overridesSlice.actions;

export default overridesSlice.reducer;
