import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { Participant, Resources } from 'api/models/Participant.model';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';

const STORE_NAME = 'participant';

const participantAdapter = createEntityAdapter<Participant>();

const initialState = participantAdapter.getInitialState({ status: IDLE });

export const updateParticipantAutoSubmit = createAsyncThunk(
  `${STORE_NAME}/updateParticipantAutoSubmit`,
  async ({
    participantId,
    autoSubmitStatus,
  }: {
    participantId: string;
    autoSubmitStatus: boolean;
  }) => {
    const response = (await AutoTraderApi.updateParticipantAutoSubmit(
      participantId,
      autoSubmitStatus
    )) as any;

    if (response.status === 200) {
      return { participantId, autoSubmitStatus, duration: response.duration };
    }
  }
);
export const validateParticipantAutoSubmitOn = createAsyncThunk(
  `${STORE_NAME}/validateParticipantAutoSubmitOn`,
  async (participantId: string) => {
    const response = await AutoTraderApi.validateParticipantAutoSubmitOn(
      participantId
    );

    return { participantId, autoSubmitStatus: !(response === 404) };
  }
);
export const fetchParticipants = createAsyncThunk(
  `${STORE_NAME}/fetchParticipants`,
  async () => {
    const response = await AutoTraderApi.getParticipants();
    return response as Participant[];
  }
);
export const fetchParticipantById = createAsyncThunk(
  `${STORE_NAME}/fetchParticipantById`,
  async (participantId: string) => {
    const response = await AutoTraderApi.getParticipantById(participantId);
    return response as Participant;
  }
);

export const participantSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    participantUpsert: (state, action) => {
      participantAdapter.upsertOne(state, action.payload);
      state.status = FULFILLED;
    },
    assignResource: (state, action) => {
      const { id, resource } = action.payload;
      const entity = state.entities[id] as Participant;
      const resources = entity?.resources;
      let newResources: Resources[] = [];
      if (resources) {
        let filteredResources = resources.filter(
          (resourceLookUp) =>
            resourceLookUp.resourceIdentifier !== resource.resourceIdentifier
        );
        newResources = [...filteredResources, resource];
      } else {
        newResources = [resource];
      }
      entity.resources = newResources;
      participantAdapter.upsertOne(state, entity);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParticipants.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(fetchParticipantById.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(updateParticipantAutoSubmit.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(validateParticipantAutoSubmitOn.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(fetchParticipants.fulfilled, (state, action) => {
        participantAdapter.setAll(state, action.payload);
        state.status = FULFILLED;
      })
      .addCase(fetchParticipantById.fulfilled, (state, action) => {
        participantAdapter.upsertOne(state, action.payload);
        state.status = FULFILLED;
      })
      .addCase(updateParticipantAutoSubmit.fulfilled, (state, action) => {
        const { participantId, autoSubmitStatus } = action.payload as {
          participantId: string;
          autoSubmitStatus: boolean;
        };
        const participant = state.entities[participantId];
        if (participant?.autoSubmit) {
          participant.autoSubmit = autoSubmitStatus;
        }

        if (participant) participantAdapter.upsertOne(state, participant);
        state.status = FULFILLED;
      })
      .addCase(validateParticipantAutoSubmitOn.fulfilled, (state, action) => {
        const { participantId, autoSubmitStatus } = action.payload as {
          participantId: string;
          autoSubmitStatus: boolean;
        };
        const participant = state.entities[participantId];
        if (
          participant?.autoSubmit &&
          participant?.autoSubmit !== autoSubmitStatus
        ) {
          participant.autoSubmit = autoSubmitStatus;
        }

        if (participant) participantAdapter.upsertOne(state, participant);
        state.status = FULFILLED;
      });
  },
});

export const { participantUpsert, assignResource } = participantSlice.actions;

const getParticipantsEntities = (state: any) => {
  return state.participant.entities;
};

const dictionaryToArray = (participants: { [entityId: string]: Participant }) =>
  Object.entries(participants).map(([_, participant]) => participant);

export const getParticipants = createDraftSafeSelector(
  getParticipantsEntities,
  dictionaryToArray
);

export default participantSlice.reducer;
