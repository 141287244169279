import FormValidation from 'utils/FormValidation';
import { TEA } from './constants';
import { IParticipantValues } from './IParticipantValues';

const teaFieldsValidation = (teaClientId: string, marketType: string) => {
  if (marketType === TEA) {
    if (teaClientId.trim().length < 1) {
      return false;
    }
  }
  return true;
};

export const participantSchema = (values: IParticipantValues) => {
  return {
    id: FormValidation(values.id?.value).string(),
    name: FormValidation(values.name?.value)
      .string()
      .max(40)
      .min(1)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?:[ a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    marketType: FormValidation(values.marketType?.value).string(),
    startAt: FormValidation(values.startAt?.value).date().required(),
    stopAt: FormValidation(values.stopAt?.value).date().required(),

    tradeFinalizeDeadline: FormValidation(values.tradeFinalizeDeadline?.value)
      .number()
      .required(),

    bidMaximumQuantityAllowed: FormValidation(
      values.bidMaximumQuantityAllowed?.value
    )
      .number()
      .min(0.0),
    bidQuantityWithheldFromFront: FormValidation(
      values.bidQuantityWithheldFromFront?.value
    )
      .number()
      .min(0.0)
      .required(),
    bidQuantityWithheldFromBack: FormValidation(
      values.bidQuantityWithheldFromBack?.value
    )
      .number()
      .min(0.0)
      .required(),
    bidCeilingPricedAtFrontQuantity: FormValidation(
      values.bidCeilingPricedAtFrontQuantity?.value
    )
      .number()
      .min(0.0)
      .required(),
    bidDiscount: FormValidation(values.bidDiscount?.value)
      .number()
      .min(0.0)
      .required(),
    bidMinDiscountPercent: FormValidation(values.bidMinDiscountPercent?.value)
      .number()
      .min(0.0)
      .required(),
    bidMaxDiscountPercent: FormValidation(values.bidMaxDiscountPercent?.value)
      .number()
      .min(0.0)
      .required(),

    offerMaximumQuantityAllowed: FormValidation(
      values.offerMaximumQuantityAllowed?.value
    )
      .number()
      .min(0.0),
    offerQuantityWithheldFromFront: FormValidation(
      values.offerQuantityWithheldFromFront?.value
    )
      .number()
      .min(0.0)
      .required(),
    offerQuantityWithheldFromBack: FormValidation(
      values.offerQuantityWithheldFromBack?.value
    )
      .number()
      .min(0.0)
      .required(),
    offerFloorPricedAtFrontQuantity: FormValidation(
      values.offerFloorPricedAtFrontQuantity?.value
    )
      .number()
      .min(0.0)
      .required(),
    offerPremium: FormValidation(values.offerPremium?.value)
      .number()
      .min(0.0)
      .required(),
    offerMinPremiumPercent: FormValidation(values.offerMinPremiumPercent?.value)
      .number()
      .min(0.0)
      .required(),
    offerMaxPremiumPercent: FormValidation(values.offerMaxPremiumPercent?.value)
      .number()
      .required(),

    autoSubmit: FormValidation(values.autoSubmit?.value).boolean().required(),
    balanceRequired: FormValidation(values.balanceRequired?.value)
      .boolean()
      .required(),
    balancerHandling: FormValidation(values.balancerHandling?.value)
      .string()
      .required(),
    balanceTolerance: FormValidation(values.balanceTolerance?.value)
      .number()
      .max(5.0)
      .min(0.1)
      .required(),
    blockSize: FormValidation(parseInt(values.blockSize?.value.toString()))
      .number()
      .min(24)
      .custom(
        (blockSize: number) => blockSize % 24 === 0,
        'This field must be a multiple of 24'
      )
      .required(),
    accountNumber: FormValidation(values.accountNumber?.value)
      .string()
      .max(40)
      .min(1)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?:[ a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    clientId: FormValidation(values.clientId?.value).string().min(1).required(),
    clientSecret: FormValidation(values.clientSecret?.value)
      .string()
      .min(1)
      .required(),
    teaClientId: FormValidation(values.teaClientId?.value)
      .string()
      .min(1)
      .custom(teaFieldsValidation, null, values.marketType?.value)
      .required(),
    teaClientSecret: FormValidation(values.teaClientSecret?.value)
      .string()
      .min(1)
      .custom(teaFieldsValidation, null, values.marketType?.value)
      .required(),
  };
};
