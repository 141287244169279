import {
  Person,
  Receipt,
  TextSnippet,
  Leaderboard,
  Settings,
  SvgIconComponent,
  Wysiwyg,
  People,
} from '@mui/icons-material';

// BASE URLS
export const APP_BASE_URL = '/app';
export const TENANTS_REQUESTOR = '/t';
export const ROOT = '/';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const NOT_PERMISSIONS = '/not-permissions';

// ADMIN ROUTES
export const ADMIN_USERS_ENDPOINT = '/users';
export const ADMIN_API_KEY_ENDPOINT = '/api-keys';

// SETUP ROUTES
export const SETUP_URL = '/setup';
export const SETUP_PARTICIPANTS = '/participants';
export const SETUP_SOURCESINKS_ENDPOINT = '/sourcessinks';
export const SETUP_RESOURCES_URL = '/resources';
export const SETUP_RESOURCES_ADD_URL = '/add-resource';
export const SETUP_METERS_URL = '/meters';

// export const SETUP_MARKETS_ENDPOINT = '/markets';

// PLAN ROUTES
export const PLAN_URL = '/plan';
export const PLAN_OPERATING_PLAN_M15 = '/operating-plan-m15';
export const PLAN_OPERATING_PLAN_OVERRIDES = '/operating-plan-overrides';

// TRADE ROUTES
export const TRADE_URL = '/trade';
export const TRADE_BIDSOFFERS_ENDPOINT = '/bidsoffers';
export const TRADE_POSITION_ENDPOINT = '/position';
export const TRADE_STRATEGY_ENDPOINT = '/bidding';
export const TRADE_DASHBOARD = '/dashboard';

// REPORTS ROUTES
export const REPORTS_URL = '/reports';
export const REPORTS_MARKETRESULTS_ENDPOINT = '/marketresults';
export const REPORTS_PERFORMANCE_ENDPOINT = '/performance';
export const REPORTS_PANDL_ENDPOINT = '/pandl';
export const REPORTS_TAGGING_ENDPOINT = '/tagging';
export const REPORTS_MATCHED_TRADES = '/matchedTrades';
export const REPORTS_AUDIT = '/audit';
export const REPORTS_LOSS = '/loss';

// SUPPORT ROUTES
export const SUPPORT_URL = '/support';
export const SUPPORT_URL_USER_GUIDE = '/user-guide';
export const SUPPORT_URL_AUTH_GUIDE = '/auth-guide';
export const SUPPORT_URL_RELEASE_NOTES = '/release-notes';

export const SWAGGER_URL = '/swagger-ui.html';

// CSM ROUTES
export const SETUP_USERS_CSM_ENDPOINT = '/users';
export const USERS_ADD_URL = '/add-user';
export const SETUP_TENANTS_CSM_ENDPOINT = '/tenants';
export const TENANTS_ADD_URL = '/add-tenant';

export type sideMenuType = {
  label: string;
  Icon: SvgIconComponent;
  to: string;
  children?: { label: string; to: string; menuRoles: string[] }[];
  menuRoles: string[];
};

//permissions
export const setUpRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
  'seem-trader:admin',
];
export const planRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const tradeRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const reportsRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
  'seem-trader:admin',
];
export const supportRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
  'seem-trader:admin',
];
export const participantsRoles = ['seem-trader:admin'];
export const resourcesRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const metersRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const operatingPlanRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const overridesRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const tradeStrategyRoles = ['seem-trader:viewer', 'seem-trader:trader'];
export const tradeDashboardRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const bidsOffersRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const performanceRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const matchedTradesRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
];
export const auditRoles = ['seem-trader:admin'];
export const lossReportRoles = [
  'seem-trader:viewer',
  'seem-trader:analyst',
  'seem-trader:trader',
];

export const sideMenu: sideMenuType[] = [
  {
    label: 'Setup',
    Icon: Settings,
    to: SETUP_URL,
    children: [
      {
        label: 'Participants',
        to: SETUP_PARTICIPANTS,
        menuRoles: participantsRoles,
      },
      {
        label: 'Resources',
        to: SETUP_RESOURCES_URL,
        menuRoles: resourcesRoles,
      },
      {
        label: 'Meters',
        to: SETUP_METERS_URL,
        menuRoles: metersRoles,
      },
    ],
    menuRoles: setUpRoles,
  },
  {
    label: 'Plan',
    Icon: Receipt,
    to: PLAN_URL,
    children: [
      {
        label: 'Operating Plans',
        to: PLAN_OPERATING_PLAN_M15,
        menuRoles: operatingPlanRoles,
      },
      {
        label: 'Overrides',
        to: PLAN_OPERATING_PLAN_OVERRIDES,
        menuRoles: overridesRoles,
      },
    ],
    menuRoles: planRoles,
  },
  {
    label: 'Trade',
    Icon: Leaderboard,
    to: TRADE_URL,
    children: [
      {
        label: 'Trade Strategy',
        to: TRADE_STRATEGY_ENDPOINT,
        menuRoles: tradeStrategyRoles,
      },
      {
        label: 'Trade Dashboard',
        to: TRADE_DASHBOARD,
        menuRoles: tradeDashboardRoles,
      },
      {
        label: 'Bids & Offers',
        to: TRADE_BIDSOFFERS_ENDPOINT,
        menuRoles: bidsOffersRoles,
      },
    ],
    menuRoles: tradeRoles,
  },
  {
    label: 'Reports',
    Icon: Wysiwyg,
    to: REPORTS_URL,
    children: [
      {
        label: 'Performance',
        to: REPORTS_PERFORMANCE_ENDPOINT,
        menuRoles: performanceRoles,
      },
      {
        label: 'Matched Trades',
        to: REPORTS_MATCHED_TRADES,
        menuRoles: matchedTradesRoles,
      },
      {
        label: 'Audit',
        to: REPORTS_AUDIT,
        menuRoles: auditRoles,
      },
      {
        label: 'Loss',
        to: REPORTS_LOSS,
        menuRoles: lossReportRoles,
      },
    ],
    menuRoles: reportsRoles,
  },
  {
    label: 'Support',
    Icon: TextSnippet,
    to: SUPPORT_URL,
    menuRoles: supportRoles,
  },
];

export const sideMenuCSM: sideMenuType[] = [
  { label: 'Users', to: SETUP_USERS_CSM_ENDPOINT, Icon: Person, menuRoles: [] },
  {
    label: 'Tenants',
    to: SETUP_TENANTS_CSM_ENDPOINT,
    Icon: People,
    menuRoles: [],
  },
];
