import { Skeleton, TableCell, TableRow } from '@mui/material';
import React from 'react';

interface TableLoaderProps {
  skeletonRows?: number;
  skeletonCols?: number;
}

const TableLoader = ({
  skeletonRows = 5,
  skeletonCols = 5,
}: TableLoaderProps) => {
  return (
    <>
      {[...Array(skeletonRows)].map((_, index) => (
        <TableRow key={index}>
          {[...Array(skeletonCols)].map((_, index) => (
            <TableCell key={index} sx={{ paddingLeft: 3 }}>
              <Skeleton
                sx={{ float: 'left' }}
                height={20}
                width='50%'
                variant='text'
              />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableLoader;
