import { IButton } from 'interfaces/component';

import { ButtonContainer } from './Button.styles';

export const RegularButton: React.FC<IButton> = ({
  children,
  onClick,
  className,
  startIcon,
  variant,
  size,
  ...btnProps
}) => (
  <ButtonContainer
    {...btnProps}
    className={className}
    variant={variant || 'contained'}
    size={size || 'medium'}
    startIcon={startIcon}
    onClick={onClick}
  >
    {children}
  </ButtonContainer>
);
