import { Route, Switch } from 'react-router-dom';

import NotFound from 'components/admin/NotFound/Index';
import OperatingPlanM15 from 'components/admin/OperatingPlan/M15/M15';
import OperatingPlan from 'components/admin/OperatingPlan/OperatingPlan';
import OperatingPlanOverrides from 'components/admin/OperatingPlan/Overrides/Overrides';
import PageInfoCard from 'components/admin/PageInfoCard/PageInfoCard';
import {
  PLAN_URL,
  PLAN_OPERATING_PLAN_M15,
  PLAN_OPERATING_PLAN_OVERRIDES,
} from 'components/admin/Sidebar/menu.config';

import { OperatingPlanM15Provider } from './context';

function index() {
  return (
    <Switch>
      <Route exact path={`*${PLAN_URL}`}>
        <PageInfoCard message='Plan section' />
      </Route>
      <Route exact path={`*${PLAN_OPERATING_PLAN_M15}`}>
        <OperatingPlanM15Provider>
          <OperatingPlan title='Operating Plan'>
            <OperatingPlanM15 />
          </OperatingPlan>
        </OperatingPlanM15Provider>
      </Route>
      <Route exact path={`*${PLAN_OPERATING_PLAN_OVERRIDES}`}>
        <OperatingPlanOverrides />
      </Route>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}

export default index;
