import { Button, TextField } from '@mui/material';
import Logo from 'components/admin/Logo/Logo';
import styles from './TenantRequestor.module.scss';
import { useFormik } from 'formik';
import { IFieldsFormat } from 'interfaces/IFieldsFormat';
import formValidation from 'utils/FormValidation/FormValidation';
import { handleTouched, isComplete } from 'utils/FormValidation/utils';
import { useLocation } from 'react-router-dom';
import useApiConfig from 'hooks/useApiConfig';
import { blankToUndefined } from 'utils/general';
import { useEffect } from 'react';
import { useAppContext } from 'App/AppProvider';

const TenantRequestor = () => {
  const { tenantAuthorization } = useAppContext();
  const {
    wrapper,
    image,
    pageTitle,
    imageWrapper,
    formWrapper,
    formWrapperLogo,
    formWrapperInputs,
    formWrapperInputsActions,
    submitBtn,
  } = styles;

  const { search } = useLocation();

  const tenantFromParams = blankToUndefined(
    new URLSearchParams(search).get('id')
  );

  const { isValid, tenantFilter, loaded: loadedConfig } = useApiConfig();

  const initialValue = {
    tenantId: {
      value: tenantFromParams || '',
      error: undefined,
      touched: undefined,
    },
  };

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: (_) => {},
    validate: async (values: {
      tenantId: IFieldsFormat<string | undefined>;
    }) => {
      const tenantValidation = formValidation(values.tenantId?.value)
        .string()
        .required()
        .custom((tenantId: string) => {
          return isValid(tenantId);
        }, 'Tenant is not authorized')
        .custom(() => {
          return !(tenantFilter?.clientId === undefined);
        }, 'Tenant is not configured correctly, please put in contact with PCI for more information');

      return {
        tenantId: tenantValidation,
      };
    },
  });

  const processTenant = (tenantId: string) => {
    window.localStorage.setItem('tenantId', tenantId);
    if (tenantAuthorization) {
      tenantAuthorization(tenantId);
    }
  };
  const canSubmit =
    isComplete(formik.values) &&
    formik.errors.tenantId?.error === null &&
    tenantFilter?.clientId !== undefined;

  useEffect(() => {
    formik.validateField('tenantId');
    formik.setFieldTouched('tenantId');
    // eslint-disable-next-line
  }, [formik.values.tenantId.value]);

  useEffect(() => {
    const tenantFromParams = () =>
      blankToUndefined(new URLSearchParams(search).get('id'));
    const tenantFromStorage = () =>
      blankToUndefined(window.localStorage.getItem('tenantId'));

    if (
      tenantFromParams() !== undefined &&
      tenantFromStorage() !== undefined &&
      tenantFromParams() !== tenantFromStorage()
    ) {
      const tenant = blankToUndefined(new URLSearchParams(search).get('id'));
      if (tenant) {
        window.localStorage.setItem('tenantId', tenant);
      }
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const tenantFromParams = () =>
      blankToUndefined(new URLSearchParams(search).get('id'));
    const tenantFromStorage = () =>
      blankToUndefined(window.localStorage.getItem('tenantId'));

    if (tenantFromParams() !== undefined && tenantFromStorage() === undefined) {
      if (loadedConfig) {
        const tenantId = formik.values.tenantId.value;
        if (isValid(tenantId)) {
          window.localStorage.setItem('tenantId', tenantId);
          window.location.reload();
        }
      }
    }
    // eslint-disable-next-line
  }, [loadedConfig]);

  return (
    <div className={wrapper}>
      <div className={imageWrapper}>
        <img
          className={image}
          alt='admin logo'
          src={`${process.env.PUBLIC_URL}/assets/images/light-pole.png`}
        />
      </div>
      <div className={formWrapper}>
        <div className={formWrapperLogo}>
          <Logo height={84} theme='dark' />
        </div>
        <div className={formWrapperInputs}>
          <h1 className={pageTitle}>Please supply your tenant id</h1>
          <div className={formWrapperInputsActions}>
            <TextField
              onClick={(e) =>
                formik.setTouched(
                  handleTouched(e.target as HTMLInputElement, formik.touched)
                )
              }
              error={
                formik.touched.tenantId &&
                formik.errors &&
                formik.errors.tenantId?.error !== null
              }
              id='tenantId'
              name='tenantId'
              value={formik.values.tenantId && formik.values.tenantId.value}
              onChange={(newValue) => {
                const tenantId = newValue.target.value;

                formik.setFieldValue('tenantId', {
                  ...formik.values.tenantId,
                  value: tenantId,
                });
              }}
              helperText={
                formik.touched.tenantId && formik.errors.tenantId !== undefined
                  ? formik.errors.tenantId.error
                  : null
              }
            />
            <Button
              disabled={!canSubmit}
              className={submitBtn}
              variant='contained'
              size='medium'
              onClick={() =>
                processTenant
                  ? processTenant(formik?.values?.tenantId?.value)
                  : null
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenantRequestor;
