import { StructuredLogging } from 'interfaces/structuredLogging';
import { isString } from 'lodash';
import { Logger } from './logger';

export class LoggerBuilder implements StructuredLogging {
  private logger!: Logger;

  constructor() {
    this.reset();
  }
  reset(): void {
    this.logger = new Logger();
  }
  setLog(log: Logger): void {
    this.setEventTime(log.eventTime);
    this.setCategory(log.category);
    this.setEventName(log.eventName);
    this.setRequestId(log.requestId);
    this.setParticipantId(log.participantId);
    this.setTimeToComplete(log.timeToComplete);
    this.setRequestStatus(log.requestStatus);
    this.setPayload(log.payload);
    this.setMessage(log.message);
    this.setTradeInterval(log.tradeInterval);
    this.setUserId(log.userId);
    this.setUserEmail(log.userEmail);
    this.setAdpId(log.adpId);
  }

  setAdpId(adpId: string | undefined): void {
    this.logger.adpId = adpId;
  }
  setEventTime(eventTime: Date | undefined): void {
    this.logger.eventTime = isString(eventTime)
      ? new Date(parseInt(eventTime))
      : eventTime;
  }
  setTradeInterval(tradeInterval: Date | undefined): void {
    this.logger.tradeInterval = tradeInterval;
  }
  setCategory(category: string | undefined): void {
    this.logger.category = category;
  }
  setEventName(eventName: string | undefined): void {
    this.logger.eventName = eventName;
  }
  setRequestId(requestId: string | undefined): void {
    this.logger.requestId = requestId;
  }
  setParticipantId(participantId: string | undefined): void {
    this.logger.participantId = participantId;
  }
  setTimeToComplete(timeToComplete: number | undefined): void {
    this.logger.timeToComplete = isString(timeToComplete)
      ? parseInt(timeToComplete)
      : timeToComplete;
  }
  setRequestStatus(requestStatus: number | undefined): void {
    this.logger.requestStatus = isString(requestStatus)
      ? parseInt(requestStatus)
      : requestStatus;
  }
  setPayload(payload: object | string | undefined): void {
    this.logger.payload = JSON.stringify(payload);
  }
  setMessage(message: string | undefined): void {
    this.logger.message = message;
  }
  setUserId(userId: string | undefined): void {
    this.logger.userId = userId;
  }
  setUserEmail(userEmail: string | undefined): void {
    this.logger.userEmail = userEmail;
  }

  getLog(): Logger {
    let log = this.logger;
    this.reset();
    return log;
  }
}
