import { EAPIState } from 'enums/general';
import { useCallback, useEffect, useState } from 'react';
import { IAPIState } from 'interfaces/general';

interface IUseApiRequest extends IAPIState {
  request: () => Promise<any>;
}

const useApiRequest = (
  apiRequest: (params?: any) => Promise<any>,
  onLoadRequest?: boolean | false
): IUseApiRequest => {
  const [status, setStatus] = useState<EAPIState>(EAPIState.Idle);
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<any>(undefined);

  const handleError = (err: any) => {
    console.error('REQUEST ERROR', err);
    setStatus(EAPIState.Error);
    setError(err);
  };

  const request = useCallback(
    async (params?: any) => {
      setStatus(EAPIState.Pending);
      setError(undefined);
      try {
        const { data } = await apiRequest(params);
        if (data) {
          if (data.error)
            return handleError(`Data error ${JSON.parse(data.error)}`);
          setStatus(EAPIState.Fulfilled);
          return setData(data);
        }
        return handleError('Empty data');
      } catch (err) {
        handleError(err);
      }
    },
    [apiRequest]
  );

  useEffect(() => {
    if (onLoadRequest) request();
  }, [onLoadRequest, request]);

  return {
    status,
    data,
    error,
    request,
  };
};

export default useApiRequest;
