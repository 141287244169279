import { useCallback, useEffect, useRef, useState } from 'react';
import {
  setTimeZonePreserveLocal,
  TZonedDateTimeRange,
  ZonedDateTime,
} from '@pci/pci-ui-library';

import { IPositionsGraphConfig } from 'interfaces/data';
import { IFifteenMinutePositions } from 'interfaces/fifteenMinutePosition';

import { useSessionContext } from 'pages/admin/Trade/context';
import AreaStackedChart from 'components/molecules/Charts/Area.stacked';
import { isEmpty, isEqual } from 'lodash';
import { RoundDateTime } from './helpers';

import { pickSelectedParticipant } from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppStore } from 'redux/store';
import { FULFILLED, LOADING } from 'redux/constants';
import { fetchFormulatedOperatingPlanM15 } from 'redux/states/operatingplans.state';
import { Skeleton } from '@mui/material';
import useWorker from 'hooks/useWorker';

const UNIT = 'generatingUnitOperatingPlans';
interface IOperatingPlanChartProps {
  positionsGraphConfig?: IPositionsGraphConfig;
  selectedMarketParticipant?: string;
  selectedTradeInterval?: ZonedDateTime;
  setSelectedTradeInterval?: (
    selectedTradeInterval: ZonedDateTime | undefined
  ) => void;
}

const GraphWorker = new Worker(
  new URL('../../../../workers/operatingplangraph.worker.ts', import.meta.url),
  {
    type: 'module',
  }
);

const OperatingPlanChart = ({
  selectedMarketParticipant = UNIT,
}: IOperatingPlanChartProps): JSX.Element => {
  const { instance: worker, workerStatus, results } = useWorker(GraphWorker);
  const dispatch = useDispatch<AppDispatch>();
  const { formulatedStatus, formulated } = useSelector(
    (store: AppStore) => store.operatingplans
  );
  const { sessionObj, setSession, startSession, timeZone, getIntervalsStatus } =
    useSessionContext();
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const ref = useRef<IFifteenMinutePositions | null>(null);

  const [currentTradeDateTime, setCurrentTradeDateTime] =
    useState<ZonedDateTime>(ZonedDateTime.parseIso(startSession, timeZone));

  const [selectedFifteenMinutePositions, setSelectedFifteenMinutePositions] =
    useState<IFifteenMinutePositions>();

  const [currentDateTimeRange, setCurrentDateTimeRange] =
    useState<TZonedDateTimeRange>([
      startSession.subtract(6, 'hour'),
      startSession.add(24, 'hour'),
    ]);

  useEffect(() => {
    const zonedInitialDate: ZonedDateTime = startSession.subtract(6, 'hour');
    const zonedEndDate: ZonedDateTime = zonedInitialDate.add(24, 'hour');
    setCurrentTradeDateTime(ZonedDateTime.parseIso(startSession, timeZone));

    if (currentDateTimeRange !== null) {
      const previousZonedInitialDate: ZonedDateTime | null =
        currentDateTimeRange[0];
      const previousEndDate: ZonedDateTime | null = currentDateTimeRange[1];

      if (previousZonedInitialDate !== null && previousEndDate !== null) {
        if (
          !zonedInitialDate.isSame(previousZonedInitialDate) ||
          !zonedEndDate.isSame(previousEndDate)
        ) {
          setCurrentDateTimeRange([zonedInitialDate, zonedEndDate]);
        }
      }
    }
  }, [currentDateTimeRange, sessionObj, startSession, timeZone]);

  const fetchPlans = useCallback(() => {
    const startAt = encodeURIComponent(
      startSession.subtract(1, 'day').toIsoString()
    );
    const stopAt = encodeURIComponent(startSession.add(2, 'day').toIsoString());
    const opProps = {
      marketParticipantId: selectedParticipant?.id,
      startAt,
      stopAt,
    };
    dispatch(fetchFormulatedOperatingPlanM15(opProps));
  }, [startSession, selectedParticipant?.id, dispatch]);

  const onSelectedDateTimeChange = useCallback(
    (selectedDate: Date) => {
      const sessionToSet = setTimeZonePreserveLocal(
        ZonedDateTime.fromDate(
          RoundDateTime(15 * 60 * 1000, selectedDate),
          ZonedDateTime.defaultTimeZone()
        ),
        timeZone
      );
      setSession(sessionToSet);
    },
    [timeZone, setSession]
  );
  useEffect(() => {
    if (workerStatus === 'complete') {
      if (!isEqual(ref.current, results as IFifteenMinutePositions)) {
        ref.current = results as IFifteenMinutePositions;

        setSelectedFifteenMinutePositions(results as IFifteenMinutePositions);
      }
    }
  }, [workerStatus, results]);

  useEffect(() => {
    if (
      getIntervalsStatus === 'complete' &&
      selectedParticipant?.id &&
      formulatedStatus !== LOADING
    ) {
      fetchPlans();
    }
    // eslint-disable-next-line
  }, [getIntervalsStatus, selectedParticipant?.id, fetchPlans]);

  useEffect(() => {
    if (formulatedStatus === FULFILLED && document.readyState === 'complete') {
      if (isEmpty(formulated)) {
        const selectedFifteenMinutePosition: IFifteenMinutePositions = {
          dataGroups: [],
          marketParticipant: null,
          dataSetKeys: [],
          dataPerTimeOffset: [],
          fields: [],
        };
        setSelectedFifteenMinutePositions(selectedFifteenMinutePosition);
      } else if (!isEmpty(formulated) && window.Worker) {
        let obj = JSON.parse(
          JSON.stringify({
            command: 'generate',
            params: {
              operatingPlan: formulated,
              timeZone,
              currentDateTimeRange,
              unit: UNIT,
            },
          })
        );
        worker.postMessage(obj);
      }
    }
    // eslint-disable-next-line
  }, [formulatedStatus, currentDateTimeRange, timeZone]);

  useEffect(() => {
    return () => {
      if (ref?.current) {
        ref.current = null;
      }
    };
  }, []);

  return (
    <>
      {selectedFifteenMinutePositions?.dataPerTimeOffset &&
      formulatedStatus === FULFILLED ? (
        <AreaStackedChart
          chartId={`daily-fifteen-minute-${selectedMarketParticipant}-position-stacked-chart`}
          currentDateTime={currentTradeDateTime}
          data={selectedFifteenMinutePositions?.dataPerTimeOffset || []}
          fields={selectedFifteenMinutePositions?.fields || []}
          selectedDateTime={sessionObj}
          timeZone={timeZone}
          onSelectedDateTimeChange={onSelectedDateTimeChange}
        />
      ) : (
        <Skeleton variant='rounded' width={'100%'} height={'100%'} />
      )}
    </>
  );
};

export default OperatingPlanChart;
