import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { ITransformedMarketBidOfferData } from 'components/market/organisms/MarketTrader/TraderChart/types';
import {
  BID_COLOUR_DARK,
  BID_COLOUR_LIGHT,
  BID_STRATEGY_BACK_COLOUR_DARK,
  BID_STRATEGY_BACK_COLOUR_LIGHT,
  CENTRAL_COLOUR,
  DEFAULT_FONT_COLOR,
  OFFER_COLOUR_DARK,
  OFFER_COLOUR_LIGHT,
  OFFER_STRATEGY_BACK_COLOUR_DARK,
  OFFER_STRATEGY_BACK_COLOUR_LIGHT,
} from 'constants/styles';
import { ETheme } from 'enums/styles';
import { useLayoutEffect, useRef } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { am4themesDark } from 'utils/styles';

interface IBidLineChartProps {
  chartId: string;
  data: ITransformedMarketBidOfferData;
}

const Chart = styled.div`
  height: 427px;
  width: 100%;
`;

const BidOfferLineChart = ({
  chartId,
  data,
}: IBidLineChartProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const chartRef = useRef<am4charts.XYChart>();

  useLayoutEffect(() => {
    const { balance, chartConfig, chartData } = data;
    const {
      adjustedMaxValue,
      adjustedMinValue,
      bidLimit,
      offerLimit,
      maxPriceInDollars,
      minPriceInDollars,
      tradeOfferLimit,
      tradeBidLimit,
      bidQuantityBack,
      offerQuantityBack,
      offerQuantityFront,
      bidQuantityFront,
    } = chartConfig;

    if (currentTheme === ETheme.Dark) {
      am4core.useTheme(am4themesDark);
    } else {
      am4core.unuseTheme(am4themesDark);
    }

    chartRef.current = am4core.create(chartId, am4charts.XYChart);
    chartRef.current.width = am4core.percent(100);
    chartRef.current.seriesContainer.draggable = false;
    chartRef.current.seriesContainer.resizable = false;
    chartRef.current.yAxes.clear();
    chartRef.current.xAxes.clear();
    chartRef.current.series.clear();

    const bidColor: am4core.Color = am4core.color(
      currentTheme === ETheme.Dark ? BID_COLOUR_DARK : BID_COLOUR_LIGHT
    );
    const offerColor: am4core.Color = am4core.color(
      currentTheme === ETheme.Dark ? OFFER_COLOUR_DARK : OFFER_COLOUR_LIGHT
    );

    const tradeBidColor: am4core.Color = am4core.color(
      currentTheme === ETheme.Dark ? BID_COLOUR_LIGHT : BID_COLOUR_DARK
    );
    const tradeOfferColor: am4core.Color = am4core.color(
      currentTheme === ETheme.Dark ? OFFER_COLOUR_LIGHT : OFFER_COLOUR_DARK
    );

    const offerQuantityBackColor: am4core.Color = am4core.color(
      currentTheme === ETheme.Dark
        ? OFFER_STRATEGY_BACK_COLOUR_DARK
        : OFFER_STRATEGY_BACK_COLOUR_LIGHT
    );

    const bidQuantityBackColor: am4core.Color = am4core.color(
      currentTheme === ETheme.Dark
        ? BID_STRATEGY_BACK_COLOUR_DARK
        : BID_STRATEGY_BACK_COLOUR_LIGHT
    );

    // Create axes
    let xAxis = chartRef.current.xAxes.push(new am4charts.ValueAxis());
    xAxis.renderer.labels.template.fill = am4core.color(CENTRAL_COLOUR);
    xAxis.min = adjustedMinValue;
    xAxis.max = adjustedMaxValue;
    xAxis.renderer.minGridDistance = 50;

    let valueAxis = chartRef.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = '$#.##';
    valueAxis.adjustLabelPrecision = false;
    valueAxis.renderer.labels.template.fill = am4core.color(CENTRAL_COLOUR);
    valueAxis.min = minPriceInDollars;
    valueAxis.max = maxPriceInDollars;

    if (valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
    }

    let bidSeries = chartRef.current.series.push(
      new am4charts.StepLineSeries()
    );
    bidSeries.name = 'bidSeries';
    bidSeries.dataFields.valueX = 'bidValue';
    bidSeries.dataFields.valueY = 'bidPrice';
    bidSeries.stroke = bidColor;
    bidSeries.strokeWidth = 3;
    bidSeries.sequencedInterpolation = false;

    let offerSeries = chartRef.current.series.push(
      new am4charts.StepLineSeries()
    );
    offerSeries.name = 'offerSeries';
    offerSeries.dataFields.valueX = 'offerValue';
    offerSeries.dataFields.valueY = 'offerPrice';
    offerSeries.stroke = bidColor;
    offerSeries.strokeWidth = 3;
    offerSeries.sequencedInterpolation = false;

    let bidTradeSeries = chartRef.current.series.push(
      new am4charts.StepLineSeries()
    );
    bidTradeSeries.name = 'bidTrade';
    bidTradeSeries.dataFields.valueX = 'bidTradeValue';
    bidTradeSeries.dataFields.valueY = 'bidTradePrice';
    bidTradeSeries.stroke = offerColor;
    bidTradeSeries.strokeWidth = 3;

    let offerTradeSeries = chartRef.current.series.push(
      new am4charts.StepLineSeries()
    );
    offerTradeSeries.name = 'offerTrade';
    offerTradeSeries.dataFields.valueX = 'offerTradeValue';
    offerTradeSeries.dataFields.valueY = 'offerTradePrice';
    offerTradeSeries.stroke = offerColor;
    offerTradeSeries.strokeWidth = 3;

    let balanceSeries = chartRef.current.series.push(
      new am4charts.LineSeries()
    );
    balanceSeries.name = 'Balance';
    balanceSeries.dataFields.valueX = 'value';
    balanceSeries.dataFields.valueY = 'balanceInDollars';
    balanceSeries.stroke = am4core.color(CENTRAL_COLOUR);
    balanceSeries.strokeWidth = 2;
    balanceSeries.sequencedInterpolation = false;

    let bullet = balanceSeries.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color(CENTRAL_COLOUR);
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 6;
    circle.strokeWidth = 2;

    if (offerLimit !== undefined) {
      let offerLine = xAxis.axisRanges.create();
      offerLine.value = offerLimit;
      offerLine.grid.strokeWidth = 0;
      offerLine.bullet = new am4core.Rectangle();
      offerLine.bullet.width = 2;
      offerLine.bullet.height = 7;
      offerLine.bullet.fill = am4core.color(CENTRAL_COLOUR);
      offerLine.bullet.horizontalCenter = 'middle';
    }

    if (bidLimit !== undefined) {
      let bidLine = xAxis.axisRanges.create();
      bidLine.value = bidLimit;
      bidLine.grid.strokeWidth = 0;
      bidLine.bullet = new am4core.Rectangle();
      bidLine.bullet.width = 2;
      bidLine.bullet.height = 7;
      bidLine.bullet.fill = am4core.color(CENTRAL_COLOUR);
      bidLine.bullet.horizontalCenter = 'middle';
    }

    if (tradeOfferLimit !== undefined) {
      let offerLine = xAxis.axisRanges.create();
      offerLine.value = tradeOfferLimit;
      offerLine.label.inside = false;
      offerLine.grid.strokeWidth = 0;
      offerLine.label.text = '{value}';
      offerLine.label.fill = am4core.color(DEFAULT_FONT_COLOR);
      offerLine.label.fontSize = '16';
      offerLine.label.fontWeight = 'bold';
      offerLine.label.dy = 20;

      offerLine.bullet = new am4core.Rectangle();
      offerLine.bullet.width = 2;
      offerLine.bullet.height = 7;
      offerLine.bullet.fill = tradeOfferColor;
      offerLine.bullet.horizontalCenter = 'middle';
    }

    if (tradeBidLimit !== undefined) {
      let bidLine = xAxis.axisRanges.create();
      bidLine.value = tradeBidLimit;
      bidLine.label.inside = false;
      bidLine.grid.strokeWidth = 0;
      bidLine.label.text = '{value}';
      bidLine.label.fill = am4core.color(DEFAULT_FONT_COLOR);
      bidLine.label.fontSize = '16';
      bidLine.label.fontWeight = 'bold';
      bidLine.label.dy = 20;

      bidLine.bullet = new am4core.Rectangle();
      bidLine.bullet.width = 2;
      bidLine.bullet.height = 7;
      bidLine.bullet.fill = tradeBidColor;
      bidLine.bullet.horizontalCenter = 'middle';
    }

    if (balance !== undefined) {
      let balancePoint = xAxis.axisRanges.create();
      balancePoint.value = balance;
      balancePoint.grid.stroke = am4core.color(DEFAULT_FONT_COLOR);
      balancePoint.grid.strokeWidth = 1;
      balancePoint.grid.strokeOpacity = 1;
      balancePoint.grid.strokeDasharray = '4, 8';
      balancePoint.grid.above = true;

      balancePoint.bullet = new am4core.Rectangle();
      balancePoint.bullet.width = 2;
      balancePoint.bullet.height = 7;
      balancePoint.bullet.fill = am4core.color(DEFAULT_FONT_COLOR);
      balancePoint.bullet.horizontalCenter = 'middle';
    }

    // STRATEGY
    if (offerQuantityBack !== undefined && offerLimit !== undefined) {
      chartData.push({
        value: offerQuantityFront || 0,
        strategyOfferValue: offerLimit,
        strategyOfferPrice: minPriceInDollars,
      });

      chartData.push({
        value: offerQuantityFront || 0,
        strategyOfferValue:
          offerLimit - offerQuantityBack < 0
            ? 0
            : offerLimit - offerQuantityBack,
        strategyOfferPrice: minPriceInDollars,
      });

      let strategyOfferSeries = chartRef.current.series.push(
        new am4charts.StepLineSeries()
      );
      strategyOfferSeries.name = 'strategyOfferSeries';
      strategyOfferSeries.dataFields.valueX = 'strategyOfferValue';
      strategyOfferSeries.dataFields.valueY = 'strategyOfferPrice';
      strategyOfferSeries.stroke = offerQuantityBackColor;
      strategyOfferSeries.strokeWidth = 2000;
      strategyOfferSeries.strokeOpacity = 0.6;
      strategyOfferSeries.sequencedInterpolation = false;
    }

    if (offerQuantityFront !== undefined && offerLimit !== undefined) {
      chartData.push({
        value: 0,
        strategyOfferFrontValue: 0,
        strategyOfferFrontPrice: minPriceInDollars,
      });
      chartData.push({
        value: 0,
        strategyOfferFrontValue: offerQuantityFront,
        strategyOfferFrontPrice: minPriceInDollars,
      });

      let strategyOfferSeries = chartRef.current.series.push(
        new am4charts.StepLineSeries()
      );
      strategyOfferSeries.name = 'strategyOfferSeries';
      strategyOfferSeries.dataFields.valueX = 'strategyOfferFrontValue';
      strategyOfferSeries.dataFields.valueY = 'strategyOfferFrontPrice';
      strategyOfferSeries.stroke = offerQuantityBackColor;
      strategyOfferSeries.strokeWidth = 2000;
      strategyOfferSeries.strokeOpacity = 0.6;
      strategyOfferSeries.sequencedInterpolation = false;
    }

    if (bidQuantityBack !== undefined && bidLimit !== undefined) {
      chartData.push({
        value: 0,
        strategyBidValue: bidLimit,
        strategyBidPrice: minPriceInDollars,
      });

      chartData.push({
        value: 0,
        strategyBidValue:
          bidLimit + bidQuantityBack > 0 ? 0 : bidLimit + bidQuantityBack,
        strategyBidPrice: minPriceInDollars,
      });

      let strategyBidSeries = chartRef.current.series.push(
        new am4charts.StepLineSeries()
      );
      strategyBidSeries.name = 'strategyBidSeries';
      strategyBidSeries.dataFields.valueX = 'strategyBidValue';
      strategyBidSeries.dataFields.valueY = 'strategyBidPrice';
      strategyBidSeries.stroke = bidQuantityBackColor;
      strategyBidSeries.strokeWidth = 2000;
      strategyBidSeries.strokeOpacity = 0.6;
      strategyBidSeries.sequencedInterpolation = false;
    }

    if (bidQuantityFront !== undefined && bidLimit !== undefined) {
      chartData.push({
        value: 0,
        strategyBidFrontValue: 0 - bidQuantityFront,
        strategyBidFrontPrice: minPriceInDollars,
      });
      chartData.push({
        value: 0,
        strategyBidFrontValue: 0,
        strategyBidFrontPrice: minPriceInDollars,
      });

      let strategyBidSeries = chartRef.current.series.push(
        new am4charts.StepLineSeries()
      );
      strategyBidSeries.name = 'strategyBidSeries';
      strategyBidSeries.dataFields.valueX = 'strategyBidFrontValue';
      strategyBidSeries.dataFields.valueY = 'strategyBidFrontPrice';
      strategyBidSeries.stroke = bidQuantityBackColor;
      strategyBidSeries.strokeWidth = 2000;
      strategyBidSeries.strokeOpacity = 0.6;
      strategyBidSeries.sequencedInterpolation = false;
    }

    chartRef.current.data = chartData;

    return () => chartRef.current?.dispose();
  }, [chartId, currentTheme, data]);

  return <Chart id={chartId} />;
};

export default BidOfferLineChart;
