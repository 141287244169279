import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { MatchedTrade } from 'api/models/MatchedTrade.model';
import { EResolution } from 'components/molecules/RangeFilterTab/constants';
import { isEmpty } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import { AppStore } from 'redux/store';

const STORE_NAME = 'matchedtrade';

export interface MatchedTradesState {
  matchedtrades: MatchedTrade[];
  status: string;
}

const initialState: MatchedTradesState = {
  matchedtrades: [],
  status: IDLE,
};

interface requestParams {
  marketParticipantId: string | undefined;
  startAt: string;
  stopAt: string;
  timeResolution: EResolution;
  outputTimeZone: string;
}

export const fetchMatchedTrades = createAsyncThunk(
  `${STORE_NAME}/fetchMatchedTrades`,
  async (requestParams: requestParams) => {
    try {
      const response = await AutoTraderApi.getMatchedTrades(requestParams);
      if (isEmpty(response?.data)) {
        return { ...initialState, status: FULFILLED };
      }
      return { matchedtrades: response?.data, status: FULFILLED };
    } catch (error) {}
  }
);

export const matchedTradesSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMatchedTrades.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(fetchMatchedTrades.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

export const selectMatchedTrades = (state: AppStore) => {
  return state.matchedtrades.matchedtrades as MatchedTrade[];
};

export default matchedTradesSlice.reducer;
