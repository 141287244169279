import { RefObject, useCallback, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { TTimeUnit } from '@pci/pci-ui-library';

import { EOrientation } from 'enums/general';

import { CATEGORY_KEY, LABEL_KEY, VALUE_KEY } from './constants';
import { IChartDefault } from './types';

export interface IChartTimeInterval {
  timeUnit: TTimeUnit;
  count: number;
}

export interface IChartLabel {
  location: number;
  rotation: number;
}
export interface IChartFieldConfig {
  name: string;
  color: string;
}
export interface IChartInputFormat {
  format: string;
}

export interface IChartConfig
  extends IChartTimeInterval,
    IChartLabel,
    IChartInputFormat {}

export type TBarChartData =
  | {
      [LABEL_KEY]: string | number;
      [VALUE_KEY]: string | number;
      [CATEGORY_KEY]: string | number;
    }[]
  | false;

export interface IBarChart<DataT> {
  orientation?: EOrientation.Vertical | EOrientation.Horizontal;
  categoryTitle?: string;
  valueTitle?: string;
  isClustered?: boolean;
  isStacked?: boolean;
  isTimeBased?: boolean;
  loaderRef?: RefObject<HTMLDivElement>;
  data?: DataT[];
  chartTitle: string;
  chartConfig?: IChartConfig;
  fieldConfig?: IChartFieldConfig[];
}

const BarChart = <DataT extends unknown>({
  fieldConfig = [],
  isClustered = false,
  isStacked = false,
  isTimeBased = false,
  orientation = EOrientation.Vertical,
  chartId,
  loaderRef,
  chartTitle,
  data,
  chartConfig,
  style,
}: IChartDefault & IBarChart<DataT>): JSX.Element => {
  am4core.options.queue = true;
  const createAxis = useCallback(
    (chartElement: am4charts.XYChart, isHorizontal: boolean): void => {
      let axis: any;
      if (isTimeBased) {
        axis = isHorizontal
          ? chartElement.yAxes.push(new am4charts.DateAxis())
          : chartElement.xAxes.push(new am4charts.DateAxis());
      } else {
        axis = isHorizontal
          ? chartElement.yAxes.push(new am4charts.CategoryAxis())
          : chartElement.xAxes.push(new am4charts.CategoryAxis());
      }

      if (isTimeBased) {
        if (chartConfig?.format)
          chartElement.dateFormatter.inputDateFormat = chartConfig.format;
        axis.renderer.minGridDistance = 120;
        axis.renderer.labels.template.location = chartConfig?.location;
        axis.renderer.labels.template.rotation = chartConfig?.rotation;
        axis.renderer.labels.template.maxWidth =
          chartConfig?.timeUnit === 'minute' || chartConfig?.timeUnit === 'hour'
            ? 120
            : 60;
        axis.baseInterval = {
          timeUnit: chartConfig?.timeUnit,
          count: chartConfig?.count,
        };
        axis.gridIntervals.setAll([
          {
            timeUnit: chartConfig?.timeUnit,
            count: chartConfig?.count,
          },
        ]);
        axis.groupData = true;
        axis.groupIntervals.setAll([
          { timeUnit: chartConfig?.timeUnit, count: chartConfig?.count },
        ]);
      } else {
        axis.renderer.labels.template.maxWidth = 120;
        axis.renderer.minGridDistance = 20;
      }
      axis.renderer.labels.template.wrap = true;
      axis.dataFields.category = 'id';
      axis.renderer.inversed = isHorizontal;

      if (isHorizontal) chartElement.xAxes.push(new am4charts.ValueAxis());
      else chartElement.yAxes.push(new am4charts.ValueAxis());
    },
    [isTimeBased, chartConfig]
  );

  const createSeries = useCallback(
    (chartElement: am4charts.XYChart, isHorizontal: boolean): void => {
      fieldConfig.forEach((config, index) => {
        const series = chartElement.series.push(new am4charts.ColumnSeries());
        series.columns.template.fill = am4core.color(config.color);
        series.columns.template.stroke = am4core.color(config.color);
        series.name = config.name;
        series.fill = am4core.color(config.color);

        if (!isClustered) series.clustered = false;
        if (isStacked) series.stacked = true;

        if (isHorizontal) {
          series.dataFields.valueX = config.name.toLowerCase();
          series.tooltipText = '{name}: [bold]{valueX.value}[/]';
          series.dataFields[isTimeBased ? 'dateY' : 'categoryY'] = 'id';
          return;
        }
        series.tooltipText = '{name}: [bold]{valueY.value}[/]';
        series.groupFields.valueY = 'sum';
        series.dataFields.valueY = config.name.toLowerCase();
        series.dataFields[isTimeBased ? 'dateX' : 'categoryX'] = 'id';
      });
    },
    [fieldConfig, isClustered, isStacked, isTimeBased]
  );

  const showIndicator = useCallback((chartElement: am4charts.XYChart): void => {
    if (chartElement.tooltipContainer) {
      let indicator = chartElement.tooltipContainer.createChild(
        am4core.Container
      );
      indicator.background.fill = am4core.color('#fff');
      indicator.background.fillOpacity = 0.8;
      indicator.width = am4core.percent(100);
      indicator.height = am4core.percent(100);

      var indicatorLabel = indicator.createChild(am4core.Label);
      indicatorLabel.text = 'No data to show';
      indicatorLabel.align = 'center';
      indicatorLabel.valign = 'middle';
      indicatorLabel.fontSize = 20;
    }
  }, []);

  useLayoutEffect(() => {
    const chartElement = am4core.create(chartId, am4charts.XYChart);
    const isHorizontal: boolean = orientation === EOrientation.Horizontal;

    if (!isTimeBased) chartElement.paddingRight = 50;

    const titleContainer = chartElement.chartContainer.createChild(
      am4core.Container
    );
    titleContainer.layout = 'absolute';
    titleContainer.toBack();
    titleContainer.width = am4core.percent(100);

    const title = titleContainer.createChild(am4core.Label);
    title.text = chartTitle;
    title.align = 'left';
    title.marginTop = -50;
    title.fontFamily = 'Lato';
    title.fontSize = 18;
    title.fontWeight = '700';

    chartElement.legend = new am4charts.Legend();
    chartElement.legend.position = 'top';
    chartElement.legend.contentAlign = 'right';
    chartElement.legend.paddingBottom = 23;
    chartElement.legend.fontSize = 14;
    chartElement.legend.useDefaultMarker = true;

    const markerTemplate = chartElement.legend.markers.template;
    markerTemplate.width = 14;
    markerTemplate.height = 14;

    const marker = chartElement.legend.markers.template.children.getIndex(
      0
    ) as am4core.RoundedRectangle;
    marker.cornerRadius(12, 12, 12, 12);

    if (data) chartElement.data = data;
    chartElement.cursor = new am4charts.XYCursor();
    chartElement.width = am4core.percent(100);
    chartElement.seriesContainer.svgContainer?.measure();

    createAxis(chartElement, isHorizontal);
    createSeries(chartElement, isHorizontal);

    chartElement.events.on('validated', () => {
      if (!data?.length) {
        showIndicator(chartElement);
      }
      if (loaderRef?.current) loaderRef.current.classList.add('hidden');
    });

    chartElement.events.on('beforedatavalidated', () => {
      if (loaderRef?.current) loaderRef.current.classList.remove('hidden');
    });

    return () => {
      chartElement.dispose();
    };
  }, [
    chartId,
    createAxis,
    createSeries,
    data,
    orientation,
    chartTitle,
    isTimeBased,
    loaderRef,
    showIndicator,
  ]);
  return <div id={chartId} style={style} />;
};

export default BarChart;
