import { forwardRef, useCallback } from 'react';

import DataGrid, { IDataGrid } from 'components/molecules/DataGrid/DataGrid';

const PlansGrid = forwardRef(
  ({ data, columnDefs, ...props }: IDataGrid, ref) => {
    const TypeComponent = (params: any) => (
      <span style={{ marginLeft: 15 }}>{params.value}</span>
    );

    const customDefaultColDef = {
      editable: false,
      sortable: false,
      filterable: false,
      floatingFilter: false,
      suppressMovable: true,
      menuTabs: [],
      minWidth: 80,
    };

    const onGridReady = useCallback((params) => {
      params.api.sizeColumnsToFit();
      window.addEventListener('resize', function () {
        setTimeout(function () {
          params.api.sizeColumnsToFit();
        });
      });
    }, []);

    return (
      <DataGrid
        ref={ref}
        data={data}
        components={{
          typeComponent: TypeComponent,
        }}
        onGridReady={onGridReady}
        columnDefs={columnDefs}
        defaultColDef={customDefaultColDef}
        {...props}
      />
    );
  }
);

export default PlansGrid;
