import {
  GridApi,
  GridReadyEvent,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
} from 'ag-grid-community';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { useAppContext } from 'App/AppProvider';
import CellEditor from 'components/molecules/CellEditor/CellEditor';
import CellRender from 'components/molecules/CellRender/CellRender';
import useQueue, { CREATE, UPDATE } from 'hooks/useQueue';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from '../ResourcesList/ResourcesList.module.scss';
import FolioSkeleton from '../GridSkeleton/GridSkeleton';
import { Meter } from 'api/models/Meter.model';
import {
  DOUBLE,
  METER_COLUMNS,
  MW,
  MW_LEVEL,
  initialValues,
} from './constants';
import { IMetersValues } from './IMetersValues';
import { metersSchema } from './metersSchema';
import { useFormik } from 'formik';
import { IAdditions, ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { isEmpty } from 'lodash';
import { rowStyler } from './helpers/helpers';
import { pickSelectedTimezone } from 'redux/states/miscellaneous.state';

import { useDispatch, useSelector } from 'react-redux';
import RootStore, { AppDispatch, AppStore } from 'redux/store';
import {
  fetchMeterStatuses,
  fetchMeters,
  fetchResourcesForMeters,
  metersUpsert,
  selectFullMeters,
  selectResourcesIdentifiers,
} from 'redux/states/meters.state';
import { FULFILLED } from 'redux/constants';
import useRequestManager from 'hooks/useRequestManager';
import { ERequestManager } from 'enums/requestManager';

const MetersPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { resourcesStatus, metersStatus, meterStatusesStatus } = useSelector(
    (store: AppStore) => store.meters
  );
  const resourcesIdentifiers = useSelector(selectResourcesIdentifiers);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { pushNotification, logEvent } = useAppContext();
  const { resourcesPageResources, resourcesWrapperResources } = styles;

  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      editable: true,
      cellEditorPopup: false,
      wrapText: true,
      minWidth: 90,
      flex: 1,
      suppressMenu: true,
      cellEditor: CellEditor,
    };
  }, []);

  // METERS
  const [oldEditing, setOldEditing] = useState<Meter>();
  const [editingModeMeter, setEditingModeMeter] = useState<boolean>(false);
  const [metersList, setMetersList] = useState<Meter[]>();
  const [loadingMeters, setLoadingMeters] = useState<boolean>(true);
  const [gridApiMeters, setGridApiMeters] = useState<GridApi>();
  const [additions, setAdditions] = useState<IAdditions>({
    key: '',
    additionsIds: [],
  });

  const meterColumnDefs = METER_COLUMNS;
  const [colDef, setColDef] = useState<any[]>([]);
  const meterQueueHook = useQueue<Meter, 'tagId'>('tagId');

  const [newRowValue, setNewRowValue] = useState(initialValues);
  const {
    enqueue,
    execute,
    result,
    status: requestManagerStatus,
  } = useRequestManager();

  const formikMeters = useFormik({
    initialValues: initialValues,
    onSubmit: () => {},
    validate: (values: IMetersValues) => {
      let errors = metersSchema(values);

      if (gridApiMeters) {
        const instances =
          gridApiMeters.getCellEditorInstances() as ICellEditorImperativeHandle[];

        if (instances.length > 0) {
          instances.forEach((instance) => {
            if (typeof instance.validateField === 'function') {
              const field = instance.columnField();
              const errored = new Map(Object.entries(errors)).get(field)?.error;
              instance.validateField(errored);
            }
          });
        }
      }
      return errors;
    },
  });

  const onClickSaveButton = async () => {
    const { getQueue } = meterQueueHook;
    const queue = getQueue();
    const created = [...queue.filter((element) => element.status === CREATE)];

    if (created.length) {
      created.forEach(({ data }) => {
        const { newRecord, ...cleanData } = data;
        enqueue(AutoTraderApi.postMeters(cleanData));
      });
    }

    const updated = [...queue.filter((element) => element.status === UPDATE)];

    if (updated.length) {
      updated.forEach(({ data }) => {
        const { newRecord, ...cleanData } = data;
        enqueue(AutoTraderApi.postMeters(cleanData));
      });
    }
    execute();
  };

  const onClickAddMeterRow = () => {
    if (gridApiMeters) {
      const rowValues = { ...newRowValue };
      const {
        id: { value },
      } = rowValues;
      const addition = {
        key: 'id',
        additionsIds: [...additions.additionsIds, value],
      };
      setAdditions(addition);

      gridApiMeters.stopEditing();
      gridApiMeters?.applyTransaction({
        add: [rowValues],
        addIndex: 0,
      });
      toggleEditingMeters(true);
      gridApiMeters?.startEditingCell({
        rowIndex: 0,
        colKey: 'tagId',
      });
    }
  };

  const onClickEnableMeterEditing = () => {
    toggleEditingMeters(true);
  };

  const onGridMetersReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(65);
    gridEvent.api.sizeColumnsToFit();
    setGridApiMeters(gridEvent.api);
  };

  const onRowEditingStoppedHandler = (e: RowEditingStoppedEvent) => {
    const { isInQueue, update, setInQueue } = meterQueueHook;
    const {
      tagId,
      intervalType,
      offThreshold,
      clockTolerance,
      description,
      source,
      resourceIdentifier,
      newRecord,
    } = e.data;

    const meter: Meter = {
      tagId,
      intervalType,
      offThreshold,
      clockTolerance,
      description,
      source,
      newRecord,
      resourceIdentifier,
      dataType: DOUBLE,
      unitOfMeasure: MW,
      measurementType: MW_LEVEL,
    };
    const key = meter.tagId;

    if (JSON.stringify(oldEditing) !== JSON.stringify(e.data)) {
      if (!meter.newRecord) {
        // UPDATE
        if (isInQueue(key)) {
          // IF is already on the queue
          update(key, meter, UPDATE);
        } else {
          setInQueue(meter, UPDATE);
        }
      } else {
        if (isInQueue(key)) {
          // UPDATE CREATED
          update(key, meter, CREATE);
        } else {
          // CREATE
          setInQueue(meter, CREATE);
        }
      }
    }
  };

  const onRowEditingStartedHandler = (e: RowEditingStartedEvent) => {
    const instances =
      gridApiMeters?.getCellEditorInstances() as ICellEditorImperativeHandle[];

    if (e.data.newRecord) {
      formikMeters.setFieldValue('newRecord', true);
    } else {
      instances.forEach((instance) => {
        if (
          instance.columnField() === 'resourceIdentifier' &&
          instance.getValue() &&
          typeof instance.setEditable === 'function'
        ) {
          instance.setEditable(true);
        }
      });

      formikMeters.setFieldValue('newRecord', false);
    }

    setOldEditing({ ...e.data });
  };

  const getMeters = () => {
    const fullMeters = selectFullMeters(RootStore.getState());
    setLoadingMeters(false);
    setMetersList(fullMeters);
    gridApiMeters?.refreshCells();
    gridApiMeters?.refreshHeader();
  };

  const toggleEditingMeters = useCallback(
    (enable: boolean) => {
      if (gridApiMeters && !enable) {
        gridApiMeters.stopEditing();
      }
      setEditingModeMeter(enable);
    },
    [gridApiMeters]
  );

  const cleanMetersQueue = useCallback(() => {
    const { cleanQueue } = meterQueueHook;
    cleanQueue();
  }, [meterQueueHook]);

  const fetchComplements = useCallback(() => {
    dispatch(fetchResourcesForMeters());
    dispatch(fetchMeterStatuses());
  }, [dispatch]);

  const onClickMeterReset = () => {
    toggleEditingMeters(false);
    cleanMetersQueue();
    setAdditions({
      key: '',
      additionsIds: [],
    });
    if (!editingModeMeter) {
      setMetersList([]);
      setLoadingMeters(true);
      fetchComplements();
    }
  };

  useEffect(() => {
    if (
      requestManagerStatus === ERequestManager.COMPLETED &&
      pushNotification
    ) {
      const { suceeded, clientErrored, serverErrored } = result;
      let created = 0;
      let updated = 0;
      if (!isEmpty(suceeded)) {
        suceeded.forEach((record) => {
          const {
            data,
            config: { method },
          }: any = record;
          if (method === 'put') {
            updated = updated + 1;
            dispatch(metersUpsert(data));
            if (logEvent) {
              logEvent({
                eventTime: new Date(),
                eventName: Event.MODIFIEDMETER,
                payload: { ...data },
                timeToComplete: data.duration,
                category: Category.METERS_PAGE,
                requestStatus: data.status,
              });
            }
          }
          if (method === 'post') {
            created = created + 1;
            dispatch(metersUpsert(data));
            if (logEvent) {
              logEvent({
                eventTime: new Date(),
                eventName: Event.ADDEDMETER,
                payload: { ...data },
                timeToComplete: data.duration,
                category: Category.METERS_PAGE,
                requestStatus: data.status,
              });
            }
          }
        });
      }
      if (!isEmpty(clientErrored)) {
        clientErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
          } = record;
          pushNotification('error', `${message}`);
        });
      }
      if (!isEmpty(serverErrored)) {
        serverErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
          } = record;
          pushNotification('error', `${message}`);
        });
      }

      if (created) pushNotification('success', `${created} Rows was created`);
      if (updated) pushNotification('info', `${updated} Rows was updated`);
      onClickMeterReset();
    }
    // eslint-disable-next-line
  }, [requestManagerStatus]);

  useEffect(() => {
    if (resourcesStatus === FULFILLED && !isEmpty(resourcesIdentifiers)) {
      const sortResources = (a: string, b: string) => a.localeCompare(b);

      const newColDef = [...meterColumnDefs];

      setColDef(() => {
        if (newColDef[1].cellEditorParams?.options) {
          const resources = [
            ...resourcesIdentifiers
              .sort(sortResources)
              .map((resourceIdentifier: string) => ({
                value: resourceIdentifier,
                label: resourceIdentifier,
              })),
          ];

          newColDef[1].cellEditorParams.options = resources;
          setNewRowValue((oldRowValue) => {
            oldRowValue.resourceIdentifier = resources[0] as any;
            return oldRowValue;
          });
        }
        return newColDef;
      });
    }
  }, [resourcesStatus, resourcesIdentifiers, setColDef, meterColumnDefs]);

  useEffect(() => {
    if (metersStatus === FULFILLED) {
      getMeters();
    }
    // eslint-disable-next-line
  }, [metersStatus]);

  useEffect(() => {
    if (meterStatusesStatus === FULFILLED) {
      dispatch(fetchMeters());
    }
  }, [meterStatusesStatus, dispatch]);

  useEffect(() => {
    if (selectedTimeZone) {
      fetchComplements();
    }
  }, [selectedTimeZone, fetchComplements]);

  useEffect(() => {
    if (gridApiMeters && colDef) gridApiMeters.setColumnDefs(colDef);
  }, [colDef, gridApiMeters]);

  useEffect(() => {
    if (
      metersStatus === FULFILLED &&
      meterStatusesStatus === FULFILLED &&
      document.readyState === 'complete' &&
      logEvent
    ) {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDMETERSPAGE,
        category: Category.METERS_PAGE,
      });
    }
  }, [metersStatus, meterStatusesStatus, logEvent]);

  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <FolioSkeleton
          fullHeight
          getRowStyle={rowStyler}
          additions={additions}
          canSave
          canAdd
          canReset
          canEdit
          context={{
            formik: formikMeters,
            queueHook: meterQueueHook,
          }}
          onRowEditingStopped={onRowEditingStoppedHandler}
          onRowEditingStarted={onRowEditingStartedHandler}
          onClickSaveButton={onClickSaveButton}
          onClickAddRow={onClickAddMeterRow}
          onClickReset={onClickMeterReset}
          onClickEnableEditing={onClickEnableMeterEditing}
          defaultDefs={defaultColDef}
          loading={loadingMeters}
          onGridReady={onGridMetersReadyHandler}
          editMode={editingModeMeter}
          components={{ CellRender: CellRender }}
          columnDefs={meterColumnDefs}
          data={metersList}
          title='Meters'
          pagination
          headerHeight={60}
        />
      </div>
    </div>
  );
};

export default MetersPage;
