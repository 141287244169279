import { useEffect, useState } from 'react';

const paginate = <T>(
  dataSet: T[],
  currentPage: number,
  recordsPerPage: number
) =>
  dataSet.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

const useTable = <T, K extends keyof T>(keyName: K) => {
  const [records, setRecords] = useState<T[]>([]);
  const [selected, setSelected] = useState<T[K][]>([]);
  const [paginatedData, setPaginatedData] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(100);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedVisible, setSelectedVisible] = useState(false);
  const ALL_RECORDS = 'ALL_RECORDS';
  const ALL_VISIBLE = 'ALL_VISIBLE';

  // const paginateData = () =>
  //   setPaginatedData(paginate<T>(records, currentPage, recordsPerPage));

  const isSelected = (rowId: T[K]) => selected.indexOf(rowId) !== -1;

  const setSelector = (rowId: T[K], checked: boolean) => {
    if (checked) {
      setSelected([...selected, rowId]);
    } else {
      const newSelected = selected.filter((selected) => selected !== rowId);
      setSelected(newSelected);
    }
  };

  const selectAll = (checked: boolean, selectionType?: string) => {
    switch (selectionType) {
      case ALL_RECORDS: {
        if (checked) {
          const allRecords = records.map((record) => record[keyName]);
          setSelected([...allRecords]);
        } else {
          setSelected([]);
        }
        break;
      }
      case ALL_VISIBLE: {
        if (checked) {
          const allVisible = paginatedData.map((record) => record[keyName]);
          setSelected([...allVisible]);
        } else {
          setSelected([]);
        }
        break;
      }
      default: {
        if (checked) {
          const allVisible = paginatedData.map((record) => record[keyName]);
          setSelected([...allVisible]);
        } else {
          setSelected([]);
        }
        break;
      }
    }
  };

  useEffect(() => {
    if (selected.length === records.length) {
      setSelectedAll(true);
      setSelectedVisible(false);
    } else if (selected.length === paginatedData.length) {
      setSelectedVisible(true);
      setSelectedAll(false);
    } else {
      setSelectedVisible(false);
      setSelectedAll(false);
    }
  }, [selected, paginatedData.length, records]);

  useEffect(() => {
    setPaginatedData(paginate<T>(records, currentPage, recordsPerPage));
    if (selectedVisible) {
      setSelected([]);
      setSelectedVisible(false);
    }
    if (records.length === 0) {
      setPages(Math.ceil(records.length / recordsPerPage));
    }
  }, [recordsPerPage, currentPage, records, selectedVisible]);

  useEffect(() => {
    setCurrentPage(1);
  }, [recordsPerPage]);

  return {
    ALL_RECORDS,
    ALL_VISIBLE,
    selectedAll,
    selectedVisible,
    selectAll,
    selected,
    isSelected,
    records,
    pages,
    paginatedData,
    recordsPerPage,
    currentPage,
    setRecords,
    setSelector,
    setRecordsPerPage,
    setCurrentPage,
  };
};

export default useTable;
