import styled, { keyframes } from 'styled-components';

const loadingFlash = keyframes`
  0% {
      background-position: -250px;
  }
  100% {
      background-position: calc(100% + 250px);
  }
`;

export const Performance = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
  height: 100%;
  padding: 24px;

  background: #fff;
`;

export const Header = styled.header``;
export const Main = styled.main`
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 0.5fr;
  gap: 32px;
`;
export const ChartHolder = styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

export const ChartLoader = styled.div`
  display: block;
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgb(240, 240, 240);

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background: linear-gradient(
      90deg,
      rgb(240, 240, 240) 0px,
      #f9f9f9 calc(50% - 25px),
      #f9f9f9 calc(50% + 25px),
      rgb(240, 240, 240) 100%
    );
    background-size: 35%;
    background-position: 0%;
    background-repeat: no-repeat;
    animation: ${loadingFlash} 1.2s infinite linear;
  }

  &.hidden {
    display: none;
  }
`;
