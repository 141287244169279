import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Button } from '@mui/material';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';

import FolioSkeleton from 'components/admin/GridSkeleton/GridSkeleton';
import CellRender from 'components/molecules/CellRender/CellRender';
import styles from './BidsOffersDetails.module.scss';
import { BidsOffers } from 'api/models/BidsOffers.model';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { useAppContext } from 'App/AppProvider';
import DateRangeSelector, {
  useDateRangeSelector,
} from 'components/admin/DateRangeSelector';

import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { keepLocalTimezone } from 'utils/dateTime';

import { BIDS_OFFERS_DEF_COLS } from './consts/consts';
import * as S from 'components/admin/OperatingPlan/OperatingPlan.styles';
import './BidsOffersDetails.module.scss';

import {
  HOUR_FILTER,
  HOURS_4_FILTER,
  HOURS_24_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from 'react-router-dom';
import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';
import { getTimeIntervalSetup } from '../Performance/helpers';
import { ITimeInterval } from 'components/molecules/RangeFilterTab/types';

import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppStore } from 'redux/store';
import { FULFILLED } from 'redux/constants';
import { fetchTradeStack } from 'redux/states/bidsoffers.state';
import { useSessionContext } from 'pages/admin/Trade/context';

const INTERVALS = [HOUR_FILTER, HOURS_4_FILTER, HOURS_24_FILTER];

const BidsOffersDetails: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { startSession } = useSessionContext();
  const { tradeStack, tradeStackStatus } = useSelector(
    (store: AppStore) => store.bidsoffers
  );

  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { logEvent } = useAppContext();
  const { intervalPreferences } = usePreferences();
  const { pathname } = useLocation();
  const [isCustomRangeActive, setIsCustomRangeActive] =
    useState<boolean>(false);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');
  const { bidsoffers, bidsoffersoptions, filterBtn, intervalOptions } = styles;
  const ref: any = useRef(null);
  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      editable: false,
      cellEditorPopup: false,
    };
  }, []);

  const bidsOffersColumnDefs = useMemo(() => BIDS_OFFERS_DEF_COLS, []);

  const [colDef, setColDef] = useState<any[]>([]);

  const [bidsOffers, setBidsOffers] = useState<BidsOffers[]>();
  const [loadingBidsOffers, setLoadingBidsOffers] = useState<boolean>(true);

  const [gridApiBidsOffers, setGridApiBidsOffers] = useState<GridApi>();
  const { handleRangeFilterChange, selectedRangeFilterTab } =
    useRangeFilterTabs(HOUR_FILTER);

  const { fromRangeDateTime, toRangeDateTime, handleDateRangeSelector } =
    useDateRangeSelector(
      selectedTimeZone as TTimeZone,
      60,
      10,
      startSession as ZonedDateTime
    );

  const getBidsOffers = useCallback(() => {
    try {
      if (tradeStack) {
        if (tradeStack.length >= 1) {
          let fullBidsOffers: BidsOffers[] = [];
          tradeStack.forEach((trades) => {
            const filteredBids = trades.bids?.map((bid) => {
              return {
                ...bid,
                bidOffer: 'BID',
                sink: bid.location,
                tradeInterval: trades.tradeInterval,
              };
            });
            const filteredOffers = trades.offers?.map((offer) => {
              return {
                ...offer,
                bidOffer: 'OFFER',
                source: offer.location,
                tradeInterval: trades.tradeInterval,
              };
            });

            if (filteredBids) {
              fullBidsOffers = [...fullBidsOffers, ...filteredBids];
            }
            if (filteredOffers) {
              fullBidsOffers = [...fullBidsOffers, ...filteredOffers];
            }
          });
          setBidsOffers(fullBidsOffers);
        }
      } else {
        setBidsOffers([]);
      }
      setLoadingBidsOffers(false);
    } catch (error: any) {
      // pushNotification('error', `Error has occured retrieving bids and offers list`);
      setLoadingBidsOffers(false);
    }
  }, [tradeStack, setBidsOffers, setLoadingBidsOffers]);

  useEffect(() => {
    if (tradeStackStatus === FULFILLED) {
      getBidsOffers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeStackStatus]);

  const fetchTrades = useCallback(
    (participantId: string) => {
      setLoadingBidsOffers(true);
      if (participantId) {
        const request = {
          marketParticipantId: participantId,
          startAt: encodeURIComponent(
            ZonedDateTime.parseIso(
              startTime,
              selectedTimeZone as TTimeZone
            ).toIsoString()
          ),
          stopAt: encodeURIComponent(
            ZonedDateTime.parseIso(
              endTime,
              selectedTimeZone as TTimeZone
            ).toIsoString()
          ),
        };
        dispatch(fetchTradeStack(request));
      }
      if (gridApiBidsOffers) {
        gridApiBidsOffers.refreshCells();
        gridApiBidsOffers.refreshHeader();
      }
    },
    [gridApiBidsOffers, startTime, endTime, selectedTimeZone, dispatch]
  );

  const onGridBidsOffersReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(70);
    gridEvent.api.sizeColumnsToFit();
    setGridApiBidsOffers(gridEvent.api);
  };

  const onRefreshData = useCallback(() => {
    setLoadingBidsOffers(true);
    setBidsOffers([]);
    if (selectedParticipant && selectedParticipant?.id)
      fetchTrades(selectedParticipant?.id);
  }, [setLoadingBidsOffers, setBidsOffers, fetchTrades, selectedParticipant]);

  const switchFilter = () => {
    setIsCustomRangeActive(!isCustomRangeActive);
  };

  const dateFromFilterTab = useCallback(
    (
      selectedFilter: ITimeInterval,
      current: ZonedDateTime,
      timezone: TTimeZone
    ) => {
      const timeIntervalSetup = getTimeIntervalSetup(selectedFilter, current);
      const { startAt: fromDate, stopAt: toDate } = timeIntervalSetup;
      setStartTime(fromDate);
      setEndTime(toDate);
    },
    [setStartTime, setEndTime]
  );

  const dateFromRange = useCallback(
    (timeZone: TTimeZone) => {
      const fromDate = ZonedDateTime.parseIso(
        fromRangeDateTime,
        timeZone
      ).toIsoString();
      const toDate = ZonedDateTime.parseIso(
        toRangeDateTime,
        timeZone
      ).toIsoString();
      setStartTime(fromDate);
      setEndTime(toDate);
    },
    [setStartTime, setEndTime, fromRangeDateTime, toRangeDateTime]
  );

  useEffect(() => {
    if (!loadingBidsOffers) {
      getBidsOffers();
    }
    // eslint-disable-next-line
  }, [selectedParticipant?.id, selectedTimeZone, getBidsOffers]);

  useEffect(() => {
    const intervalPreference = intervalPreferences?.find(
      (interval) => interval.page === pathname
    );
    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'BASIC'
    ) {
      setIsCustomRangeActive(false);
    }

    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'CUSTOM'
    ) {
      setIsCustomRangeActive(true);
    }
  }, [intervalPreferences, pathname]);

  useEffect(() => {
    if (
      startTime &&
      endTime &&
      selectedParticipant &&
      selectedParticipant?.id
    ) {
      setBidsOffers([]);
      fetchTrades(selectedParticipant?.id);
    }
    // eslint-disable-next-line
  }, [
    startTime,
    endTime,
    selectedParticipant,
    selectedParticipant?.id,
    setBidsOffers,
  ]);

  useEffect(() => {
    if (selectedTimeZone && selectedRangeFilterTab) {
      dateFromFilterTab(selectedRangeFilterTab, startSession, selectedTimeZone);
    }
    // eslint-disable-next-line
  }, [selectedRangeFilterTab.key, dateFromFilterTab, selectedTimeZone]);

  useEffect(() => {
    if (selectedTimeZone) {
      dateFromRange(selectedTimeZone);
    }
    // eslint-disable-next-line
  }, [fromRangeDateTime, toRangeDateTime, dateFromRange]);

  useEffect(() => {
    if (logEvent && document.readyState === 'complete') {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDBIDSANDOFFERSPAGE,
        category: Category.BIDSOFFERS_PAGE,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTimeZone) {
      const newColDef = [...bidsOffersColumnDefs];
      setColDef(() => {
        return newColDef;
      });
    }
    // eslint-disable-next-line
  }, [selectedTimeZone, setColDef]);

  useEffect(() => {
    if (gridApiBidsOffers && colDef) gridApiBidsOffers.setColumnDefs(colDef);
  }, [colDef, gridApiBidsOffers]);

  return (
    <div className={bidsoffers} ref={ref}>
      <S.Wrapper style={{ height: '90%' }}>
        <S.PageTitle data-testid='title'>Bids and Offers</S.PageTitle>
        <FolioSkeleton
          fullHeight
          onClickReset={onRefreshData}
          defaultDefs={defaultColDef}
          components={{ CellRender: CellRender }}
          columnDefs={bidsOffersColumnDefs}
          data={bidsOffers}
          loading={loadingBidsOffers}
          onGridReady={onGridBidsOffersReadyHandler}
          canEdit={false}
          canAdd={false}
          canReset
          pagination={true}
          headerHeight={60}
          toolExtra={
            <div className={bidsoffersoptions}>
              {isCustomRangeActive ? (
                <DateRangeSelector
                  startDate={keepLocalTimezone(
                    fromRangeDateTime,
                    selectedTimeZone as TTimeZone
                  )}
                  endDate={keepLocalTimezone(
                    toRangeDateTime,
                    selectedTimeZone as TTimeZone
                  )}
                  handleDateRangeSelector={handleDateRangeSelector}
                  timezone={selectedTimeZone as TTimeZone}
                />
              ) : (
                <RangeFilterTab
                  tab={selectedRangeFilterTab.key}
                  handleChange={handleRangeFilterChange}
                  timeIntervals={INTERVALS}
                />
              )}
              <div className={intervalOptions}>
                <Button
                  className={filterBtn}
                  variant='contained'
                  size='medium'
                  onClick={switchFilter}
                >
                  {!isCustomRangeActive ? 'Custom Range' : 'Basic Range'}
                </Button>
              </div>
            </div>
          }
        />
      </S.Wrapper>
    </div>
  );
};

export default BidsOffersDetails;
