import FormValidation from 'utils/FormValidation';
import { IMetersValues } from './IMetersValues';

export const metersSchema = (values: IMetersValues) => {
  return {
    tagId: FormValidation(values.tagId?.value)
      .string()
      .min(1)
      .max(32)
      .pattern(new RegExp('^[ a-zA-Z0-9_:-]{1,32}$'))
      .required(),
    resourceIdentifier: FormValidation(values.dataType?.value)
      .string()
      .required(),
    dataType: FormValidation(values.dataType?.value).string().required(),
    intervalType: FormValidation(values.intervalType?.value).required(),
    unitOfMeasure: FormValidation(values.unitOfMeasure?.value).required(),
    offThreshold: FormValidation(values.offThreshold?.value)
      .number()
      .required(),
    clockTolerance: FormValidation(values.clockTolerance?.value)
      .number()
      .min(0)
      .max(120),
    description: FormValidation(values.description?.value),
    source: FormValidation(values.source?.value),
  };
};
