export enum EBidOfferType {
  All = 'all',
  Bid = 'bid',
  Offer = 'offer',
}

export enum EGridType {
  Bids = 'bids',
  Offers = 'offers',
}

export enum EMarketBidOfferStatus {
  CancelFailed = 'cancelFailed',
  Cancelled = 'cancelled',
  Cancelling = 'cancelling',
  Failed = 'failed',
  NotReady = 'notReady',
  Ready = 'ready',
  SubmissionFailed = 'submissionFailed',
  Submitted = 'submitted',
  Submitting = 'submitting',
}
