import { useEffect } from 'react';

import { useSessionContext } from 'pages/admin/Trade/context';
import useTimer from 'hooks/useTimer';

import * as S from '../Shared.styles';
import { secondsToTime } from 'utils/dateTime';
import { useAppContext } from 'App/AppProvider';

const Timer = () => {
  const { updateSession, pastSession, deadlineSeconds, intervals } =
    useSessionContext();

  const { pushNotification } = useAppContext();

  const timer = useTimer({
    seconds: deadlineSeconds,
    infinite: true,
  });

  useEffect(() => {
    if (timer === 0 && intervals) {
      updateSession();
      if (pushNotification)
        pushNotification('info', 'Trade Interval has closed');
    }
    // eslint-disable-next-line
  }, [timer]);

  const timeDisplayHandler = pastSession ? (
    <span>Closed</span>
  ) : (
    `${secondsToTime(timer).m}:${secondsToTime(timer).s}`
  );

  return (
    <S.Data data-testid='closing_in'>
      {deadlineSeconds !== 0 ? timeDisplayHandler : <span>Loading...</span>}
    </S.Data>
  );
};

export default Timer;
