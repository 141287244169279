import BidsOffersChart from './partials/BidsOffersChart';
import { EGridType } from 'enums/bidOffer';
import * as S from './TradeDashboard.styles';
import OperatingPlanChart from './partials/OperantingPlanChart';
import { Event } from 'services/StructuredLogging/events';
import { useAppContext } from 'App/AppProvider';
import { useEffect } from 'react';
import { Category } from 'services/StructuredLogging/categories';
import { AppDispatch } from 'redux/store';
import { useDispatch } from 'react-redux';
import { resetStatuses } from 'redux/states/operatingplans.state';

const TradeDashboard = () => {
  const { logEvent } = useAppContext();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (logEvent && document.readyState === 'complete') {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDTRADEPAGE,
        category: Category.TRADEDASHBOARD_PAGE,
      });
    }

    return () => {
      dispatch(resetStatuses());
    };
    // eslint-disable-next-line
  }, []);

  return (
    <S.TradeDashboard>
      <S.ChartsWrapper>
        <S.ChartBox>
          <S.OptionsContainer />
          <OperatingPlanChart />
        </S.ChartBox>
        <S.Box>
          <S.InformationContainer />
          <BidsOffersChart />
        </S.Box>
      </S.ChartsWrapper>
      <S.GridsWrapper>
        <S.Box>
          <S.BoxTitle>Bids</S.BoxTitle>
          <S.BidsGridContainer />
        </S.Box>
        <S.Box>
          <S.BoxTitle>Offers</S.BoxTitle>
          <S.OffersGridContainer type={EGridType.Offers} />
        </S.Box>
      </S.GridsWrapper>
    </S.TradeDashboard>
  );
};

export default TradeDashboard;
