export class Logger {
  public eventName?: string = '';
  public eventTime?: Date;
  public timeToComplete?: number = 0;
  public userId?: string = '';
  public userEmail?: string = '';
  public category?: string = '';
  public requestId?: string = '';
  public adpId?: string = '';
  public participantId?: string = '';
  public requestStatus?: number = 0;
  public payload?: any = {};
  public message?: string = '';
  public tradeInterval?: Date;
}
