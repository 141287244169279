import {
  Button,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import i18n from 'i18next';
import DatePicker from '@mui/lab/DatePicker';
import { useFormik } from 'formik';
import styles from './GeneratingUnit.module.scss';
import { IGenUnitValues } from './IGenUnitValues';
import { generatingUtilSchema } from '../formsSchema';
import TextField from 'components/admin/TextField';
import { handleTouched, isComplete } from 'utils/FormValidation/utils';
import { CREATING, EDITING, VIEWING } from '../../constant';
import { useAddResourcesContext } from '../../AddResourcesProvider';

const initialValue = {
  resourceName: { value: '', error: undefined, touched: undefined },
  effectiveDate: {
    value: new Date(),
    error: '',
    touched: undefined,
  },
  name: { value: '', error: undefined, touched: undefined },
  area: { value: '', error: undefined, touched: undefined },
  category: { value: '', error: undefined, touched: undefined },
  owner: { value: '', error: undefined, touched: undefined },
  retired: { value: false, error: undefined, touched: undefined },
  fuelType: { value: 'NA', error: undefined, touched: undefined },
  cycleType: { value: 'NA', error: undefined, touched: undefined },
  plant: { value: '', error: undefined, touched: undefined },
  line: { value: '', error: undefined, touched: undefined },
  quickStart: { value: false, error: undefined, touched: undefined },
  // incrementalCost: { value: false, error: undefined, touched: undefined },
  maxCapacity: { value: '', error: undefined, touched: undefined },
  // startupCost: { value: '', error: undefined, touched: undefined },
  maxRegulationDown: { value: '', error: undefined, touched: undefined },
  maxBalanceDown: { value: '', error: undefined, touched: undefined },
  maxOfflineSpin: { value: '', error: undefined, touched: undefined },
  maxRegulationUp: { value: '', error: undefined, touched: undefined },
  maxBalanceUp: { value: '', error: undefined, touched: undefined },
  maxOfflineNonSpin: { value: '', error: undefined, touched: undefined },
  maxOfflineBalanceUp: {
    value: '',
    error: undefined,
    touched: undefined,
  },
  rampRateDown: { value: '', error: undefined, touched: undefined },
  rampRateUp: { value: '', error: undefined, touched: undefined },
  minDownTime: { value: '', error: undefined, touched: undefined },
  minUpTime: { value: '', error: undefined, touched: undefined },
};

interface IFieldSchema {
  type: string;
  label: string;
  key: string;
  options?: { value: string; label: string }[];
}

const fieldsSchema: Array<IFieldSchema> = [
  { type: 'text', label: 'resources.labels.resourceName', key: 'resourceName' },
  {
    type: 'date',
    label: 'resources.labels.effectiveDate',
    key: 'effectiveDate',
  },
  { type: 'text', label: 'resources.labels.name', key: 'name' },
  { type: 'text', label: 'resources.labels.area', key: 'area' },
  { type: 'text', label: 'resources.labels.category', key: 'category' },
  { type: 'text', label: 'resources.labels.owner', key: 'owner' },
  { type: 'bool', label: 'resources.labels.retired', key: 'retired' },
  {
    type: 'select',
    label: 'resources.labels.fuelType',
    key: 'fuelType',
    options: [
      { value: 'NA', label: 'NA' },
      { value: 'COAL', label: 'COAL' },
      { value: 'NATURAL_GAS', label: 'NATURAL_GAS' },
      { value: 'OIL', label: 'OIL' },
      { value: 'NUCLEAR', label: 'NUCLEAR' },
      { value: 'SOLAR', label: 'SOLAR' },
      { value: 'WIND', label: 'WIND' },
      { value: 'HYDRO', label: 'HYDRO' },
      { value: 'H2', label: 'H2' },
      { value: 'GEOTHERMAL', label: 'GEOTHERMAL' },
      { value: 'BIOMASS', label: 'BIOMASS' },
      { value: 'TIDAL', label: 'TIDAL' },
    ],
  },
  {
    type: 'select',
    label: 'resources.labels.cycleType',
    key: 'cycleType',
    options: [
      { value: 'NA', label: 'NA' },
      { value: 'BASE_LOAD', label: 'BASE_LOAD' },
      { value: 'INTERMEDIATE', label: 'INTERMEDIATE' },
      { value: 'CYCLIC', label: 'CYCLIC' },
      { value: 'PEAKING', label: 'PEAKING' },
      { value: 'FIXED', label: 'FIXED' },
    ],
  },
  { type: 'text', label: 'resources.labels.plant', key: 'plant' },
  { type: 'text', label: 'resources.labels.line', key: 'line' },
  { type: 'bool', label: 'resources.labels.quickStart', key: 'quickStart' },
  // {
  //   type: 'bool',
  //   label: 'resources.labels.incrementalCost',
  //   key: 'incrementalCost',
  // },
  {
    type: 'number',
    label: 'resources.labels.maxCapacity',
    key: 'maxCapacity',
  },
  // {
  //   type: 'number',
  //   label: 'resources.labels.startupCost',
  //   key: 'startupCost',
  // },
  {
    type: 'number',
    label: 'resources.labels.maxRegulationDown',
    key: 'maxRegulationDown',
  },
  {
    type: 'number',
    label: 'resources.labels.maxBalanceDown',
    key: 'maxBalanceDown',
  },
  {
    type: 'number',
    label: 'resources.labels.maxOfflineSpin',
    key: 'maxOfflineSpin',
  },
  {
    type: 'number',
    label: 'resources.labels.maxRegulationUp',
    key: 'maxRegulationUp',
  },
  {
    type: 'number',
    label: 'resources.labels.maxBalanceUp',
    key: 'maxBalanceUp',
  },
  {
    type: 'number',
    label: 'resources.labels.maxOfflineNonSpin',
    key: 'maxOfflineNonSpin',
  },
  {
    type: 'number',
    label: 'resources.labels.maxOfflineBalanceUp',
    key: 'maxOfflineBalanceUp',
  },
  {
    type: 'number',
    label: 'resources.labels.rampRateDown',
    key: 'rampRateDown',
  },
  { type: 'number', label: 'resources.labels.rampRateUp', key: 'rampRateUp' },
  {
    type: 'number',
    label: 'resources.labels.minDownTime',
    key: 'minDownTime',
  },
  { type: 'number', label: 'resources.labels.minUpTime', key: 'minUpTime' },
];

interface IGenUnitProps {
  mode: string;
  data?: IGenUnitValues;
}

const GeneratingUnit = ({ mode, data }: IGenUnitProps) => {
  const { back, addResource } = useAddResourcesContext();
  const {
    formHeader,
    genUnitForm,
    formRow,
    description,
    currentVal,
    formTitle,
    negative,
    positive,
    submitBtn,
  } = styles;
  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: (_) => {},
    validate: (values: IGenUnitValues) => generatingUtilSchema(values),
  });

  const fieldTypeHandler = (
    type: string,
    label: string,
    key: string,
    options?: { value: string; label: string }[]
  ) => {
    switch (type) {
      case 'text': {
        return (
          <div key={key}>
            <div className={formRow}>
              <span className={description}>{i18n.t(label)}</span>
              {(mode === VIEWING || mode === EDITING) && (
                <span className={currentVal}>
                  {data && data[key as keyof IGenUnitValues]}
                </span>
              )}
              {(mode === CREATING || mode === EDITING) && (
                <TextField
                  onClick={(e) =>
                    formik.setTouched(
                      handleTouched(
                        e.target as HTMLInputElement,
                        formik.touched
                      )
                    )
                  }
                  error={
                    new Map(Object.entries(formik.touched)).get(key) &&
                    formik.errors &&
                    new Map(Object.entries(formik.errors)).get(key)?.error !==
                      null
                  }
                  id={key}
                  name={key}
                  value={
                    new Map(Object.entries(formik.values)).get(key) &&
                    new Map(Object.entries(formik.values)).get(key)?.value
                    // formik.values[key as keyof IGenUnitValues] &&
                    // formik.values[key as keyof IGenUnitValues].value
                  }
                  onChange={(newValue) =>
                    formik.setFieldValue(key, {
                      ...new Map(Object.entries(formik.values)).get(key),
                      value: newValue.target.value,
                    })
                  }
                  helperText={
                    new Map(Object.entries(formik.touched)).get(key) &&
                    // formik.errors[key as keyof IGenUnitValues] &&
                    new Map(Object.entries(formik.errors)).get(key) !==
                      undefined
                      ? new Map(Object.entries(formik.errors)).get(key)?.error
                      : null
                  }
                />
              )}
            </div>
            <Divider />
          </div>
        );
      }

      case 'date': {
        return (
          <div key={key}>
            <div className={formRow}>
              <span className={description}>{i18n.t(label)}</span>
              {(mode === VIEWING || mode === EDITING) && (
                <span className={currentVal}>
                  {data && data[key as keyof IGenUnitValues]}
                </span>
              )}
              {(mode === CREATING || mode === EDITING) && (
                <DatePicker
                  openTo='year'
                  views={['year', 'month', 'day']}
                  value={
                    new Map(Object.entries(formik.values)).get(key) &&
                    new Map(Object.entries(formik.values)).get(key)?.value
                  }
                  onChange={(newValue: any) =>
                    formik.setFieldValue(key, {
                      ...new Map(Object.entries(formik.values)).get(key),
                      value: newValue,
                    })
                  }
                  renderInput={(params: any) => (
                    <TextField
                      id={key}
                      name={key}
                      onClick={(e) =>
                        formik.setTouched(
                          handleTouched(
                            e.target as HTMLInputElement,
                            formik.touched
                          )
                        )
                      }
                      helperText={
                        new Map(Object.entries(formik.touched)).get(key) &&
                        // formik.errors[key as keyof IGenUnitValues] &&
                        new Map(Object.entries(formik.errors)).get(key) !==
                          undefined
                          ? new Map(Object.entries(formik.errors)).get(key)
                              ?.error
                          : null
                      }
                      {...params}
                    />
                  )}
                />
              )}
            </div>
            <Divider />
          </div>
        );
      }

      case 'bool': {
        return (
          <div key={key}>
            <div className={formRow}>
              <span className={description}>{i18n.t(label)}</span>
              {(mode === VIEWING || mode === EDITING) && (
                <span className={currentVal}>
                  {data && data[key as keyof IGenUnitValues]}
                </span>
              )}
              {(mode === CREATING || mode === EDITING) && (
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={
                    new Map(Object.entries(formik.values)).get(key) &&
                    new Map(Object.entries(formik.values)).get(key)?.value
                  }
                  onChange={(e) =>
                    formik.setFieldValue(key, {
                      ...new Map(Object.entries(formik.values)).get(key),
                      value: e.target.value,
                    })
                  }
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label='Yes'
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label='No'
                  />
                </RadioGroup>
              )}
            </div>
            <Divider />
          </div>
        );
      }

      case 'select': {
        return (
          <div key={key}>
            <div className={formRow}>
              <span className={description}>{i18n.t(label)}</span>
              {(mode === VIEWING || mode === EDITING) && (
                <span className={currentVal}>
                  {data && data[key as keyof IGenUnitValues]}
                </span>
              )}
              {(mode === CREATING || mode === EDITING) && (
                <TextField
                  error={
                    new Map(Object.entries(formik.touched)).get(key) &&
                    formik.errors &&
                    new Map(Object.entries(formik.errors)).get(key)?.error !==
                      null
                  }
                  onClick={(e) =>
                    formik.setTouched(
                      handleTouched(
                        e.target as HTMLInputElement,
                        formik.touched
                      )
                    )
                  }
                  select
                  key={key}
                  id={key}
                  name={key}
                  options={options}
                  value={
                    new Map(Object.entries(formik.values)).get(key) &&
                    new Map(Object.entries(formik.values)).get(key)?.value
                  }
                  onChange={formik.handleChange}
                  helperText={
                    new Map(Object.entries(formik.touched)).get(key) &&
                    // formik.errors[key as keyof IGenUnitValues] &&
                    new Map(Object.entries(formik.errors)).get(key) !==
                      undefined
                      ? new Map(Object.entries(formik.errors)).get(key)?.error
                      : null
                  }
                />
              )}
            </div>
            <Divider />
          </div>
        );
      }

      case 'number': {
        return (
          <div key={key}>
            <div className={formRow}>
              <span className={description}>{i18n.t(label)}</span>
              {(mode === VIEWING || mode === EDITING) && (
                <span className={currentVal}>
                  {data && data[key as keyof IGenUnitValues]}
                </span>
              )}
              {(mode === CREATING || mode === EDITING) && (
                <TextField
                  inputProps={{
                    min: 1,
                    type: 'number',
                  }}
                  error={
                    new Map(Object.entries(formik.touched)).get(key) &&
                    formik.errors &&
                    new Map(Object.entries(formik.errors)).get(key)?.error !==
                      null
                  }
                  onClick={(e) =>
                    formik.setTouched(
                      handleTouched(
                        e.target as HTMLInputElement,
                        formik.touched
                      )
                    )
                  }
                  id={key}
                  name={key}
                  value={
                    new Map(Object.entries(formik.values)).get(key) &&
                    new Map(Object.entries(formik.values)).get(key)?.value
                  }
                  onChange={(newValue) =>
                    formik.setFieldValue(key, {
                      ...new Map(Object.entries(formik.values)).get(key),
                      value: parseInt(newValue.target.value),
                    })
                  }
                  helperText={
                    new Map(Object.entries(formik.touched)).get(key) &&
                    // formik.errors[key as keyof IGenUnitValues] &&
                    new Map(Object.entries(formik.errors)).get(key) !==
                      undefined
                      ? new Map(Object.entries(formik.errors)).get(key)?.error
                      : null
                  }
                />
              )}
            </div>
            <Divider />
          </div>
        );
      }

      default:
        break;
    }
  };

  return (
    <div className={genUnitForm}>
      <div className={formTitle}>
        <h3>complete information:</h3>
        <div className={submitBtn}>
          <Button
            className={negative}
            variant='outlined'
            size='medium'
            onClick={() => (back ? back() : null)}
          >
            Cancel
          </Button>
          <Button
            disabled={!isComplete(formik.values)}
            className={positive}
            variant='contained'
            size='medium'
            onClick={() => (addResource ? addResource(formik.values) : null)}
          >
            Save
          </Button>
        </div>
      </div>
      <div className={formHeader}>
        <span>Description</span>
        {(mode === VIEWING || mode === EDITING) && <span>Current Value</span>}
        <span>New Value</span>
      </div>
      <div className='formFields'>
        {fieldsSchema.map(({ type, label, key, options }) =>
          fieldTypeHandler(type, label, key, options)
        )}
      </div>
    </div>
  );
};

export default GeneratingUnit;
