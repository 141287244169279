import { GridApi, GridReadyEvent } from 'ag-grid-community';
import CellRender from 'components/molecules/CellRender/CellRender';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FolioSkeleton from '../GridSkeleton/GridSkeleton';
import styles from '../ResourcesList/ResourcesList.module.scss';
import auditStyles from './AuditPage.module.scss';
import * as S from '../OperatingPlan/OperatingPlan.styles';
import { auditColumnDefs } from './constants';
import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import {
  HOURS_4_FILTER,
  HOURS_24_FILTER,
  WEEK_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import DateRangeSelector from '../DateRangeSelector';
import { keepLocalTimezone } from 'utils/dateTime';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { useDateRangeSelector } from 'components/admin/DateRangeSelector';
import { Button } from '@mui/material';
import useSSE from 'hooks/useSSE';
import { Event, EventType } from 'services/StructuredLogging/events';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { getTimeIntervalSetup } from '../Performance/helpers';
import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from 'react-router-dom';
import { pickSelectedTimezone } from 'redux/states/miscellaneous.state';

import { useSelector } from 'react-redux';
import { AuditLog } from 'api/models/AuditLog.model';

const AuditPage = () => {
  const [logs, setLogs] = useState({});
  const [gridApi, setGridApi] = useState<GridApi>();
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { intervalPreferences } = usePreferences();
  const { pathname } = useLocation();
  const [sourceUri, setSourceUri] = useState<string | undefined>(undefined);
  const sseInit = {
    event: Event.ADDEDRESOURCE,
    type: EventType.AUDIT,
    sourceURL: sourceUri,
  };
  const { data, records, close, done } = useSSE(sseInit);
  const now = ZonedDateTime.now(selectedTimeZone as TTimeZone);

  const { resourcesPageResources, resourcesWrapperResources } = styles;
  const { toolBarOptions, toolButton } = auditStyles;
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');

  const [loadingLogs, setLoadingLogs] = useState<boolean>(false);

  const [isCustomRangeActive, setIsCustomRangeActive] =
    useState<boolean>(false);

  const { fromRangeDateTime, toRangeDateTime, handleDateRangeSelector } =
    useDateRangeSelector(selectedTimeZone as TTimeZone);

  const { handleRangeFilterChange, selectedRangeFilterTab } =
    useRangeFilterTabs(HOURS_4_FILTER);

  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      editable: true,
      cellEditorPopup: false,
      wrapText: true,
    };
  }, []);

  const onGridParticipantsReadyHandler = useCallback(
    (gridEvent: GridReadyEvent) => {
      setGridApi(gridEvent.api);
      // gridEvent.api.setRowData(auditLogs);
      gridEvent.api.sizeColumnsToFit();
    },
    []
  );

  const cleanTable = () => {
    setLogs({});
    setSourceUri(undefined);
    setTimeout(() => {
      gridApi?.setRowData([]);
      gridApi?.hideOverlay();
    }, 0);
    close();
  };

  const onClickReload = () => {
    cleanTable();
    if (isCustomRangeActive) {
      dateFromRange();
    } else {
      dateFromFilter();
    }
  };

  const dateFromFilter = () => {
    cleanTable();
    if (selectedTimeZone) {
      const timeIntervalSetup = getTimeIntervalSetup(
        selectedRangeFilterTab,
        now
      );
      const { startAt: fromDate, stopAt: toDate } = timeIntervalSetup;

      setStartTime(fromDate);
      setEndTime(toDate);
    }
  };
  const dateFromRange = () => {
    cleanTable();
    if (selectedTimeZone) {
      const fromDate = ZonedDateTime.parseIso(
        fromRangeDateTime,
        selectedTimeZone
      ).toIsoString();
      const toDate = ZonedDateTime.parseIso(
        toRangeDateTime,
        selectedTimeZone
      ).toIsoString();
      if (fromDate.localeCompare(startTime) && toDate.localeCompare(endTime)) {
        getAuditSource();
      } else {
        setStartTime(fromDate);
        setEndTime(toDate);
      }
    }
  };

  const switchFilter = () => {
    setIsCustomRangeActive(!isCustomRangeActive);
  };

  const getAuditSource = async () => {
    const auditUri = await AutoTraderApi.getAuditSSEURL(startTime, endTime, [
      Event.ADDEDRESOURCE,
      Event.MODIFIEDRESOURCE,
      Event.TOGGLEDAUTOSUBMIT,
      Event.ADDEDPARTICIPANT,
      Event.MODIFIEDPARTICIPANT,
      Event.ADDEDOVERRIDE,
      Event.REMOVEDOVERRIDE,
      Event.MODIFIEDOVERRIDE,
      Event.ADDEDTRADESTRATEGY,
      Event.MODIFIEDTRADESTRATEGY,
      Event.REMOVEDTRADESTRATEGY,
    ]);
    setSourceUri(auditUri);
  };

  useEffect(() => {
    if (startTime && endTime) {
      getAuditSource();
    }
    // eslint-disable-next-line
  }, [startTime, endTime]);

  useEffect(() => {
    if (selectedTimeZone && selectedRangeFilterTab) {
      dateFromFilter();
    }
    // eslint-disable-next-line
  }, [selectedRangeFilterTab.key, selectedTimeZone]);

  useEffect(() => {
    dateFromRange();
    // eslint-disable-next-line
  }, [fromRangeDateTime, toRangeDateTime]);

  useEffect(() => {
    const intervalPreference = intervalPreferences?.find(
      (interval) => interval.page === pathname
    );
    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'CUSTOM'
    ) {
      setIsCustomRangeActive(true);
    }
  }, [intervalPreferences, pathname]);

  useMemo(() => {
    const dataRecord = data as AuditLog;
    if (dataRecord !== undefined && dataRecord.eventTime !== undefined) {
      const eventTime = dataRecord.eventTime.toString();

      const auditObj: { [key: string]: any } = {};
      auditObj[eventTime] = dataRecord;
      setLogs({ ...logs, ...auditObj });
    }

    // eslint-disable-next-line
  }, [records]);

  useEffect(() => {
    setTimeout(() => {
      gridApi?.setRowData(Object.values(logs));
      gridApi?.hideOverlay();
      gridApi?.refreshCells();
    }, 0);
  }, [logs, gridApi]);

  useEffect(() => {
    if (done) {
      setTimeout(() => {
        setLoadingLogs(false);
      }, 200);
    } else {
      setLoadingLogs(true);
    }
  }, [done]);

  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <S.PageTitle>Audit Logs</S.PageTitle>
        <FolioSkeleton
          fullHeight
          canReset
          toolExtra={
            <div className={toolBarOptions}>
              <div>
                {isCustomRangeActive ? (
                  <DateRangeSelector
                    startDate={keepLocalTimezone(
                      fromRangeDateTime,
                      selectedTimeZone as TTimeZone
                    )}
                    endDate={keepLocalTimezone(
                      toRangeDateTime,
                      selectedTimeZone as TTimeZone
                    )}
                    handleDateRangeSelector={handleDateRangeSelector}
                    timezone={selectedTimeZone as TTimeZone}
                  />
                ) : (
                  <RangeFilterTab
                    tab={selectedRangeFilterTab.key}
                    handleChange={handleRangeFilterChange}
                    timeIntervals={[
                      HOURS_4_FILTER,
                      HOURS_24_FILTER,
                      WEEK_FILTER,
                    ]}
                  />
                )}
              </div>
              <div>
                <Button
                  className={toolButton}
                  variant='contained'
                  size='medium'
                  onClick={switchFilter}
                >
                  {!isCustomRangeActive ? 'Custom Range' : 'Basic Range'}
                </Button>
              </div>
            </div>
          }
          onClickReset={onClickReload}
          defaultDefs={defaultColDef}
          loading={loadingLogs}
          onGridReady={onGridParticipantsReadyHandler}
          components={{ CellRender: CellRender }}
          columnDefs={auditColumnDefs}
          data={[]}
          pagination={true}
          headerHeight={60}
        />
      </div>
    </div>
  );
};

export default AuditPage;
