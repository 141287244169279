import { AxiosResponse } from 'axios';
import { ERequestManager } from 'enums/requestManager';
import { isEmpty } from 'lodash';
import { useState } from 'react';

const useRequestManager = () => {
  let promisesList: any[] = [];
  const [status, setStatus] = useState<ERequestManager>(ERequestManager.NONE);
  const [suceeded, setSuceeded] = useState<AxiosResponse[]>([]);
  const [clientErrored, setClientErrored] = useState<AxiosResponse[]>([]);
  const [serverErrored, setServerErrored] = useState<AxiosResponse[]>([]);

  const requestMannager = (promise: Promise<any>) => {
    return new Promise(async (resolve, reject) => {
      const response = await promise;
      if (response.data.status >= 200 && response.data.status < 400) {
        resolve(response);
      } else {
        reject(response);
      }
    });
  };

  const enqueue = (promise: Promise<any>) => {
    promisesList.push(requestMannager(promise).catch((e) => e));
  };

  const clearPromisesList = () => {
    promisesList = [];
  };

  const execute = async () => {
    setStatus(ERequestManager.FETCHING);
    const responses: AxiosResponse[] = await Promise.all(promisesList);
    if (!isEmpty(responses)) {
      setSuceeded([...responses.filter((response) => response.status < 400)]);
      setClientErrored([
        ...responses.filter(
          (response) => response.status >= 400 && response.status < 500
        ),
      ]);
      setServerErrored([
        ...responses.filter((response) => response.status >= 500),
      ]);
      clearPromisesList();
      setStatus(ERequestManager.COMPLETED);
    }
  };
  return {
    enqueue,
    execute,
    result: { suceeded, clientErrored, serverErrored },
    status,
  };
};

export default useRequestManager;
