import { ZonedDateTime } from '@pci/pci-ui-library';
import { IApiTimeInterval } from 'api/AutoTraderAPI';
import {
  IChartTimeInterval,
  IChartConfig,
} from 'components/molecules/Charts/XY.bar';
import {
  HOURS_24_FILTER,
  HOURS_4_FILTER,
  HOURS_12_FILTER,
  WEEKS_2_FILTER,
  WEEK_FILTER,
  EResolution,
  DAYS_30_FILTER,
  MONTH_FILTER,
  MONTHS_3_FILTER,
  MONTHS_12_FILTER,
  HOUR_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import { ITimeInterval } from 'components/molecules/RangeFilterTab/types';
import { CHART_DATE_TIME_FORMAT, MONTH_FORMAT } from 'constants/dateTime';
import { IPerformanceChartDataWithTotal } from './Performance';

interface IGetTimeSetup {
  api: IChartTimeInterval;
  chart: IChartConfig;
  resolution: EResolution;
}

export interface IGetTimeIntervalSetup extends Required<IApiTimeInterval> {
  chartSetup: IChartConfig;
}

const setupSelector = (selectedTimeInterval: ITimeInterval): IGetTimeSetup => {
  switch (selectedTimeInterval.key) {
    case HOUR_FILTER.key: {
      return {
        api: { count: 1, timeUnit: 'hour' },
        chart: {
          count: 15,
          timeUnit: 'minute',
          location: 1,
          rotation: 90,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.NONE,
      };
    }
    case HOURS_4_FILTER.key: {
      return {
        api: { count: 4, timeUnit: 'hour' },
        chart: {
          count: 15,
          timeUnit: 'minute',
          location: 1,
          rotation: 90,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.NONE,
      };
    }
    case HOURS_12_FILTER.key: {
      return {
        api: { count: 12, timeUnit: 'hour' },
        chart: {
          count: 1,
          timeUnit: 'hour',
          location: 0.7,
          rotation: 90,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.HOURLY,
      };
    }
    case HOURS_24_FILTER.key: {
      return {
        api: { count: 24, timeUnit: 'hour' },
        chart: {
          count: 1,
          timeUnit: 'hour',
          location: 0.7,
          rotation: 90,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.NONE,
      };
    }
    case DAYS_30_FILTER.key: {
      return {
        api: { count: 30, timeUnit: 'day' },
        chart: {
          count: 1,
          timeUnit: 'day',
          location: 0.7,
          rotation: 90,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.NONE,
      };
    }
    case WEEK_FILTER.key: {
      return {
        api: { count: 1, timeUnit: 'week' },
        chart: {
          count: 1,
          timeUnit: 'day',
          location: 0.5,
          rotation: 0,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.DAILY,
      };
    }
    case WEEKS_2_FILTER.key: {
      return {
        api: { count: 2, timeUnit: 'week' },
        chart: {
          count: 1,
          timeUnit: 'day',
          location: 0.5,
          rotation: 0,
          format: CHART_DATE_TIME_FORMAT,
        },
        resolution: EResolution.DAILY,
      };
    }
    case MONTH_FILTER.key: {
      return {
        api: { count: 1, timeUnit: 'month' },
        chart: {
          count: 1,
          timeUnit: 'month',
          location: 0,
          rotation: 0,
          format: '',
        },
        resolution: EResolution.NONE,
      };
    }
    case MONTHS_3_FILTER.key: {
      return {
        api: { count: 3, timeUnit: 'month' },
        chart: {
          count: 1,
          timeUnit: 'month',
          location: 0.5,
          rotation: 0,
          format: '',
        },
        resolution: EResolution.NONE,
      };
    }
    case MONTHS_12_FILTER.key: {
      return {
        api: { count: 12, timeUnit: 'month' },
        chart: {
          count: 1,
          timeUnit: 'month',
          location: 0.5,
          rotation: 0,
          format: '',
        },
        resolution: EResolution.NONE,
      };
    }

    default:
      return {
        api: { count: 0, timeUnit: 'minute' },
        chart: {
          count: 0,
          timeUnit: 'minute',
          location: 0.5,
          rotation: 0,
          format: MONTH_FORMAT,
        },
        resolution: EResolution.NONE,
      };
  }
};

export const getTimeIntervalSetup = (
  selectedTimeInterval: ITimeInterval,
  zonedNow: ZonedDateTime
): IGetTimeIntervalSetup => {
  const getTimeSetup: IGetTimeSetup = setupSelector(selectedTimeInterval);

  let startAt;
  let stopAt;

  if (selectedTimeInterval?.key === WEEKS_2_FILTER.key) {
    startAt = zonedNow
      .add(-getTimeSetup.api.count, getTimeSetup.api.timeUnit)
      .format('YYYY-MM-DDT23:59:00');
    stopAt = zonedNow.add(20, 'minute').format('YYYY-MM-DDT23:59:00');
  } else if (selectedTimeInterval?.key === MONTH_FILTER.key) {
    startAt = zonedNow
      .add(-getTimeSetup.api.count, getTimeSetup.api.timeUnit)
      .format('YYYY-MM-01T00:00:00');
    stopAt = zonedNow.add(20, 'minute').toIsoString();
  } else {
    startAt = zonedNow
      .add(-getTimeSetup.api.count, getTimeSetup.api.timeUnit)
      .toIsoString();
    stopAt = zonedNow.add(5, 'minute').toIsoString();
  }

  return {
    startAt,
    stopAt,
    timeResolution: getTimeSetup.resolution,
    chartSetup: getTimeSetup.chart,
  };
};

export const sortTotalMarginDesc = (
  a: IPerformanceChartDataWithTotal,
  b: IPerformanceChartDataWithTotal
) => {
  if (a.total > b.total) return -1;
  if (a.total <= b.total) return 1;
  return 0;
};
