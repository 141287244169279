import FormValidation from 'utils/FormValidation';
import { IUserValues } from './CreateUser/IUserValues';

export const userUtilSchema = (values: IUserValues) => {
  return {
    id: FormValidation(values.id?.value).string().max(6).required(),
    username: FormValidation(values.username?.value)
      .string()
      .pattern(new RegExp('^([^@]{1,64})@(\\S{1,255})$'))
      .max(256)
      .required(),
    roles: FormValidation(values.roles?.value).array().required(),
    name: FormValidation(values.name?.value).string().max(64).required(),
    givenName: FormValidation(values.givenName?.value)
      .string()
      .max(64)
      .required(),
    familyName: FormValidation(values.familyName?.value)
      .string()
      .max(64)
      .required(),
  };
};
