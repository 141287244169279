import {
  GridApi,
  GridReadyEvent,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
} from 'ag-grid-community';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import {
  Participant,
  Registration,
  TradeStrategy,
} from 'api/models/Participant.model';
import { useAppContext } from 'App/AppProvider';
import CellRender from 'components/molecules/CellRender/CellRender';
import { useFormik } from 'formik';
import useQueue, { CREATE, UPDATE } from 'hooks/useQueue';
import { IAdditions, ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FolioSkeleton from '../GridSkeleton/GridSkeleton';
import { IParticipantValues } from './IParticipantValues';
import { participantSchema } from './participantSchema';
import styles from '../ResourcesList/ResourcesList.module.scss';
import {
  initialValues,
  participantColumnDefs,
  participantTransformer,
} from './constants';
import useSSE, { AutoTraderEvent } from 'hooks/useSSE';
import { Event, EventType } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { AppDispatch, AppStore } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchParticipantById,
  fetchParticipants,
  participantUpsert,
} from 'redux/states/participants.state';
import { isEmpty } from 'lodash';
import useRequestManager from 'hooks/useRequestManager';
import { ERequestManager } from 'enums/requestManager';
import { FULFILLED } from 'redux/constants';
import { pickSelectedTimezone } from 'redux/states/miscellaneous.state';
import { ZonedDateTime } from '@pci/pci-ui-library';

const MarketParticipantPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const {
    status: entitiesStatus,
    entities: participantEntities,
    ids,
  } = useSelector((store: AppStore) => store.participant);
  const { pushNotification, logEvent } = useAppContext();
  const {
    enqueue,
    execute,
    result,
    status: requestManagerStatus,
  } = useRequestManager();

  const [sourceUri, setSourceUri] = useState<string | undefined>(undefined);
  const sseInit = {
    event: 'MarketParticipantUpdatedEvent',
    type: EventType.AUTOTRADER,
    sourceURL: sourceUri,
  };
  const { data } = useSSE(sseInit);

  const { resourcesPageResources, resourcesWrapperResources } = styles;

  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      editable: true,
      cellEditorPopup: false,
      wrapText: true,
    };
  }, []);

  const hourStart = ZonedDateTime.parseIso(
    '1900-12-25T01:00:00.000Z',
    selectedTimeZone
  )
    .toIsoString()
    .replace('+00:00', '.000Z');
  const hourEnd = ZonedDateTime.parseIso(
    '2099-11-24T01:15:00.000Z',
    selectedTimeZone
  )
    .toIsoString()
    .replace('+00:00', '.000Z');

  // PARTICIPANTS
  const [oldEditing, setOldEditing] = useState<Participant>();
  const [validName, setValidName] = useState<boolean>(false);
  const [editingModeParticipants, setEditingModeParticipants] =
    useState<boolean>(false);
  const [additions, setAdditions] = useState<IAdditions>({
    key: '',
    additionsIds: [],
  });
  const [participants, setParticipants] = useState<Participant[]>();
  const [gridApiParticipants, setGridApiParticipants] = useState<GridApi>();

  const participantQueueHook = useQueue<Participant, 'id'>('id');

  const initialValueParticipants = initialValues(hourStart, hourEnd);

  const formikParticipants = useFormik({
    initialValues: initialValueParticipants,
    onSubmit: () => {},
    validate: (values: IParticipantValues) => {
      let errors = participantSchema(values);

      if (gridApiParticipants) {
        const instances =
          gridApiParticipants.getCellEditorInstances() as ICellEditorImperativeHandle[];

        if (instances.length > 0) {
          instances.forEach((instance) => {
            if (typeof instance.validateField === 'function') {
              const field = instance.columnField();
              const errored = new Map(Object.entries(errors)).get(field)?.error;
              instance.validateField(errored);
            }
          });
        }
        if (
          errors.name.error === null &&
          errors.name.value &&
          values.newRecord === true
        ) {
          onValidate(errors.name.value);
        }
      }
      return errors;
    },
  });

  const onValidate = async (name: string) => {
    const status = await AutoTraderApi.validateParticipantName(name);
    const valid = status === 404;
    const instances =
      gridApiParticipants?.getCellEditorInstances() as ICellEditorImperativeHandle[];

    if (!valid) {
      instances.forEach((instance) => {
        if (instance.columnField() === 'name') {
          if (typeof instance.validateField === 'function') {
            instance.validateField('This name is already taken');
          }
        }
      });
    }
    setValidName(valid);
  };

  const onClickSaveParticipantButton = async () => {
    const { getQueue } = participantQueueHook;
    const queue = getQueue();
    const created = [...queue.filter((element) => element.status === CREATE)];

    if (created.length) {
      created.forEach(({ data }) => {
        let outStartAt: string;
        let outStopAt: string;
        const { newRecord, id, ...cleanData } = data;
        const { startAt, stopAt, ...rest } = cleanData;

        if (typeof startAt === 'object') {
          const startAtString = startAt as unknown as {
            value: string;
            error: undefined;
            touched: undefined;
          };
          outStartAt = startAtString.value;
        } else {
          outStartAt = startAt;
        }
        if (typeof stopAt === 'object') {
          const stopAtString = stopAt as unknown as {
            value: string;
            error: undefined;
            touched: undefined;
          };
          outStopAt = stopAtString.value;
        } else {
          outStopAt = stopAt;
        }

        enqueue(
          AutoTraderApi.postParticipants({
            startAt: outStartAt,
            stopAt: outStopAt,
            ...rest,
          })
        );
      });
    }

    const updated = [...queue.filter((element) => element.status === UPDATE)];

    if (updated.length) {
      updated.forEach(({ data }) => {
        const { newRecord, id, ...cleanData } = data;
        enqueue(AutoTraderApi.postParticipants(cleanData));
      });
    }
    execute();
  };

  const onClickAddParticipantsRow = () => {
    if (gridApiParticipants) {
      const {
        id: { value },
      } = initialValueParticipants;
      const addition = {
        key: 'id',
        additionsIds: [...additions.additionsIds, value],
      };
      setAdditions(addition);

      gridApiParticipants.stopEditing();
      gridApiParticipants?.applyTransaction({
        add: [initialValueParticipants],
        addIndex: 0,
      });
      toggleEditingParticipants(true);
      gridApiParticipants?.startEditingCell({
        rowIndex: 0,
        colKey: 'name',
      });
    }
  };

  const onClickEnableParticipantEditing = () => {
    toggleEditingParticipants(true);
  };

  const onGridParticipantsReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(70);
    gridEvent.api.sizeColumnsToFit();
    setGridApiParticipants(gridEvent.api);
  };

  const toggleEditingParticipants = useCallback(
    (enable: boolean) => {
      if (gridApiParticipants && !enable) {
        gridApiParticipants.stopEditing();
      }
      setEditingModeParticipants(enable);
    },
    [gridApiParticipants]
  );

  const cleanParticipantQueue = useCallback(() => {
    const { cleanQueue } = participantQueueHook;
    cleanQueue();
  }, [participantQueueHook]);

  const onClickParticipantReset = useCallback(() => {
    toggleEditingParticipants(false);
    cleanParticipantQueue();
    setValidName(false);
    setAdditions({
      key: '',
      additionsIds: [],
    });
    // eslint-disable-next-line
  }, [cleanParticipantQueue, toggleEditingParticipants]);

  const onRowEditingStoppedHandler = (e: RowEditingStoppedEvent) => {
    const { isInQueue, update, setInQueue } = participantQueueHook;
    const {
      id,
      name,
      marketType,
      startAt,
      stopAt,
      tradeFinalizeDeadline,

      autoSubmit,
      balanceRequired,
      balancerHandling,
      balanceTolerance,
      blockSize,
      accountNumber,

      trader,
      primaryKey,
      primaryExpiration,
      secondaryKey,
      secondaryExpiration,

      newRecord,
    } = e.data;
    let registrationObj: Registration;
    let strategy: TradeStrategy | undefined;

    strategy = participants?.find((participant) => participant.id === id)
      ?.strategy || {
      bidStrategy: {
        quantityWithheldFromFront: 0,
        quantityWithheldFromBack: 0,
        ceilingPricedAtFrontQuantity: 0,
        discount: 0,
        minDiscountPercent: 0,
        maxDiscountPercent: 0,
      },
      offerStrategy: {
        quantityWithheldFromFront: 0,
        quantityWithheldFromBack: 0,
        floorPricedAtFrontQuantity: 0,
        premium: 0,
        minPremiumPercent: 0,
        maxPremiumPercent: 0,
      },
    };

    registrationObj = {
      accountNumber,
      trader,
      primaryKey: primaryKey !== null ? primaryKey.trim().split('') : null,
      primaryExpiration,
      secondaryKey:
        secondaryKey !== null ? secondaryKey.trim().split('') : null,
      secondaryExpiration,
    };

    const participant: Participant = {
      id,
      name,
      marketType,
      startAt,
      stopAt,
      tradeFinalizeDeadline,
      autoSubmit,
      balanceRequired,
      balancerHandling,
      balanceTolerance,
      blockSize,
      newRecord,
      resources: [],
      registration: registrationObj,
      strategy,
    };
    const key = participant.id;

    if (JSON.stringify(oldEditing) !== JSON.stringify(e.data)) {
      if (!participant.newRecord) {
        // UPDATE
        if (isInQueue(key)) {
          // IF is already on the queue
          update(key, participant, UPDATE);
        } else {
          setInQueue(participant, UPDATE);
        }
      } else {
        if (isInQueue(key)) {
          // UPDATE CREATED
          update(key, participant, CREATE);
        } else {
          // CREATE
          setInQueue(participant, CREATE);
        }
      }
    }
  };

  const onRowEditingStartedHandler = (e: RowEditingStartedEvent) => {
    const instances =
      gridApiParticipants?.getCellEditorInstances() as ICellEditorImperativeHandle[];

    if (e.data.newRecord) {
      formikParticipants.setFieldValue('newRecord', true);
      onValidate(e.data.name.value);
    } else {
      instances.forEach((instance) => {
        const isNameOrMakertType =
          instance.columnField() === 'name' ||
          instance.columnField() === 'marketType' ||
          instance.columnField() === 'offerMaximumQuantityAllowed' ||
          instance.columnField() === 'offerQuantityWithheldFromFront' ||
          instance.columnField() === 'offerQuantityWithheldFromBack' ||
          instance.columnField() === 'offerFloorPricedAtFrontQuantity' ||
          instance.columnField() === 'offerPremium' ||
          instance.columnField() === 'offerMinPremiumPercent' ||
          instance.columnField() === 'offerMaxPremiumPercent' ||
          instance.columnField() === 'bidMaximumQuantityAllowed' ||
          instance.columnField() === 'bidQuantityWithheldFromFront' ||
          instance.columnField() === 'bidQuantityWithheldFromBack' ||
          instance.columnField() === 'bidCeilingPricedAtFrontQuantity' ||
          instance.columnField() === 'bidDiscount' ||
          instance.columnField() === 'bidMinDiscountPercent' ||
          instance.columnField() === 'bidMaxDiscountPercent';
        if (isNameOrMakertType && typeof instance.setEditable === 'function') {
          instance.setEditable(true);
        }
      });

      setValidName(true);
      formikParticipants.setFieldValue('newRecord', false);
    }

    setOldEditing({ ...e.data });
  };

  const getParticipants = useCallback(() => {
    const participantsArray = Object.values(
      participantEntities
    ) as Participant[];

    if (participantsArray.length) {
      const transformed = participantTransformer(participantsArray);
      setParticipants(transformed);
    }
    gridApiParticipants?.refreshCells();
    gridApiParticipants?.refreshHeader();
  }, [participantEntities, gridApiParticipants]);

  useEffect(() => {
    if (
      requestManagerStatus === ERequestManager.COMPLETED &&
      pushNotification
    ) {
      onClickParticipantReset();
      const { suceeded, clientErrored, serverErrored } = result;
      let created = 0;
      let updated = 0;
      if (!isEmpty(suceeded)) {
        suceeded.forEach((record) => {
          const {
            data,
            config: { method },
            duration,
            status,
          }: any = record;
          if (method === 'put') {
            updated = updated + 1;
            dispatch(participantUpsert(data));
            if (logEvent) {
              logEvent({
                eventTime: new Date(),
                eventName: Event.MODIFIEDPARTICIPANT,
                payload: { ...data },
                timeToComplete: duration,
                category: Category.PARTICIPANTS_PAGE,
                requestStatus: status,
              });
            }
          }
          if (method === 'post') {
            created = created + 1;
            dispatch(participantUpsert(data));
            if (logEvent) {
              logEvent({
                eventTime: new Date(),
                eventName: Event.ADDEDPARTICIPANT,
                payload: { ...data },
                timeToComplete: duration,
                category: Category.PARTICIPANTS_PAGE,
                requestStatus: data.status,
              });
            }
          }
        });
      }
      if (!isEmpty(clientErrored)) {
        clientErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
          } = record;
          pushNotification('error', `${message}`);
        });
      }
      if (!isEmpty(serverErrored)) {
        serverErrored.forEach((record) => {
          const {
            data: {
              error: { message },
            },
          } = record;
          pushNotification('error', `${message}`);
        });
      }

      if (created) pushNotification('success', `${created} Rows was created`);
      if (updated) pushNotification('info', `${updated} Rows was updated`);
      getParticipants();
    }
    // eslint-disable-next-line
  }, [requestManagerStatus]);

  useEffect(() => {
    getParticipants();
    onClickParticipantReset();
    // eslint-disable-next-line
  }, [selectedTimeZone]);

  const fetchById = useCallback(
    (participantId: string) => {
      dispatch(fetchParticipantById(participantId));
    },
    [dispatch]
  );

  useEffect(() => {
    if (data) {
      const { targets } = data as AutoTraderEvent;
      if (targets.length) {
        const participantId = targets[0];
        fetchById(participantId);
      }
    }
  }, [data, fetchById]);

  useEffect(() => {
    if (logEvent) {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDPARTICIPANTSPAGE,
        category: Category.PARTICIPANTS_PAGE,
      });
    }
  }, [logEvent]);

  useEffect(() => {
    if (entitiesStatus === FULFILLED) getParticipants();
  }, [entitiesStatus, getParticipants]);

  useEffect(() => {
    getAuditSource();
    if (!ids.length) dispatch(fetchParticipants());
  }, [ids.length, dispatch]);

  const getAuditSource = async () => {
    const auditUri = await AutoTraderApi.getAutoTraderSSEURL();
    setSourceUri(auditUri);
  };

  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <FolioSkeleton
          sorted
          fullHeight
          additions={additions}
          canSave={validName}
          canAdd
          canEdit
          context={{
            formik: formikParticipants,
            queueHook: participantQueueHook,
          }}
          onRowEditingStopped={onRowEditingStoppedHandler}
          onRowEditingStarted={onRowEditingStartedHandler}
          onClickSaveButton={onClickSaveParticipantButton}
          onClickAddRow={onClickAddParticipantsRow}
          onClickReset={onClickParticipantReset}
          onClickEnableEditing={onClickEnableParticipantEditing}
          defaultDefs={defaultColDef}
          loading={entitiesStatus === ('loading' || 'idle')}
          onGridReady={onGridParticipantsReadyHandler}
          editMode={editingModeParticipants}
          components={{ CellRender: CellRender }}
          columnDefs={participantColumnDefs}
          data={participants}
          title='Market Participants'
          pagination={true}
          headerHeight={60}
        />
      </div>
    </div>
  );
};

export default MarketParticipantPage;
