import CellEditor from 'components/molecules/CellEditor/CellEditor';
import PercentEditor from 'components/molecules/PercentEditor/PercentEditor';
import SelectEditor from 'components/molecules/SelectEditor/SelectEditor';
import {
  checkFormatter,
  decimalsCurrencyFormatter,
  generateToken,
  infinityFormatter,
  percentNoDecimalsFormatter,
} from 'utils/general';

export const columnsDef = (getParticipantHeaderName: Function) => [
  {
    headerClass: 'strategy-resource-identifier',
    headerName: getParticipantHeaderName(),
    field: 'resourceIdentifier',
    maxWidth: 170,
    minWidth: 160,
    cellEditor: SelectEditor,
    pinned: 'left',
    cellEditorParams: {
      options: [{ value: 'N/A', label: 'N/A' }],
    },
  },
  {
    headerName: 'Bid Strategy',
    headerClass: 'strategy-bid-header',
    children: [
      {
        headerName: 'Bid?',
        headerClass: 'strategy-bid-header',
        field: 'isBid',
        minWidth: 60,
        maxWidth: 60,
        valueFormatter: checkFormatter,
        cellStyle: { fontSize: '20px' },
        cellClass: 'strategy-bid-cell',
      },
      {
        headerName: 'Max Qty Allowed \n (MW)',
        headerClass: 'strategy-bid-header',
        field: 'bidMaximumQuantityAllowed',
        minWidth: 80,
        cellEditor: CellEditor,
        valueFormatter: infinityFormatter,
        cellClass: 'strategy-bid-cell',
      },
      {
        headerName: 'Front Qty \n Withheld \n (MW)',
        headerClass: 'strategy-bid-header',
        field: 'bidQuantityWithheldFromFront',
        minWidth: 65,
        cellEditor: CellEditor,
        cellRenderer: 'CellRender',
        cellClass: 'strategy-bid-cell',
      },
      {
        headerName: 'Back Qty \n Withheld \n (MW)',
        headerClass: 'strategy-bid-header',
        field: 'bidQuantityWithheldFromBack',
        minWidth: 65,
        cellEditor: CellEditor,
        cellRenderer: 'CellRender',
        cellClass: 'strategy-bid-cell',
      },
      {
        headerName: 'Discount \n Amount \n ($/MWH)',
        headerClass: 'strategy-bid-header',
        field: 'bidDiscount',
        minWidth: 80,
        cellEditor: CellEditor,
        valueFormatter: decimalsCurrencyFormatter,
        cellClass: 'strategy-bid-cell-currency',
      },
      {
        headerName: 'Minimum \n Discount \n (%)',
        headerClass: 'strategy-bid-header',
        field: 'bidMinDiscountPercent',
        minWidth: 65,
        cellEditor: PercentEditor,
        valueFormatter: percentNoDecimalsFormatter,
        cellClass: 'strategy-bid-cell',
      },
      {
        headerName: 'Maximum \n Discount \n (%)',
        headerClass: 'strategy-bid-header',
        field: 'bidMaxDiscountPercent',
        minWidth: 66,
        cellEditor: PercentEditor,
        valueFormatter: percentNoDecimalsFormatter,
        cellClass: 'strategy-bid-cell',
      },
      {
        headerName: 'Ceiling Price \n At Front Qty \n (MW)',
        headerClass: 'strategy-bid-header',
        field: 'bidCeilingPricedAtFrontQuantity',
        minWidth: 85,
        cellEditor: CellEditor,
        cellRenderer: 'CellRender',
        cellClass: 'strategy-bid-cell',
      },
    ],
  },
  {
    headerName: 'Offer Strategy',
    headerClass: 'strategy-offer-header',
    children: [
      {
        headerName: 'Offer?',
        headerClass: 'strategy-offer-header',
        field: 'isOffer',
        minWidth: 60,
        maxWidth: 60,
        valueFormatter: checkFormatter,
        cellStyle: { fontSize: '20px' },
        cellClass: 'strategy-offer-cell',
      },
      {
        headerName: 'Max Qty \n Allowed \n (MW)',
        headerClass: 'strategy-offer-header',
        field: 'offerMaximumQuantityAllowed',
        minWidth: 80,
        cellEditor: CellEditor,
        valueFormatter: infinityFormatter,
        cellClass: 'strategy-offer-cell',
      },
      {
        headerName: 'Front Qty \n Withheld \n (MW)',
        headerClass: 'strategy-offer-header',
        field: 'offerQuantityWithheldFromFront',
        minWidth: 65,
        cellEditor: CellEditor,
        cellRenderer: 'CellRender',
        cellClass: 'strategy-offer-cell',
      },
      {
        headerName: 'Back Qty \n Withheld \n (MW)',
        headerClass: 'strategy-offer-header',
        field: 'offerQuantityWithheldFromBack',
        minWidth: 65,
        cellEditor: CellEditor,
        cellRenderer: 'CellRender',
        cellClass: 'strategy-offer-cell',
      },
      {
        headerName: 'Premium \n Amount \n ($/MWH)',
        headerClass: 'strategy-offer-header',
        field: 'offerPremium',
        minWidth: 80,
        cellEditor: CellEditor,
        valueFormatter: decimalsCurrencyFormatter,
        cellClass: 'strategy-offer-cell-currency',
      },
      {
        headerName: 'Minimum \n Premium \n (%)',
        headerClass: 'strategy-offer-header',
        field: 'offerMinPremiumPercent',
        minWidth: 65,
        cellEditor: PercentEditor,
        valueFormatter: percentNoDecimalsFormatter,
        cellClass: 'strategy-offer-cell',
      },
      {
        headerName: 'Maximum \n Premium \n (%)',
        headerClass: 'strategy-offer-header',
        field: 'offerMaxPremiumPercent',
        minWidth: 66,
        cellEditor: PercentEditor,
        valueFormatter: percentNoDecimalsFormatter,
        cellClass: 'strategy-offer-cell',
      },
      {
        headerName: 'Floor Price \n At Front Qty \n (MW)',
        headerClass: 'strategy-offer-header',
        field: 'offerFloorPricedAtFrontQuantity',
        minWidth: 85,
        cellEditor: CellEditor,
        cellRenderer: 'CellRender',
        cellClass: 'strategy-offer-cell',
      },
    ],
  },
];

export const initialNewRowValue = {
  id: { value: generateToken(), error: undefined, touched: undefined },
  resourceIdentifier: {
    value: 'TREGION_01',
    error: undefined,
    touched: undefined,
    required: false,
  },
  isBid: {
    value: false,
    error: undefined,
    touched: undefined,
    required: false,
  },
  isOffer: {
    value: false,
    error: undefined,
    touched: undefined,
    required: false,
  },
  bidQuantityWithheldFromFront: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  bidMaximumQuantityAllowed: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  bidQuantityWithheldFromBack: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  bidCeilingPricedAtFrontQuantity: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  bidDiscount: { value: '0', error: undefined, touched: undefined },
  bidMinDiscountPercent: { value: '0', error: undefined, touched: undefined },
  bidMaxDiscountPercent: { value: '0', error: undefined, touched: undefined },

  offerMaximumQuantityAllowed: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  offerQuantityWithheldFromFront: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  offerQuantityWithheldFromBack: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  offerFloorPricedAtFrontQuantity: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  offerPremium: { value: '0', error: undefined, touched: undefined },
  offerMinPremiumPercent: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  offerMaxPremiumPercent: {
    value: '0',
    error: undefined,
    touched: undefined,
  },
  newRecord: true,
};
