import {
  ArrowCircleDown,
  ArrowCircleUp,
  Cached,
  Settings,
} from '@mui/icons-material';
import style from './AddResources.module.scss';
import {
  AddResourcesContextProvider,
  useAddResourcesContext,
} from './AddResourcesProvider';
import {
  CREATING,
  GENERATING_UNIT,
  LOAD,
  POWER_EXPORT,
  POWER_IMPORT,
} from './constant';
import GeneratingUnit from './Forms/GeneratingUnit/GeneratingUnit';

const FormsContainer = () => {
  const { resourceToAdd } = useAddResourcesContext();
  return (
    <div>
      {resourceToAdd === GENERATING_UNIT && <GeneratingUnit mode={CREATING} />}
      {resourceToAdd === LOAD && <div>{LOAD}</div>}
      {resourceToAdd === POWER_IMPORT && <div>{POWER_IMPORT}</div>}
      {resourceToAdd === POWER_EXPORT && <div>{POWER_EXPORT}</div>}
    </div>
  );
};

const AddResourcesComponent = () => {
  const {
    resourceContainer,
    selected,
    resourceIcon,
    resourceMeta,
    resourceList,
  } = style;
  const { setResourceToAdd, resourceToAdd } = useAddResourcesContext();
  return (
    <div className={resourceList}>
      <div>
        <h3>Select type of resource:</h3>
      </div>
      <div
        onClick={() => setResourceToAdd && setResourceToAdd(GENERATING_UNIT)}
        className={`${resourceContainer} ${
          resourceToAdd === GENERATING_UNIT ? selected : ''
        }`}
      >
        <div id='resourceicon' className={resourceIcon}>
          <Settings />
        </div>
        <div className={resourceMeta}>
          <h4>Generation Unit</h4>
          <p>
            A unit (such as a thermal or hydro unit) that has capacities and can
            be actively throttled up or down.
          </p>
        </div>
      </div>
      <div
        onClick={() => setResourceToAdd && setResourceToAdd(LOAD)}
        className={`${resourceContainer} ${
          resourceToAdd === LOAD ? selected : ''
        }`}
      >
        <div id='resourceicon' className={resourceIcon}>
          <Cached />
        </div>
        <div className={resourceMeta}>
          <h4>Load</h4>
          <p>
            An energy obligation, often to a final consumer, that may
            unexpectedly change throughout a day.
          </p>
        </div>
      </div>
      <div
        onClick={() => setResourceToAdd && setResourceToAdd(POWER_IMPORT)}
        className={`${resourceContainer} ${
          resourceToAdd === POWER_IMPORT ? selected : ''
        }`}
      >
        <div id='resourceicon' className={resourceIcon}>
          <ArrowCircleUp />
        </div>
        <div className={resourceMeta}>
          <h4>Power Import</h4>
          <p>
            Incoming energy supply, often from a third party energy generator.
          </p>
        </div>
      </div>
      <div
        onClick={() => setResourceToAdd && setResourceToAdd(POWER_EXPORT)}
        className={`${resourceContainer} ${
          resourceToAdd === POWER_EXPORT ? selected : ''
        }`}
      >
        <div id='resourceicon' className={resourceIcon}>
          <ArrowCircleDown />
        </div>
        <div className={resourceMeta}>
          <h4>Power Export</h4>
          <p>
            Outgoing energy commitments, generally to help a third party meet
            their load.
          </p>
        </div>
      </div>
    </div>
  );
};

const AddResources = () => {
  return (
    <AddResourcesContextProvider>
      <AddResourcesComponent />
      <FormsContainer />
    </AddResourcesContextProvider>
  );
};

export default AddResources;
