import { Logger } from './logger';
import { LoggerBuilder } from './LoggerBuilder';

export class Director {
  private builder!: LoggerBuilder;

  public setBuilder(builder: LoggerBuilder): void {
    this.builder = builder;
  }

  public buildLog(log: Logger): void {
    this.builder.setLog(log);
  }

  public getBuilder(): Logger {
    return this.builder.getLog();
  }
}
