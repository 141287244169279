import { Skeleton } from '@mui/material';
import React from 'react';

type ItemWrapperProps = {
  children?: React.ReactNode;
  loading: boolean;
};

const SkeletonWrapper: React.FC<ItemWrapperProps> = ({ children, loading }) => {
  return (
    <>{loading ? <Skeleton animation='wave' height={'100%'} /> : children}</>
  );
};

export default SkeletonWrapper;
