import i18n from 'i18next';
import { isAbsent } from './utils';

const booleanField = <T>(value: T, error: string | null) => {
  return {
    value,
    error,
    required: function required(errorMessage: string | null = null) {
      if (this.error) return this;
      if (isAbsent(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.required_field');
      }
      return this;
    },
    isTrue: function isTrue(errorMessage: string | null = null) {
      if (this.error) return this;
      if (this.value) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_true');
      }
      return this;
    },
    isFalse: function isFalse(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!this.value) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_false');
      }
      return this;
    },
    // custom: function custom(fn, errorMessage, ...args) {
    //   if (this.error) return this;
    //   if (!fn(this.value, ...args)) {
    //     this.error = errorMessage ? i18n.t(errorMessage) : i18n.t('form_validation_util.field_no_valid');
    //   }
    //   return this;
    // },
    validate: function validate() {
      return this.error;
    },
  };
};

export default booleanField;
