import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import { ApiKeyCreatedDto } from '../../../api/models/ApiKey.model';
import { useTranslation } from 'react-i18next';

export default function ViewApiKeyWithSecretDialog({
  apiKeyWithSecret,
  setApiKeyWithSecret,
}: {
  apiKeyWithSecret: ApiKeyCreatedDto | null;
  setApiKeyWithSecret: (apiKeyWithSecret: ApiKeyCreatedDto | null) => void;
}) {
  const { t } = useTranslation();

  const handleClose = () => {
    setApiKeyWithSecret(null);
  };

  return (
    <div>
      <Dialog open={apiKeyWithSecret !== null} onClose={handleClose}>
        <DialogTitle>
          {t('apiKeys.dialogs.titles.newApiKeyCreated')}
        </DialogTitle>
        {apiKeyWithSecret !== null && (
          <DialogContent>
            <DialogContentText>
              Username: {apiKeyWithSecret.apiKeyUsername}
            </DialogContentText>
            <DialogContentText>
              Secret: {apiKeyWithSecret.apiKeySecret}
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
