import { TChildren } from 'types/general';
import * as S from './OperatingPlan.styles';

interface IOperatingPlanPages {
  title?: TChildren;
}

const OperatingPlan: React.FC<IOperatingPlanPages> = ({ children, title }) => {
  return (
    <S.OperatingPlan>
      <S.Wrapper>
        {title && <S.PageTitle data-testid='title'>{title}</S.PageTitle>}
        {children}
      </S.Wrapper>
    </S.OperatingPlan>
  );
};

export default OperatingPlan;
