import { IShowTime } from '@pci/pci-ui-library';

export const MONTH_FORMAT = 'MM/yyyy';

export const WEEKLY_FORMAT = 'ww MMMM/YY';

export const DAILY_FORMAT = 'MM/DD/yyyy';

export const DAY_FORMAT = 'MM/DD';

export const DATE_FORMAT = `${DAY_FORMAT}/YYYY`;

export const TIME_FORMAT = 'HH:mm';

export const DETAILED_TIME_FORMAT = `${TIME_FORMAT}:ss`;

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const CHART_DATE_TIME_FORMAT = `MM/dd/yyyy HH:mm`;

export const TEST_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const DETAILED_DATE_TIME_FORMAT = `${DATE_FORMAT} ${DETAILED_TIME_FORMAT}`;

export const SECONDS_IN_HOUR = 3600;

export const SECONDS_IN_MINUTE = 60;

export const MILLISECONDS_IN_SECOND = 1000;

export const FIFTEEN_MINUTES_IN_MILLISECONDS =
  15 * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;

export const SHOW_TIME: IShowTime = {
  format: TIME_FORMAT,
};

export const SHOW_TIME_TRADE_INTERVALS: IShowTime = {
  format: TIME_FORMAT,
  minuteStep: 15,
};
