import { IImage } from 'interfaces/component';
import { IRoute } from 'interfaces/route';
import { TTimeZoneOptions } from 'types/dateTime';

export const UPDATE_INTERVAL_IN_MILLISECONDS = 499;

export const ALL_TIME_ZONE_OPTIONS: TTimeZoneOptions = [
  {
    label: 'Chicago',
    value: 'America/Chicago',
  },
  {
    label: 'Detroit',
    value: 'America/Detroit',
  },
  {
    label: 'Denver',
    value: 'America/Denver',
  },
  {
    label: 'Los Angeles',
    value: 'America/Los_Angeles',
  },
  {
    label: 'New York',
    value: 'America/New_York',
  },
  {
    label: 'Phoenix',
    value: 'America/Phoenix',
  },
];

export const LOGIN_ROUTE = '*';

export const ROUTES: IRoute[] = [];

export const PCI_LOGO_DARK: IImage = {
  height: 23,
  image: 'PCI-Logo-dark.svg',
};

export const PCI_LOGO_LIGHT: IImage = {
  height: 23,
  image: 'PCI-Logo-light.svg',
};

export const SEEM_LOGO_DARK: IImage = {
  height: 36,
  image: 'SEEM-Logo-dark.svg',
  width: 70,
};

export const SEEM_LOGO_LIGHT: IImage = {
  height: 36,
  image: 'SEEM-Logo-light.svg',
  width: 70,
};

export const allAvailableRoles = [
  'seem-trader:viewer',
  'seem-trader:oversight',
  'seem-trader:analyst',
  'seem-trader:trader',
  'seem-trader:admin',
];
