import FormValidation from 'utils/FormValidation';
import { ITradeStrategyValues } from './ITradeStrategyValues';

export const tradeStrategySchema = (values: ITradeStrategyValues) => {
  return {
    resourceIdentifier: FormValidation(values.resourceIdentifier?.value)
      .string()
      .max(26)
      .pattern(new RegExp('^[A-Z_][A-Z0-9_]{0,25}$'))
      .required(),

    bidMaximumQuantityAllowed: FormValidation(
      values.bidMaximumQuantityAllowed?.value
    )
      .number()
      .min(0.0),
    bidQuantityWithheldFromFront: FormValidation(
      values.bidQuantityWithheldFromFront?.value
    )
      .number()
      .min(0.0),
    bidQuantityWithheldFromBack: FormValidation(
      values.bidQuantityWithheldFromBack?.value
    )
      .number()
      .min(0.0),
    bidCeilingPricedAtFrontQuantity: FormValidation(
      values.bidCeilingPricedAtFrontQuantity?.value
    )
      .number()
      .min(0.0),
    bidDiscount: FormValidation(values.bidDiscount?.value).number().min(0.0),
    bidMinDiscountPercent: FormValidation(values.bidMinDiscountPercent?.value)
      .number()
      .min(0.0),
    bidMaxDiscountPercent: FormValidation(values.bidMaxDiscountPercent?.value)
      .number()
      .min(0.0),
    offerMaximumQuantityAllowed: FormValidation(
      values.offerMaximumQuantityAllowed?.value
    )
      .number()
      .min(0.0),
    offerQuantityWithheldFromFront: FormValidation(
      values.offerQuantityWithheldFromFront?.value
    )
      .number()
      .min(0.0),
    offerQuantityWithheldFromBack: FormValidation(
      values.offerQuantityWithheldFromBack?.value
    )
      .number()
      .min(0.0),
    offerFloorPricedAtFrontQuantity: FormValidation(
      values.offerFloorPricedAtFrontQuantity?.value
    )
      .number()
      .min(0.0),
    offerPremium: FormValidation(values.offerPremium?.value).number().min(0.0),
    offerMinPremiumPercent: FormValidation(values.offerMinPremiumPercent?.value)
      .number()
      .min(0.0),
    offerMaxPremiumPercent: FormValidation(values.offerMaxPremiumPercent?.value)
      .number()
      .min(0.0),
  };
};
