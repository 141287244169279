import { useAppContext } from 'App/AppProvider';
import React from 'react';
import styles from './Logo.module.scss';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';

interface Props {
  theme?: string;
  margin?: number;
  height?: number;
}

function Logo({ theme, margin, height }: Props) {
  const sideBarCollapsed = useSelector(
    (store: AppStore) => store.miscellaneous.navigation.sideBarCollapsed
  );
  const { isCSMUser } = useAppContext();
  const { logoContainer, logo, logoText, collapsed, csm } = styles;
  const seem =
    theme === 'dark'
      ? `${process.env.PUBLIC_URL}/assets/images/seemtrader-dark.svg`
      : `${process.env.PUBLIC_URL}/assets/images/seemtrader.svg`;
  return (
    <div
      className={`${logoContainer} ${isCSMUser && csm}`}
      style={{ margin, height }}
    >
      <img
        className={logo}
        alt='admin logo'
        src={
          isCSMUser
            ? `${process.env.PUBLIC_URL}/assets/images/PCI-Logo-CSM.svg`
            : `${process.env.PUBLIC_URL}/assets/images/Logo.svg`
        }
      />
      <img
        className={`${logoText} ${sideBarCollapsed ? collapsed : ''}`}
        alt='admin logo'
        src={
          isCSMUser
            ? `${process.env.PUBLIC_URL}/assets/images/pci-csm.svg`
            : seem
        }
      />
    </div>
  );
}

export default Logo;
