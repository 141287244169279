import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { ValueGetterParams } from 'ag-grid-enterprise';
import {
  HOURS_24_FILTER,
  HOURS_4_FILTER,
  HOUR_FILTER,
  MONTHS_3_FILTER,
  MONTH_FILTER,
  WEEK_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import { ITimeInterval } from 'components/molecules/RangeFilterTab/types';

import { IDataGridColumn } from 'interfaces/dataGrid';
import { IIndexable } from 'interfaces/general';
import { TDateISO } from 'types/data';

import { CELL_MIN_WIDTH, ENERGY_ANCILLARY_KEYS } from './constants';
import M15CellRenderer from './M15.cellRenderer';
import { EDataGridView } from 'enums/dataGrid';
import { roundValue } from 'utils/general';

interface IDynamicColumnsDef {
  startDate: TDateISO;
  endDate: TDateISO;
  timeZone: TTimeZone;
}

export const dynamicColumnsDef = ({
  startDate,
  endDate,
  timeZone,
}: IDynamicColumnsDef): IDataGridColumn[] => {
  const headerClass =
    'ag-header-cell-aligned-center ag-border-cell operating-plan-header';
  const childrenHeaderClass =
    'ag-header-cell-aligned-center ag-border-cell operating-plan-hours-header';
  const childrenHeaderNames = ['0:00', '0:15', '0:30', '0:45'];
  const type = 'rightAligned';
  const maxWidth = CELL_MIN_WIDTH;
  const minWidth = CELL_MIN_WIDTH;

  const parentColumnDef = {
    marryChildren: true,
    headerClass,
  };

  const childrenColumnDef = {
    headerClass: childrenHeaderClass,
    minWidth,
    maxWidth,
    type,
  };

  const defaultColumnsDef = [
    {
      field: 'resourceIdentifier',
      hide: true,
      minWidth: 100,
      rowGroup: true,
    },
    {
      cellClass: 'ag-cell-pinned-group',
      headerName: 'Resources',
      filter: 'agTextColumnFilter',
      filterValueGetter: (params: ValueGetterParams) =>
        params.data.resourceIdentifier,
      menuTabs: ['filterMenuTab'],
      suppressSizeToFit: true,
      field: 'type',
      flex: 1,
      minWidth: 130,
      pinned: 'left',
    },
  ];

  // select custom range
  // change timezone

  const customValueGetter = (params: any) => {
    if (params.data !== undefined) {
      type ObjectKeyParent = keyof typeof params.data;
      const fieldGroup = params.colDef.field;
      const parentField = fieldGroup.split('.')[0] as ObjectKeyParent;
      const dataField = fieldGroup.split('.')[1] as ObjectKeyParent;
      const currentParentField = params.data[parentField];

      if (currentParentField === undefined) return;
      const currentDataSearch = currentParentField[dataField];
      if (currentDataSearch === undefined) return;
      return params.data.type === 'Imbalance'
        ? currentDataSearch.energy
        : currentDataSearch.num;
    }
  };

  const zonedStartDate = ZonedDateTime.parseIso(startDate, timeZone);
  const zonedStartDateDayStart = zonedStartDate.startOf('day');
  const zonedEndDate = ZonedDateTime.parseIso(endDate, timeZone);
  const zonedEndDateDayStart = zonedEndDate.startOf('day');
  const daysDiff = zonedEndDateDayStart.diff(zonedStartDateDayStart, 'days');

  const minuteColumnsDef = (parentHeaderName: string) =>
    childrenHeaderNames.map((item) => {
      return {
        ...childrenColumnDef,
        headerClass: childrenHeaderClass,
        headerName: item,
        field: `${parentHeaderName}.${item}`,
        cellRenderer: M15CellRenderer,
        valueGetter: customValueGetter,
      };
    });

  const hourColumnsDef = (
    dailyHours: number,
    time: ZonedDateTime,
    dayKey: string
  ) =>
    Array.from(Array(dailyHours)).map((_, i) => {
      const zonedTime = time.add(i, 'hours');
      const hourAhead = zonedTime.getHour() + 1;
      const hour = hourAhead;
      const headerName = hour <= 9 ? `HE 0${hour}` : `HE ${hour}`;
      const headerNameCleaned = dayKey + headerName.replace(' ', '');

      return {
        headerName,
        ...parentColumnDef,
        children: minuteColumnsDef(headerNameCleaned),
      };
    });

  const dayColumnsDef = Array.from(Array(daysDiff + 1)).map((_, i) => {
    const isFirstDay: boolean = i === 0;
    const isLastDay: boolean = i === daysDiff;

    const zonedTime = zonedStartDate.add(i, 'days');
    const zonedCorrectTime = !isFirstDay ? zonedTime.startOf('day') : zonedTime;
    const zonedNextDayTime = zonedTime.startOf('day').add(1, 'days');
    const minDiff =
      daysDiff === 0
        ? zonedEndDate.diff(zonedTime, 'minutes')
        : isLastDay
        ? zonedEndDate.diff(zonedEndDateDayStart, 'minutes')
        : zonedNextDayTime.diff(zonedCorrectTime, 'minutes');
    const dayKey = `${zonedTime.format('YYYY-MM-DD')}-`;
    let hoursDiff = Math.ceil(minDiff / 60);
    const isToday: boolean =
      zonedTime.format('YYYY-MM-DD') ===
      ZonedDateTime.now(timeZone).format('YYYY-MM-DD');

    if (
      zonedEndDate.getMinute() > 0 &&
      zonedStartDate.getMinute() > 0 &&
      minDiff === 60
    )
      hoursDiff += 1;

    return {
      ...parentColumnDef,
      headerName: isToday
        ? `TODAY, ${zonedTime.format('MMMM DD, YYYY')}`
        : zonedTime.format('MMMM DD, YYYY'),
      children: hourColumnsDef(hoursDiff, zonedCorrectTime, dayKey),
    };
  });

  const columnsDef = [...defaultColumnsDef, ...dayColumnsDef];

  return columnsDef;
};

type TGetHeaderTitle = (
  startAt: string,
  timeZone: TTimeZone
) => {
  minuteKey: string; // 0:15
  dateHourKey: string; // YYYY-MM-DD-HE01
};

export const timeRelativeVariables: TGetHeaderTitle = (startAt, timeZone) => {
  const zonedTime = ZonedDateTime.parseIso(startAt, timeZone);
  const minute = zonedTime.getMinute();
  const hourAhead = zonedTime.getHour() + 1;
  const hourKey = hourAhead <= 9 ? `HE0${hourAhead}` : `HE${hourAhead}`;
  const minuteKey = minute === 0 ? `0:0${minute}` : `0:${minute}`;
  const dateKey = zonedTime.format('YYYY-MM-DD');
  const dateHourKey = `${dateKey}-${hourKey}`;

  return {
    minuteKey,
    dateHourKey,
  };
};

interface IGetEnergyClassName {
  energy: number;
  lowLimit: number;
  highLimit: number;
  status: string;
}

export const getEnergyClassName = ({
  energy,
  lowLimit,
  highLimit,
  status,
}: IGetEnergyClassName): string => {
  const startingValue = 0.01;
  if (status === 'FIXED_MW' || status === 'FIXED_SCH') {
    return 'fixed';
  }
  if (energy === 0 && lowLimit > 0) {
    return 'dash';
  }
  if (energy > highLimit) {
    return 'upper';
  }
  if (energy <= lowLimit) {
    return 'lower';
  }
  if (energy >= lowLimit + startingValue && energy <= highLimit) {
    const oneChunk = (highLimit - lowLimit) / 3;
    const relativeEnergy = energy - lowLimit;

    if (relativeEnergy <= oneChunk) return 'lower-middle';
    if (relativeEnergy <= oneChunk * 2) return 'middle';
    return 'higher-middle';
  }

  return 'default';
};

export const getImbalanceClassName = (energy: number): string => {
  if (energy > 1) {
    return 'upper';
  }
  if (energy < 0) {
    return 'lower';
  }

  return 'default';
};

interface IGetImbalanceValues extends IIndexable {
  dateHourKey: string;
  minuteKey: string;
  planType: string;
  imbalanceDataHolder: IIndexable;
  isLastIteration?: boolean;
  energy: number;
  spin: number;
  spindn: number;
  regup: number;
  regdn: number;
  balup: number;
  baldn: number;
}

export const getImbalanceValues = ({
  dateHourKey,
  minuteKey,
  planType,
  imbalanceDataHolder,
  isLastIteration,
  ...energyProps
}: IGetImbalanceValues) => {
  if (!imbalanceDataHolder?.dateKey)
    imbalanceDataHolder.dateKey = { type: 'Imbalance' };
  if (!imbalanceDataHolder.dateKey?.[dateHourKey])
    imbalanceDataHolder.dateKey[dateHourKey] = {};
  if (!imbalanceDataHolder.dateKey[dateHourKey]?.[minuteKey])
    imbalanceDataHolder.dateKey[dateHourKey][minuteKey] = {
      energy: 0,
      spin: 0,
      spindn: 0,
      regup: 0,
      regdn: 0,
      balup: 0,
      baldn: 0,
      generation: {
        energy: 0,
        spin: 0,
        spindn: 0,
        regup: 0,
        regdn: 0,
        balup: 0,
        baldn: 0,
      },
      load: {
        energy: 0,
        spin: 0,
        spindn: 0,
        regup: 0,
        regdn: 0,
        balup: 0,
        baldn: 0,
      },
    };

  const setCumulativeEnergyValues = (energyType: string) => {
    imbalanceDataHolder.dateKey[dateHourKey][minuteKey][planType][energyType] +=
      energyProps?.[energyType] || 0;
  };

  const setTotalEnergy = (energyType: string) => {
    imbalanceDataHolder.dateKey[dateHourKey][minuteKey][energyType] =
      imbalanceDataHolder.dateKey[dateHourKey][minuteKey].generation[
        energyType
      ] - imbalanceDataHolder.dateKey[dateHourKey][minuteKey].load[energyType];
  };

  ENERGY_ANCILLARY_KEYS.map((item: string) => setCumulativeEnergyValues(item));

  if (isLastIteration) {
    ENERGY_ANCILLARY_KEYS.map((item: string) => setTotalEnergy(item));
  }
};

export const planTypeAPIMap =
  (
    planType: string,
    timeZone: TTimeZone,
    imbalanceDataHolder: any,
    isLastIteration?: boolean
  ) =>
  (apiData: any) => {
    const { startAt, energy, spin, spindn, regup, regdn, balup, baldn } =
      apiData;
    const { minuteKey, dateHourKey } = timeRelativeVariables(startAt, timeZone);
    getImbalanceValues({
      planType,
      dateHourKey,
      minuteKey,
      energy,
      spin,
      spindn,
      regup,
      regdn,
      balup,
      baldn,
      imbalanceDataHolder,
      isLastIteration,
    });
  };

export const getParsedFilter = (
  selectedRangeFilterTab: ITimeInterval
): number => {
  if (selectedRangeFilterTab === undefined) return 0;

  return selectedRangeFilterTab?.key === HOUR_FILTER.key
    ? 1
    : selectedRangeFilterTab?.key === HOURS_4_FILTER.key
    ? 4
    : selectedRangeFilterTab?.key === HOURS_24_FILTER.key
    ? 24
    : selectedRangeFilterTab?.key === WEEK_FILTER.key
    ? 7
    : selectedRangeFilterTab?.key === MONTH_FILTER.key
    ? 30
    : selectedRangeFilterTab?.key === MONTHS_3_FILTER.key
    ? 90
    : 0;
};

export const gridDataByGroupForEach = (
  selectedPlanTypeData: any[],
  timeZone: TTimeZone,
  selectedGridViewType: EDataGridView
) => {
  const accumulator = Object.create(null);
  selectedPlanTypeData.forEach((item) => {
    const {
      id,
      incrementalCost,
      modifiedBy,
      modifiedOn,
      resourceIdentifier,
      startAt,
      startupCost,
      stopAt,
      ...props
    } = item;
    const className =
      (props.lowLimit &&
        props.highLimit &&
        getEnergyClassName({
          status: props.status,
          energy: props.energy,
          highLimit: props.highLimit,
          lowLimit: props.lowLimit,
        })) ||
      undefined;

    const { minuteKey, dateHourKey } = timeRelativeVariables(startAt, timeZone);

    if (selectedGridViewType === EDataGridView.Resources) {
      getResourcesGroup(
        props,
        accumulator,
        resourceIdentifier,
        dateHourKey,
        minuteKey,
        item,
        className
      );
    } else {
      getValuesGroupPushed(
        props,
        accumulator,
        resourceIdentifier,
        dateHourKey,
        minuteKey,
        item,
        className
      );
    }
  });
  const gridDataMainValues = Object.values(accumulator);
  const gridDataRowValues = gridDataMainValues.map((item: any) =>
    Object.values(item)
  );
  const energyFirstSort = (a: any, b: any) => {
    if (a.resourceIdentifier === 'energy') {
      if (b.resourceIdentifier === 'energy') {
        return a.type > b.type ? 1 : -1;
      }
      return -1;
    }
    return 0;
  };

  return gridDataRowValues.flat().sort(energyFirstSort);
};

const getValuesGroupPushed = (
  props: any[],
  acc: any,
  resourceIdentifier: string,
  dateHourKey: string,
  minuteKey: string,
  item: any,
  className?: string
) => {
  let colector = acc;
  Object.keys(props).forEach((key: string) => {
    if (!colector?.[key]) colector[key] = {};
    if (!colector[key]?.[resourceIdentifier]) {
      colector[key][resourceIdentifier] = {
        resourceIdentifier: key,
        type: resourceIdentifier,
      };
    }
    if (!colector[key][resourceIdentifier][dateHourKey]) {
      colector[key][resourceIdentifier][dateHourKey] = {};
    }
    colector[key][resourceIdentifier][dateHourKey][minuteKey] = {
      num: roundValue(item[key], 0),
      className,
    };
  });
};

const getResourcesGroup = (
  props: any[],
  acc: any,
  resourceIdentifier: string,
  dateHourKey: string,
  minuteKey: string,
  item: any,
  className?: string
) => {
  if (!acc?.[resourceIdentifier]) {
    acc[resourceIdentifier] = {};
  }

  Object.keys(props).forEach((key: string) => {
    if (!acc[resourceIdentifier]?.[key]) {
      acc[resourceIdentifier][key] = {
        resourceIdentifier,
        type: key,
      };
    }
    if (!acc[resourceIdentifier][key]?.[dateHourKey]) {
      acc[resourceIdentifier][key][dateHourKey] = {};
    }
    acc[resourceIdentifier][key][dateHourKey][minuteKey] = {
      num: roundValue(item[key], 0),
      className,
    };
  });
};
