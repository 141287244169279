import styled from 'styled-components';

export const SessionSelectorWrapper = styled.div``;

export const SessionSelectorContainer = styled.div`
  display: flex;
`;

export const ResetButton = styled.button`
  all: unset;
  margin-left: 12px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 50px;
  color: #468388;
  box-sizing: border-box;
  box-shadow: none;
  text-transform: #fff;
  border: 1px solid #468388;
  border-radius: 8px;

  &:hover {
    background: #468388;
    color: white;
  }

  &.disabled {
    color: #7e8c9d !important;
  }
`;

export const SelectorButton = styled.button`
  all: unset;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  padding: 6px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
`;
