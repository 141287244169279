import { createSelector, createSlice } from '@reduxjs/toolkit';
import { Miscellaneous } from 'api/models/Miscellaneous.model';
import { Participant } from 'api/models/Participant.model';
import { GENERATING_UNIT } from 'components/admin/AddResources/constant';
import { MVP_TIMEZONES } from 'constants/general';
import { IZones } from 'interfaces/general';
import { isEmpty } from 'lodash';
import { AppStore } from 'redux/store';

const STORE_NAME = 'miscellaneous';

export const miscellaneousSlice = createSlice({
  name: STORE_NAME,
  initialState: {
    selectedParticipant: '',
    lockedTimezone: false,
    lastPageSeen: '',
    selectedTimezone: 'UTC',
    selectedUnit: GENERATING_UNIT,
    timezones: [...MVP_TIMEZONES],
    navigation: {
      sideBarCollapsed: false,
      expand: '',
    },
  } as Miscellaneous,
  reducers: {
    updateMiscellaneous: (state, action) => ({ ...state, ...action.payload }),
  },
});

const getSelectedTimezone = (state: any) =>
  state.miscellaneous.selectedTimezone;
const getTimezones = (state: any) => state.miscellaneous.timezones;

const selectedTimezone = (selectedTimezone: string, timezones: IZones[]) =>
  timezones.find((timezones) => timezones.zone === selectedTimezone)?.zone ||
  'UTC';

export const pickSelectedTimezone = createSelector(
  getSelectedTimezone,
  getTimezones,
  selectedTimezone
);

const getSelectedParticipant = (state: AppStore) =>
  state.miscellaneous.selectedParticipant;

export const getSelectedUnit = (state: AppStore) =>
  state.miscellaneous.selectedUnit;

const getParticipantsEntities = (state: any) => state.participant.entities;

const selectedParticipant = (
  selectedParticipantId: string,
  participants: { [entityId: string]: Participant }
) =>
  isEmpty(selectedParticipantId)
    ? Object.values(participants)[0]
    : participants[selectedParticipantId];

export const pickSelectedParticipant = createSelector(
  getSelectedParticipant,
  getParticipantsEntities,
  selectedParticipant
);

export const { updateMiscellaneous } = miscellaneousSlice.actions;

export default miscellaneousSlice.reducer;
