import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { Preferences } from 'api/models/Preferences.model';
import { UserPreferenceDto } from 'api/models/User.model';
import {
  INTERVAL_PEFERENCE,
  LAST_PAGE_SEEN_PREFERENCE,
  OPERATING_PLAN_PEFERENCE,
  SELECTED_MARKET_PARTICIPANT_PREFERENCE,
  TABLE_PEFERENCE,
  TIMEZONE_PREFERENCE,
  UNIT_TYPE_PEFERENCE,
} from 'constants/peferences';
import { isEmpty } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import { AppStore } from 'redux/store';

const STORE_NAME = 'preferences';

const initialState = {
  timezone: '',
  lastPageSeen: '',
  selectedMarketParticipant: '',
  tablePreference: [],
  intervalPreference: [],
  unitTypePreference: [],
  operatingPlanPreference: { planSelected: '', grouping: '' },
  status: IDLE,
};

export const fetchPreferences = createAsyncThunk(
  `${STORE_NAME}/fetchPreferences`,
  async () => {
    try {
      const response = await AutoTraderApi.getUserPreferences();
      if (isEmpty(response)) {
        return { ...initialState, status: FULFILLED };
      }
      return {
        timezone: response[TIMEZONE_PREFERENCE],
        lastPageSeen: response[LAST_PAGE_SEEN_PREFERENCE],
        selectedMarketParticipant:
          response[SELECTED_MARKET_PARTICIPANT_PREFERENCE],
        tablePreference: response[TABLE_PEFERENCE],
        intervalPreference: response[INTERVAL_PEFERENCE],
        unitTypePreference: response[UNIT_TYPE_PEFERENCE],
        operatingPlanPreference: response[OPERATING_PLAN_PEFERENCE],
        status: FULFILLED,
      };
    } catch (error) {}
  }
);

export const savePreferences = createAsyncThunk(
  `${STORE_NAME}/savePreferences`,
  async (preferencesDto: UserPreferenceDto) => {
    try {
      await AutoTraderApi.saveUserPreferences(preferencesDto);
      return {
        timezone: preferencesDto[TIMEZONE_PREFERENCE],
        lastPageSeen: preferencesDto[LAST_PAGE_SEEN_PREFERENCE],
        selectedMarketParticipant:
          preferencesDto[SELECTED_MARKET_PARTICIPANT_PREFERENCE],
        tablePreference: preferencesDto[TABLE_PEFERENCE],
        intervalPreference: preferencesDto[INTERVAL_PEFERENCE],
        unitTypePreference: preferencesDto[UNIT_TYPE_PEFERENCE],
        operatingPlanPreference: preferencesDto[OPERATING_PLAN_PEFERENCE],
        status: FULFILLED,
      };
    } catch (error) {}
  }
);

export const preferencesSlice = createSlice({
  name: STORE_NAME,
  initialState: {
    timezone: '',
    lastPageSeen: '',
    selectedMarketParticipant: '',
    tablePreference: [],
    intervalPreference: [],
    unitTypePreference: [],
    operatingPlanPreference: { planSelected: '', grouping: '' },
    status: IDLE,
  } as Preferences & { status: string },
  reducers: {
    updatePreferences: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreferences.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(savePreferences.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(fetchPreferences.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      })
      .addCase(savePreferences.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

export const getPreferences = (state: AppStore) =>
  state.preferences as Preferences & { status: string };

const transformPreferences = (preferences: Preferences) => {
  const {
    tablePreference,
    intervalPreference,
    unitTypePreference,
    operatingPlanPreference,
    lastPageSeen,
    selectedMarketParticipant,
    timezone,
  } = preferences;
  let preferencesDto = {} as UserPreferenceDto;

  preferencesDto[TABLE_PEFERENCE] = tablePreference;
  preferencesDto[INTERVAL_PEFERENCE] = intervalPreference;
  preferencesDto[UNIT_TYPE_PEFERENCE] = unitTypePreference;
  preferencesDto[OPERATING_PLAN_PEFERENCE] = operatingPlanPreference;
  preferencesDto[LAST_PAGE_SEEN_PREFERENCE] = lastPageSeen;
  preferencesDto[SELECTED_MARKET_PARTICIPANT_PREFERENCE] =
    selectedMarketParticipant;
  preferencesDto[TIMEZONE_PREFERENCE] = timezone;
  return preferencesDto;
};

export const toPreferenceDto = createSelector(
  getPreferences,
  transformPreferences
);
export const { updatePreferences } = preferencesSlice.actions;

export default preferencesSlice.reducer;
