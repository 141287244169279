import { RowClassParams } from 'ag-grid-community';
import { differenceInMinutes, parseISO, secondsToMinutes } from 'date-fns';

export const timeOnLineNoUnitsFormatter = (params: any) => {
  const secondsInStatus = params.value;
  if (secondsInStatus) {
    return `${secondsToMinutes(secondsInStatus)}`;
  } else {
    return '';
  }
};

export const timeOnLineFormatter = (params: any) => {
  const secondsInStatus = params.value;
  if (secondsInStatus) {
    return `${secondsToMinutes(secondsInStatus)} minutes`;
  } else {
    return '';
  }
};

export const rowStyler = ({ data }: RowClassParams) => {
  const threshold = 30;
  const { lastUpdateTime } = data;
  const diff = differenceInMinutes(new Date(), parseISO(lastUpdateTime));
  if (diff > threshold) {
    return { background: '#ff7979' };
  }
  return undefined;
};
