import React, { useEffect } from 'react';
import style from './UserGuidePage.module.scss';

import Logo from 'components/admin/Logo/Logo';
import { Event } from 'services/StructuredLogging/events';
import { useAppContext } from 'App/AppProvider';
import { Category } from 'services/StructuredLogging/categories';

function UserGuidePage({ guide }: { guide: string | undefined }) {
  const { logoWrapper, header, footer, copyright, main } = style;
  const { logEvent } = useAppContext();

  useEffect(() => {
    if (logEvent) {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDUSERGUIDE,
        category: Category.USER_GUIDE_PAGE,
      });
    }
  }, [logEvent]);

  return (
    <>
      <header className={header}>
        <div className={logoWrapper}>
          <Logo theme='dark' />
        </div>
      </header>
      <main className={main}>
        <iframe
          title='user-guide-iframe'
          width={'100%'}
          height={'100%'}
          src={`${process.env.PUBLIC_URL}${guide}`}
        ></iframe>
      </main>
      <footer className={footer}>
        <div className={copyright}>
          <span>© 2022 Power Cost Company</span>
        </div>
      </footer>
    </>
  );
}

export default UserGuidePage;
