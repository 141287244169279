import * as i18n from 'i18next';
import StringField from './StringField';
import NumberField from './NumberField';
import BooleanField from './BooleanField';
import DateField from './DateField';
import ArrayField from './ArrayField';
import {
  isString,
  isNumber,
  isBoolean,
  isDate,
  isArray,
  isAbsent,
} from './utils';

const formValidation = <T>(
  value: T,
  error: string | null = null,
  touched?: boolean
) => {
  return {
    value,
    error,
    touched,
    boolean: function boolean(errorMessage: string | null = null) {
      if (!isAbsent(this.value) && !isBoolean(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : 'This field must be a valid boolean';
      }
      return BooleanField<T>(this.value, this.error);
    },
    date: function date(errorMessage: string | null = null) {
      if (!isAbsent(this.value) && !isDate(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : 'This field must be a valid date';
      }
      return DateField<T>(this.value, this.error);
    },
    string: function string(errorMessage: string | null = null) {
      if (!isAbsent(this.value) && !isString(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_valid_string');
      }
      return StringField<T>(this.value, this.error);
    },
    array: function array(errorMessage: string | null = null) {
      if (!isAbsent(this.value) && !isArray(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_valid_array');
      }
      return ArrayField<T>(this.value, this.error);
    },
    number: function number(errorMessage: string | null = null) {
      if (!isAbsent(this.value) && !isNumber(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : 'This field must be a valid number';
      }
      return NumberField<T>(this.value, this.error);
    },
    required: function required(errorMessage: string | null = null) {
      if (this.error) return this;
      if (isAbsent(this.value)) {
        this.error = errorMessage ? errorMessage : 'This field is required';
      }
      return this;
    },
    custom: function custom(
      fn: Function,
      errorMessage: string | null,
      ...args: []
    ) {
      if (this.error) return this;
      if (!fn(this.value, ...args)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.field_no_valid');
      }
      return this;
    },
    // when: function when(condition, streamValidation1, streamValidation2 = () => null) {
    //   return condition ? streamValidation1(this) : streamValidation2(this);
    // },
    validate: function validate() {
      return this.error;
    },
  };
};

export default formValidation;
