/* eslint-disable */
import { useEffect, useState } from 'react';

const GroupRowInnerRenderer = (props: any) => {
  const { node, api } = props;
  const { key } = node;

  const [resource, setReource] = useState(key);

  const refreshUi = () => {
    setReource(key);
  };

  const dataChangedListener = () => refreshUi();

  useEffect(() => {
    api.addEventListener('cellValueChanged', dataChangedListener);
    api.addEventListener('filterChanged', dataChangedListener);

    return () => {
      api.removeEventListener('cellValueChanged', dataChangedListener);
      api.removeEventListener('filterChanged', dataChangedListener);
    };
  }, []);

  return (
    <div>
      <span className='groupTitle'>{resource}</span>
    </div>
  );
};

export default GroupRowInnerRenderer;
