import {
  SvgIconComponent,
  Wysiwyg,
  PolicyOutlined,
  VerifiedUserOutlined,
  NewReleasesOutlined,
} from '@mui/icons-material';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { useAppContext } from 'App/AppProvider';
import {
  SUPPORT_URL,
  SUPPORT_URL_AUTH_GUIDE,
  SUPPORT_URL_RELEASE_NOTES,
  SUPPORT_URL_USER_GUIDE,
  SWAGGER_URL,
} from 'components/admin/Sidebar/menu.config';
import SupportCard from 'components/admin/SupportCard/SupportCard';
import { useEffect, useState } from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { Route, Switch } from 'react-router-dom';
import { Category } from 'services/StructuredLogging/categories';
import { Event } from 'services/StructuredLogging/events';

export type SupportItemType = {
  Icon: SvgIconComponent;
  title: string;
  description: string;
  link: { name: string; path: string };
};

const themeMap = {
  light: 'public/assets/css/compact-light-theme.css',
  dark: 'public/assets/css/compact-dark-theme.css',
};

function SupportPage() {
  const { logEvent } = useAppContext();
  const [config, setConfig] = useState<any>();
  const [supportLink, setSupportLink] = useState<any[]>([]);

  const getConfig = async () => {
    const config = await AutoTraderApi.getAxiosConfig();
    setConfig(config);
  };

  useEffect(() => {
    const supportLink: SupportItemType[] = [
      {
        Icon: Wysiwyg,
        title: 'API documentation',
        description:
          'View the Live API Documentation for SEEMTrader in Swagger.',
        link: {
          name: 'Visit Page',
          path: `${config?.baseURL}${SWAGGER_URL}`,
        },
      },
      {
        Icon: PolicyOutlined,
        title: 'User Guide',
        description:
          'This application provides a SEEM Trading workflow, data entry screens, and reports to manage various aspects of SEEMTrader and to view results.',
        link: {
          name: 'Visit Page',
          path: `${SUPPORT_URL_USER_GUIDE}`,
        },
      },
      {
        Icon: VerifiedUserOutlined,
        title: 'Auth0 Guide',
        description:
          'Learn about onboarding members and users to Auth0. Note: This is a public link that can be used by those without SEEMTrader access, and contains no privileged information.',
        link: {
          name: 'Visit Page',
          path: `${SUPPORT_URL_AUTH_GUIDE}`,
        },
      },
      {
        Icon: NewReleasesOutlined,
        title: 'Release Notes',
        description: 'View a summary of recent changes to SEEMTrader!',
        link: {
          name: 'Visit Page',
          path: `${SUPPORT_URL_RELEASE_NOTES}`,
        },
      },
    ];
    setSupportLink(supportLink);
  }, [config?.baseURL]);

  useEffect(() => {
    getConfig();
  }, []);
  useEffect(() => {
    if (logEvent) {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDSUPPORTPAGE,
        category: Category.SUPPORT_PAGE,
      });
    }
  }, [logEvent]);
  return (
    <Switch>
      <Route exact path={`*${SUPPORT_URL}`}>
        <ThemeSwitcherProvider defaultTheme='light' themeMap={themeMap}>
          <SupportCard content={supportLink} mainTitle='Support' />
        </ThemeSwitcherProvider>
      </Route>
    </Switch>
  );
}

export default SupportPage;
