import {
  HOURS_4_FILTER,
  HOURS_12_FILTER,
  WEEKS_2_FILTER,
} from 'components/molecules/RangeFilterTab/constants';

import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';

import * as S from './Peformance.styles';

interface IPerformanceHeaderProps {
  selectedRangeFilterTab: any;
  handleRangeFilterChange: any;
}

const PerformanceHeader = ({
  selectedRangeFilterTab,
  handleRangeFilterChange,
}: IPerformanceHeaderProps): JSX.Element => {
  return (
    <S.Header>
      <RangeFilterTab
        tab={selectedRangeFilterTab.key}
        handleChange={handleRangeFilterChange}
        timeIntervals={[HOURS_4_FILTER, HOURS_12_FILTER, WEEKS_2_FILTER]}
      />
    </S.Header>
  );
};

export default PerformanceHeader;
