export interface IFilter {
  key: string;
  description: string;
  fullDescription: string;
  calculationUnit?: string;
  scale?: number;
}
export const ALL = {
  key: 'ALL',
  description: 'All',
  fullDescription: 'All',
};
export const HOUR_FILTER = {
  key: '1H',
  description: 'Hour',
  fullDescription: 'Last hour',
  calculationUnit: 'hour',
  scale: 1,
};
export const HOURS_4_FILTER = {
  key: '4H',
  description: '4 Hours',
  fullDescription: 'Last 4 hours',
  calculationUnit: 'hour',
  scale: 4,
};
export const HOURS_12_FILTER = {
  key: '12H',
  description: '12 Hours',
  fullDescription: 'Last 12 hours',
  calculationUnit: 'hour',
  scale: 12,
};
export const HOURS_24_FILTER = {
  key: '24H',
  description: '24 Hours',
  fullDescription: 'Last 24 hours',
  calculationUnit: 'hour',
  scale: 24,
};
export const WEEK_FILTER = {
  key: '1W',
  description: 'Week',
  fullDescription: '',
};
export const WEEKS_2_FILTER = {
  key: '2W',
  description: '2 Weeks',
  fullDescription: '',
};
export const DAYS_30_FILTER = {
  key: '30D',
  description: '30 Days',
  fullDescription: 'Last 30 Days',
  calculationUnit: 'day',
  scale: 30,
};
export const MONTH_FILTER = {
  key: '1M',
  description: 'Month',
  fullDescription: 'Last Month',
  calculationUnit: 'month',
  scale: 1,
};
export const MONTHS_3_FILTER = {
  key: '3M',
  description: '3 Months',
  fullDescription: 'Last 3 Months',
  calculationUnit: 'month',
  scale: 3,
};
export const MONTHS_12_FILTER = {
  key: '12M',
  description: 'Year',
  fullDescription: 'Last Year',
  calculationUnit: 'month',
  scale: 12,
};
export const CUSTOM = {
  key: 'CUSTOM',
  description: 'Custom Range',
};
export const PARTICIPANT = {
  key: 'PARTICIPANT',
  description: 'Participant',
};
export const RESOURCE = {
  key: 'RESOURCE',
  description: 'Resource',
};
export enum EResolution {
  NONE = 'NONE',
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export const TIME_INTERVALS = [
  ALL.key,
  HOUR_FILTER.key,
  HOURS_4_FILTER.key,
  HOURS_12_FILTER.key,
  HOURS_24_FILTER.key,
  DAYS_30_FILTER.key,
  WEEK_FILTER.key,
  WEEKS_2_FILTER.key,
  MONTH_FILTER.key,
  MONTHS_3_FILTER.key,
  MONTHS_12_FILTER.key,
];
