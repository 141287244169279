import { useEffect, useState } from 'react';

import {
  GENERATING_UNIT,
  LOAD,
  POWER_IMPORT,
  POWER_EXPORT,
} from 'components/admin/AddResources/constant';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { useAppContext } from 'App/AppProvider';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';
import usePreferences from './usePreferences';
import { IUnitTypePreference } from 'api/models/Preferences.model';

export interface ISelectedUnitTypeTabState {
  key: string;
  description: string;
}

const unitSelector = (unitKey: string) => {
  switch (unitKey) {
    case GENERATING_UNIT: {
      return {
        unit: {
          key: GENERATING_UNIT,
          description: 'Generating Unit',
        },
        event: {
          eventTime: new Date(),
          eventName: Event.VIEWEDGENERATINGUNITTAB,
          category: Category.RESOURCE_PAGE,
        },
      };
    }
    case LOAD: {
      return {
        unit: {
          key: LOAD,
          description: 'Load',
        },
        event: {
          eventTime: new Date(),
          eventName: Event.VIEWEDLOADTAB,
          category: Category.RESOURCE_PAGE,
        },
      };
    }

    case POWER_IMPORT: {
      return {
        unit: {
          key: POWER_IMPORT,
          description: 'Power Import',
        },
        event: {
          eventTime: new Date(),
          eventName: Event.VIEWEDPOWERIMPORTTAB,
          category: Category.RESOURCE_PAGE,
        },
      };
    }

    case POWER_EXPORT: {
      return {
        unit: {
          key: POWER_EXPORT,
          description: 'Power Export',
        },
        event: {
          eventTime: new Date(),
          eventName: Event.VIEWEDPOWEREXPORTTAB,
          category: Category.RESOURCE_PAGE,
        },
      };
    }

    default: {
      return {
        unit: {
          key: GENERATING_UNIT,
          description: 'Generating Unit',
        },
        event: {
          eventTime: new Date(),
          eventName: Event.VIEWEDGENERATINGUNITTAB,
          category: Category.RESOURCE_PAGE,
        },
      };
    }
  }
};

const useUnitsTab = () => {
  const { logEvent } = useAppContext();
  const { setPreference, unitTypePreference, lastPageSeenPreference } =
    usePreferences();
  const { selectedUnit } = useSelector(
    (store: AppStore) => store.miscellaneous
  );
  const [selectedUnitTypeTab, setSelectedUnitTypeTab] =
    useState<ISelectedUnitTypeTabState>(unitSelector(selectedUnit).unit);

  useEffect(() => {
    const unitPreference = unitTypePreference?.find(
      (unit) => unit.page === lastPageSeenPreference
    );
    if (
      unitPreference &&
      lastPageSeenPreference &&
      unitPreference.unitType !== selectedUnitTypeTab.key
    ) {
      const tempUnitPreference: IUnitTypePreference = {
        unitType: selectedUnitTypeTab.key,
        page: lastPageSeenPreference,
      };
      const filteredUnit = unitTypePreference?.filter(
        (unit) => unit.page !== lastPageSeenPreference
      );
      filteredUnit?.push(tempUnitPreference);
      setPreference({ unitTypePreference: filteredUnit });
    } else if (!unitPreference && lastPageSeenPreference) {
      const notDefinedUnitPreference: IUnitTypePreference = {
        unitType: selectedUnitTypeTab.key,
        page: lastPageSeenPreference,
      };
      setPreference({ unitTypePreference: [notDefinedUnitPreference] });
    }
    // eslint-disable-next-line
  }, [selectedUnitTypeTab.key]);

  useEffect(() => {
    setSelectedUnitTypeTab(unitSelector(selectedUnit).unit);
  }, [selectedUnit]);

  const handleOnChangeUnitTypeTab = (_: any, newValue: string) => {
    const { unit, event } = unitSelector(newValue);
    setSelectedUnitTypeTab(unit);
    if (logEvent) {
      logEvent(event);
    }
  };

  return { selectedUnitTypeTab, handleOnChangeUnitTypeTab };
};

export default useUnitsTab;
