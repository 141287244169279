import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from '@mui/material';
import styles from './TenantPage.module.scss';
import React, { useEffect } from 'react';
import { Add } from '@mui/icons-material';
import {
  AutoTraderApi,
  AutoTraderApiCsm,
  IAutoTraderUsersApi,
} from 'api/AutoTraderAPI';
import useTable from 'components/admin/UserTable/useTable';
import { UserDto } from 'api/models/User.model';
import { useHistory, useLocation } from 'react-router-dom';
import { USERS_ADD_URL } from 'components/admin/Sidebar/menu.config';
import Switch from 'components/molecules/Switch/Switch';
import TableLoader from 'components/admin/UserTable/TableLoader';
import { useSnackbar, VariantType } from 'notistack';
import IError from '../../../interfaces/IError';
import { AxiosError } from 'axios';
import { useAppContext } from 'App/AppProvider';
import { PAGINATION } from 'constants/general';

const UsersPage: React.FC<{}> = () => {
  const { isCSMUser } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const {
    tenantPage,
    tableToolbar,
    tenantWrapper,
    addTenantBtn,
    tablePagination,
    tableRowHead,
    tableCell,
    tableRowBody,
    nameColumn,
    name,
    userName,
  } = styles;
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    records,
    paginatedData,
    currentPage,
    recordsPerPage,
    setRecords,
    setRecordsPerPage,
    setCurrentPage,
  } = useTable<UserDto, 'id'>('id');

  const API: IAutoTraderUsersApi = isCSMUser ? AutoTraderApiCsm : AutoTraderApi;

  const pushNotification = (variant: VariantType, message: string) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleRoleStatus = async (
    checked: boolean,
    tenantId: string,
    roleToUpdate: string,
    currentRoles: string[]
  ) => {
    let userToUpdate = paginatedData.filter(({ id }) => id === tenantId).at(0);
    const newRoles = updateRoleHandler(currentRoles, roleToUpdate, checked);
    try {
      if (userToUpdate && tenantId) {
        const updated = { ...userToUpdate, roles: newRoles };
        await API.patchUser(updated);
        pushNotification('info', `User successfully updated`);
        getUsers();
      }
    } catch (error) {
      const { response } = error as AxiosError;
      const formatedError = response?.data as IError;
      if (formatedError.apiVersion) {
        pushNotification('error', `${formatedError.error.message}`);
      } else {
        pushNotification('error', `Error has occured updating user`);
      }
      setLoading(false);
    }
  };

  const updateRoleHandler = (
    currentRoles: string[],
    roleToUpdate: string,
    operation: boolean
  ) =>
    operation
      ? [...currentRoles, roleToUpdate]
      : currentRoles.filter((role) => role !== roleToUpdate);

  const handleChangeStatus = async (isChecked: boolean, tenantId?: string) => {
    const userToUpdate = paginatedData
      .filter(({ id }) => id === tenantId)
      .at(0);
    try {
      if (userToUpdate && tenantId) {
        const updated = { ...userToUpdate, enabled: isChecked };
        await API.patchUser(updated);
        pushNotification(
          'info',
          `User successfully ${isChecked ? 'Enabled' : 'Disabled'}`
        );
        getUsers();
      }
    } catch (error) {
      const { response } = error as AxiosError;
      const formatedError = response?.data as IError;
      if (formatedError.apiVersion) {
        pushNotification('error', `${formatedError.error.message}`);
      } else {
        pushNotification('error', `Error has occured updating user`);
      }
      setLoading(false);
    }
  };

  const getUsers = async () => {
    try {
      const users = await API.getUsers();
      setRecords(users);
      setLoading(false);
    } catch (error: any) {
      pushNotification('error', `Error has occured retrieving user list`);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={tenantPage}>
      <div className={tenantWrapper}>
        <TableContainer component={Paper}>
          <Toolbar className={tableToolbar}>
            <div style={{ flex: '1 1 50%' }}>
              <h3>Users</h3>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                className={`${addTenantBtn}`}
                variant='contained'
                size='medium'
                startIcon={<Add fontSize='small' />}
                onClick={() => {
                  history.push(`${pathname}${USERS_ADD_URL}`);
                }}
              >
                Add New
              </Button>
            </div>
          </Toolbar>
          <Table
            sx={{ minWidth: 650, tableLayout: 'fixed' }}
            aria-label='simple table'
          >
            <TableHead className={tableRowHead}>
              <TableRow>
                <TableCell className={tableCell}>NAME</TableCell>
                <TableCell className={tableCell}>STATUS</TableCell>

                {!isCSMUser && (
                  <>
                    <TableCell className={tableCell}>VIEWER</TableCell>
                    <TableCell className={tableCell}>ANALYST</TableCell>
                    <TableCell className={tableCell}>TRADER</TableCell>
                    <TableCell className={tableCell}>ADMIN</TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <TableLoader skeletonCols={2} />}
              {!loading &&
                paginatedData.map(
                  ({ id, username, givenName, familyName, enabled, roles }) => {
                    return (
                      <TableRow className={tableRowBody} key={id}>
                        <TableCell>
                          <div className={nameColumn}>
                            <span className={name}>
                              {givenName} {familyName}
                            </span>
                            <span className={userName}>{username}</span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Switch
                            meta={id}
                            onChangeHandler={handleChangeStatus}
                            status={enabled}
                          />
                        </TableCell>
                        {!isCSMUser && (
                          <>
                            <TableCell>
                              <Checkbox
                                onChange={(_, checked) =>
                                  handleRoleStatus(checked, id, 'VIEWER', roles)
                                }
                                checked={roles.includes('VIEWER')}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                onChange={(_, checked) =>
                                  handleRoleStatus(
                                    checked,
                                    id,
                                    'ANALYST',
                                    roles
                                  )
                                }
                                checked={roles.includes('ANALYST')}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                onChange={(_, checked) =>
                                  handleRoleStatus(checked, id, 'TRADER', roles)
                                }
                                checked={roles.includes('TRADER')}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                onChange={(_, checked) =>
                                  handleRoleStatus(checked, id, 'ADMIN', roles)
                                }
                                checked={roles.includes('ADMIN')}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                              />
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
          </Table>
          <TablePagination
            className={tablePagination}
            component='div'
            count={records.length}
            rowsPerPage={recordsPerPage}
            page={currentPage - 1}
            onPageChange={(event: unknown, newPage: number) =>
              setCurrentPage(newPage + 1)
            }
            onRowsPerPageChange={({ target }) => {
              setRecordsPerPage(parseInt(target.value));
            }}
            rowsPerPageOptions={PAGINATION}
          />
        </TableContainer>
      </div>
    </div>
  );
};

export default UsersPage;
