import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AutoTraderApi, IBidsOffersRequest } from 'api/AutoTraderAPI';
import { TradeStack } from 'api/models/TradeStack.model';
import { isEmpty } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';

const STORE_NAME = 'bidsoffers';

export interface TradeStackState {
  tradeStack: TradeStack[];
  tradeStackStatus: string;
}

const initialState: TradeStackState = {
  tradeStack: [],
  tradeStackStatus: IDLE,
};

export const fetchTradeStack = createAsyncThunk(
  `${STORE_NAME}/fetchTradeStack`,
  async (requestParams: IBidsOffersRequest) => {
    try {
      const response = await AutoTraderApi.getBidsOffersNew(requestParams);
      if (response && isEmpty(response?.data)) {
        return { ...initialState, status: FULFILLED };
      } else if (response) {
        return { tradeStack: response.data, tradeStackStatus: FULFILLED };
      }
    } catch (error) {}
  }
);

export const bidsOffersSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTradeStack.pending, (state) => {
        state.tradeStackStatus = LOADING;
      })
      .addCase(fetchTradeStack.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

export default bidsOffersSlice.reducer;
