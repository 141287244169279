import { Route, Switch } from 'react-router-dom';

import MatchedTradesPage from 'components/admin/MatchedTrades/MatchedTradesPage';
import NotFound from 'components/admin/NotFound/Index';
import PageInfoCard from 'components/admin/PageInfoCard/PageInfoCard';
import PerformancePage from 'components/admin/Performance/Performance';
import {
  REPORTS_AUDIT,
  REPORTS_LOSS,
  REPORTS_MARKETRESULTS_ENDPOINT,
  REPORTS_MATCHED_TRADES,
  REPORTS_PANDL_ENDPOINT,
  REPORTS_PERFORMANCE_ENDPOINT,
  REPORTS_TAGGING_ENDPOINT,
} from 'components/admin/Sidebar/menu.config';
import { SessionProvider } from '../Trade/context';
import AuditPage from 'components/admin/Audit/AuditPage';
import LossPage from 'components/admin/Loss/LossPage';

function index() {
  return (
    <Switch>
      <Route exact path={`*${REPORTS_MARKETRESULTS_ENDPOINT}`}>
        <PageInfoCard message='Market Results section' />
      </Route>
      <Route exact path={`*${REPORTS_PERFORMANCE_ENDPOINT}`}>
        <SessionProvider>
          <PerformancePage />
        </SessionProvider>
      </Route>
      <Route exact path={`*${REPORTS_PANDL_ENDPOINT}`}>
        <PageInfoCard message='Profit and laws' />
      </Route>
      <Route exact path={`*${REPORTS_TAGGING_ENDPOINT}`}>
        <PageInfoCard message='Process with the accounting area' />
      </Route>
      <Route exact path={`*${REPORTS_MATCHED_TRADES}`}>
        <SessionProvider>
          <MatchedTradesPage />
        </SessionProvider>
      </Route>
      <Route exact path={`*${REPORTS_AUDIT}`}>
        <AuditPage />
      </Route>
      <Route exact path={`*${REPORTS_LOSS}`}>
        <LossPage />
      </Route>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}

export default index;
