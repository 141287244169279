import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { AuditLog } from 'api/models/AuditLog.model';
import { FULFILLED, IDLE } from 'redux/constants';
import { AppStore } from 'redux/store';

const STORE_NAME = 'auditlogs';

const auditlogAdapter = createEntityAdapter<AuditLog>({
  selectId: (log) => log.eventTime,
});

const initialState = auditlogAdapter.getInitialState({
  status: IDLE,
});

export const auditlogSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    auditlogUpsert: (state, action) => {
      auditlogAdapter.upsertMany(state, action.payload);
      state.status = FULFILLED;
    },
  },
  extraReducers: (builder) => {},
});

export const selectAuditLogs = (state: AppStore) => [
  ...Object.values(state.auditlogs.entities),
];

export const { auditlogUpsert } = auditlogSlice.actions;

export default auditlogSlice.reducer;
