import {
  setTimeZonePreserveLocal,
  TTimeZone,
  ZonedDateTime,
} from '@pci/pci-ui-library';
import { zeroPad } from './general';

export const getUTCDateTimeForTimeZone = (
  dateTime: ZonedDateTime,
  timeZone: TTimeZone
): ZonedDateTime =>
  dateTime.withTimeZone(timeZone, true).withTimeZone('UTC', false);

export const roundToNearestMinute = (
  zonedDateTime: ZonedDateTime,
  minute: number
): ZonedDateTime => {
  const dateTime: Date = zonedDateTime.asDate();
  const timeZone: TTimeZone = zonedDateTime.timeZone();
  const minuteInMilliseconds: number = minute * 60 * 1000;

  return ZonedDateTime.fromDate(
    new Date(
      Math.round(dateTime.valueOf() / minuteInMilliseconds) *
        minuteInMilliseconds
    ),
    timeZone
  );
};

export const secondsToTime = (secs: number) => {
  const hours = Math.floor(secs / (60 * 60));
  const perMinute = secs % (60 * 60);
  const minutes = Math.floor(perMinute / 60);
  const perSeconds = perMinute % 60;
  const seconds = Math.ceil(perSeconds);

  const obj = {
    h: zeroPad(hours),
    m: zeroPad(minutes),
    s: zeroPad(seconds),
  };
  return obj;
};

export const keepLocalTimezone = (datetime: string, timezone: TTimeZone) =>
  setTimeZonePreserveLocal(
    ZonedDateTime.parseIso(datetime, timezone),
    ZonedDateTime.defaultTimeZone()
  ).toIsoString();

export const switchLocalTimezone = (datetime: string, timezone: TTimeZone) =>
  setTimeZonePreserveLocal(
    ZonedDateTime.parseIso(datetime, ZonedDateTime.defaultTimeZone()),
    timezone
  ).toIsoString();
