import React, { Context } from 'react';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { FIFTEEN_MINUTES_IN_MILLISECONDS } from 'constants/dateTime';

export const createContext = <T>() => {
  const context: Context<T | undefined> = React.createContext<T | undefined>(
    undefined
  );
  const useContext = () => {
    const ctx = React.useContext(context);
    if (!ctx)
      throw new Error('useContext must be inside a Provider with a value');
    return ctx;
  };
  return [useContext, context.Provider] as const;
};

export const getNextTradeInterval = (
  timeZone: TTimeZone,
  currentInterval: Date
): ZonedDateTime =>
  ZonedDateTime.fromDate(
    new Date(
      Math.ceil(currentInterval.getTime() / FIFTEEN_MINUTES_IN_MILLISECONDS) *
        FIFTEEN_MINUTES_IN_MILLISECONDS +
        FIFTEEN_MINUTES_IN_MILLISECONDS
    ),
    timeZone
  );
