import { BidStrategy, OfferStrategy } from 'api/models/Participant.model';
import {
  IBidOfferData,
  ITransformedMarketBidOfferData,
  TBidOfferLineChartData,
} from 'components/market/organisms/MarketTrader/TraderChart/types';
import { IBidsOffers, IBidsOffersFiltered } from 'interfaces/bidOffer';
import { isEmpty, isNumber, omitBy, sortBy } from 'lodash';

import {
  TTimeZone,
  TZonedDateTimeRange,
  ZonedDateTime,
} from '@pci/pci-ui-library';
import {
  IGeneratingUnitOperatingPlan,
  IOperatingPlansDefaultPowerResource,
} from 'interfaces/resource';
import { OperatingPlan } from 'redux/states/operatingplans.state';
import { IIndexable } from 'interfaces/general';
import { DATE_TIME_FORMAT } from 'constants/dateTime';
import {
  IDataPerTimeOffset,
  IFifteenMinutePositions,
} from 'interfaces/fifteenMinutePosition';

export const bidsOffersColumnDef = {
  bids: {
    headerName: 'Sink',
    field: 'location',
  },
  offers: {
    headerName: 'Source',
    field: 'location',
  },
};

const marketBidOfferSortDescending = (
  a: IBidsOffers,
  b: IBidsOffers
): number => {
  if (a.planCost === null && b.planCost === null) {
    return 0;
  } else if (a.planCost === null && b.planCost !== null) {
    return 1;
  } else if (a.planCost !== null && b.planCost === null) {
    return -1;
  }

  return b.planCost! - a.planCost!;
};

const marketBidOfferSortAscending = (
  a: IBidsOffers,
  b: IBidsOffers
): number => {
  if (a.planCost === null && b.planCost === null) {
    return 0;
  } else if (a.planCost === null && b.planCost !== null) {
    return -1;
  } else if (a.planCost !== null && b.planCost === null) {
    return 1;
  }

  return a.planCost! - b.planCost!;
};

const marketTradeBidOfferSortDescending = (
  a: IBidsOffers,
  b: IBidsOffers
): number => {
  if (a.tradePrice === null && b.tradePrice === null) {
    return 0;
  } else if (a.tradePrice === null && b.tradePrice !== null) {
    return 1;
  } else if (a.tradePrice !== null && b.tradePrice === null) {
    return -1;
  }

  return b.tradePrice! - a.tradePrice!;
};

const marketTradeBidOfferSortAscending = (
  a: IBidsOffers,
  b: IBidsOffers
): number => {
  if (a.tradePrice === null && b.tradePrice === null) {
    return 0;
  } else if (a.tradePrice === null && b.tradePrice !== null) {
    return -1;
  } else if (a.tradePrice !== null && b.tradePrice === null) {
    return 1;
  }

  return a.tradePrice! - b.tradePrice!;
};

const addMarketBidOffers = (
  isTrade: boolean,
  isBid: boolean,
  marketBidOffers: IBidsOffers[] | [],
  chartData: TBidOfferLineChartData,
  strategy?: {
    bidQuantityWithheldFromFront: number;
    offerQuantityWithheldFromFront: number;
  }
) => {
  if (!marketBidOffers) return;
  const sortedMarketBidOffers: IBidsOffers[] = [...marketBidOffers].sort(
    isBid
      ? isTrade
        ? marketTradeBidOfferSortDescending
        : marketBidOfferSortDescending
      : isTrade
      ? marketTradeBidOfferSortAscending
      : marketBidOfferSortAscending
  );

  let cumulativeMwValue = 0;
  let cumulativeBidTradeQuantityValue =
    (strategy?.bidQuantityWithheldFromFront as number) ?? 0;
  let cumulativeOfferTradeQuantityValue =
    (strategy?.offerQuantityWithheldFromFront as number) ?? 0;

  sortedMarketBidOffers.forEach((marketBidOffer: IBidsOffers) => {
    const { planQuantity, planCost, tradePrice, tradeQuantity } =
      marketBidOffer;

    if (
      !isTrade &&
      planQuantity !== null &&
      planQuantity !== 0 &&
      planCost !== null
    ) {
      if (isBid) {
        const bidData: IBidOfferData = {
          value: -cumulativeMwValue,
          bidValue: -cumulativeMwValue,
          bidPrice: planCost,
          initialBidOfferPriceInDollars: planCost,
        };
        chartData.push(bidData);

        cumulativeMwValue += planQuantity;

        const nextBidData: IBidOfferData = {
          value: -cumulativeMwValue,
          bidValue: -cumulativeMwValue,
          bidPrice: planCost,
          initialBidOfferPriceInDollars: planCost,
        };
        chartData.push(nextBidData);
      } else {
        const offerData: IBidOfferData = {
          value: cumulativeMwValue,
          offerValue: cumulativeMwValue,
          offerPrice: planCost,
          initialBidOfferPriceInDollars: planCost,
        };
        chartData.push(offerData);

        cumulativeMwValue += planQuantity;

        const nextOfferData: IBidOfferData = {
          value: cumulativeMwValue,
          offerValue: cumulativeMwValue,
          offerPrice: planCost,
          initialBidOfferPriceInDollars: planCost,
        };
        chartData.push(nextOfferData);
      }
    }

    if (
      isTrade &&
      tradeQuantity !== null &&
      tradeQuantity !== 0 &&
      tradePrice !== null
    ) {
      if (isBid) {
        const tradeBidData: IBidOfferData = {
          value: 0,
          bidTradeValue: -cumulativeBidTradeQuantityValue,
          bidTradePrice: tradePrice,
          editedBidOfferPriceInDollars: tradePrice,
        };
        chartData.push(tradeBidData);

        cumulativeBidTradeQuantityValue += tradeQuantity;

        const nextTradeBidData: IBidOfferData = {
          value: 0,
          bidTradeValue: -cumulativeBidTradeQuantityValue,
          bidTradePrice: tradePrice,
          editedBidOfferPriceInDollars: tradePrice,
        };
        chartData.push(nextTradeBidData);
      } else {
        const tradeOfferData: IBidOfferData = {
          value: 0,
          offerTradeValue: cumulativeOfferTradeQuantityValue,
          offerTradePrice: tradePrice,
          editedBidOfferPriceInDollars: tradePrice,
        };
        chartData.push(tradeOfferData);

        cumulativeOfferTradeQuantityValue += tradeQuantity;

        const nextTradeOfferData: IBidOfferData = {
          value: 0,
          offerTradeValue: cumulativeOfferTradeQuantityValue,
          offerTradePrice: tradePrice,
          editedBidOfferPriceInDollars: tradePrice,
        };
        chartData.push(nextTradeOfferData);
      }
    }
  });
};

export const transformMarketBidOfferData = (
  initialMarketBidOfferData?: IBidsOffersFiltered,
  bidStrategy?: BidStrategy,
  offerStrategy?: OfferStrategy
): ITransformedMarketBidOfferData => {
  const data: ITransformedMarketBidOfferData = {
    chartConfig: {
      adjustedMaxValue: 1,
      adjustedMinValue: 0,
      maxPriceInDollars: 1,
      minPriceInDollars: 0,
    },
    chartData: [],
  };

  if (initialMarketBidOfferData !== undefined) {
    const { bids, offers } = initialMarketBidOfferData;

    data.balance = 0;

    const strategyFront = {
      bidQuantityWithheldFromFront:
        bidStrategy?.quantityWithheldFromFront as number,
      offerQuantityWithheldFromFront:
        offerStrategy?.quantityWithheldFromFront as number,
    };

    addMarketBidOffers(false, true, bids, data.chartData, strategyFront);
    addMarketBidOffers(false, false, offers, data.chartData, strategyFront);

    addMarketBidOffers(true, true, bids, data.chartData, strategyFront);
    addMarketBidOffers(true, false, offers, data.chartData, strategyFront);

    let maxPriceInDollars: number = -Number.MAX_SAFE_INTEGER;
    let minPriceInDollars: number = Number.MAX_SAFE_INTEGER;
    let maxValue: number = -Number.MAX_SAFE_INTEGER;
    let minValue: number = Number.MAX_SAFE_INTEGER;

    data.chartData.forEach((bidOfferData: IBidOfferData) => {
      const {
        initialBidOfferPriceInDollars,
        editedBidOfferPriceInDollars,
        value,
        bidTradeValue,
        offerTradeValue,
      } = bidOfferData;

      if (initialBidOfferPriceInDollars !== undefined) {
        if (initialBidOfferPriceInDollars > maxPriceInDollars) {
          maxPriceInDollars = initialBidOfferPriceInDollars;
        }

        if (initialBidOfferPriceInDollars < minPriceInDollars) {
          minPriceInDollars = initialBidOfferPriceInDollars;
        }
      }

      if (editedBidOfferPriceInDollars !== undefined) {
        if (editedBidOfferPriceInDollars > maxPriceInDollars) {
          maxPriceInDollars = editedBidOfferPriceInDollars;
        }

        if (editedBidOfferPriceInDollars < minPriceInDollars) {
          minPriceInDollars = editedBidOfferPriceInDollars;
        }

        if (data.chartConfig.offerLimit === undefined && offerTradeValue) {
          data.chartConfig.offerLimit =
            value > offerTradeValue ? value : offerTradeValue;
        }

        if (data.chartConfig.bidLimit === undefined && bidTradeValue) {
          data.chartConfig.bidLimit =
            value > bidTradeValue ? value : bidTradeValue;
        }
      }

      if (value > maxValue) {
        maxValue = value;
      }

      if (value < minValue) {
        minValue = value;
      }
    });

    const maxValueOfOfferLimit = Math.max(
      ...data.chartData.map((o) => o.value),
      0
    );

    const maxValueOfOfferLimit2 = Math.max(
      ...data.chartData.map((o) => (o.offerTradeValue ? o.offerTradeValue : 0)),
      0
    );

    const minValueOfOfferLimit = Math.min(
      ...data.chartData.map((o) => o.value),
      0
    );
    const minValueOfOfferLimit2 = Math.min(
      ...data.chartData.map((o) => (o.bidTradeValue ? o.bidTradeValue : 0)),
      0
    );

    data.chartConfig.offerLimit = maxValueOfOfferLimit;
    data.chartConfig.bidLimit = minValueOfOfferLimit;

    data.chartConfig.tradeOfferLimit = maxValueOfOfferLimit2;
    data.chartConfig.tradeBidLimit = minValueOfOfferLimit2;

    const valueStep: number = Math.floor((maxValue - minValue) / 20);
    data.chartConfig.adjustedMaxValue = maxValue + 2 * valueStep;
    data.chartConfig.adjustedMinValue = minValue - 2 * valueStep;

    if (bidStrategy !== undefined && offerStrategy !== undefined) {
      const bidQuantityFront = Number(bidStrategy.quantityWithheldFromFront);
      const offerQuantityFront = Number(
        offerStrategy.quantityWithheldFromFront
      );

      // adjustment for reserved values
      const bidQuantityBack = Number(bidStrategy.quantityWithheldFromBack);
      const offerQuantityBack = Number(offerStrategy.quantityWithheldFromBack);

      data.chartConfig.bidQuantityFront = bidQuantityFront;
      data.chartConfig.bidQuantityBack = bidQuantityBack;
      data.chartConfig.offerQuantityFront = offerQuantityFront;
      data.chartConfig.offerQuantityBack = offerQuantityBack;
    }

    const priceInDollarsStep: number = Math.floor(
      (maxPriceInDollars - minPriceInDollars) / 10
    );

    data.chartConfig.maxPriceInDollars = maxPriceInDollars + priceInDollarsStep;
    data.chartConfig.minPriceInDollars = minPriceInDollars - priceInDollarsStep;
  }

  return data;
};

export const RoundDateTime = (intervalMilliseconds: number, datetime: Date) => {
  datetime = datetime || new Date();
  var modTicks = datetime.getTime() % intervalMilliseconds;
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks;
  var shouldRoundUp = modTicks > intervalMilliseconds / 2;
  delta += shouldRoundUp ? intervalMilliseconds : 0;
  return new Date(delta);
};

//Net Load MW = Load + Power_Exports - Power_Imports
const netLoadMwCalculator = (time: string, formulated: OperatingPlan) => {
  const loadEnergyValue = !isEmpty(formulated['loadOperatingPlans'])
    ? Object.values(formulated['loadOperatingPlans'])
        ?.filter(
          (resource: IOperatingPlansDefaultPowerResource) =>
            resource.startAt === time
        )
        .reduce(
          (previous: number, current: any) => previous + current.energy,
          0
        )
    : 0;
  const powerImportEnergyValue = !isEmpty(
    formulated['powerImportOperatingPlans']
  )
    ? Object.values(formulated['powerImportOperatingPlans'])
        .filter(
          (resource: IOperatingPlansDefaultPowerResource) =>
            resource.startAt === time
        )
        .reduce(
          (previous: number, current: any) => previous + current.energy,
          0
        )
    : 0;
  const powerExportEnergyValue = !isEmpty(
    formulated['powerExportOperatingPlans']
  )
    ? Object.values(formulated['powerExportOperatingPlans'])
        .filter(
          (resource: IOperatingPlansDefaultPowerResource) =>
            resource.startAt === time
        )
        .reduce(
          (previous: number, current: any) => previous + current.energy,
          0
        )
    : 0;
  return loadEnergyValue + powerExportEnergyValue - powerImportEnergyValue;
};

export const fifteenMinuteTransformation = (
  operatingPlan: OperatingPlan,
  timeZone: TTimeZone,
  currentDateTimeRange: TZonedDateTimeRange,
  unit: string
) => {
  const selectedFifteenMinutePosition: IFifteenMinutePositions = {
    dataGroups: [],
    marketParticipant: null,
    dataSetKeys: [],
    dataPerTimeOffset: [],
    fields: [],
  };
  const generatingUnitOperatingPlans =
    operatingPlan[unit as keyof OperatingPlan];
  if (generatingUnitOperatingPlans) {
    const fields = new Set();
    const sortedM15data = sortBy(generatingUnitOperatingPlans, [
      'resourceIdentifier',
      'startAt',
    ]);
    const sortedM15Array = Object.values(sortedM15data) as any[];
    const dataPerTimeOffset = sortedM15Array.reduce(
      (acc: IIndexable, item: IGeneratingUnitOperatingPlan) => {
        const zonedStartAt = ZonedDateTime.parseIso(item?.startAt, timeZone);
        const [zonedInitialDate, zonedEndDate] =
          currentDateTimeRange as ZonedDateTime[];
        const isSameOrBefore = zonedStartAt.isSameOrBefore(zonedEndDate);
        const isSameOrAfter = zonedStartAt.isSameOrAfter(zonedInitialDate);

        if (isSameOrBefore && isSameOrAfter) {
          const { energy, resourceIdentifier } = item;
          const time: string = zonedStartAt.format(DATE_TIME_FORMAT);

          if (!acc?.[time] && isNumber(energy)) {
            acc[time] = {
              [resourceIdentifier]: energy,
              Time: time,
            };
          }
          if (acc[time]) {
            acc[time].load = netLoadMwCalculator(item.startAt, operatingPlan);
            acc[time][resourceIdentifier] = energy;
            if (energy > 0) fields.add(resourceIdentifier);
          }
        }

        return acc;
      },
      {}
    );

    const units = Object.values(dataPerTimeOffset).map((element) => {
      const { Time, load, ...rest } = element as any;
      return omitBy(rest, (v) => v === 0);
    }) as any;
    const unitSum = units.reduce((preVal: any, current: any) => {
      Object.keys(current).forEach((unitKey) => {
        preVal[unitKey] = current[unitKey] + (preVal[unitKey] || 0);
      });
      return preVal;
    }, {});

    const sortedData = Object.fromEntries(
      Object.entries(unitSum as { [key: string]: number }).sort(
        ([, a], [, b]) => b - a
      )
    );
    const labels = Object.entries(sortedData as { [key: string]: number }).map(
      ([a]) => a
    );
    selectedFifteenMinutePosition.dataPerTimeOffset = Object.values(
      dataPerTimeOffset
    ) as IDataPerTimeOffset[];
    selectedFifteenMinutePosition.fields = Array.from(labels) as string[];
  }

  return selectedFifteenMinutePosition;
};
