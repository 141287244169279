import { SupportItemType } from 'pages/admin/Support';
import styles from './SupportCard.module.scss';
import SupportCardItem from './SupportItem/SupportCardItem';

type Props = {
  mainTitle?: string;
  content: SupportItemType[];
};

function SupportCard({ mainTitle, content }: Props) {
  const { supportCard, supportCardTitle, supportCardItemContainer } = styles;

  return (
    <div className={supportCard}>
      <span className={supportCardTitle} data-testid='title'>
        {mainTitle}
      </span>
      {content.map((supportItem, index) => (
        <div className={supportCardItemContainer} key={index}>
          <SupportCardItem item={supportItem} />
        </div>
      ))}
    </div>
  );
}

export default SupportCard;
