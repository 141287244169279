/**
 * BASE URLS
 */
export const BASE_URL = '';
// export const BASE_AUTH_URL = process.env.AUTH_URL;

/**
 * ENDPOINTS
 */

export const AUTH = '/realms/pci/protocol/openid-connect/token';

export const REALMS = '/realms';
export const USER = '/users';
export const RESOURCE_LIST = '/settings/resources';
export const RESOURCE_CREATE = '/settings/resources';
export const OPERATING_PLAN_M15 = '/operating-plan-m15';
export const EFFECTIVE_OPERATING_PLAN_M15 = '/effective-operating-plan-m15';
export const OPTIMIZED_OPERATING_PLAN_M15 = '/optimized-operating-plan-m15';
export const OPERATING_PLAN_OVERRIDES = '/operating-plan/overrides';
export const FORMULATED_OPERATING_PLAN_M15 = '/formulated-operating-plan-m15';

export const TENANTS_LIST = '/tenants';
export const TENANTS_CREATE = '/tenants';

export const LOSS_REPORT = '/loss-reports/participants';

export const BIDS_OFFERS = '/operating-plan-m15/bids-offers';

export const TRADE_STACK = '/operating-plan-m15/trade-stacks';

export const AUTOTRADER_SSE = '/v1/events';

export const AUDIT_SSE = '/autotrader-audit/v1/logs';

export const MATCHED_TRADES = '/operating-plan-m15/matched-trades';
