export const ANCILLARY_KEYS = [
  'spin',
  'spindn',
  'regup',
  'regdn',
  'balup',
  'baldn',
];

export const ENERGY_ANCILLARY_KEYS = [...ANCILLARY_KEYS, 'energy'];

export const CELL_MIN_WIDTH = 75;

export const DATA_SOURCE = {
  Formulated: 'formulated',
  Effective: 'effective',
  Optimized: 'optimized',
  Original: 'original',
};
