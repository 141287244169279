import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TTimeZone } from '@pci/pci-ui-library';
import { IZones } from 'interfaces/general';
import React from 'react';
import styles from './TimeZoneDropdown.module.scss';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';

interface Props {
  timeZones: IZones[] | [] | undefined;
  handleSelector:
    | ((
        event: SelectChangeEvent<TTimeZone | ''>,
        child: React.ReactNode
      ) => void)
    | undefined;
  selectedTimeZone: TTimeZone | '';
}
const { container, timeZoneSelector } = styles;
const TimeZoneDropdown = ({
  timeZones,
  handleSelector,
  selectedTimeZone,
}: Props) => {
  const miscellaneousState = useSelector(
    (store: AppStore) => store.miscellaneous
  );
  const { lockedTimezone } = miscellaneousState;
  const executeSelector = (event: any, child: React.ReactNode) => {
    if (handleSelector) handleSelector(event, child);
  };
  return (
    <div className={timeZoneSelector}>
      <div className={container}>
        <FormControl fullWidth size='small' data-testid='topbar_timezone'>
          <InputLabel id='portfolio-input'>TimeZone</InputLabel>
          <Select
            disabled={lockedTimezone}
            labelId='participant-selector'
            id='participant-selector'
            value={selectedTimeZone ? selectedTimeZone : ''}
            label='Participant'
            onChange={executeSelector}
          >
            {Array.isArray(timeZones) &&
              timeZones.map((timeZone, index) => (
                <MenuItem key={index} value={timeZone.zone}>
                  {timeZone.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default TimeZoneDropdown;
