import { MVP_TIMEZONES, STRING_ARRAY_SEPARATOR } from 'constants/general';
import { IArrayFilter } from 'interfaces/filter';
import { isNil, omitBy } from 'lodash';
import { TArrayFilter } from 'types/filter';
import { TArraySorter } from 'types/sort';
import { andThen } from 'utils/sort';
import RootState from 'redux/store';
import { ZonedDateTime } from '@pci/pci-ui-library';
import { pickSelectedTimezone } from 'redux/states/miscellaneous.state';
import { IZones } from 'interfaces/general';

const EDIT_INFO_KEY_REGEXP = /(.+):(\d+):(\d+)/;

export const uniqueId = (prefix = 'id-') =>
  prefix + Math.random().toString(16).slice(-4);

export const dollarsToCents = (
  dollars: number | undefined
): number | undefined => (dollars === undefined ? undefined : dollars * 100);

export const centsToDollars = (
  priceInCents: number | undefined
): number | undefined =>
  priceInCents === undefined ? undefined : priceInCents / 100;

export const valueInCentsToDollarDisplay = (
  valueInCents: number | undefined
): string =>
  valueInCents === undefined ? '' : `${(valueInCents / 100).toFixed(2)}`;

export const valueInCentsToDollarFullDisplay = (
  valueInCents: number | undefined
): string =>
  valueInCents === undefined
    ? ''
    : `$${(valueInCents / 100).toFixed(2).replace('-', '-$')}`.replace(
        '$-$',
        '-$'
      );

export const dollarDisplayToValueInCents = (dollarDisplay: string): number => {
  const dollarValue: number = parseFloat(dollarDisplay);

  if (Number.isNaN(dollarValue)) {
    throw new Error(`Invalid dollarDisplay: ${dollarDisplay}`);
  }

  return Math.round(dollarValue * 100);
};

export const currencyFormatter = (params: { value: number }): string => {
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const { value } = params;

  const verifiedValue = !isNaN(value) ? value / 100 : 0;

  return usdFormatter.format(verifiedValue);
};

export const formatNumber = (value: number) => {
  return Math.floor(value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const currencyInDollarsFormatter = (params: {
  value: number;
  decimals?: number;
}): string => {
  const { value, decimals = 2 } = params;
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals,
  });

  const verifiedValue = !isNaN(value) ? value : 0;

  return usdFormatter.format(verifiedValue);
};

export const currencyInDollarsDashFormatter = (params: {
  value: number;
}): string => {
  const usdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const { value } = params;

  const verifiedValue = !isNaN(value) ? value : 0;

  return verifiedValue > 0 ? usdFormatter.format(verifiedValue) : '-';
};

export const percentFormatter = (params: { value: number }): string => {
  const { value } = params;

  const verifiedValue = !isNaN(value) ? value : 0;

  return (verifiedValue * 100).toFixed(2) + '%';
};

export const percentNoDecimalsFormatter = (params: {
  value: number;
}): string => {
  const { value } = params;

  const verifiedValue = !isNaN(value) ? Math.round(value * 100) : 0;

  return verifiedValue + '%';
};

export const decimalsCurrencyFormatter = (params: {
  value: number;
}): string => {
  const { value } = params;
  const verifiedValue = !isNaN(value) ? value : 0;
  return '$' + (verifiedValue * 1).toFixed(2);
};

export const infinityFormatter = (params: { value: number }): string => {
  const { value } = params;

  const verifiedValue = !isNaN(value) ? value : null;

  return isNaN(value) || verifiedValue === null
    ? '∞'
    : '' + parseInt(verifiedValue.toString()) + '';
};

export const checkFormatter = (params: { value: boolean }): string => {
  const { value } = params;
  return value === true ? '✔' : '✘';
};

export const shallowObjectCompare = <T>(
  obj1: T & { [index: string]: any },
  obj2: T & { [index: string]: any },
  excludeKeys?: string[]
): boolean =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1)
    .filter((key: string): boolean =>
      excludeKeys === undefined ? true : !excludeKeys.includes(key)
    )
    .every((key) => obj2.hasOwnProperty(key) && obj1[key] === obj2[key]);

export const sleep = async (milliseconds: number) => {
  await new Promise((resolve) => window.setTimeout(resolve, milliseconds));
};

export const valueAsString = (value: any): string => value as string;

// Note: label can be another key allowing us to embed keys within keys
export const getEditInfoKey = (
  label: string,
  primaryId: number,
  editIndex: number
): string => `${label}:${primaryId}:${editIndex}`;

export const getSplitEditInfoKey = (editInfoKey: string) => {
  // We expect all keys to have the format 'label:{primaryId}:{editIndex}', where
  // label could potentially be another key
  const matches: RegExpMatchArray | null =
    editInfoKey.match(EDIT_INFO_KEY_REGEXP);

  if (matches === null) {
    throw new Error(`Invalid editInfoKey: ${editInfoKey}`);
  }

  return {
    label: matches[1],
    primaryId: parseInt(matches[2], 10),
    editIndex: parseInt(matches[3], 10),
  };
};

export const stringArrayToString = (value: string[] | null): string =>
  value === null ? '' : value.join(STRING_ARRAY_SEPARATOR);

export const capitaliseString = (value: string): string =>
  value[0].toLocaleUpperCase() + value.substring(1);

export const filterAndSort = <T>(
  items: T[] | undefined,
  arrayFilters: IArrayFilter<TArrayFilter<T>>[],
  arraySorters: TArraySorter<T>[]
) => {
  if (items !== undefined) {
    const filtered: T[] = items.filter(
      arrayFilters
        .map(
          (arrayFilter: IArrayFilter<TArrayFilter<T>>): TArrayFilter<T> =>
            arrayFilter.filter
        )
        .reduce(
          (
              previous: TArrayFilter<T>,
              current: TArrayFilter<T>
            ): TArrayFilter<T> =>
            (item: T): boolean =>
              previous(item) && current(item),
          (_: T): boolean => true
        )
    );

    if (arraySorters.length > 0) {
      const sorter: TArraySorter<T> = arraySorters.reduceRight(
        (previousValue: TArraySorter<T>, currentValue: TArraySorter<T>) =>
          andThen(currentValue)(previousValue)
      );

      return filtered.sort(sorter);
    }

    return filtered;
  }

  return [];
};

export const areKeysEqual = (
  keyA: string | string[],
  keyB: string | string[]
): boolean =>
  Array.isArray(keyA)
    ? Array.isArray(keyB) &&
      keyA.length === keyB.length &&
      keyA.every((key: string, index: number): boolean => key === keyB[index])
    : keyA === keyB;

export const zeroPad = (num: number, zerosQnt = 2) => {
  return num.toString().padStart(zerosQnt, '0');
};

export const generateToken = () => {
  return (
    Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2)
  );
};

/**
 * Avoid having to check if a string is null or undefined or empty
 * or only whitespace. Collapse all these cases to undefined.
 */
export const blankToUndefined = (
  s: string | null | undefined
): string | undefined => {
  if (s === null || s === undefined || s.trim() === '') {
    return undefined;
  } else {
    return s;
  }
};

export const roundValue = (
  value: number,
  decimals?: number
): number | string => {
  if (isNaN(value)) return value;
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals ?? 2,
  });

  let formattedNum = formatter.format(value);
  if (formattedNum === '-0') formattedNum = formattedNum.replace('-', '');

  return formattedNum;
};

export const removeEmpty = (obj: any) => {
  return omitBy(obj, (v) => v === isNil || v === '') as any;
};

export const timeExportValueGetter = (params: any) => {
  let currentDate;
  switch (params.column.colId) {
    case 'startAt': {
      currentDate = params.data.startAt;
      break;
    }
    case 'stopAt': {
      currentDate = params.data.stopAt;
      break;
    }
    case 'tradeInterval': {
      currentDate = params.data.tradeInterval;
      break;
    }
    case 'modifiedOn': {
      currentDate = params.data.modifiedOn;
      break;
    }
    case 'lastUpdateTime': {
      currentDate = params.data.lastUpdateTime;
      break;
    }
  }

  const dateFallback =
    currentDate?.value || currentDate || new Date().toISOString();

  const zonedTime = ZonedDateTime.fromDate(
    new Date(dateFallback),
    pickSelectedTimezone(RootState.getState())
  );
  return params.data.tradeInterval !== null
    ? zonedTime.format('MM/DD/YYYY HH:mm')
    : '';
};
export const timeExportHeaderValueGetter = (params: any) => {
  const selectedTimezone = MVP_TIMEZONES.find(
    (timezone: IZones) =>
      timezone.zone === pickSelectedTimezone(RootState.getState())
  );
  return `${params.colDef.headerName} \n ${selectedTimezone?.label}`;
};
