import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { SourceSink as SourceSinkEnum } from 'enums/general';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { Participant } from 'api/models/Participant.model';
import { SourceSink } from 'api/models/SourceSink.model';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import { arrayToMap } from 'utils/arrayToMap';

const STORE_NAME = 'sourcesandsinks';

export interface SourcesAndSinkState {
  sources: SourceSink[];
  sinks: SourceSink[];
  sourcesSinks: SourceSink[];
}

const initialState = {
  sources: [],
  sinks: [],
  sourcesSinks: [],
  status: IDLE,
} as SourcesAndSinkState & { status: string };

export const fetchSourcesAndSinks = createAsyncThunk(
  `${STORE_NAME}/fetchSourcesAndSinks`,
  async () => {
    const response = await AutoTraderApi.getSourcesAndSinks();
    const SS = arrayToMap(response, 'type');
    return {
      sinks: SS[SourceSinkEnum.SINK] ?? initialState.sinks,
      sources: SS[SourceSinkEnum.SOURCE] ?? initialState.sources,
      sourcesSinks: SS[SourceSinkEnum.SOURCE_SINK] ?? initialState.sourcesSinks,
      status: FULFILLED,
    };
  }
);

export const sourcesAndSinksSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSourcesAndSinks.pending, (state) => {
        state.status = LOADING;
      })
      .addCase(fetchSourcesAndSinks.fulfilled, (state, action) => {
        return { ...state, ...action.payload };
      });
  },
});

const getParticipantsEntities = (state: any) => {
  return state.participant.entities;
};

const dictionaryToArray = (participants: { [entityId: string]: Participant }) =>
  Object.entries(participants).map(([_, participant]) => participant);

export const getParticipants = createDraftSafeSelector(
  getParticipantsEntities,
  dictionaryToArray
);

export default sourcesAndSinksSlice.reducer;
