import { PowerSettingsNew } from '@mui/icons-material';
import { AppBar, SelectChangeEvent, Toolbar } from '@mui/material';
import { Box } from '@mui/system';
import { useAppContext } from 'App/AppProvider';
import { RegularButton } from 'components/atoms/Button/Button';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Category } from 'services/StructuredLogging/categories';
import { Event } from 'services/StructuredLogging/events';
import ParticipantDropdown from '../ParticipantDropdown/ParticipantDropdown';
import {
  ADMIN_USERS_ENDPOINT,
  ADMIN_API_KEY_ENDPOINT,
  SETUP_PARTICIPANTS,
  SUPPORT_URL,
  SETUP_METERS_URL,
  LOGOUT,
} from '../Sidebar/menu.config';
import TimeZoneDropdown from '../TimeZoneDropdown/TimeZoneDropdown';
import './topbar.scss';
import { useSelector } from 'react-redux';
import RootStore, { AppStore } from 'redux/store';
import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { getParticipants } from 'redux/states/participants.state';
import usePreferences from 'hooks/usePreferences';

function TopBar() {
  const { timezones } = useSelector((store: AppStore) => store.miscellaneous);
  const participants = getParticipants(RootStore.getState());
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const history = useHistory();
  const [isBanned, setIsBanned] = useState(false);
  const { setPreference } = usePreferences();

  const { isCSMUser, logEvent } = useAppContext();
  const { pathname } = useLocation();
  const portfolioSelector = (event: SelectChangeEvent<string>) => {
    if (participants !== undefined) {
      const selected = participants.find((participant) => {
        return participant.name === event.target.value;
      });
      setPreference({ selectedMarketParticipant: selected?.id });
      if (selected !== undefined && selected.name) {
        if (logEvent) {
          logEvent({
            category: Category.TOP_BAR,
            eventTime: new Date(),
            eventName: Event.SELECTEDTENANT,
            payload: selected,
          });
        }
      }
    }
  };

  const timeZonesSelector = (event: SelectChangeEvent<string>) => {
    if (timezones !== undefined) {
      const selected = timezones.find(
        (timeZone) => timeZone.zone === event.target.value
      );
      if (selected) {
        if (logEvent) {
          logEvent({
            category: Category.TOP_BAR,
            eventTime: new Date(),
            eventName: Event.MODIFIEDTIMEZONE,
          });
        }
        setPreference({ timezone: selected.zone });
      }
    }
  };

  useEffect(() => {
    const BANNED = [
      SETUP_PARTICIPANTS,
      ADMIN_USERS_ENDPOINT,
      ADMIN_API_KEY_ENDPOINT,
      SUPPORT_URL,
      SETUP_METERS_URL,
    ];

    setIsBanned(BANNED.some((currentPath) => pathname.includes(currentPath)));
  }, [pathname]);

  const setLogoutRoute = () => {
    if (logEvent) {
      logEvent({
        category: Category.TOP_BAR,
        eventTime: new Date(),
        eventName: Event.LOGGEDOUT,
      });
    }
    setTimeout(async () => {
      history.push(LOGOUT);
    }, 10);
  };

  return (
    <div className='topbar-wrapper'>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar className='topbar' position='static'>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              {!isCSMUser && !isBanned ? (
                <ParticipantDropdown
                  participants={participants}
                  handleSelector={portfolioSelector}
                  selectedParticipant={undefined || selectedParticipant}
                />
              ) : null}
            </Box>

            {!isCSMUser && (
              <TimeZoneDropdown
                timeZones={timezones}
                handleSelector={timeZonesSelector}
                selectedTimeZone={selectedTimeZone || ''}
              />
            )}

            {!isCSMUser && (
              <RegularButton
                className='logoutbtn'
                data-testid='topbar_logout'
                variant='outlined'
                startIcon={<PowerSettingsNew fontSize='small' />}
                onClick={setLogoutRoute}
              >
                Logout
              </RegularButton>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}

export default TopBar;
