import * as oauth2 from 'oauth4webapi';
import { blankToUndefined } from './general';
import { authDebug } from './log';

const SCOPE_ROLES = [
  'seem-trader:seem_market',
  'seem-trader:caiso_market',
  'seem-trader:admin',
  'seem-trader:analyst',
  'seem-trader:trader',
  'seem-trader:viewer',
  'seem-trader:oversight',
];

/**
 * Tells us how to get tokens for users in a particular tenant.
 * Also, we use the tenant id to match against the tenant id given
 * in the ui.
 */
export interface ITenantAuthConfigBase {
  tenantId: string;
  issuer: string;
  clientId: string;
  /**
   * Choose an audience when getting a token.
   * Sometimes auth server has a default audience, so this is not
   * necessary. Sometimes there are a few audiences to choose from.
   * The server may not accept all audiences.
   */
  acceptedAudiences: string[];
  logoutUrlTemplate: string;
}

/**
 * Extends ITenantAuthConfigBase with 'redirectSignIn' and 'redirectSignOut',
 * which are the same for all tenants, so the apiConfig.json
 * file has it listed outside the tenants section.
 * It is more convenient for use to combine these.
 */
export interface ITenantAuthConfig extends ITenantAuthConfigBase {
  redirectSignIn: string;
  redirectSignOut: string;
}

/**
 * Type declaration for structure of public/apiConfig.json.
 */
export interface IApiConfig {
  /**
   * api base url.
   */
  baseUrl: string;
  /**
   * Must be registered as one of the callback urls in each tenant's web client.
   */
  redirectSignIn: string;
  /**
   * Must be registered as one of the logout urls in each tenant's web client.
   */
  redirectSignOut: string;
  /**
   * Find tenant app client via one of these configs.
   */
  tenants: ITenantAuthConfigBase[];
}

/**
 * Puts all the token parts together to easily
 * save into local storage / load from local storage / delete from local storage.
 */
export class TokenContainer {
  private parsedIdToken: Record<string, any> | undefined = undefined;
  private parsedAccessToken: Record<string, any> | undefined = undefined;
  constructor(
    readonly access_token: string,
    readonly refresh_token: string,
    readonly id_token: string,
    readonly scope: string
  ) {}

  /**
   * Factory method. When getting a brand-new token from the provider.
   * @param response from a previous call to third-party library to get a token
   */
  public static ofTokenEndpointResponse(
    response: oauth2.TokenEndpointResponse
  ): TokenContainer {
    return new TokenContainer(
      response.access_token,
      response.refresh_token ?? '',
      response.id_token ?? '',
      response.scope ?? ''
    );
  }

  /**
   * Check if there is a token already stored.
   * Get it if we can, otherwise get null.
   * @param authConfig we use the client id here as part of the key in local storage
   */
  public static loadFromLocalStorage(
    authConfig: ITenantAuthConfigBase
  ): TokenContainer | null {
    const keys = new TokenContainerKeys(authConfig);
    const access_token = window.localStorage.getItem(keys.accessTokenKey());
    const refresh_token = window.localStorage.getItem(keys.refreshTokenKey());
    const id_token = window.localStorage.getItem(keys.idTokenKey());
    const scope = window.localStorage.getItem(keys.scopeKey());
    if (
      access_token === null ||
      refresh_token === null ||
      id_token === null ||
      scope === null
    ) {
      return null;
    }
    return new TokenContainer(access_token, refresh_token, id_token, scope);
  }

  public async refreshIfNecessary(
    asContext: Oauth2AuthorizationServerContext
  ): Promise<TokenContainer> {
    if (this.isAccessTokenExpired()) {
      authDebug(`Access token is expired. Using refresh token`);
      try {
        return await asContext.getTokenViaRefresh(this);
      } catch (e) {
        // the currently stored token container is useless, get rid
        // of it to avoid having to detect this later
        TokenContainer.delete(asContext.authConfig);
        throw new RefreshTokenError('Failed to refresh token: ' + e);
      }
    }
    return this;
  }

  public getSub(): string {
    const sub = this.parseAccessToken()?.sub;
    return sub;
  }

  /**
   * Check the exp claim on the access token
   * from the request for the original token.
   * Compare to current time + 30s.
   */
  isAccessTokenExpired(): boolean {
    const exp = this.parseAccessToken()?.exp;
    return (
      exp === null ||
      exp === undefined ||
      exp <= new Date().getTime() / 1000 + 30
    );
  }

  /**
   * Put all the token pieces into browser storage.
   * @param authConfig we use the client id here as part of the key in local storage
   */
  public saveToLocalStorage(authConfig: ITenantAuthConfigBase): void {
    const keys = new TokenContainerKeys(authConfig);
    window.localStorage.setItem(keys.accessTokenKey(), this.access_token);
    window.localStorage.setItem(keys.refreshTokenKey(), this.refresh_token);
    window.localStorage.setItem(keys.idTokenKey(), this.id_token);
    window.localStorage.setItem(keys.scopeKey(), this.scope);
  }

  /**
   * Remove all keys managed here from local storage.
   * @param authConfig we use the client id here as part of the key in local storage
   */
  static delete(authConfig: ITenantAuthConfigBase) {
    authDebug('deleting Tenant Container from local storage');
    const keys = new TokenContainerKeys(authConfig);
    window.localStorage.removeItem(keys.accessTokenKey());
    window.localStorage.removeItem(keys.refreshTokenKey());
    window.localStorage.removeItem(keys.idTokenKey());
    window.localStorage.removeItem(keys.scopeKey());
  }

  private parseIdToken(): Record<string, any> {
    if (this.parsedIdToken === undefined) {
      this.parsedIdToken = this.parseClaims(this.id_token);
    }
    return this.parsedIdToken!;
  }

  private parseAccessToken(): Record<string, any> {
    if (this.parsedAccessToken === undefined) {
      this.parsedAccessToken = this.parseClaims(this.access_token);
      authDebug('parsed access token', this.parsedAccessToken);
    }
    return this.parsedAccessToken!;
  }

  private parseClaims(token: string): Record<string, any> {
    try {
      return JSON.parse(
        new TextDecoder().decode(this.decodeBase64Url(token.split('.')[1]))
      );
    } catch {
      authDebug('Failed to parse token', token);
      return {};
    }
  }

  getEmail(): string | undefined {
    return this.parseIdToken()['email'];
  }

  private decodeBase64Url(input: string) {
    try {
      const binary = window.atob(
        input.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '')
      );
      const bytes = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i);
      }
      return bytes;
    } catch {
      throw new TypeError('The input to be decoded is not correctly encoded.');
    }
  }
}

/**
 * Simple encapsulation of the local storage keys used in TokenContainer.
 */
class TokenContainerKeys {
  constructor(readonly authConfig: ITenantAuthConfigBase) {}

  accessTokenKey() {
    return this.localStorageKeyPrefix() + 'access_token';
  }

  refreshTokenKey() {
    return this.localStorageKeyPrefix() + 'refresh_token';
  }

  idTokenKey() {
    return this.localStorageKeyPrefix() + 'id_token';
  }

  scopeKey() {
    return this.localStorageKeyPrefix() + 'scope';
  }

  private localStorageKeyPrefix() {
    return this.authConfig.tenantId + '.' + this.authConfig.clientId + '.';
  }
}

/**
 * Wrapper + customization based on sample in {@link https://github.com/panva/oauth4webapi/blob/main/examples/public.ts}.
 */
export class Oauth2AuthorizationServerContext {
  private constructor(
    readonly authConfig: ITenantAuthConfig,
    private readonly as: oauth2.AuthorizationServer,
    private readonly client: oauth2.Client
  ) {}

  codeVerifierSessionKey(authConfig: ITenantAuthConfigBase): string {
    return authConfig.clientId + '.code_verifier';
  }

  /**
   * Will remove code verifier from session.
   */
  clearSession() {
    window.sessionStorage.removeItem(
      this.codeVerifierSessionKey(this.authConfig)
    );
  }

  /**
   * Factory method. Will load issuer discovery url and configure
   * the oauth2 client so that we can get tokens.
   * @param authConfig required. contains redirectSignIn along with client id, needed to get a token
   */
  public static async of(
    authConfig: ITenantAuthConfig
  ): Promise<Oauth2AuthorizationServerContext> {
    const issuer = new URL(authConfig.issuer);
    const discoveryResponse = await oauth2.discoveryRequest(issuer);
    const as = await oauth2.processDiscoveryResponse(issuer, discoveryResponse);
    const client: oauth2.Client = {
      client_id: authConfig.clientId,
      token_endpoint_auth_method: 'none',
    };
    return new Oauth2AuthorizationServerContext(authConfig, as, client);
  }

  async getTokenViaAuthorizationCodeGrant(): Promise<TokenContainer | null> {
    const params = oauth2.validateAuthResponse(
      this.as,
      this.client,
      new URL(window.location.href),
      oauth2.expectNoState
    );
    if (oauth2.isOAuth2Error(params)) {
      authDebug('no authorization code in url?', params);
      return null;
    } else if (params instanceof URLSearchParams) {
      if (!params.has('code')) {
        return null;
      }
    }

    const code_verifier = blankToUndefined(
      window.sessionStorage.getItem(
        this.codeVerifierSessionKey(this.authConfig)
      )
    );
    window.sessionStorage.removeItem(
      this.codeVerifierSessionKey(this.authConfig)
    );
    if (code_verifier === undefined) {
      authDebug(
        'Cannot complete authorization code grant request without code verifier from session storage'
      );
      return null;
    }
    let response;
    try {
      response = await oauth2.authorizationCodeGrantRequest(
        this.as,
        this.client,
        params,
        this.authConfig.redirectSignIn,
        code_verifier
      );
    } catch (e: any) {
      console.error('authorizationCodeGrantRequest failed with params', params);
      throw new Error('authorizationCodeGrantRequest failed');
    }

    let challenges: oauth2.WWWAuthenticateChallenge[] | undefined;
    if ((challenges = oauth2.parseWwwAuthenticateChallenges(response))) {
      for (const challenge of challenges) {
        console.error('unexpected challenge', challenge);
      }
      throw new Error('Handle www-authenticate challenges as needed');
    }

    const result = await oauth2.processAuthorizationCodeOpenIDResponse(
      this.as,
      this.client,
      response
    );
    if (oauth2.isOAuth2Error(result)) {
      console.error('oauth2 error', result);
      throw new GetTokenViaAuthorizationCodeGrantError(
        'Handle OAuth 2.0 response body error'
      );
    }

    const tokenContainer = TokenContainer.ofTokenEndpointResponse(result);
    if (tokenContainer === null) {
      throw new Error('Failed to parse tokens from response: ' + result);
    }
    return tokenContainer;
  }

  async redirectToIdentityProvider() {
    const code_challenge_method = 'S256';
    if (
      this.as.code_challenge_methods_supported?.includes(
        code_challenge_method
      ) !== true
    ) {
      // This example assumes S256 PKCE support is signalled
      // If it isn't supported, random `nonce` must be used for CSRF protection.
      throw new Error();
    }

    const code_verifier = oauth2.generateRandomCodeVerifier();
    window.sessionStorage.setItem(
      this.codeVerifierSessionKey(this.authConfig),
      code_verifier
    );
    authDebug(
      'generated new random code verifier and put in session storage',
      code_verifier
    );
    const code_challenge = await oauth2.calculatePKCECodeChallenge(
      code_verifier
    );

    // redirect user to as.authorization_endpoint
    const authorizationUrl = new URL(this.as.authorization_endpoint!);
    authorizationUrl.searchParams.set('client_id', this.client.client_id);
    authorizationUrl.searchParams.set('code_challenge', code_challenge);
    authorizationUrl.searchParams.set(
      'code_challenge_method',
      code_challenge_method
    );
    authorizationUrl.searchParams.set(
      'redirect_uri',
      this.authConfig.redirectSignIn
    );
    authorizationUrl.searchParams.set('response_type', 'code');
    authorizationUrl.searchParams.set(
      'scope',
      // to get refresh token, include offline_access scope
      `openid email offline_access ${SCOPE_ROLES.join(' ')}`
    );
    if (
      this.authConfig.acceptedAudiences !== undefined &&
      this.authConfig.acceptedAudiences.length > 0
    ) {
      authorizationUrl.searchParams.set(
        'audience',
        this.authConfig.acceptedAudiences[0]
      );
    }

    authDebug('redirecting to', authorizationUrl);
    window.location.replace(authorizationUrl);
  }

  async getTokenViaRefresh(
    tokenContainer: TokenContainer
  ): Promise<TokenContainer> {
    if (
      tokenContainer.refresh_token === undefined ||
      tokenContainer.refresh_token === null ||
      tokenContainer.refresh_token.length === 0
    ) {
      throw new RefreshTokenError('No refresh_token defined.');
    }
    const response = await oauth2.refreshTokenGrantRequest(
      this.as,
      this.client,
      tokenContainer.refresh_token
    );
    const result = await oauth2.processRefreshTokenResponse(
      this.as,
      this.client,
      response
    );
    if (oauth2.isOAuth2Error(result)) {
      throw new Error('Failed to use refresh_token to get new access token');
    }
    const newTokenContainer = TokenContainer.ofTokenEndpointResponse(result);
    if (newTokenContainer === null) {
      throw new Error('Failed to read tokens from response: ' + result);
    }
    return newTokenContainer;
  }
}

/**
 * In progress, this is here because we are replacing the cognito flow.
 * This is the closest approximation to the cognito user.
 * We can scrap this for a better class later if we like.
 */
export interface Oauth2User {
  scopes: string[];
  email: string | undefined;
}

/**
 * In progress, this is here because we are replacing the cognito flow.
 * This is the closest approximation to the cognito session.
 * We can scrap this for a better class later if we like.
 */
export interface Oauth2Session {
  getAccessToken(): string;
  getSub(): string;
}

export class RefreshTokenError extends Error {}
export class GetTokenViaAuthorizationCodeGrantError extends Error {}

/**
 * In progress, this is here because we are replacing the cognito flow.
 * This is the closest approximation to the cognito Auth class.
 * We can scrap this for a better class later if we like.
 */
export class OAuth2SessionManager {
  private readonly onSignOuts: (() => void)[] = [];

  private constructor(
    private readonly asContext: Oauth2AuthorizationServerContext,
    private tokenContainer: TokenContainer
  ) {}

  /**
   * Imitating cognito api Auth.currentSession().
   */
  async currentSession(): Promise<Oauth2Session> {
    authDebug('getting current authenticated oauth2 session');
    this.tokenContainer = await this.tokenContainer.refreshIfNecessary(
      this.asContext
    );
    return Promise.resolve({
      getAccessToken: () => this.tokenContainer.access_token,
      getSub: () => this.tokenContainer.getSub(),
    });
  }

  /**
   * Imitating cognito api Auth.currentAuthenticatedUser().
   */
  async currentAuthenticatedUser(): Promise<Oauth2User> {
    authDebug('getting current authenticated oauth2 user');
    this.tokenContainer = await this.tokenContainer.refreshIfNecessary(
      this.asContext
    );
    const user = {
      scopes: this.tokenContainer.scope.split(' '),
      email: this.tokenContainer.getEmail(),
    };
    authDebug('current authenticated user', user);
    return user;
  }

  /**
   * Not imitating anything from cognito.
   * We just need a way to hook in to the sign-out
   * and add callbacks
   * @param onSignOut callback to invoke when signing out
   */
  addOnSignOut(onSignOut: () => Promise<void>): void {
    this.onSignOuts.push(onSignOut);
  }

  /**
   * Imitating cognito api Auth.signOut().
   */
  async signOut() {
    this.asContext.clearSession();
    TokenContainer.delete(this.asContext.authConfig);
    for (let onSignOut of this.onSignOuts) {
      try {
        await onSignOut();
      } catch (e) {
        console.error('Error calling onSignOut handler. Moving on.', e);
      }
    }

    const logoutUrl = this.asContext.authConfig.logoutUrlTemplate
      .replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${issuer}',
        this.asContext.authConfig.issuer
      )
      .replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${redirectSignOut}',
        encodeURIComponent(this.asContext.authConfig.redirectSignOut)
      )
      .replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${clientId}',
        encodeURIComponent(this.asContext.authConfig.clientId)
      );
    authDebug('redirecting to', logoutUrl);
    window.location.replace(logoutUrl);
  }

  static async of(
    asContext: Oauth2AuthorizationServerContext
  ): Promise<OAuth2SessionManager | undefined> {
    let tokenContainer: TokenContainer | null =
      TokenContainer.loadFromLocalStorage(asContext.authConfig);
    authDebug('local-storage token container', tokenContainer);
    if (tokenContainer === null) {
      tokenContainer = await asContext.getTokenViaAuthorizationCodeGrant();
      authDebug('code-grant token container', tokenContainer);
    } else {
      tokenContainer = await tokenContainer?.refreshIfNecessary(asContext);
    }

    if (tokenContainer != null) {
      tokenContainer.saveToLocalStorage(asContext.authConfig);
      return new OAuth2SessionManager(asContext, tokenContainer);
    }
    return undefined;
  }
}
