import styled from 'styled-components';

export const Label = styled.div`
  font-size: 14px;
`;

export const SecondLabel = styled.div`
  font-size: 14px;
  padding-left: 16px;
`;

export const FilterContainer = styled.div`
  display: flex;
  height: 50px;
  grid-template-columns: 250px 250px;
  align-items: center;
  justify-items: left;
  font-size: 14px;
`;

export const FilterBox = styled.div`
  display: flex;
  height: 50px;
  grid-template-columns: 50px 200px;
  grid-gap: 8px;
  align-items: center;
  justify-items: left;
  font-size: 14px;
`;
