import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ApiKeyCreatedDto } from '../../../api/models/ApiKey.model';
import { AxiosResponse } from 'axios';
import { AutoTraderApi } from '../../../api/AutoTraderAPI';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'App/AppProvider';

export default function AddApiKeyDialog({
  open,
  setOpen,
  setApiKeyWithSecret,
}: {
  open: boolean;
  setOpen: Function;
  setApiKeyWithSecret: (apiKeyWithSecret: ApiKeyCreatedDto) => void;
}) {
  const { t } = useTranslation();
  const { pushNotification } = useAppContext();
  const [apiKeyName, setApiKeyName] = useState<string>('');
  const [apiKeyDescription, setApiKeyDescription] = useState<string>('');
  const [apiKeyCreateError, setApiKeyCreateError] = useState<string | null>(
    null
  );

  const createApiKey = async () => {
    setApiKeyCreateError(null);
    const roleFlags: [boolean, string][] = [
      [true, 'SEEM_MARKET'],
      [true, 'TRADER'],
      [true, 'VIEWER'],
    ];
    const roles: string[] = [];
    for (const roleFlag of roleFlags) {
      if (roleFlag[0] as boolean) {
        roles.push(roleFlag[1] as string);
      }
    }
    const response: AxiosResponse = await AutoTraderApi.createApiKey({
      roles,
      apiKeyName,
      description: apiKeyDescription,
    });
    if (200 <= response.status && response.status < 300) {
      setApiKeyWithSecret(response.data as ApiKeyCreatedDto);
      if (pushNotification) {
        pushNotification('success', 'Api key succesfully created');
      }
    } else if (400 <= response.status && response.status < 500) {
      setApiKeyCreateError(response.data.error?.message);
    } else {
      setApiKeyCreateError(
        'Could not create api key at this time. Please try again later'
      );
      console.error(
        'Failure from api call to add api key with roles',
        roles,
        response.data
      );
    }
  };

  const handleClose = () => {
    setApiKeyCreateError(null);
    setOpen(false);
    clearOut();
  };

  const handleAdd = async () => {
    await createApiKey();
    handleClose();
  };

  const clearOut = () => {
    setApiKeyName('');
    setApiKeyDescription('');
  };

  useEffect(() => {
    if (apiKeyCreateError !== null && pushNotification) {
      pushNotification('error', apiKeyCreateError);
    }
  }, [apiKeyCreateError, pushNotification]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        {apiKeyCreateError === null && (
          <>
            <DialogTitle>Add new api key</DialogTitle>
            <DialogContent>
              <FormControl fullWidth component='fieldset' variant='standard'>
                <TextField
                  autoFocus
                  margin='dense'
                  id='apiKeyName'
                  label={t('apiKeys.labels.apiKeyName')}
                  fullWidth
                  variant='standard'
                  value={apiKeyName}
                  onChange={(event) => setApiKeyName(event.target.value)}
                />
                <TextField
                  autoFocus
                  margin='dense'
                  id='apiKeyDescription'
                  label={t('apiKeys.labels.apiKeyDescription')}
                  fullWidth
                  variant='standard'
                  value={apiKeyDescription}
                  onChange={(event) => setApiKeyDescription(event.target.value)}
                />
              </FormControl>
            </DialogContent>
          </>
        )}
        {apiKeyCreateError !== null && (
          <>
            <DialogTitle>Error adding new api key</DialogTitle>
            <DialogContent>
              <DialogContentText>{apiKeyCreateError}</DialogContentText>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {apiKeyCreateError === null && (
            <Button onClick={handleAdd}>Add</Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
