const AUTH_DEBUG_LOG_PREFIX = '[auth] ';
let authDebugLog;
if (process.env.REACT_APP_LOG_LEVEL_AUTH === 'DEBUG') {
  authDebugLog = function () {
    const args = Array.prototype.slice.call(arguments);
    args.unshift(AUTH_DEBUG_LOG_PREFIX);
    console.log.apply(console, args);
  };
} else {
  authDebugLog = () => {};
}
export const authDebug: Function = authDebugLog;
