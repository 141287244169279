export enum EResourceUnitType {
  Coal = 'coal',
  Gas = 'gas',
  Solar = 'solar',
  Wind = 'wind',
  LOAD = 'LOAD',
  GENERATING_UNIT = 'GENERATING_UNIT',
  POWER_EXPORT = 'POWER_EXPORT',
  POWER_IMPORT = 'POWER_IMPORT',
}
