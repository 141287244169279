import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import { breadcrumbsify } from '../AddResources/constant';
import styles from './BreadcrumbsBar.module.scss';

interface Props {
  path: string;
}

const BreadcrumbsBar = ({ path }: Props) => {
  const { breadcrumbs } = styles;
  const breads = path
    .replaceAll('*/', '')
    .split('/')
    .map((bread) => breadcrumbsify(bread));

  const back = () => {
    // eslint-disable-next-line no-script-url
    return 'javascript:history.back();';
  };

  return (
    <div id='breadcrumbs' className={breadcrumbs}>
      <Breadcrumbs
        separator={<NavigateNext fontSize='small' />}
        aria-label='breadcrumb'
      >
        {breads.map((element, index, arry) =>
          index === arry.length - 1 ? (
            <Typography key='3' color='text.primary'>
              {element}
            </Typography>
          ) : (
            <Link underline='hover' key='1' color='inherit' href={back()}>
              {element}
            </Link>
          )
        )}
      </Breadcrumbs>
    </div>
  );
};

export default BreadcrumbsBar;
