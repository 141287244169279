/* eslint-disable */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { AgGridReact } from 'ag-grid-react';
import { IsGroupOpenByDefaultParams } from 'ag-grid-enterprise';
import { RowGroupOpenedEvent } from 'ag-grid-community';

import { AutoTraderApi } from 'api/AutoTraderAPI';
import { EAPIState } from 'enums/general';
import { EDataGridView } from 'enums/dataGrid';
import { TDateISO } from 'types/data';
import { IOperatingPlanM15Resources } from 'interfaces/resource';
import useSSE, { AutoTraderEvent } from 'hooks/useSSE';
import {
  GENERATING_UNIT,
  LOAD,
  POWER_EXPORT,
  POWER_IMPORT,
} from 'components/admin/AddResources/constant';

import { Event, EventType } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { IIndexable } from 'interfaces/general';
import { useOperatingPlanM15Context } from 'pages/admin/Plan/context';

import { dynamicColumnsDef, getParsedFilter, planTypeAPIMap } from './helpers';
import { M15GridContainer } from '../OperatingPlan.styles';
import GroupRowInnerRenderer from '../partials/Grid/groupRowInnerRenderer';
import M15Header from './M15.header';
import { DATA_SOURCE } from './constants';
import { AppDispatch, AppStore } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEffectiveOperatingPlanM15,
  fetchFormulatedOperatingPlanM15,
  fetchOptimizedOperatingPlanM15,
  fetchOriginalOperatingPlanM15,
  selectPlan,
} from 'redux/states/operatingplans.state';
import { FULFILLED, IDLE } from 'redux/constants';
import useWorker from 'hooks/useWorker';
import { isEmpty } from 'lodash';

interface IGridRowOpened {
  key: string | null;
  index: number | null;
}

// need to add more setTimeOuts on the useEffects that triggers first

export type IDataSource =
  | typeof DATA_SOURCE.Effective
  | typeof DATA_SOURCE.Optimized
  | typeof DATA_SOURCE.Formulated
  | typeof DATA_SOURCE.Original;

export interface ISharpHourDateRef {
  to: string;
  from: string;
}

const GridWorker = new Worker(
  new URL('../../../../workers/buildgrid.worker.ts', import.meta.url),
  {
    type: 'module',
  }
);

const OperatingPlanM15 = () => {
  const {
    fromRangeDateTime,
    toRangeDateTime,
    logEvent,
    selectedParticipant,
    selectedTimeZone,
    isCustomRangeActive,
    selectedGridViewType,
    selectedDataSource,
    selectedRangeFilterTab,
    selectedPlanTypeTab,
  } = useOperatingPlanM15Context();

  const dispatch = useDispatch<AppDispatch>();

  const { instance: worker, workerStatus, results } = useWorker(GridWorker);

  useEffect(() => {
    const api = gridRef.current!.api!;
    if (api && workerStatus === 'complete' && !isEmpty(results)) {
      api.hideOverlay();
      api.setRowData(results as any[]);
      rowGroupEvent();
    }
  }, [workerStatus, results]);

  const {
    originalRange,
    originalStatus,
    original,
    effectiveRange,
    effectiveStatus,
    effective,
    optimizedStatus,
    optimizedRange,
    optimized,
    formulatedStatus,
    formulatedRange,
    formulated,
  } = useSelector((store: AppStore) => store.operatingplans);

  const gridRowOpenedRef = useRef<IGridRowOpened[]>([
    {
      key: 'energy',
      index: null,
    },
  ]);
  const [gridRowOpened, setGridRowOpened] = useState<IGridRowOpened[]>([
    { key: 'energy', index: null },
  ]);

  const [timeZone, setTimeZone] = useState<TTimeZone>(
    selectedTimeZone as TTimeZone
  );

  const zonedNow = ZonedDateTime.now(timeZone);
  const zonedNowHourSharp = zonedNow.startOf('hour');

  const [sourceUri, setSourceUri] = useState<string | undefined>(undefined);
  const sseInit = {
    event: 'TradeStackInstanceUpdatedEvent',
    type: EventType.AUTOTRADER,
    sourceURL: sourceUri,
  };
  const { data } = useSSE(sseInit);
  const currentDataSource = useRef(selectedDataSource);

  const isEventListenerRunning = useRef<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);
  const [operatingPlans, setOperatingPlans] =
    useState<IOperatingPlanM15Resources>();
  const [operatingPlansStatus, setOperatingPlansStatus] = useState<string>(
    EAPIState.Pending
  );
  const [gridData, setGridData] = useState<any>([]);
  const [imbalanceGridData, setImbalanceGridData] = useState<any>([]);
  const [dataByRange, setDataByRange] = useState<any>([]);
  const dataByTimeRangeProps = useCallback(
    (fromDate: TDateISO, toDate: TDateISO) => ({
      selectedPlanTypeData: retrieveResource(selectedPlanTypeTab.key) || [],
      planTypeData: {
        generating: operatingPlans?.generatingUnitOperatingPlans,
        load: operatingPlans?.loadOperatingPlans,
        import: operatingPlans?.powerImportOperatingPlans,
        export: operatingPlans?.powerExportOperatingPlans,
      },
      start: fromDate,
      end: toDate,
    }),
    [operatingPlans, selectedPlanTypeTab.key]
  );

  const retrieveResource = (selected: string) => {
    switch (selected) {
      case GENERATING_UNIT: {
        return operatingPlans?.generatingUnitOperatingPlans;
      }
      case LOAD: {
        return operatingPlans?.loadOperatingPlans;
      }
      case POWER_IMPORT: {
        return operatingPlans?.powerImportOperatingPlans;
      }
      case POWER_EXPORT: {
        return operatingPlans?.powerExportOperatingPlans;
      }
    }
  };

  const dynamicColumnsDefHandler = useMemo(() => {
    if (isCustomRangeActive) {
      const fromDate = ZonedDateTime.parseIso(
        fromRangeDateTime,
        timeZone
      ).isoFormat() as TDateISO;
      const toDate = ZonedDateTime.parseIso(
        toRangeDateTime,
        timeZone
      ).isoFormat() as TDateISO;

      return dynamicColumnsDef({
        startDate: fromDate,
        endDate: toDate,
        timeZone,
      });
    }

    const nextHour = getParsedFilter(selectedRangeFilterTab);
    const fromDate = zonedNowHourSharp.isoFormat() as TDateISO;
    const toDate = zonedNowHourSharp
      .add(nextHour, nextHour > 24 || nextHour === 7 ? 'days' : 'hours')
      .isoFormat() as TDateISO;

    return dynamicColumnsDef({
      startDate: fromDate,
      endDate: toDate,
      timeZone,
    });
  }, [
    timeZone,
    selectedRangeFilterTab.key,
    fromRangeDateTime,
    toRangeDateTime,
    isCustomRangeActive,
  ]);

  const rowGroupEvent = useCallback(() => {
    if (gridRef.current && gridRef.current.api) {
      const { api } = gridRef.current;

      const rowGroupOpenedHandler = ({
        rowIndex,
        node: { key, expanded },
      }: RowGroupOpenedEvent) => {
        setGridRowOpened((oldState) => {
          if (!expanded) {
            const newState = oldState.filter((row) => row.key !== key);
            gridRowOpenedRef.current = [...newState];
            return newState;
          }

          const newState = [...oldState, { key, index: rowIndex }];
          gridRowOpenedRef.current = [...newState];
          return newState;
        });
      };

      api.addEventListener('rowGroupOpened', rowGroupOpenedHandler);

      return () => {
        api.removeEventListener('rowGroupOpened', rowGroupOpenedHandler);
      };
    }
  }, [gridRef.current, setGridRowOpened]);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setRowData([]);
      gridRef.current.api.refreshCells();
      gridRef.current.api.showLoadingOverlay();
    }
    const { selectedPlanTypeData, planTypeData } = dataByRange;
    const imbalanceDataHolder: IIndexable = {};

    planTypeData?.load?.map(
      planTypeAPIMap('load', timeZone, imbalanceDataHolder)
    );

    planTypeData?.export?.map(
      planTypeAPIMap('load', timeZone, imbalanceDataHolder)
    );

    planTypeData?.import?.map(
      planTypeAPIMap('generation', timeZone, imbalanceDataHolder)
    );

    planTypeData?.generating?.map(
      planTypeAPIMap('generation', timeZone, imbalanceDataHolder, true)
    );

    setImbalanceGridData(Object.values(imbalanceDataHolder));

    if (selectedPlanTypeData && selectedPlanTypeData.length) {
      if (window.Worker) {
        worker.postMessage({
          command: 'generate',
          params: { selectedPlanTypeData, timeZone, selectedGridViewType },
        });
      }
    } else {
      setGridData(selectedPlanTypeData);
    }
  }, [dataByRange, selectedGridViewType, timeZone]);

  const columnTypes = {
    number: {
      editable: true,
      valueParser: (params: any) => {
        return parseInt(params.newValue);
      },
      aggFunc: 'sum',
    },
  };

  const groupDisplayType = 'groupRows';
  const autoGroupColumnDef = {};
  const groupRowRendererParams = {
    innerRenderer: GroupRowInnerRenderer,
    rowDrag: false,
    suppressCount: true,
  };
  const loadOperatingPlans = async (
    dataSource: string,
    id: string | undefined,
    fromDate: TDateISO,
    toDate: TDateISO,
    reload: boolean
  ) => {
    const opProps = {
      marketParticipantId: id,
      startAt: fromDate,
      stopAt: toDate,
    };
    if (
      dataSource === DATA_SOURCE.Formulated &&
      (formulatedStatus === IDLE || reload)
    ) {
      dispatch(fetchFormulatedOperatingPlanM15(opProps));
    } else if (
      dataSource === DATA_SOURCE.Effective &&
      (effectiveStatus === IDLE || reload)
    ) {
      dispatch(fetchEffectiveOperatingPlanM15(opProps));
    } else if (
      dataSource === DATA_SOURCE.Optimized &&
      (optimizedStatus === IDLE || reload)
    ) {
      dispatch(fetchOptimizedOperatingPlanM15(opProps));
    } else {
      if (originalStatus === IDLE || reload) {
        dispatch(fetchOriginalOperatingPlanM15(opProps));
      }
    }
  };

  const isGroupOpenByDefault = useCallback(
    (params: IsGroupOpenByDefaultParams) => {
      if (gridRowOpenedRef?.current?.length) {
        return (
          gridRowOpenedRef.current.filter((row: any) => row.key === params.key)
            .length > 0
        );
      }

      return (
        gridRowOpened.filter((row: any) => row.key === params.key).length > 0
      );
    },
    [gridRowOpened]
  );

  useEffect(() => {
    if (selectedTimeZone) {
      setTimeZone(selectedTimeZone);
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.refreshCells();
        gridRef.current.api.refreshHeader();
        gridRef.current.api.sizeColumnsToFit();
      }
    }
  }, [selectedTimeZone]);

  const updateOperatingPlan = (reload: boolean = false) => {
    if (isCustomRangeActive && fromRangeDateTime) {
      loadOperatingPlans(
        currentDataSource.current,
        selectedParticipant?.id,
        fromRangeDateTime as TDateISO,
        toRangeDateTime as TDateISO,
        true
      );
    } else {
      const nextHour = getParsedFilter(selectedRangeFilterTab);
      const fromDate = zonedNowHourSharp.isoFormat() as TDateISO;
      const toDate = zonedNowHourSharp
        .add(nextHour, nextHour > 24 || nextHour === 7 ? 'days' : 'hours')
        .isoFormat() as TDateISO;
      const timeZone = ZonedDateTime.defaultTimeZone();
      const currentRangeInSmallUnit = ZonedDateTime.parseIso(
        toDate,
        timeZone
      ).diff(ZonedDateTime.parseIso(fromDate, timeZone), 'm');
      loadOperatingPlans(
        currentDataSource.current,
        selectedParticipant?.id,
        fromDate,
        toDate,
        reload ||
          effectiveRange !== currentRangeInSmallUnit ||
          originalRange !== currentRangeInSmallUnit ||
          optimizedRange !== currentRangeInSmallUnit ||
          formulatedRange !== currentRangeInSmallUnit
      );
    }
  };

  useEffect(() => {
    if (selectedParticipant && document.readyState === 'complete') {
      currentDataSource.current = selectedDataSource;
      updateOperatingPlan();
    }
  }, [isCustomRangeActive, selectedDataSource]);

  useEffect(() => {
    if (
      selectedParticipant &&
      (optimizedStatus === FULFILLED ||
        originalStatus === FULFILLED ||
        effectiveStatus === FULFILLED ||
        formulatedStatus === FULFILLED)
    ) {
      currentDataSource.current = selectedDataSource;
      updateOperatingPlan(true);
    } else if (selectedParticipant) {
      currentDataSource.current = selectedDataSource;
      updateOperatingPlan();
    }
  }, [
    selectedParticipant?.id,
    selectedRangeFilterTab?.key,
    fromRangeDateTime,
    toRangeDateTime,
  ]);

  useEffect(() => {
    if (operatingPlans) {
      setTimeout(() => {
        if (isCustomRangeActive) {
          setDataByRange(
            dataByTimeRangeProps(fromRangeDateTime, toRangeDateTime)
          );
        } else {
          const nextHour = getParsedFilter(selectedRangeFilterTab);
          const fromDate = zonedNowHourSharp.isoFormat() as TDateISO;
          const toDate = zonedNowHourSharp
            .add(nextHour, nextHour > 24 || nextHour === 7 ? 'days' : 'hours')
            .isoFormat() as TDateISO;
          setTimeout(() => {
            setDataByRange(dataByTimeRangeProps(fromDate, toDate));
          });
        }
      }, 4);
    }
    if (gridRef.current && gridRef.current.api) {
      const { api } = gridRef.current;
      api.refreshCells();
      api.refreshHeader();
      api.sizeColumnsToFit();
    }
  }, [
    operatingPlans,
    timeZone,
    selectedPlanTypeTab.key,
    selectedRangeFilterTab.key,
    fromRangeDateTime,
    toRangeDateTime,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.setPinnedTopRowData(imbalanceGridData);
      }
    }, 0);
  }, [imbalanceGridData]);

  useEffect(() => {
    if (document.readyState === 'complete') {
      if (logEvent) {
        logEvent({
          eventTime: new Date(),
          eventName: Event.VIEWEDOPERATINGPLANPAGE,
          category: Category.OPERATING_PLAN_PAGE,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (logEvent)
      logEvent({
        eventTime: new Date(),
        eventName:
          selectedGridViewType === EDataGridView.Resources
            ? Event.GROUPEDBYRESOURCE
            : Event.GROUPEDBYVALUE,
        category: Category.OPERATING_PLAN_PAGE,
      });
  }, [selectedGridViewType]);

  const startSyncDate = useRef(Date.now());
  const endSyncDate = useRef(Date.now());
  const eventCounter = useRef(0);

  const getSyncDiffInSeconds = () => {
    return Math.ceil((endSyncDate.current - startSyncDate.current) / 1000);
  };

  useEffect(() => {
    if (data) {
      const { timestamp } = data as AutoTraderEvent;

      if (timestamp && isEventListenerRunning.current === false) {
        startSyncDate.current = timestamp;
        isEventListenerRunning.current = true;
      } else {
        endSyncDate.current = timestamp;

        const dateDiff = getSyncDiffInSeconds();
        if (dateDiff && dateDiff > 60) {
          startSyncDate.current = timestamp;
          updateOperatingPlan(true);
        } else {
          eventCounter.current = dateDiff
            ? dateDiff + eventCounter.current
            : eventCounter.current;
        }
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        isEventListenerRunning.current === true &&
        eventCounter.current <= 1891
      ) {
        eventCounter.current = 1000 + eventCounter.current;
        isEventListenerRunning.current = false;
        updateOperatingPlan();
      }
    }, 90000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getSseSource();
  }, []);

  useEffect(() => {
    if (
      selectedDataSource === DATA_SOURCE.Formulated &&
      formulatedStatus === FULFILLED
    ) {
      const formulatedPlan = selectPlan(formulated);
      setOperatingPlansStatus(EAPIState.Fulfilled);
      setOperatingPlans(formulatedPlan);
    } else if (
      selectedDataSource === DATA_SOURCE.Effective &&
      effectiveStatus === FULFILLED
    ) {
      const effectivePlan = selectPlan(effective);
      setOperatingPlansStatus(EAPIState.Fulfilled);
      setOperatingPlans(effectivePlan);
    } else if (
      selectedDataSource === DATA_SOURCE.Optimized &&
      optimizedStatus === FULFILLED
    ) {
      const optimizedPlan = selectPlan(optimized);
      setOperatingPlansStatus(EAPIState.Fulfilled);
      setOperatingPlans(optimizedPlan);
    } else if (
      selectedDataSource === DATA_SOURCE.Original &&
      originalStatus === FULFILLED
    ) {
      const originalPlan = selectPlan(original);
      setOperatingPlansStatus(EAPIState.Fulfilled);
      setOperatingPlans(originalPlan);
    }
  }, [
    selectedDataSource,
    originalStatus,
    optimizedStatus,
    effectiveStatus,
    formulatedStatus,
  ]);

  const getSseSource = async () => {
    const sseUri = await AutoTraderApi.getAutoTraderSSEURL();
    setSourceUri(sseUri);
  };

  return (
    <>
      <M15Header />
      <M15GridContainer
        ref={gridRef}
        data={gridData}
        isLoading={operatingPlansStatus === EAPIState.Pending}
        autoGroupColumnDef={autoGroupColumnDef}
        columnTypes={columnTypes}
        columnDefs={dynamicColumnsDefHandler}
        isGroupOpenByDefault={isGroupOpenByDefault}
        groupDisplayType={groupDisplayType}
        groupRowRendererParams={groupRowRendererParams}
        suppressScrollOnNewData
      />
    </>
  );
};

export default OperatingPlanM15;
