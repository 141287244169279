import { SupportItemType } from 'pages/admin/Support';
import styles from './SupportCardItem.module.scss';
import { Link } from '@mui/material';

type Props = {
  item: SupportItemType;
};

function SupportCardItem({ item }: Props) {
  const {
    supportCardItem,
    supportCardItemIcon,
    supportCardItemContent,
    supportCardItemTitle,
    supportCardItemDescription,
    supportCardItemButton,
  } = styles;
  return (
    <div className={supportCardItem}>
      <item.Icon className={supportCardItemIcon}></item.Icon>
      <div className={supportCardItemContent}>
        <span className={supportCardItemTitle}>{item.title}</span>
        <span className={supportCardItemDescription}>{item.description}</span>
      </div>
      <Link
        className={supportCardItemButton}
        href={item.link.path}
        data-testid={`support_visit_${item.title
          .toLowerCase()
          .replace(' ', '_')}`}
        target='_blank'
      >
        {item.link.name}
      </Link>
    </div>
  );
}

export default SupportCardItem;
