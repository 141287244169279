import i18n from 'i18next';
import { isAbsent } from './utils';

const arrayField = <T>(value: T, error: string | null) => {
  return {
    value,
    error,
    required: function required(errorMessage: string | null = null) {
      if (this.error) return this;
      if (isAbsent(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.required_field');
      }
      return this;
    },
    max: function max(maxLength: number, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && Array(this.value).length > maxLength) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.max_length', { length: maxLength });
      }
      return this;
    },
    min: function min(minLength: number, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && Array(this.value).length < minLength) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.min_length', { length: minLength });
      }
      return this;
    },
    custom: function custom(
      fn: Function,
      errorMessage: string | null = null,
      ...args: []
    ) {
      if (this.error) return this;
      if (!fn(this.value, ...args)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.field_no_valid');
      }
      return this;
    },
    validate: function validate() {
      return this.error;
    },
  };
};

export default arrayField;
