import { sideMenuType } from 'components/admin/Sidebar/menu.config';
import Sidebar from 'components/admin/Sidebar/Sidebar';
import TopBar from 'components/admin/TopBar/TopBar';
import React from 'react';
import styles from './Layout.module.scss';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';
type Props = {
  children?: React.ReactNode;
  sideMenu: sideMenuType[];
};
const Layout = ({ children, sideMenu }: Props) => {
  const sideBarCollapsed = useSelector(
    (store: AppStore) => store.miscellaneous.navigation.sideBarCollapsed
  );
  const { layout, header, aside, collapsed, main, full } = styles;
  return (
    <div className={layout}>
      <header className={`${header} ${sideBarCollapsed ? full : ''}`}>
        <TopBar />
      </header>
      <aside className={`${aside} ${sideBarCollapsed ? collapsed : ''}`}>
        <Sidebar sideMenu={sideMenu} />
      </aside>
      <main className={`${main} ${sideBarCollapsed ? full : ''}`}>
        {children}
      </main>
    </div>
  );
};

export default Layout;
