import { memo, useCallback, useEffect, useState } from 'react';
import { isDate, isEmpty } from 'lodash';
import {
  GridApi,
  GridReadyEvent,
  RowEditingStoppedEvent,
  RowEditingStartedEvent,
  CellEvent,
} from 'ag-grid-community';
import { useFormik } from 'formik';
import { VariantType, useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import * as S from '../../OperatingPlan/OperatingPlan.styles';
import styles from '../../ResourcesList/ResourcesList.module.scss';

import { useAppContext } from 'App/AppProvider';

import { IAdditions, ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import {
  IOperatingPlanOverrides,
  IOperatingPlanOverridesValidation,
} from 'interfaces/resource';
import useQueue, { CREATE, DELETE, UPDATE } from 'hooks/useQueue';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import CellRender from 'components/molecules/CellRender/CellRender';

import {
  columnsDef,
  initialNewRowValue,
  NON_GENUNIT_FIELDS,
  operatingPlanOverridesSchema,
} from './helpers';
import CellEditor from 'components/molecules/CellEditor/CellEditor';

import { IIndexable } from 'interfaces/general';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import {
  GENERATING_UNIT,
  LOAD,
  POWER_EXPORT,
  POWER_IMPORT,
} from 'components/admin/AddResources/constant';
import ResourcesTab from 'components/admin/ResourcesList/ResourcesTab';
import FolioSkeleton from 'components/admin/GridSkeleton/GridSkeleton';
import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';

import { useDispatch, useSelector } from 'react-redux';
import RootStore, { AppDispatch, AppStore } from 'redux/store';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import {
  fetchResourcesFromParticipant,
  selectResourcesEntitiesByUnit,
} from 'redux/states/resources.state';
import {
  fetchOperatingPlanOverrides,
  overridesDelete,
  overridesUpsert,
  selectOverridesByUnit,
} from 'redux/states/overrides.state';

interface IOperatingPlanOverridesQueue {
  data: IOperatingPlanOverrides;
  status: string;
}

type TPushNotification = (variant: VariantType, message: string) => void;

const OperatingPlanOverrides = () => {
  const { resourcesPageResources, resourcesWrapperResources } = styles;
  const [gridApi, setGridApi] = useState<GridApi>();
  const { enqueueSnackbar } = useSnackbar();
  const [editingMode, setEditingMode] = useState<boolean>(false);
  const [colDef, setColDef] = useState<any[]>([]);
  const [oldEditing, setOldEditing] = useState<IOperatingPlanOverrides>();
  const [rowData, setRowData] = useState<IOperatingPlanOverrides[] | undefined>(
    undefined
  );
  const [additions, setAdditions] = useState<IAdditions>({
    key: '',
    additionsIds: [],
  });
  const [newRowValue, setNewRowValue] = useState(initialNewRowValue);
  const [selectedTab, setSelectedTab] = useState({
    key: GENERATING_UNIT,
    unit: 'generatingUnitOperatingPlans',
    identifier: 'RESOURCE_GEN_UNIT',
    description: 'Generating Unit',
    endpoint: 'gen-units',
  });
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { resourcesStatus } = useSelector((store: AppStore) => store.resource);
  const { status: overridesStatus } = useSelector(
    (store: AppStore) => store.overrides
  );
  const dispatch = useDispatch<AppDispatch>();

  const { logEvent } = useAppContext();

  const handleChange = (
    event: React.SyntheticEvent | undefined,
    newValue: string
  ) => {
    switch (newValue) {
      case GENERATING_UNIT: {
        setSelectedTab({
          key: GENERATING_UNIT,
          unit: 'generatingUnitOperatingPlans',
          identifier: 'RESOURCE_GEN_UNIT',
          description: 'Generating Unit',
          endpoint: 'gen-units',
        });

        break;
      }
      case LOAD: {
        setSelectedTab({
          key: LOAD,
          unit: 'loadOperatingPlans',
          identifier: 'RESOURCE_LOAD',
          description: 'Load',
          endpoint: 'loads',
        });

        break;
      }
      case POWER_IMPORT: {
        setSelectedTab({
          key: POWER_IMPORT,
          unit: 'powerImportOperatingPlans',
          identifier: 'RESOURCE_POWER_IMPORT',
          description: 'Power Import',
          endpoint: 'power-imports',
        });

        break;
      }
      case POWER_EXPORT: {
        setSelectedTab({
          key: POWER_EXPORT,
          unit: 'powerExportOperatingPlans',
          identifier: 'RESOURCE_POWER_EXPORT',
          description: 'Power Export',
          endpoint: 'power-exports',
        });

        break;
      }

      default:
        break;
    }
  };

  const enableValid = (instances: ICellEditorImperativeHandle[]) => {
    if (selectedTab.key !== GENERATING_UNIT) {
      instances.forEach((instance) => {
        if (NON_GENUNIT_FIELDS.includes(instance.columnField())) {
          if (typeof instance.setEditable === 'function') {
            instance.setEditable(true);
          }
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: initialNewRowValue,
    onSubmit: () => {},
    validate: (values: IOperatingPlanOverridesValidation) => {
      let errors = operatingPlanOverridesSchema(values);

      if (gridApi) {
        const instances =
          gridApi.getCellEditorInstances() as ICellEditorImperativeHandle[];

        if (instances.length > 0) {
          instances.forEach((instance) => {
            if (typeof instance.validateField === 'function') {
              const field = instance.columnField();
              const errored = new Map(Object.entries(errors)).get(field)?.error;
              instance.validateField(errored);
            }
          });
        }

        enableValid(instances);
      }
      return errors;
    },
  });
  const queueHook = useQueue<IOperatingPlanOverrides, 'resourceIdentifier'>(
    'resourceIdentifier'
  );
  const handleCreateUpdateOperatingPlanOverrides = useCallback(
    (data: any, type: string) => {
      AutoTraderApi.createUpdateOperatingPlanOverrides.bind(AutoTraderApi);
      return AutoTraderApi.createUpdateOperatingPlanOverrides(data, type);
    },
    []
  );
  const handleDeleteOperatingPlanOverrides = useCallback(
    (overrideId: string) => {
      AutoTraderApi.deleteOperatingPlanOverrides.bind(AutoTraderApi);
      return AutoTraderApi.deleteOperatingPlanOverrides(overrideId);
    },
    []
  );

  useEffect(() => {
    const pickSelectedEntities = selectResourcesEntitiesByUnit(
      RootStore.getState(),
      selectedTab.key
    );

    if (overridesStatus === FULFILLED) {
      const pickOverrides = selectOverridesByUnit(
        RootStore.getState().overrides,
        selectedTab.key
      );
      setRowData(pickOverrides);
    }

    if (resourcesStatus === FULFILLED && !isEmpty(pickSelectedEntities)) {
      const resourcesIdentifiers = Object.keys(pickSelectedEntities);
      const sortResources = (a: string, b: string) => a.localeCompare(b);

      const prevColDef = [...columnsDef];

      const newColDef: any = prevColDef.map((currColDef) => {
        if (
          selectedTab.key !== GENERATING_UNIT &&
          NON_GENUNIT_FIELDS.includes(currColDef.field)
        ) {
          return {
            ...currColDef,
            hide: true,
          };
        }
        return { ...currColDef, hide: currColDef.field === 'id' };
      });

      setColDef(() => {
        if (newColDef[0].cellEditorParams?.options) {
          const resources = [
            ...resourcesIdentifiers
              .sort(sortResources)
              .map((resourceIdentifier: string) => ({
                value: resourceIdentifier,
                label: resourceIdentifier,
              })),
          ];
          newColDef[0].cellEditorParams.options = resources;
          setNewRowValue((oldRowValue) => {
            oldRowValue.resourceIdentifier = resources[0] as any;
            return oldRowValue;
          });
        }
        return newColDef;
      });
    }
  }, [resourcesStatus, overridesStatus, selectedTab.key]);

  useEffect(() => {
    if (gridApi && colDef) gridApi.setColumnDefs(colDef);
  }, [colDef, gridApi]);

  useEffect(() => {
    if (selectedParticipant?.id) {
      if (overridesStatus === IDLE) {
        dispatch(fetchOperatingPlanOverrides(selectedParticipant?.id));
        if (resourcesStatus === IDLE) {
          dispatch(fetchResourcesFromParticipant(selectedParticipant?.id));
        }
      }
      if (overridesStatus === FULFILLED) {
        dispatch(fetchOperatingPlanOverrides(selectedParticipant?.id));
        dispatch(fetchResourcesFromParticipant(selectedParticipant?.id));
      }
    }
    // eslint-disable-next-line
  }, [selectedParticipant?.id, dispatch]);

  useEffect(() => {
    if (selectedTab.unit) {
      if (overridesStatus === FULFILLED) {
        const pickOverrides = selectOverridesByUnit(
          RootStore.getState().overrides,
          selectedTab.key
        );
        setRowData(pickOverrides);
      }
    }
    // eslint-disable-next-line
  }, [selectedTab.key, selectedTimeZone]);

  const onGridReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(65);
    gridEvent.api.sizeColumnsToFit();
    return setGridApi(gridEvent.api);
  };

  const onRowEditingStartedHandler = (e: RowEditingStartedEvent) => {
    const instances =
      gridApi?.getCellEditorInstances() as ICellEditorImperativeHandle[];
    enableValid(instances);
    setOldEditing({ ...e.data });
  };

  const onRowEditingStoppedHandler = (e: RowEditingStoppedEvent) => {
    const { isInQueue, update, setInQueue } = queueHook;
    // post data to API
    const data = e.data as IOperatingPlanOverrides;
    const key = data.resourceIdentifier;

    if (JSON.stringify(oldEditing) !== JSON.stringify(data)) {
      if (!data.newRecord) {
        // UPDATE
        if (isInQueue(key)) {
          // IF is already on the queue
          update(key, data, UPDATE);
        } else {
          setInQueue(data, UPDATE);
        }
      } else {
        if (isInQueue(key)) {
          // UPDATE CREATED
          update(key, data, CREATE);
        } else {
          // CREATE
          setInQueue(data, CREATE);
        }
      }
    }
  };

  const toggleEditing = useCallback(
    (enable: boolean) => {
      if (gridApi && !enable) {
        gridApi.stopEditing();
        // gridApi.setRowData([]);
      }
      setEditingMode(enable);
    },
    [gridApi]
  );

  const addRowOnClickHandler = useCallback(() => {
    if (gridApi) {
      const rowValues = { ...newRowValue };
      const {
        id: { value },
      } = rowValues;
      const addition = {
        key: 'id',
        additionsIds: [...additions.additionsIds, value],
      };
      setAdditions(addition);

      gridApi.stopEditing();
      gridApi?.applyTransaction({
        add: [rowValues],
        addIndex: 0,
      });

      toggleEditing(true);
      gridApi?.startEditingCell({
        rowIndex: 0,
        colKey: 'resourceIdentifier',
      });
    }
  }, [gridApi, newRowValue, toggleEditing, additions.additionsIds]);

  const removeRowOnClickHandler = async (cellEvent: CellEvent) => {
    const { updateStatus, isInQueue, setInQueue, getQueued, remove } =
      cellEvent.context.queueHook;
    const key = cellEvent.data.resourceIdentifier;

    if (isInQueue(key)) {
      if (getQueued(key).status === CREATE) {
        remove(key);
      } else {
        updateStatus(key, DELETE);
      }
    } else {
      setInQueue({ ...cellEvent.data }, DELETE);
    }
    cellEvent.api.applyTransaction({ remove: [cellEvent.data] });
  };

  useEffect(() => {
    if (queueHook.getLast()?.status === DELETE) {
      saveChangesOnClickHandler();
    }
    // eslint-disable-next-line
  }, [queueHook.getQueue().length]);

  const cleanOverridesQueue = useCallback(() => {
    const { cleanQueue } = queueHook;
    cleanQueue();
  }, [queueHook]);

  const resetGridOnClickHandler = useCallback(() => {
    toggleEditing(false);
    cleanOverridesQueue();
    setAdditions({
      key: '',
      additionsIds: [],
    });
    if (!editingMode && selectedParticipant?.id) {
      setRowData([]);
      dispatch(fetchOperatingPlanOverrides(selectedParticipant?.id));
      dispatch(fetchResourcesFromParticipant(selectedParticipant?.id));
      const pickOverrides = selectOverridesByUnit(
        RootStore.getState().overrides,
        selectedTab.key
      );
      setRowData(pickOverrides);
    }
    // eslint-disable-next-line
  }, [gridApi, cleanOverridesQueue, selectedParticipant?.id]);

  const onClickEnableEditing = useCallback(() => {
    toggleEditing(true);
  }, [toggleEditing]);

  const cleanseEachOverride = (
    overrideQueueData: IOperatingPlanOverridesQueue
  ) => {
    const {
      data: { newRecord, modifiedBy, modifiedOn, ...override },
    } = overrideQueueData as IIndexable;

    const cleanOverride = Object.fromEntries(
      Object.entries(override).filter(([_, v]) => {
        return !!v && (isDate(v) || typeof v !== 'object');
      })
    );

    const cleanseEachEntry = (acc: Record<string, string>, item: string) => {
      if (item === 'startAt' || item === 'stopAt') {
        if (typeof override?.[item] === 'string') {
          acc[item] = override?.[item].replace('+00:00', 'Z');
        } else {
          acc[item] = override?.[item].toISOString().replace('+00:00', 'Z');
        }
        return acc;
      }
      acc[item] = override?.[item];
      return acc;
    };

    const fullData = Object.keys(cleanOverride).reduce(cleanseEachEntry, {});
    return fullData;
  };

  const saveCreatedOverrides = async (
    overridesToSave: IOperatingPlanOverrides[] | IIndexable,
    pushNotification: TPushNotification
  ) => {
    try {
      const requests = await Promise.all(
        overridesToSave.map((data: any) =>
          handleCreateUpdateOperatingPlanOverrides(data, selectedTab.endpoint)
        )
      );
      let success = 0;

      requests.forEach((response) => {
        if (/20[0-9]/.test(response.status)) {
          const { data } = response;
          dispatch(overridesUpsert({ data, type: selectedTab.key }));
          success++;
          if (logEvent) {
            logEvent({
              eventTime: new Date(),
              eventName: Event.ADDEDOVERRIDE,
              payload: { ...response.data },
              timeToComplete: response.duration,
              category: Category.OVERRIDE_PAGE,
              requestStatus: response.status,
            });
          }
        } else {
          pushNotification(
            'error',
            `An error has occurred on ${
              JSON.parse(response.config.data).resourceIdentifier
            } ${response.data.error.message}`
          );
        }
        pushNotification('success', `${success} Rows was created`);
      });
    } catch (error) {
      const { response, config } = error as AxiosError;
      if (response)
        pushNotification(
          'error',
          `An error has occurred on ${
            JSON.parse(config.data).resourceIdentifier
          } ${response.data.error.message}`
        );
    } finally {
      resetGridOnClickHandler();
    }
  };

  const createdOverridesHandler = async (
    createdOverrides: IOperatingPlanOverridesQueue[],
    pushNotification: TPushNotification
  ) => {
    if (!createdOverrides.length) return false;
    const overridesToSave = createdOverrides.map(cleanseEachOverride);
    return saveCreatedOverrides(overridesToSave, pushNotification);
  };

  const updatedOverridesHandler = async (
    updatedOverrides: IOperatingPlanOverridesQueue[],
    pushNotification: TPushNotification
  ) => {
    if (!updatedOverrides.length) return false;

    const overridesToUpdate = updatedOverrides.map(cleanseEachOverride);

    let success = 0;

    try {
      const requests = await Promise.all(
        overridesToUpdate.map((data: any) =>
          handleCreateUpdateOperatingPlanOverrides(data, selectedTab.endpoint)
        )
      );
      requests.forEach((response: any) => {
        if (response.status === 200 || response.status === 201) {
          const { data } = response;
          dispatch(overridesUpsert({ data, type: selectedTab.key }));
          success++;
          if (logEvent) {
            logEvent({
              eventTime: new Date(),
              eventName: Event.MODIFIEDOVERRIDE,
              payload: { ...response.data },
              timeToComplete: response.duration,
              category: Category.OVERRIDE_PAGE,
              requestStatus: response.status,
            });
          }
        } else {
          pushNotification(
            'error',
            `An error has occurred on ${
              JSON.parse(response.config.data).resourceIdentifier
            } ${response.data.error.message}`
          );
        }
        pushNotification('info', `${success} Rows was updated`);
      });
    } catch (error) {
      const err = error as AxiosError;
      pushNotification('error', `${err.response?.data.error.message}`);
    } finally {
      resetGridOnClickHandler();
    }
  };

  const deletedOverridesHandler = async (
    deletedOverrides: IOperatingPlanOverridesQueue[],
    pushNotification: TPushNotification
  ) => {
    if (!deletedOverrides.length) return false;

    const overridesToDelete = deletedOverrides.map(cleanseEachOverride);

    let success = 0;

    try {
      const requests = await Promise.all(
        overridesToDelete.map((data: any) =>
          handleDeleteOperatingPlanOverrides(data.id)
        )
      );

      overridesToDelete.forEach((data: any) => {
        dispatch(
          overridesDelete({ overrideId: data.id, type: selectedTab.key })
        );
      });

      requests.forEach((response: any) => {
        if (response.status === 200 || response.status === 201) {
          const currentDeleted = overridesToDelete[success];
          success++;
          if (logEvent) {
            logEvent({
              eventTime: new Date(),
              eventName: Event.REMOVEDOVERRIDE,
              payload: { ...currentDeleted },
              timeToComplete: response.duration,
              category: Category.OVERRIDE_PAGE,
              requestStatus: response.status,
            });
          }
        } else {
          pushNotification(
            'error',
            `An error has occurred on ${
              JSON.parse(response.config.data).resourceIdentifier
            } ${response.data.error.message}`
          );
        }
      });
      pushNotification('info', `${success} Rows was deleted`);
    } catch (error) {
      const err = error as AxiosError;
      pushNotification('error', `${err.response?.data.error.message}`);
    } finally {
      resetGridOnClickHandler();
    }
  };

  const saveChangesOnClickHandler = () => {
    const { getQueue } = queueHook;
    const overridesQueue = getQueue();

    const pushNotification = (variant: VariantType, message: string) => {
      enqueueSnackbar(message, { variant });
    };

    const overridesActions = overridesQueue.reduce(
      (
        acc: Record<string, IOperatingPlanOverridesQueue[]>,
        override: IOperatingPlanOverridesQueue
      ) => {
        if (override.status === CREATE) acc.create.push(override);
        if (override.status === UPDATE) acc.update.push(override);
        if (override.status === DELETE) acc.delete.push(override);
        return acc;
      },
      { create: [], update: [], delete: [] }
    );

    createdOverridesHandler(overridesActions.create, pushNotification);
    updatedOverridesHandler(overridesActions.update, pushNotification);
    deletedOverridesHandler(overridesActions.delete, pushNotification);
  };

  useEffect(() => {
    if (logEvent && document.readyState === 'complete') {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDOVERRIDESPAGE,
        category: Category.OVERRIDE_PAGE,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={resourcesPageResources}>
      <div className={resourcesWrapperResources}>
        <S.PageTitle data-testid='title'>Overrides</S.PageTitle>
        <FolioSkeleton
          fullHeight
          canSave
          additions={additions}
          context={{
            formik: formik,
            queueHook: queueHook,
          }}
          toolExtra={
            <ResourcesTab tab={selectedTab.key} handleChange={handleChange} />
          }
          canReset
          canAdd={selectedParticipant !== undefined}
          onRowEditingStopped={onRowEditingStoppedHandler}
          onRowEditingStarted={onRowEditingStartedHandler}
          onClickSaveButton={saveChangesOnClickHandler}
          onClickAddRow={addRowOnClickHandler}
          onClickReset={resetGridOnClickHandler}
          onClickEnableEditing={onClickEnableEditing}
          onClickRemoveButton={removeRowOnClickHandler}
          defaultDefs={{
            editable: true,
            minWidth: 90,
            flex: 1,
            suppressMenu: true,
            cellRenderer: CellRender,
            cellEditor: CellEditor,
          }}
          loading={overridesStatus === LOADING}
          onGridReady={onGridReadyHandler}
          editMode={editingMode}
          components={{
            CellRender: CellRender,
          }}
          columnDefs={colDef}
          data={rowData}
          pagination={true}
        />
      </div>
    </div>
  );
};

export default memo(OperatingPlanOverrides);
