import {
  TextField as TextFieldMUI,
  TextFieldProps,
  MenuItem,
} from '@mui/material';

import styles from './TextField.module.scss';

interface IOption {
  value: string;
  label: string;
}

type TextFieldTypePCI = TextFieldProps & { options?: IOption[] | undefined };

const TextField = (props: TextFieldTypePCI) => {
  const { textFieldMUI } = styles;
  const { label, options, ...others } = props;
  return (
    <div style={{ width: '100%' }}>
      {/* <h5 className='inputLabel'>{label}</h5> */}
      {options && options.length === 0 ? (
        <TextFieldMUI data-testid='textfield' {...others} />
      ) : (
        <TextFieldMUI
          required
          className={textFieldMUI}
          data-testid='textfield'
          {...others}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextFieldMUI>
      )}
    </div>
  );
};

export default TextField;
