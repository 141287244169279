import styled from 'styled-components';

interface IStyledCellEditorProps {
  cellHeight: string;
}

export const DateEditor = styled.div<IStyledCellEditorProps>`
  position: relative;
  height: 100%;

  /* 	&::-webkit-calendar-picker-indicator {

	} */

  .MuiFormControl-root {
    border-radius: 0;
    line-height: unset;
    height: 100%;
  }

  .MuiOutlinedInput-root {
    height: 100%;
    display: flex;
  }

  .MuiInputAdornment-root {
    margin-left: 0;
  }

  .MuiFormHelperText-root {
    margin-top: 0;
  }

  input {
    box-sizing: border-box;
    padding: 0 0 0 14px;
    height: auto;
  }
`;
