import { useCallback, useEffect, forwardRef, useMemo, useState } from 'react';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { GridOptions, GridApi } from 'ag-grid-enterprise';
import { GridReadyEvent } from 'ag-grid-community';
import 'ag-grid-enterprise';

import { IIndexable } from 'interfaces/general';
import { IDataGridColumn } from 'interfaces/dataGrid';

import { DEFAULT_DATA_GRID_ROW_HEIGHT } from './constants';

import './ag-grid-theme.scss';

import * as S from './DataGrid.styles';
import { CircularProgress } from '@mui/material';

interface IDataGridData extends IIndexable {}

export interface IDataGrid extends AgGridReactProps {
  children?: JSX.Element | JSX.Element[];
  additionalClass?: string;
  columnDefs?: IDataGridColumn[];
  chartThemes?: string[];
  data?: IDataGridData[] | undefined;
  isLoading?: boolean;
  ref?: any;
}

const DataGrid = forwardRef<AgGridReact, IDataGrid>(
  (
    {
      additionalClass = '',
      animateRows = true,
      chartThemeOverrides,
      chartThemes,
      children,
      className = 'pci-grid',
      columnDefs,
      columnTypes,
      components,
      context,
      createChartContainer,
      data,
      defaultColDef,
      defaultColGroupDef,
      domLayout,
      editType,
      enableCharts,
      enableRangeSelection,
      getChartToolbarItems,
      getRowNodeId,
      headerHeight,
      isLoading,
      loadingOverlayComponent,
      noRowsOverlayComponent,
      onCellValueChanged,
      onFirstDataRendered,
      onGridReady,
      onRowDoubleClicked,
      onRowEditingStarted,
      onRowEditingStopped,
      onRowValueChanged,
      onSelectionChanged,
      popupParent,
      rowHeight = DEFAULT_DATA_GRID_ROW_HEIGHT,
      rowModelType,
      rowSelection,
      sideBar,
      singleClickEdit,
      statusBar,
      suppressClickEdit,
      suppressDragLeaveHidesColumns,
      onCellEditingStarted,
      onCellEditingStopped,
      pagination,
      overlayNoRowsTemplate,
      paginationAutoPageSize,
      rowClassRules,
      ...props
    },
    ref
  ): JSX.Element => {
    const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined);

    const LoadingOverlayComponent = useCallback(
      () => (
        <S.LoaderWrapper>
          <CircularProgress />
        </S.LoaderWrapper>
      ),
      []
    );

    useEffect(() => {
      setTimeout(() => {
        if (gridApi !== undefined && isLoading) {
          gridApi.showLoadingOverlay();
          return;
        }

        if (!isLoading) {
          gridApi?.hideOverlay();
          return;
        }

        if (data === undefined || !data || !data.length || data.length < 1) {
          gridApi?.showNoRowsOverlay();
          return;
        } else {
          gridApi?.hideOverlay();
          return;
        }
      }, 0);
    }, [gridApi, data, isLoading]);

    const handleGridReady = useCallback(
      (gridReadyEvent: GridReadyEvent) => {
        if (onGridReady) onGridReady(gridReadyEvent);
        return setGridApi(gridReadyEvent.api);
      },
      [onGridReady]
    );

    const gridOptions: GridOptions = useMemo(
      (): GridOptions => ({
        columnHoverHighlight: false,
        headerHeight,
        rowHeight,
        onRowDoubleClicked,
      }),

      [headerHeight, onRowDoubleClicked, rowHeight]
    );

    return (
      <AgGridReact
        debounceVerticalScrollbar={true}
        animateRows={animateRows}
        chartThemeOverrides={chartThemeOverrides}
        chartThemes={chartThemes}
        className={`ag-theme-alpine ${className} ${additionalClass}`.trim()}
        columnDefs={columnDefs}
        columnTypes={columnTypes}
        components={components}
        context={context}
        createChartContainer={createChartContainer}
        defaultColDef={{
          ...defaultColDef,
          cellClass: 'ag-cell-aligned-center',
          headerClass: 'ag-header-cell-aligned-center',
        }}
        defaultColGroupDef={defaultColGroupDef}
        domLayout={domLayout}
        editType={editType}
        enableCharts={enableCharts}
        enableRangeSelection={enableRangeSelection}
        getChartToolbarItems={getChartToolbarItems}
        getRowNodeId={getRowNodeId}
        gridOptions={gridOptions}
        loadingOverlayComponent={
          loadingOverlayComponent || LoadingOverlayComponent
        }
        onCellValueChanged={onCellValueChanged}
        onFirstDataRendered={onFirstDataRendered}
        onCellEditingStarted={onCellEditingStarted}
        onCellEditingStopped={onCellEditingStopped}
        onGridReady={handleGridReady}
        onRowEditingStarted={onRowEditingStarted}
        onRowEditingStopped={onRowEditingStopped}
        onRowValueChanged={onRowValueChanged}
        onSelectionChanged={onSelectionChanged}
        popupParent={popupParent}
        ref={ref}
        rowData={data}
        rowModelType={rowModelType}
        rowSelection={rowSelection}
        sideBar={sideBar}
        singleClickEdit={singleClickEdit}
        statusBar={statusBar}
        suppressClickEdit={suppressClickEdit}
        suppressDragLeaveHidesColumns={suppressDragLeaveHidesColumns}
        pagination={pagination}
        paginationAutoPageSize={paginationAutoPageSize}
        overlayNoRowsTemplate={overlayNoRowsTemplate}
        rowClassRules={rowClassRules}
        defaultExcelExportParams={{ allColumns: true }}
        {...props}
      >
        {children}
      </AgGridReact>
    );
  }
);

export default DataGrid;
