import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import * as S from './SelectEditor.styles';
import { ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import TextField from 'components/admin/TextField';

type TRef = {};

type TProps = {
  [field: string]: any;
};

const SelectEditor = React.forwardRef<TRef, TProps>((props, ref) => {
  const {
    context: { formik },
    eGridCell: { offsetHeight },
    options,
  } = props;

  const [value, setValue] = useState(props.value);
  const [field, setField] = useState(props.colDef.field);
  const [error, setError] = useState<string | null>();
  const [editable, setEditable] = useState<boolean>(false);

  const refInput = useRef<React.RefObject<HTMLInputElement>>(null);

  useEffect(() => {
    if (typeof props.value === 'string') {
      setValue(props.value);
    }

    if (typeof props.value === 'object') {
      setValue(props.value.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.current?.focus();
    }
    setField(props.colDef.field);
  }, [props.colDef.field]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, (): ICellEditorImperativeHandle => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any cell that has an error
        return !!error;
      },

      columnField: () => field,
      validateField: (error) => setError(error),
      setEditable: (editable) => setEditable(editable),
    };
  });

  return (
    <S.SelectEditor cellHeight={offsetHeight}>
      <TextField
        id='grid-selector'
        // error={
        //   new Map(Object.entries(formik.touched)).get(key) &&
        //   formik.errors &&
        //   new Map(Object.entries(formik.errors)).get(key)?.error !==
        //     null
        // }
        // onClick={(e) =>
        //   formik.setTouched(
        //     handleTouched(
        //       e.target as HTMLInputElement,
        //       formik.touched,
        //     ),
        //   )
        // }
        select
        // key={key}
        // id={key}
        // name={key}
        disabled={editable}
        options={options}
        value={value?.value || value}
        onChange={(e) => {
          const fieldName = props.colDef.field;
          const fieldValues = formik.values[props.colDef.field];
          if (fieldName) {
            formik.setFieldValue(fieldName, {
              ...fieldValues,
              value: e.target.value,
            });
          }
          setValue(e.target.value);
        }}
      />
    </S.SelectEditor>
  );
});

export default SelectEditor;
