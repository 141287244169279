import React from 'react';
import { AddUserContextProvider } from './AddUserProvider';
import { CREATING } from './constant';
import CreateTenants from './Forms/CreateUser/CreateUser';
const AddUsers = () => {
  return (
    <AddUserContextProvider>
      <CreateTenants mode={CREATING} />
    </AddUserContextProvider>
  );
};

export default AddUsers;
