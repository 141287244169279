import { useEffect, useState } from 'react';

import { useInterval } from 'hooks/useInterval';

interface ITimer {
  seconds?: number;
  infinite: boolean;
}

const DEFAULT_TIMER_VALUE = 15 * 60;

const useTimer = ({ seconds = DEFAULT_TIMER_VALUE, infinite }: ITimer) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTimer(seconds);
  }, [seconds]);

  useInterval(() => {
    if (seconds > 0) {
      setTimer(timer - 1);
      if (infinite && timer === 0) setTimer(seconds);
    }
    if (infinite === false) setTimer(seconds);
  }, 1000);

  return timer;
};

export default useTimer;
