import AddResources from 'components/admin/AddResources';
import PageInfoCard from 'components/admin/PageInfoCard/PageInfoCard';
import MarketParticipantPage from 'components/admin/MarketParticipant/MarketParticipantPage';
import ResourcesList from 'components/admin/ResourcesList/ResourcesList';
import {
  SETUP_PARTICIPANTS,
  SETUP_RESOURCES_ADD_URL,
  SETUP_RESOURCES_URL,
  SETUP_SOURCESINKS_ENDPOINT,
  SETUP_METERS_URL,
} from 'components/admin/Sidebar/menu.config';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from 'components/admin/NotFound/Index';
import MetersPage from 'components/admin/Meters/MetersPage';

function index() {
  return (
    <Switch>
      <Route exact path={`*${SETUP_RESOURCES_URL}`} component={ResourcesList} />
      <Route
        exact
        path={`*${SETUP_RESOURCES_URL}${SETUP_RESOURCES_ADD_URL}`}
        component={AddResources}
      />
      <Route
        exact
        path={`*${SETUP_PARTICIPANTS}`}
        component={MarketParticipantPage}
      />
      <Route exact path={`*${SETUP_METERS_URL}`} component={MetersPage} />
      <Route exact path={`*${SETUP_SOURCESINKS_ENDPOINT}`}>
        <PageInfoCard message='Actions: Through EIR registry, pretty static data' />
      </Route>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}

export default index;
