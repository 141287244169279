import { useCallback, useEffect, useState } from 'react';
import {
  HOUR_FILTER,
  HOURS_12_FILTER,
  HOURS_24_FILTER,
  HOURS_4_FILTER,
  MONTH_FILTER,
  MONTHS_3_FILTER,
  WEEK_FILTER,
  WEEKS_2_FILTER,
  CUSTOM,
  PARTICIPANT,
  RESOURCE,
  MONTHS_12_FILTER,
  TIME_INTERVALS,
  DAYS_30_FILTER,
  ALL,
} from './constants';
import { ITimeInterval } from './types';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from 'react-router-dom';
import { IIntervalPreference } from 'api/models/User.model';

const useRangeFilterTabs = (defaultTimeInterval: ITimeInterval) => {
  const [selectedRangeFilterTab, setSelectedRangeFilterTab] = useState({
    key: defaultTimeInterval.key,
    description: defaultTimeInterval.description,
  });
  const { intervalPreferences, setPreference } = usePreferences();
  const { pathname } = useLocation();

  const handleRangeFilterChange = (
    event: React.SyntheticEvent | null,
    newValue: string
  ) => {
    const selectedRangeKey = newValue;
    if (selectedRangeKey && TIME_INTERVALS.includes(selectedRangeKey)) {
      const intervalPreference = intervalPreferences?.find(
        (interval) => interval.page === pathname
      );

      if (
        intervalPreference &&
        intervalPreference.interval !== selectedRangeKey
      ) {
        const intervalPreference: IIntervalPreference = {
          intervalType: 'BASIC',
          interval: selectedRangeKey,
          page: pathname,
        };
        const filteredIntervals = intervalPreferences?.filter(
          (interval) => interval.page !== pathname
        );
        filteredIntervals?.push(intervalPreference);
        setPreference({ intervalPreference: filteredIntervals });
      } else {
        const notDefinedUnitPreference: IIntervalPreference = {
          intervalType: 'BASIC',
          interval: selectedRangeKey,
          page: pathname,
        };
        setPreference({ intervalPreference: [notDefinedUnitPreference] });
      }
    }
    setSelectedRangeFilterTab(filterSelector(selectedRangeKey));
  };

  const filterSelector = useCallback(
    (newFilter: string) => {
      switch (newFilter) {
        case ALL.key: {
          return {
            key: ALL.key,
            description: ALL.description,
          };
        }
        case HOUR_FILTER.key: {
          return {
            key: HOUR_FILTER.key,
            description: HOUR_FILTER.description,
          };
        }
        case HOURS_4_FILTER.key: {
          return {
            key: HOURS_4_FILTER.key,
            description: HOURS_4_FILTER.description,
          };
        }
        case HOURS_12_FILTER.key: {
          return {
            key: HOURS_12_FILTER.key,
            description: HOURS_12_FILTER.description,
          };
        }
        case HOURS_24_FILTER.key: {
          return {
            key: HOURS_24_FILTER.key,
            description: HOURS_24_FILTER.description,
          };
        }
        case WEEK_FILTER.key: {
          return {
            key: WEEK_FILTER.key,
            description: WEEK_FILTER.description,
          };
        }
        case WEEKS_2_FILTER.key: {
          return {
            key: WEEKS_2_FILTER.key,
            description: WEEKS_2_FILTER.description,
          };
        }
        case MONTH_FILTER.key: {
          return {
            key: MONTH_FILTER.key,
            description: MONTH_FILTER.description,
          };
        }
        case MONTHS_3_FILTER.key: {
          return {
            key: MONTHS_3_FILTER.key,
            description: MONTHS_3_FILTER.description,
          };
        }
        case MONTHS_12_FILTER.key: {
          return {
            key: MONTHS_12_FILTER.key,
            description: MONTHS_12_FILTER.description,
          };
        }
        case DAYS_30_FILTER.key: {
          return {
            key: DAYS_30_FILTER.key,
            description: DAYS_30_FILTER.description,
          };
        }
        case CUSTOM.key: {
          return {
            key: CUSTOM.key,
            description: CUSTOM.description,
          };
        }
        case PARTICIPANT.key: {
          return {
            key: PARTICIPANT.key,
            description: PARTICIPANT.description,
          };
        }
        case RESOURCE.key: {
          return {
            key: RESOURCE.key,
            description: RESOURCE.description,
          };
        }
        default:
          return {
            key: defaultTimeInterval.key,
            description: defaultTimeInterval.description,
          };
      }
    },
    [defaultTimeInterval]
  );

  useEffect(() => {
    const intervalPreference = intervalPreferences?.find(
      (interval) => interval.page === pathname
    );
    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'BASIC'
    ) {
      setSelectedRangeFilterTab(
        filterSelector(intervalPreference.interval as string)
      );
    }
    // eslint-disable-next-line
  }, [filterSelector, pathname]);

  return {
    handleRangeFilterChange,
    selectedRangeFilterTab,
    setSelectedRangeFilterTab,
  };
};

export default useRangeFilterTabs;
