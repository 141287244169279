import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import Switch from 'components/molecules/Switch/Switch';
import './api-keys-page.scss';
import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import useTable from 'components/admin/UserTable/useTable';
import {
  ApiKeyCreatedDto,
  ApiKeyResponseDto,
} from '../../../api/models/ApiKey.model';
import AddApiKeyDialog from './AddApiKeyDialog';
import ViewApiKeyWithSecretDialog from './ViewApiKeyWithSecretDialog';
import useAsyncEffect from 'use-async-effect';
import { PAGINATION } from 'constants/general';
import { useAppContext } from 'App/AppProvider';
import { AxiosError } from 'axios';
import IError from 'interfaces/IError';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';

const ApiKeysPage: React.FC = () => {
  const { username } = useSelector((store: AppStore) => store.authentication);
  const { pushNotification } = useAppContext();
  const [addApiKeyDialogOpen, setAddApiKeyDialogOpen] =
    useState<boolean>(false);
  const [apiKeyWithSecret, setApiKeyWithSecret] =
    useState<ApiKeyCreatedDto | null>(null);
  const {
    records,
    paginatedData,
    currentPage,
    recordsPerPage,
    setRecords,
    setRecordsPerPage,
    setCurrentPage,
  } = useTable<ApiKeyResponseDto, 'apiKeyUsername'>('apiKeyUsername');

  const refreshApiKeys = async () => {
    try {
      const apiKeys = await AutoTraderApi.getApiKeys();
      setRecords(apiKeys);
    } catch (error: any) {
      console.error('Failed to load api keys', error);
    }
  };

  useAsyncEffect(refreshApiKeys, []);

  const handleChangeStatus = async (
    isChecked: boolean,
    keyName?: string | undefined
  ) => {
    const domain = username?.split('@')[1];
    const keyToUpdate = `${keyName}@${domain}`;
    try {
      if (keyToUpdate && keyName && pushNotification) {
        await AutoTraderApi.activateApiKey(keyToUpdate, isChecked);
        pushNotification(
          'info',
          `User successfully ${isChecked ? 'Enabled' : 'Disabled'}`
        );
        await refreshApiKeys();
      }
    } catch (error) {
      const { response } = error as AxiosError;
      const formatedError = response?.data as IError;
      if (formatedError.apiVersion) {
        if (pushNotification)
          pushNotification('error', `${formatedError.error.message}`);
      } else {
        if (pushNotification)
          pushNotification('error', `Error has occured updating ApiKey`);
      }
    }
  };

  return (
    <div className='api-keys-page'>
      <div className='api-keys-wrapper'>
        <TableContainer component={Paper}>
          <Toolbar className='table-toolbar'>
            <Typography
              sx={{ flex: '1 1 50%' }}
              variant='h6'
              id='tableTitle'
              component='div'
            >
              API KEYS
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                className='add-api-key-btn'
                variant='contained'
                size='medium'
                startIcon={<Add fontSize='small' />}
                onClick={() => setAddApiKeyDialogOpen(true)}
              >
                Add New
              </Button>
            </div>
          </Toolbar>
          <Table
            sx={{ minWidth: 650, tableLayout: 'fixed' }}
            aria-label='simple table'
          >
            <TableHead>
              <TableRow className='table-row-head'>
                <TableCell className='pretitle table-cell'>KEY</TableCell>
                <TableCell className='pretitle table-cell'>
                  DESCRIPTION
                </TableCell>
                <TableCell className='pretitle table-cell'>STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map(
                (
                  { apiKeyUsername, apiKeyName, description, active },
                  index
                ) => (
                  <TableRow
                    className='table-row-body'
                    key={`${index}-${apiKeyUsername}`}
                  >
                    <TableCell>{apiKeyName}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell>
                      <Switch
                        meta={apiKeyName}
                        onChangeHandler={handleChangeStatus}
                        status={active}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
          <TablePagination
            className='table-pagination'
            component='div'
            count={records.length}
            rowsPerPage={recordsPerPage}
            page={currentPage - 1}
            onPageChange={(event: unknown, newPage: number) =>
              setCurrentPage(newPage + 1)
            }
            onRowsPerPageChange={({ target }) => {
              setRecordsPerPage(parseInt(target.value));
            }}
            rowsPerPageOptions={PAGINATION}
          />
        </TableContainer>
        <AddApiKeyDialog
          open={addApiKeyDialogOpen}
          setOpen={setAddApiKeyDialogOpen}
          setApiKeyWithSecret={setApiKeyWithSecret}
        />
        <ViewApiKeyWithSecretDialog
          apiKeyWithSecret={apiKeyWithSecret}
          setApiKeyWithSecret={async (a: ApiKeyCreatedDto | null) => {
            setApiKeyWithSecret(a);
            if (a === null) {
              // when closing the secret dialog,
              // an api key must have just been added
              // clear the secret and update the table to show the
              // recently added api key by refreshing
              await refreshApiKeys();
            }
          }}
        />
      </div>
    </div>
  );
};

export default ApiKeysPage;
