import React from 'react';
import styles from './PageInfoCard.module.scss';
type Props = {
  message?: string;
};
function PageInfoCard({ message }: Props) {
  const { pageInfoCard } = styles;
  return <div className={pageInfoCard}>{message}</div>;
}

export default PageInfoCard;
