import { FormControl, MenuItem, Select, Switch } from '@mui/material';
import styled, { css } from 'styled-components';

const commonFontStyle = css`
  font-size: 12px;
  color: var(--bodyText);
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 45px;

  ${commonFontStyle}
`;

export const HeaderInformation = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1.5fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  align-items: center;

  ${commonFontStyle}
`;

export const Title = styled.span`
  display: block;
  color: var(--subtleText);

  margin-right: 5px;
`;
export const Data = styled.strong``;

const commonBoxStyle = css`
  & + & {
    margin-left: 24px;
  }
`;
export const Options = styled(Header)`
  align-items: center;
  margin-bottom: 15px !important;
`;
export const OptionItem = styled.div`
  ${commonBoxStyle}
  display: flex;
  align-items: center;

  ${Title} {
    display: inline;
  }
`;
export const FocusedOptionItem = styled.div`
  ${commonBoxStyle}
  display: flex;
  align-items: center;
  margin-right: 30px;
  color: var(--bodyText);

  border: 1px solid #d6d6d6;
  border-radius: 5px;
  padding: 5px 8px;
  background-color: #8895a41f;
  cursor: pointer;
`;
export const Information = styled(HeaderInformation)``;
export const InformationItem = styled.p`
  margin: 0;
`;

export const DashboardSelector = styled(Select)`
  ${commonFontStyle}

  min-width: 100px;
  height: 33px;

  border-radius: 8px;
  border-color: #d6d6d6;
`;
export const DashboardSelectorItem = styled(MenuItem)``;

export const SessionSelector = styled(Select)`
  .MuiSvgIcon-root {
    display: none;
    width: 0;
  }

  .MuiSelect-select {
    padding-right: 0 !important;
    ${commonFontStyle}
    font-weight: 600;
  }

  &:before {
    content: none;
  }
`;
export const SessionSelectorItem = styled(MenuItem)``;

export const AutosubmitSwitch = styled(Switch)`
  width: 58px;
  height: 26px;
  padding: 0;

  .MuiSwitch {
    &-switchBase {
      padding: 3px;
      color: #fff;
      transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &-thumb {
      width: 20px;
      height: 20px;
      background-color: #fff;
    }

    &-track {
      opacity: 1 !important;
      border-radius: 20px;
      position: relative;
      background-color: var(--page-background);
      transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      &:before,
      &:after {
        display: inline-block;
        position: absolute;
        font-size: 14px;
        top: 50%;
        width: 50%;
        transform: translateY(-50%);
        color: #868686;
        text-align: center;
      }
      &:before {
        content: 'Yes';
        color: #fff;
        left: 4px;
        opacity: 0;
      }
      &:after {
        content: 'No';
        right: 4px;
      }
    }
  }

  .Mui-checked {
    &.MuiSwitch {
      &-switchBase {
        color: #185a9d;
        transform: translateX(32px);
        &:hover: {
          background-color: rgba(24, 90, 257, 0.08);
        }
      }
      &-thumb {
        background-color: #fff;
      }
    }
    + .MuiSwitch-track {
      background-color: var(--blue2shade);
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0;
      }
    }
  }
`;

export const IntervalSelector = styled(FormControl)`
  border-radius: 8px !important;
`;
