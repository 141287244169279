import i18n from 'i18next';
import {
  isAfter as isAfterFns,
  isBefore as isBeforeFns,
  isEqual as isEqualFns,
} from 'date-fns';
import { isAbsent } from './utils';

const dateField = <T>(value: T, error: string | null) => {
  return {
    value,
    error,
    max: function max(maxValue: T, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) || this.value > maxValue) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.date_max', { date: maxValue });
      }
      return this;
    },
    min: function min(minValue: T, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) || this.value < minValue) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.date_min', { date: minValue });
      }
      return this;
    },
    isAfter: function isAfter(
      dateValue: T,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (
        !isAbsent(this.value) &&
        dateValue != null &&
        !isAfterFns(
          Date.parse(String(this.value)),
          Date.parse(String(dateValue))
        )
      ) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.date_after', {
              date: Date.parse(String(dateValue)),
            });
      }
      return this;
    },
    isBefore: function isBefore(
      dateValue: T,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (
        !isAbsent(this.value) &&
        dateValue != null &&
        !isBeforeFns(
          Date.parse(String(this.value)),
          Date.parse(String(dateValue))
        )
      ) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.date_before', {
              date: Date.parse(String(dateValue)),
            });
      }
      return this;
    },
    isAfterOrEqual: function isAfterOrEqual(
      dateValue: T,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (
        !isAbsent(this.value) &&
        dateValue != null &&
        !isAfterFns(
          Date.parse(String(this.value)),
          Date.parse(String(dateValue))
        ) &&
        !isEqualFns(
          Date.parse(String(this.value)),
          Date.parse(String(dateValue))
        )
      ) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.date_after', {
              date: Date.parse(String(dateValue)),
            });
      }
      return this;
    },
    isBeforeOrEqual: function isBeforeOrEqual(
      dateValue: T,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (
        !isAbsent(this.value) &&
        dateValue != null &&
        !isBeforeFns(
          Date.parse(String(this.value)),
          Date.parse(String(dateValue))
        ) &&
        !isEqualFns(
          Date.parse(String(this.value)),
          Date.parse(String(dateValue))
        )
      ) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.date_before', {
              date: Date.parse(String(dateValue)),
            });
      }
      return this;
    },
    required: function required(errorMessage: string | null = null) {
      if (this.error) return this;
      if (isAbsent(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.required_field');
      }
      return this;
    },
    pattern: function pattern(
      regex: RegExp,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (!regex.test(String(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.not_match');
      }
      return this;
    },
    custom: function custom(
      fn: Function,
      errorMessage: string | null = null,
      ...args: []
    ) {
      if (this.error) return this;
      if (!fn(this.value, ...args)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.field_no_valid');
      }
      return this;
    },
    validate: function validate() {
      return this.error;
    },
  };
};

export default dateField;
