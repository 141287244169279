import { useAppContext } from 'App/AppProvider';
import { AuditLog } from 'api/models/AuditLog.model';
import { isEqual, uniqBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { EventType } from 'services/StructuredLogging/events';

export interface AutoTraderEvent {
  timestamp: number;
  targets: string[];
}

const useSSE = ({
  event,
  type,
  sourceURL,
}: {
  event: string;
  type: string;
  sourceURL: string | undefined;
}) => {
  let collector: AuditLog[] = [];
  const sourceEvent = useRef<EventSource | undefined>(undefined);
  const [data, setData] = useState<AuditLog | AutoTraderEvent | undefined>();
  const [done, setDone] = useState(false);
  const [records, setRecords] = useState(0);
  const [error, setError] = useState<String | null>(null);
  const { pushNotification } = useAppContext();
  let reconnectionSeconds = 1;

  const onError = (event: any) => {
    if (event.error) {
      setError(event.error);
    } else {
      setError('An error has occurred');
    }
  };

  const onOpen = (event: Event) => {};

  const onMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data);
    if (message.eventName === 'done') {
      setDone(true);
      setTimeout(() => {}, 800);
    } else {
      const catcher: AuditLog[] = uniqBy([...collector, message], 'eventTime');

      if (!isEqual(catcher, collector)) {
        collector = [...catcher];
        setDone(false);
        setTimeout(() => {
          setData(message);
          setRecords(catcher.length);
        }, 800);
      }
    }
  };

  const eventHandler = (e: any) => {
    setData(JSON.parse(e.data) as AutoTraderEvent);
  };

  const traderSSE = (url: string) => {
    const source = new EventSource(url, {
      withCredentials: false,
    });
    source.addEventListener(event, eventHandler);
    source.onerror = (event) => onError(event);
    source.onopen = (event) => onOpen(event);

    return source;
  };

  const auditSSE = (url: string) => {
    const source = new EventSource(url, {
      withCredentials: false,
    });
    source.onmessage = (event) => onMessage(event);
    source.onerror = (event) => onError(event);
    source.onopen = (event) => onOpen(event);
    return source;
  };

  const initSource = (url: string) => {
    let source: EventSource | undefined = undefined;
    if (type === EventType.AUDIT) {
      source = auditSSE(url);
    } else if (type === EventType.AUTOTRADER) {
      source = traderSSE(url);
    }
    return source;
  };

  // eslint-disable-next-line
  const retry = () => {
    setTimeout(() => {
      if (sourceURL) initSource(sourceURL);
      if (pushNotification) pushNotification('info', 'Retrying');
      reconnectionSeconds *= 2;
      if (reconnectionSeconds >= 64) {
        reconnectionSeconds = 64;
      }
    }, reconnectionSeconds * 1000);
  };

  useEffect(() => {
    if (sourceURL) {
      const source = initSource(sourceURL);
      sourceEvent.current = source;
      setDone(false);
      setError(null);
    }
    // eslint-disable-next-line
  }, [sourceURL, type, event]);

  const close = () => {
    setData(undefined);
    sourceEvent.current?.close();
  };

  return { sourceEvent, data, error, done, records, close };
};

export default useSSE;
