import React from 'react';
import SwitchMUI from '@mui/material/Switch';
import styles from './Switch.module.scss';

interface Props {
  status: boolean;
  meta?: string;
  onChangeHandler: (isChecked: boolean, id?: string | undefined) => void;
}

export default function Switch({ status, onChangeHandler, meta }: Props) {
  const { switchRoot, thumb, track, base, checked, input } = styles;
  return (
    <SwitchMUI
      onChange={(event) => onChangeHandler(event.target.checked, meta)}
      className={switchRoot}
      checked={status}
      inputProps={{ 'aria-label': 'controlled' }}
      classes={{ thumb, track, switchBase: base, checked, input }}
    />
  );
}
