export enum Category {
  RESOURCE_PAGE = 'Resources Page',
  PARTICIPANTS_PAGE = 'Participants Page',
  METERS_PAGE = 'Meters Page',
  OPERATING_PLAN_PAGE = 'Operating Plan Page',
  OVERRIDE_PAGE = 'Override Page',
  TRADEDASHBOARD_PAGE = 'Tradedashboard Page',
  BIDSOFFERS_PAGE = 'Bids and Offers Page',
  MATCHED_TRADES_PAGE = 'Matched Trades Page',
  SUPPORT_PAGE = 'Support Page',
  USER_GUIDE_PAGE = 'User Guide Page',
  TOP_BAR = 'Top Bar',
  TRADESTRATEGY_PAGE = 'Trade Strategy Page',
}
