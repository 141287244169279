import { EResolution } from 'components/molecules/RangeFilterTab/constants';
import { IDataGridColumn } from 'interfaces/dataGrid';
import {
  currencyInDollarsDashFormatter,
  currencyInDollarsFormatter,
  timeExportHeaderValueGetter,
  timeExportValueGetter,
} from 'utils/general';

interface IDynamicColumnsDef {
  timeResolution: ITimeResolution;
}

export const columnDefs = [
  {
    headerName: 'Bid/Offer',
    field: 'type',
    minWidth: 100,
    maxWidth: 100,
    cellRenderer: 'CellRender',
    pinned: 'left',
  },
  {
    headerName: 'Resource \n identifier',
    field: 'resourceIdentifier',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Trade \n interval',
    headerValueGetter: timeExportHeaderValueGetter,
    field: 'tradeInterval',
    minWidth: 120,
    maxWidth: 150,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Plan \n Cost \n ($/MWh)',
    field: 'planCost',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Quote \n Price \n ($/MWh)',
    field: 'quotePrice',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Cleared \n Price \n ($/MWh)',
    field: 'clearedPrice',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Matched \n quantity \n (MW)',
    field: 'matchedQuantity',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Gross \n Margin \n ($)',
    field: 'grossMargin',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Transmission \n loss',
    field: 'transmissionLoss',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsDashFormatter,
  },
  {
    headerName: 'Net \n margin \n ($)',
    field: 'netMargin',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsDashFormatter,
  },
  {
    headerName: 'Sink / \n Source',
    field: 'sourceOrSink',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Counter \n party',
    field: 'counterparty',
    minWidth: 180,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Tag ID',
    field: 'tagId',
    minWidth: 200,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Tag \n Status',
    field: 'tagStatus',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Tag \n Detail',
    field: 'tagDetail',
    minWidth: 400,
    maxWidth: 400,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Id',
    field: 'bidOfferId',
    hide: true,
  },
];

export const dailyColumnDefs = [
  {
    headerName: 'Bid/Offer',
    field: 'type',
    minWidth: 100,
    maxWidth: 100,
    cellRenderer: 'CellRender',
    pinned: 'left',
  },
  {
    headerName: 'Resource \n identifier',
    field: 'resourceIdentifier',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Day',
    field: 'tradeInterval',
    minWidth: 120,
    maxWidth: 150,
    cellRendererParams: {
      type: 'dateTimeDay',
    },
    valueGetter: timeExportValueGetter,
    headerValueGetter: timeExportHeaderValueGetter,
  },
  {
    headerName: 'Aggregated \n Plan Cost \n ($/MWh)',
    field: 'planCost',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Aggregated \n Quote Price \n ($/MWh)',
    field: 'quotePrice',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Aggregated \n Cleared Price \n ($/MWh)',
    field: 'clearedPrice',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Matched \n quantity \n (MWh)',
    field: 'matchedQuantity',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Gross \n Margin \n ($)',
    field: 'grossMargin',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Transmission \n loss',
    field: 'transmissionLoss',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsDashFormatter,
  },
  {
    headerName: 'Net \n margin \n ($)',
    field: 'netMargin',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsDashFormatter,
  },
  {
    headerName: 'Sink / \n Source',
    field: 'sourceOrSink',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Counter \n party',
    field: 'counterparty',
    minWidth: 180,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Id',
    field: 'bidOfferId',
    hide: true,
  },
];

export const monthlyColumnDefs = [
  {
    headerName: 'Bid/Offer',
    field: 'type',
    minWidth: 100,
    maxWidth: 100,
    cellRenderer: 'CellRender',
    pinned: 'left',
  },
  {
    headerName: 'Resource \n identifier',
    field: 'resourceIdentifier',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Month',
    field: 'tradeInterval',
    minWidth: 120,
    maxWidth: 150,
    cellRendererParams: {
      type: 'dateTimeMonth',
    },
    valueGetter: timeExportValueGetter,
    headerValueGetter: timeExportHeaderValueGetter,
  },
  {
    headerName: 'Aggregated \n Plan Cost \n ($/MWh)',
    field: 'planCost',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Aggregated \n Quote Price \n ($/MWh)',
    field: 'quotePrice',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Aggregated \n Cleared Price \n ($/MWh)',
    field: 'clearedPrice',
    minWidth: 120,
    maxWidth: 120,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Matched \n quantity \n (MWh)',
    field: 'matchedQuantity',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
  },
  {
    headerName: 'Gross \n Margin \n ($)',
    field: 'grossMargin',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsFormatter,
  },
  {
    headerName: 'Transmission \n loss',
    field: 'transmissionLoss',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsDashFormatter,
  },
  {
    headerName: 'Net \n margin \n ($)',
    field: 'netMargin',
    minWidth: 90,
    maxWidth: 90,
    cellClass: 'ag-cell-aligned-center',
    headerClass: 'ag-header-cell-aligned-center',
    filter: 'agNumberColumnFilter',
    valueFormatter: currencyInDollarsDashFormatter,
  },
  {
    headerName: 'Sink / \n Source',
    field: 'sourceOrSink',
    minWidth: 150,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Counter \n party',
    field: 'counterparty',
    minWidth: 180,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Id',
    field: 'bidOfferId',
    hide: true,
  },
];

export const dynamicColumnsDef = ({
  timeResolution,
}: IDynamicColumnsDef): IDataGridColumn[] => {
  switch (timeResolution) {
    case EResolution.NONE:
      return columnDefs;
    case EResolution.DAILY:
      return dailyColumnDefs;
    case EResolution.MONTHLY:
      return monthlyColumnDefs;
  }
};

export type ITimeResolution =
  | typeof EResolution.NONE
  | typeof EResolution.DAILY
  | typeof EResolution.MONTHLY;

export const DATA_SOURCE = {
  Interval: 'Interval',
  Daily: 'Daily',
  Monthly: 'Monthly',
};

export type IDataSource =
  | typeof DATA_SOURCE.Interval
  | typeof DATA_SOURCE.Daily
  | typeof DATA_SOURCE.Monthly;
