import { apiConfigPath, OAuth2Wrapper } from 'api/AutoTraderAPI';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { ITenantAuthConfigBase } from 'utils/auth';

const useApiConfig = () => {
  const [tenants, setTenants] = useState<ITenantAuthConfigBase[] | undefined>();
  const [loaded, setLoaded] = useState<Boolean>(false);
  const [tenantFilter, setTenantsFilter] = useState<
    ITenantAuthConfigBase | undefined
  >();
  const getTenants = async () => {
    const response: AxiosResponse = await axios.get(apiConfigPath());
    const apiConfig = response.data;
    if (apiConfig?.tenants) {
      setTenants(apiConfig?.tenants);
      setLoaded(true);
    }
  };

  const isValid = (tenantToValidate: string) => {
    const tenant = tenants?.filter(
      (tenant) => tenant.tenantId === tenantToValidate
    );
    if (tenant?.length) {
      setTenantsFilter(tenant[0]);
    }
    return tenant?.length && tenant[0].clientId !== undefined;
  };

  const OAuth2FromTenant = async (tenantId: string) => {
    return await OAuth2Wrapper.ofTenant(tenantId);
  };

  useEffect(() => {
    void getTenants();
  }, []);

  return {
    loaded,
    OAuth2FromTenant,
    isValid,
    getTenants,
    tenants,
    tenantFilter,
  };
};

export default useApiConfig;
