import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styles from './GridSkeleton.module.scss';
import DataGrid from 'components/molecules/DataGrid/DataGrid';
import { IAdditions, IDataGridColumn } from 'interfaces/dataGrid';
import {
  CellEvent,
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent,
  RowClassParams,
  RowClassRules,
  RowEditingStartedEvent,
  RowEditingStoppedEvent,
  RowStyle,
} from 'ag-grid-community';
import GridToolbar from './GridToolbar';
import ActionButtons from 'components/organisms/Grids/ActionButtons';
import { AgGridReact } from 'ag-grid-react';
import usePreferences from 'hooks/usePreferences';
import { isEmpty } from 'lodash';
import { IFilterPreference } from 'api/models/User.model';
import { DEFAULT_DATA_GRID_HEADER_HEIGHT } from 'components/molecules/DataGrid/constants';
import { getPreferences } from 'redux/states/preferences.state';
import RootState from 'redux/store';

interface Props {
  title?: string | undefined;
  paginationAutoPageSize?: boolean | false;
  editMode?: boolean | false;
  canSave?: boolean | false;
  sorted?: boolean | false;
  fullHeight?: boolean | false;
  canEdit?: boolean | true;
  canAdd?: boolean | true;
  canReset?: boolean | true;
  pagination?: boolean | false;
  headerHeight?: number;
  loading: boolean;
  data?: any[] | undefined;
  additions?: IAdditions;
  defaultDefs?: ColDef;
  columnDefs?: IDataGridColumn[];
  components?:
    | {
        [p: string]: any;
      }
    | undefined;
  context?: any;
  toolExtra?: ReactNode;
  rowClassRules?: RowClassRules;
  onClickReset?: () => void;
  onClickEnableEditing?: () => void;
  onClickAddRow?: () => void;
  onClickSaveButton?: () => void;
  onClickRemoveButton?: (cellEvent: CellEvent) => void;
  getRowStyle?: (params: RowClassParams) => RowStyle | undefined;
  onGridReady?: (gridEvent: GridReadyEvent) => void;
  onRowEditingStopped?: (event: RowEditingStoppedEvent) => void;
  onRowEditingStarted?: (event: RowEditingStartedEvent) => void;
  onCellValueChanged?: (event: CellValueChangedEvent) => void;
}

const FolioSkeleton = ({
  title,
  data,
  columnDefs,
  editMode,
  onGridReady,
  loading,
  components,
  defaultDefs,
  onClickReset,
  onClickEnableEditing,
  onClickAddRow,
  onClickSaveButton,
  onClickRemoveButton,
  onRowEditingStopped,
  onRowEditingStarted,
  onCellValueChanged,
  pagination,
  toolExtra,
  canSave,
  canEdit,
  canAdd,
  canReset,
  context,
  fullHeight,
  headerHeight = DEFAULT_DATA_GRID_HEADER_HEIGHT,
  paginationAutoPageSize = false,
  rowClassRules,
  sorted,
  getRowStyle,
  additions,
}: Props) => {
  const gridRef = useRef<AgGridReact>(null);
  const { container, gridContainer, fullH } = styles;
  const [colDefs, setColDefs] = useState<IDataGridColumn[] | undefined>(
    columnDefs
  );
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rowEditingStoppedEvent, setRowEditingStoppedEvent] =
    useState<boolean>(false);
  const [cellKeyDownEnter, setCellKeyDownEnter] = useState<boolean>(false);
  const [executingSave, setExecutingSave] = useState<boolean>(false);
  const { tablePreference, setPreference, lastPageSeenPreference } =
    usePreferences();

  useEffect(() => {
    if (rowEditingStoppedEvent && !cellKeyDownEnter && executingSave) {
      executeSave();
    }
    // eslint-disable-next-line
  }, [rowEditingStoppedEvent]);

  useEffect(() => {
    if (tablePreference && gridApi) {
      const preference = tablePreference?.find(
        (preference) => preference.page === lastPageSeenPreference
      );
      if (preference) {
        const { sorting, filter } = preference;
        const { columnModel } = gridApi as any;
        if (sorting) {
          columnModel.applyColumnState({
            state: [{ colId: sorting?.field, sort: sorting?.sort }],
          });
        }
        if (filter) {
          gridApi.setFilterModel(filter);
        }
        // applyColumnState
      }
    }

    // eslint-disable-next-line
  }, [tablePreference, gridApi]);

  const eventTracker = useCallback(
    (eventType: string, gridInterface: any) => {
      const { tablePreference, lastPageSeen: pathname } = getPreferences(
        RootState.getState()
      );
      if (eventType === 'filterChanged') {
        const model = gridInterface.api.getFilterModel();
        const preference = tablePreference.find(
          (preference) => preference.page === pathname
        );
        if (preference) {
          const tempTablePreference: IFilterPreference = {
            filter: !isEmpty(model) ? model : null,
            page: pathname,
            sorting: preference.sorting,
          };
          const filteredPreferences = tablePreference?.filter(
            (unit) => unit.page !== pathname
          );
          filteredPreferences?.push(tempTablePreference);
          setPreference({
            tablePreference: filteredPreferences,
          });
        } else {
          const tempTablePreference: IFilterPreference = {
            filter: !isEmpty(model) ? model : null,
            page: pathname,
            sorting: null,
          };
          setPreference({ tablePreference: [tempTablePreference] });
        }
      }
      if (eventType === 'sortChanged') {
        const listOfSortModel = gridInterface.columnApi
          .getColumnState()
          .filter((column: any) => column.sort !== null)
          .map((sortedColumn: any) => {
            const { colId, sort } = sortedColumn;
            return { field: colId, sort: sort };
          });
        if (listOfSortModel) {
          const preference = tablePreference.find(
            (preference) => preference.page === pathname
          );
          if (preference) {
            const tempTablePreference: IFilterPreference = {
              filter: tablePreference.filter,
              page: pathname,
              sorting: listOfSortModel.length ? listOfSortModel[0] : null,
            };
            const filteredPreferences = tablePreference?.filter(
              (unit) => unit.page !== pathname
            );
            filteredPreferences?.push(tempTablePreference);
            setPreference({
              tablePreference: [...filteredPreferences, tempTablePreference],
            });
          } else {
            const tempTablePreference: IFilterPreference = {
              filter: null,
              page: pathname,
              sorting: listOfSortModel.length ? listOfSortModel[0] : null,
            };
            setPreference({ tablePreference: [tempTablePreference] });
          }
        }
      }
      if (eventType === 'rowEditingStopped') {
        setRowEditingStoppedEvent(true);
      }
      if (eventType === 'rowEditingStarted') {
        setRowEditingStoppedEvent(false);
      }
      if (eventType === 'cellKeyDown' || eventType === 'cellKeyPress') {
        if (gridInterface.event.key === 'Enter') {
          setCellKeyDownEnter(true);
        } else {
          setCellKeyDownEnter(false);
        }
      }
    },
    [setPreference]
  );

  useEffect(() => {
    gridApi?.addGlobalListener(eventTracker);

    return () => {
      gridApi?.removeGlobalListener(eventTracker);
    };
    // eslint-disable-next-line
  }, [gridApi]);

  const executeSave = () => {
    if (onClickSaveButton && rowEditingStoppedEvent) {
      onClickSaveButton();
      setExecutingSave(false);
      setCellKeyDownEnter(false);
      setRowEditingStoppedEvent(false);
    }
  };

  useEffect(() => {
    const findActionBtnDef = columnDefs?.find(
      (item) => item.headerName === 'Action Buttons'
    );
    if (onClickRemoveButton && columnDefs && !findActionBtnDef) {
      setColDefs(() => {
        const newColumnDefs = [...columnDefs];
        newColumnDefs?.push({
          headerName: 'Action Buttons',
          cellClass: 'action-button-close',
          cellRenderer: ActionButtons,
          cellRendererParams: {
            onClickRemoveRow: onClickRemoveButton,
          },
          width: 20,
          minWidth: 20,
          maxWidth: 20,
          pinned: 'right',
          editable: false,
        });
        return newColumnDefs;
      });
    }
  }, [columnDefs, onClickRemoveButton]);

  useEffect(() => {
    if (data && sorted && gridRef) {
      const allColumns = gridRef.current?.columnApi?.getAllColumns();
      if (allColumns)
        gridRef?.current?.columnApi.applyColumnState({
          state: [{ colId: allColumns[0].getColId(), sort: 'desc' }],
          defaultState: { sort: null },
        });
    }
  }, [data, sorted]);

  return (
    <div className={`${container} ${fullHeight && fullH}`}>
      <GridToolbar
        toolExtra={toolExtra}
        editMode={editMode}
        title={title}
        onCancel={() => {
          if (additions && !isEmpty(additions?.additionsIds)) {
            gridApi?.forEachNode((rowNode, index) => {
              const idToRemove = rowNode.data[additions.key]?.value;
              if (idToRemove && additions?.additionsIds?.includes(idToRemove)) {
                gridApi?.applyTransaction({
                  remove: [rowNode.data],
                });
              }
            });
          }
          if (onClickReset) onClickReset();
        }}
        onClickReset={onClickReset}
        onClickEnableEditing={onClickEnableEditing}
        onClickAddRow={() => {
          if (onClickAddRow) onClickAddRow();
          const allColumns = gridRef.current?.columnApi?.getAllColumns();

          setTimeout(() => {
            if (allColumns) {
              gridRef.current?.api?.refreshCells();
              gridRef.current?.api?.startEditingCell({
                rowIndex: 0,
                colKey: allColumns[0],
                rowPinned: undefined,
              });
            }
          }, 100);
        }}
        onClickSaveButton={() => {
          setExecutingSave(true);
          if (!rowEditingStoppedEvent) {
            gridApi?.stopEditing();
          } else {
            executeSave();
          }
        }}
        canSave={canSave}
        canEdit={canEdit}
        canAdd={canAdd}
        canReset={canReset}
      />
      <div className={gridContainer} data-testid='table_1'>
        <DataGrid
          ref={gridRef}
          getRowStyle={getRowStyle}
          onRowEditingStopped={onRowEditingStopped}
          onRowEditingStarted={onRowEditingStarted}
          onCellValueChanged={onCellValueChanged}
          context={context}
          rowSelection='single'
          rowHeight={60}
          editType='fullRow'
          defaultColDef={{
            ...defaultDefs,
            suppressMenu: true,
            filter: 'agTextColumnFilter',
            sortable: true,
            floatingFilter: true,
            resizable: true,
          }}
          components={components}
          isLoading={loading}
          onGridReady={(gridEvent: GridReadyEvent) => {
            setGridApi(gridEvent.api);
            if (onGridReady) onGridReady(gridEvent);
          }}
          suppressClickEdit={!editMode}
          columnDefs={colDefs}
          data={data}
          singleClickEdit
          pagination={pagination}
          headerHeight={headerHeight}
          paginationAutoPageSize={paginationAutoPageSize}
          paginationPageSize={100}
          gridOptions={{
            alwaysShowHorizontalScroll: true,
          }}
          rowClassRules={rowClassRules}
        />
      </div>
    </div>
  );
};

export default FolioSkeleton;
