import {
  ADMIN_API_KEY_ENDPOINT,
  ADMIN_USERS_ENDPOINT,
  USERS_ADD_URL,
} from 'components/admin/Sidebar/menu.config';
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import ApiKeysPage from './ApiKeysPage';
import AddUsers from 'components/admin/AddUser/AddUser';
import UsersPage from 'pages/csm/Setup/UsersPage';
import NotFound from 'components/admin/NotFound/Index';

function index() {
  return (
    <Switch>
      <Route exact path={`*${ADMIN_USERS_ENDPOINT}`}>
        <UsersPage />
      </Route>
      <Route
        exact
        path={`*${ADMIN_USERS_ENDPOINT}${USERS_ADD_URL}`}
        component={AddUsers}
      />

      <Route exact path={`*${ADMIN_API_KEY_ENDPOINT}`}>
        <ApiKeysPage />
      </Route>
      <Route path='*' component={NotFound} />
    </Switch>
  );
}

export default index;
