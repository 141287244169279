import { formatISO, isValid } from 'date-fns';
import { FormikTouched, FormikValues } from 'formik';

export const isAbsent = <T>(value: T) => {
  return (
    value === null ||
    value === undefined ||
    String(value) === '' ||
    (value instanceof Array && value.length === 0)
  );
};

export const isBoolean = <T>(value: T) => {
  return [true, false, 'true', 'false'].includes(String(value));
};

export const isDate = <T>(value: T) => {
  let isoDate: string = '';

  try {
    isoDate = formatISO(new Date(`${value}`), { representation: 'date' });
  } catch (error) {
    return false;
  }

  if (new RegExp('[1-9]\\d{3,}-\\d{1,2}-\\d{1,2}').test(isoDate)) {
    return true;
  }
  return isValid(isoDate);
};

export const isString = <T>(value: T) => {
  return typeof value === 'string';
};

export const isNumber = <T>(value: T) => {
  return typeof value === 'number' || !Number.isNaN(+value);
};

export const isArray = <T>(value: T) => {
  return Array.isArray(value);
};

export const handleTouched = (
  targetName: HTMLInputElement,
  touched: FormikTouched<FormikValues>
) => {
  return { ...touched, [targetName.name]: true };
};

export const isComplete = (values: FormikValues) => {
  const allDefined = Object.values(values).filter(({ value }) => {
    return value === undefined || value === '' || value === null
      ? true
      : false ||
        (value instanceof Array && value.includes('')) ||
        value.length === 0
      ? true
      : false;
  });

  return allDefined.length === 0;
};
