import { SelectChangeEvent } from '@mui/material';
import { TTimeUnit, TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { MatchedTrade } from 'api/models/MatchedTrade.model';
import IntervalSelector from 'components/admin/IntervalSelector/IntervalSelector';
import {
  HOURS_24_FILTER,
  HOURS_4_FILTER,
  HOURS_12_FILTER,
  IFilter,
  EResolution,
} from 'components/molecules/RangeFilterTab/constants';
import { MVP_TIMEZONES } from 'constants/general';
import { IContainer } from 'interfaces/general';
import { useCallback, useEffect, useState } from 'react';
import * as S from './Shared.styles';
import { currencyInDollarsFormatter, formatNumber } from 'utils/general';
import SkeletonWrapper from 'components/admin/SkeletonWrapper/SkeletonWrapper';

import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppStore } from 'redux/store';
import { FULFILLED, LOADING } from 'redux/constants';
import { fetchMatchedTrades } from 'redux/states/matchedtrades.state';

interface ISummaryData {
  profitAndLoss: number;
  bidsMw: number;
  offersMw: number;
}

const INTERVALS = [HOURS_4_FILTER, HOURS_12_FILTER, HOURS_24_FILTER];

const Information = ({ className }: IContainer) => {
  const dispatch = useDispatch<AppDispatch>();
  const { status, matchedtrades } = useSelector(
    (store: AppStore) => store.matchedtrades
  );
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const [selectedInterval, setSelectedInterval] = useState<any>(HOURS_4_FILTER);
  const [loading, setLoading] = useState<boolean>(true);
  const [profitAndLoss, setProfitAndLoss] = useState<number>();
  const [bidsMw, setBidsMw] = useState<number>();
  const [offersMw, setOffersMw] = useState<number>();
  const [startDate, setStartDate] = useState<any>(
    ZonedDateTime.now(selectedTimeZone || ZonedDateTime.defaultTimeZone())
      .subtract(4, 'hour')
      .toString()
  );
  const [endDate, setEndDate] = useState<any>(
    ZonedDateTime.now(selectedTimeZone || ZonedDateTime.defaultTimeZone())
      .add(20, 'minutes')
      .toString()
  );

  const handleIntervalSelected = (
    event: SelectChangeEvent<string | IFilter>
  ) => {
    const selected: IFilter | undefined = INTERVALS.find(
      (interval) => interval.key === event.target.value
    );
    setSelectedInterval(selected && selected);
    if (selectedTimeZone && selected) {
      calculateIntervals(selectedTimeZone, selected);
    }
  };

  const calculateIntervals = useCallback(
    (selectedTimeZone: TTimeZone, selected: IFilter) => {
      if (selected.scale && selected.calculationUnit) {
        const startingDate = ZonedDateTime.now(selectedTimeZone)
          .subtract(selected.scale, selected.calculationUnit as TTimeUnit)
          .toString();
        const endingDate = ZonedDateTime.now(selectedTimeZone)
          .add(20, 'minutes')
          .toString();

        setStartDate(startingDate);
        setEndDate(endingDate);
      }
    },
    [setStartDate, setEndDate]
  );

  const fetchTrades = useCallback(
    (participantId: string) => {
      const timezone = MVP_TIMEZONES.filter(
        (timeZone) => timeZone.zone === selectedTimeZone
      )[0];
      const requestProps = {
        marketParticipantId: participantId,
        startAt: encodeURIComponent(startDate),
        stopAt: encodeURIComponent(endDate),
        timeResolution: EResolution.HOURLY,
        outputTimeZone: timezone.identifier,
      };
      dispatch(fetchMatchedTrades(requestProps));
    },
    [startDate, endDate, selectedTimeZone, dispatch]
  );

  useEffect(() => {
    if (selectedParticipant && selectedParticipant?.id) {
      fetchTrades(selectedParticipant?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipant?.id, selectedInterval, selectedTimeZone]);

  useEffect(() => {
    var currentProfitAndLoss = 0;
    var currentBidsMw = 0;
    var currentOffersMw = 0;
    if (matchedtrades?.length) {
      const bidsOffersReducer = (
        result: ISummaryData,
        bidsOffers: MatchedTrade
      ) => {
        const { grossMargin, matchedQuantity, type } = bidsOffers;

        if (!result)
          result = {
            profitAndLoss: 0,
            bidsMw: 0,
            offersMw: 0,
          };

        if (!result?.profitAndLoss) result.profitAndLoss = 0;
        if (!result?.bidsMw) result.bidsMw = 0;
        if (!result?.offersMw) result.offersMw = 0;

        result.profitAndLoss += grossMargin;
        result.bidsMw += type === 'BID' ? matchedQuantity : 0;
        result.offersMw += type === 'OFFER' ? matchedQuantity : 0;

        return result;
      };

      const roughtData = (matchedtrades as any).reduce(bidsOffersReducer, {});
      currentProfitAndLoss =
        Math.round((roughtData.profitAndLoss + Number.EPSILON) * 100) / 100;
      currentBidsMw = roughtData.bidsMw;
      currentOffersMw = roughtData.offersMw;
    }

    setProfitAndLoss(Math.round(currentProfitAndLoss));
    setBidsMw(Math.round(currentBidsMw / 4));
    setOffersMw(Math.round(currentOffersMw / 4));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, selectedTimeZone]);

  useEffect(() => {
    if (selectedTimeZone) {
      calculateIntervals(selectedTimeZone, selectedInterval);
    }
  }, [
    selectedTimeZone,
    selectedParticipant,
    calculateIntervals,
    selectedInterval,
  ]);

  useEffect(() => {
    if (status === LOADING) {
      setLoading(true);
    } else if (status === FULFILLED) {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }, [status]);

  return (
    <S.Information className={className}>
      <>
        <S.InformationItem>
          <IntervalSelector
            inputTitle='Since:'
            intervals={INTERVALS}
            handleIntervalSelected={handleIntervalSelected}
            selectedInterval={selectedInterval}
          />
        </S.InformationItem>
        <SkeletonWrapper loading={loading}>
          <S.InformationItem>
            <S.Title>Gross:</S.Title>
            <S.Data>
              {profitAndLoss &&
                profitAndLoss > 0 &&
                currencyInDollarsFormatter({
                  value: profitAndLoss,
                  decimals: 0,
                })}
            </S.Data>
          </S.InformationItem>
        </SkeletonWrapper>
        <SkeletonWrapper loading={loading}>
          <S.InformationItem>
            <S.Title>Offers (MWh):</S.Title>
            <S.Data>
              {offersMw && offersMw > 0 && formatNumber(offersMw)}
            </S.Data>
          </S.InformationItem>
        </SkeletonWrapper>
        <SkeletonWrapper loading={loading}>
          <S.InformationItem>
            <S.Title>Bids (MWh):</S.Title>
            <S.Data>{bidsMw && bidsMw > 0 && formatNumber(bidsMw)}</S.Data>
          </S.InformationItem>
        </SkeletonWrapper>
      </>
    </S.Information>
  );
};

export default Information;
