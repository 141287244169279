import { CircularProgress } from '@mui/material';
import React from 'react';
import styles from './Loader.module.scss';

export default function Loader() {
  const { logo, container, wrapper, image, progress } = styles;
  return (
    <div className={wrapper}>
      <div className={container}>
        <div className={image}>
          <img
            className={logo}
            alt='admin logo'
            src={`${process.env.PUBLIC_URL}/assets/images/Logo.svg`}
          />
        </div>
        <div className={progress}>
          <CircularProgress size={60} />
        </div>
      </div>
    </div>
  );
}
