import { useCallback, useEffect, useState } from 'react';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';

import { useAppContext } from 'App/AppProvider';
import { useDateRangeSelector } from 'components/admin/DateRangeSelector';
import { createContext } from 'contexts/helpers';
import { TChildren } from 'types/general';
import { TDataGridView } from 'types/dataGrid';
import { EDataGridView } from 'enums/dataGrid';
import { IDataSource } from 'components/admin/OperatingPlan/M15/M15';
import { DATA_SOURCE } from 'components/admin/OperatingPlan/M15/constants';
import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import { HOURS_4_FILTER } from 'components/molecules/RangeFilterTab/constants';
import usePlanTypeTab from 'hooks/useUnitsTab';
import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';

import { useSelector } from 'react-redux';

interface IOperatingPlanM15ProviderProps {
  children: TChildren;
}

const createOperatingPlanM15Context = () => {
  const [useOperatingPlanM15Context, Provider] = createContext<any>();

  const OperatingPlanM15Provider = ({
    children,
  }: IOperatingPlanM15ProviderProps): JSX.Element => {
    createContext<any>();
    const selectedParticipant = useSelector(pickSelectedParticipant);
    const selectedTimeZone = useSelector(pickSelectedTimezone);
    const { logEvent } = useAppContext();
    const { handleRangeFilterChange, selectedRangeFilterTab } =
      useRangeFilterTabs(HOURS_4_FILTER);
    const { fromRangeDateTime, toRangeDateTime, handleDateRangeSelector } =
      useDateRangeSelector(selectedTimeZone as TTimeZone, 0, 60);
    const { selectedUnitTypeTab, handleOnChangeUnitTypeTab } = usePlanTypeTab();

    const [dates, setDates] = useState<any>({
      from: ZonedDateTime.parseIso(
        fromRangeDateTime,
        selectedTimeZone as TTimeZone
      ).toIsoString(),
      to: ZonedDateTime.parseIso(
        toRangeDateTime,
        selectedTimeZone as TTimeZone
      ).toIsoString(),
    });
    const [selectedGridViewType, setSelectedGridViewType] =
      useState<TDataGridView>(EDataGridView.Values);
    const [selectedDataSource, setSelectedDataSource] = useState<IDataSource>(
      DATA_SOURCE.Effective
    );

    useEffect(() => {
      if (fromRangeDateTime || toRangeDateTime) {
        setDates({
          from: ZonedDateTime.parseIso(
            fromRangeDateTime,
            selectedTimeZone as TTimeZone
          ).toIsoString(),
          to: ZonedDateTime.parseIso(
            toRangeDateTime,
            selectedTimeZone as TTimeZone
          ).toIsoString(),
        });
      }
    }, [fromRangeDateTime, toRangeDateTime, selectedTimeZone]);

    const [isCustomRangeActive, setIsCustomRangeActive] =
      useState<boolean>(false);

    const toggleCustomRangeButton = useCallback(
      () =>
        setIsCustomRangeActive((oldValue) => {
          const newValue = !oldValue;
          setDates({
            from: ZonedDateTime.parseIso(
              fromRangeDateTime,
              selectedTimeZone as TTimeZone
            )
              .startOf('hour')
              .toIsoString(),
            to: ZonedDateTime.parseIso(
              toRangeDateTime,
              selectedTimeZone as TTimeZone
            )
              .startOf('hour')
              .toIsoString(),
          });
          return newValue;
        }),
      [fromRangeDateTime, selectedTimeZone, toRangeDateTime]
    );
    return (
      <Provider
        value={{
          fromRangeDateTime: dates.from,
          toRangeDateTime: dates.to,
          selectedTimeZone,
          selectedParticipant,
          selectedGridViewType,
          setSelectedGridViewType,
          selectedDataSource,
          setSelectedDataSource,
          isCustomRangeActive,
          toggleCustomRangeButton,
          logEvent,
          handleDateRangeSelector,
          handleRangeFilterChange,
          selectedRangeFilterTab,
          selectedPlanTypeTab: selectedUnitTypeTab,
          handleOnChangePlanTypeTab: handleOnChangeUnitTypeTab,
        }}
      >
        {children}
      </Provider>
    );
  };

  return [useOperatingPlanM15Context, OperatingPlanM15Provider] as const;
};

export default createOperatingPlanM15Context;
