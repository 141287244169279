import { ZonedDateTime } from '@pci/pci-ui-library';
import CellEditor from 'components/molecules/CellEditor/CellEditor';
import DateTimeEditor from 'components/molecules/DateEditor/DateTimeEditor';
import SelectEditor from 'components/molecules/SelectEditor/SelectEditor';
import { IIndexable } from 'interfaces/general';
import { IOperatingPlanOverridesValidation } from 'interfaces/resource';
import FormValidation from 'utils/FormValidation';
import {
  generateToken,
  timeExportHeaderValueGetter,
  timeExportValueGetter,
} from 'utils/general';

const zonedOneHourBefore = ZonedDateTime.now(
  ZonedDateTime.defaultTimeZone()
).subtract(20, 'year');
const zonedStartOfHour = zonedOneHourBefore.startOf('year');
const zonedEndOfHour = zonedStartOfHour.add(90, 'year');

const hourStart = zonedStartOfHour.toIsoString();
const hourEnd = zonedEndOfHour.toIsoString();

export const staticOverrideItem: IIndexable = {
  baldn: 100,
  balup: 100,
  downRampRate: 10,
  energy: 100,
  highLimit: 500,
  highRegLimit: 500,
  incrementalCost: {
    sloped: true,
    minLoadCost: 100.0,
    points: [
      {
        mw: 100.0,
        cost: 2000.0,
      },
      {
        mw: 200.0,
        cost: 2500.0,
      },
    ],
  },
  lowLimit: 0,
  lowRegLimit: 50,
  maxBaldn: 100,
  maxBalup: 100,
  maxCap: 500,
  maxNspin: 100,
  maxOfflineBalup: 100,
  maxOfflineNspin: 100,
  maxOfflineSpin: 100,
  maxRegdn: 100,
  maxRegup: 100,
  maxSpin: 100,
  maxSpindn: 100,
  minCap: 0,
  minDownTime: 1440,
  minUpTime: 1440,
  nspin: 100,
  regdn: 100,
  regup: 100,
  resourceIdentifier: 'RESZ_GEN_UNIT',
  spin: 100,
  spindn: 100,
  startupCost: {
    hotStartupCost: 200.0,
    hotToWarmTime: 2,
    warmStartupCost: 300.0,
    hotToColdTime: 3,
    coldStartupCost: 400.0,
  },
  status: 'ONLINE',
  upRampRate: 10,
};

export const columnsDef = [
  {
    field: 'resourceIdentifier',
    headerName: 'Resource',
    minWidth: 130,
    cellEditor: SelectEditor,
    pinned: 'left',
    cellEditorParams: {
      options: [{ value: 'N/A', label: 'N/A' }],
    },
  },
  {
    headerName: 'Start At',
    field: 'startAt',
    minWidth: 200,
    cellEditor: DateTimeEditor,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Stop At',
    field: 'stopAt',
    minWidth: 200,
    cellEditor: DateTimeEditor,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    field: 'modifiedOn',
    headerName: 'Created At',
    minWidth: 200,
    editable: false,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 130,
    maxWidth: 160,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellEditorParams: {
      options: [
        { value: 'ONLINE', label: 'ONLINE' },
        { value: 'FIXED_SCH', label: 'FIXED_SCH' },
        { value: 'FIXED_MW', label: 'FIXED_MW' },
        { value: 'FIXED_AS', label: 'FIXED_AS' },
        { value: 'STANDBY', label: 'STANDBY' },
        { value: 'OUTAGE', label: 'OUTAGE' },
      ],
    },
  },
  {
    field: 'id',
    hide: true,
  },
  {
    headerName: 'Energy\n\n (MW)',
    field: 'energy',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'spin\n\n (MW)',
    field: 'spin',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'spindn\n\n (MW)',
    field: 'spindn',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Regup\n\n (MW)',
    field: 'regup',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'regdn\n\n (MW)',
    field: 'regdn',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'balup\n\n (MW)',
    field: 'balup',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'baldn\n\n (MW)',
    field: 'baldn',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'nspin\n\n (MW)',
    field: 'nspin',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Low \n Limit \n (MW)',
    field: 'lowLimit',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'High \n Limit \n (MW)',
    field: 'highLimit',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Min \n Cap \n (MW)',
    minWidth: 70,
    maxWidth: 70,
    field: 'minCap',
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n Cap \n (MW)',
    minWidth: 70,
    maxWidth: 70,
    field: 'maxCap',
    cellEditor: CellEditor,
  },
  {
    headerName: 'Low Reg \n Limit \n (MW)',
    field: 'lowRegLimit',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'High Reg \n Limit \n (MW)',
    field: 'highRegLimit',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n Spin \n (MW)',
    field: 'maxSpin',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n SpinDn \n (MW)',
    field: 'maxSpindn',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n RegUp \n (MW)',
    field: 'maxRegup',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n RegDn \n (MW)',
    field: 'maxRegdn',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n BalUp \n (MW)',
    field: 'maxBalup',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n BalDn \n (MW)',
    field: 'maxBaldn',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max \n Nspin \n (MW)',
    field: 'maxNspin',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max Offline\n Spin \n (MW)',
    field: 'maxOfflineSpin',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max Offline\n Nspin \n (MW)',
    field: 'maxOfflineNspin',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Max Offline\n BalUp \n (MW)',
    field: 'maxOfflineBalup',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Up Ramp\n Rate \n (MW/Min)',
    field: 'upRampRate',
    minWidth: 70,
    maxWidth: 70,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Down Ramp\n Rate \n (MW/Min)',
    field: 'downRampRate',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Min Up\n Time \n (Minutes)',
    field: 'minUpTime',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
  },
  {
    headerName: 'Min Down\n Time \n (Minutes)',
    field: 'minDownTime',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
  },
];

export const initialNewRowValue = {
  id: { value: generateToken(), error: undefined, touched: undefined },
  resourceIdentifier: {
    value: 'N/A',
    error: undefined,
    touched: undefined,
    required: false,
  },
  startAt: {
    value: hourStart,
    error: undefined,
    touched: undefined,
    required: true,
  },
  stopAt: {
    value: hourEnd,
    error: undefined,
    touched: undefined,
    required: true,
  },
  status: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  stage: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  energy: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  spin: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  spindn: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  regup: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  regdn: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  balup: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  baldn: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  nspin: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  lowLimit: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  highLimit: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  minCap: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxCap: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  lowRegLimit: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  highRegLimit: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxSpin: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxSpindn: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxRegup: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxRegdn: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxBalup: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxBaldn: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxNspin: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxOfflineSpin: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxOfflineNspin: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  maxOfflineBalup: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  upRampRate: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  downRampRate: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  minUpTime: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },
  minDownTime: {
    value: '',
    error: undefined,
    touched: undefined,
    required: false,
  },

  newRecord: true,
};

export const operatingPlanOverridesSchema = (
  values: IOperatingPlanOverridesValidation
) => {
  return {
    resourceIdentifier: FormValidation(values.resourceIdentifier?.value)
      .string()
      .required(),
    startAt: FormValidation(values.startAt?.value).date().required(),
    stopAt: FormValidation(values.stopAt?.value).date().required(),
    baldn: FormValidation(values.baldn?.value).number(),
    balup: FormValidation(values.balup?.value).number(),
    downRampRate: FormValidation(values.downRampRate?.value).number(),
    energy: FormValidation(values.energy?.value).number(),
    status: FormValidation(values.status?.value).string(),
    stage: FormValidation(values.stage?.value).number(),
    highLimit: FormValidation(values.highLimit?.value).number(),
    highRegLimit: FormValidation(values.highRegLimit?.value).number(),
    lowLimit: FormValidation(values.lowLimit?.value).number(),
    lowRegLimit: FormValidation(values.lowRegLimit?.value).number(),
    maxBaldn: FormValidation(values.maxBaldn?.value).number(),
    maxBalup: FormValidation(values.maxBalup?.value).number(),
    maxCap: FormValidation(values.maxCap?.value).number(),
    maxNspin: FormValidation(values.maxNspin?.value).number(),
    maxOfflineBalup: FormValidation(values.maxOfflineBalup?.value).number(),
    maxOfflineNspin: FormValidation(values.maxOfflineNspin?.value).number(),
    maxOfflineSpin: FormValidation(values.maxOfflineSpin?.value).number(),
    maxRegdn: FormValidation(values.maxRegdn?.value).number(),
    maxRegup: FormValidation(values.maxRegup?.value).number(),
    maxSpin: FormValidation(values.maxSpin?.value).number(),
    maxSpindn: FormValidation(values.maxSpindn?.value).number(),
    minCap: FormValidation(values.minCap?.value).number(),
    minDownTime: FormValidation(values.minDownTime?.value).number(),
    minUpTime: FormValidation(values.minUpTime?.value).number(),
    nspin: FormValidation(values.nspin?.value).number(),
    regdn: FormValidation(values.regdn?.value).number(),
    regup: FormValidation(values.regup?.value).number(),
    spin: FormValidation(values.spin?.value).number(),
    spindn: FormValidation(values.spindn?.value).number(),
    upRampRate: FormValidation(values.upRampRate?.value).number(),
  };
};

export const NON_GENUNIT_FIELDS = [
  'maxCap',
  'highRegLimit',
  'maxBaldn',
  'upRampRate',
  'lowLimit',
  'maxOfflineNspin',
  'lowRegLimit',
  'maxSpin',
  'maxOfflineBalup',
  'maxOfflineSpin',
  'maxSpindn',
  'maxNspin',
  'maxRegdn',
  'minDownTime',
  'minUpTime',
  'startupCost',
  'downRampRate',
  'maxBalup',
  'incrementalCost',
  'minCap',
  'highLimit',
  'maxRegup',
  'status',
];
