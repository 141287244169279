import styled, { css } from 'styled-components';
import DashboardGrid from './partials/Grid';
import Information from './partials/Information';
import Options from './partials/Options/Options';

export const TradeDashboard = styled.div`
  display: grid;
  grid-gap: 24px;

  background: #fff;
  grid-template-rows: 2fr 2fr;
  width: 100%;
  padding: 25px;
  height: inherit;
`;

const commonBoxStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BoxTitle = styled.h3`
  color: #213955;
  font-size: 14px;

  margin: 0;
  padding: 8px 0;
`;

const commonHeaderStyle = css`
  margin-bottom: 16px;
`;

export const OptionsContainer = styled(Options)`
  ${commonHeaderStyle}
  margin-bottom: 25px;
`;
export const InformationContainer = styled(Information)`
  ${commonHeaderStyle}
`;

export const ChartsWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 20px;
`;
export const GridsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 27px;
`;

export const ChartBox = styled.div`
  ${commonBoxStyle}
  z-index: 100;
`;

export const Box = styled.div`
  ${commonBoxStyle}
`;

const commonGridStyle = css`
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 auto;

  .ag-root-wrapper {
    border-radius: 8px;
  }

  .ag-cell-wrapper {
    display: block;
  }

  & > div {
    width: 100%;
    height: 100%;
  }
`;

export const BidsGridContainer = styled(DashboardGrid)`
  ${commonGridStyle}
`;
export const OffersGridContainer = styled(DashboardGrid)`
  ${commonGridStyle}
`;
