import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { DateTimePicker } from '@mui/x-date-pickers';
import { ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import TextField from 'components/admin/TextField';

import * as S from './DateEditor.styles';

type TRef = {};

type TProps = {
  [field: string]: any;
};

const DateTimeEditor = React.forwardRef<TRef, TProps>((props, ref) => {
  const {
    eGridCell: { offsetHeight },
  } = props;

  const [value, setValue] = useState<string | null>(
    props.value.value || props.value
  );
  const [field, setField] = useState(props.colDef.field);
  const [error, setError] = useState<string | null>();

  const refInput = useRef<React.RefObject<HTMLInputElement>>(null);

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.current?.focus();
    }
    setField(props.colDef.field);
    // eslint-disable-next-line
  }, [props.colDef.field]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, (): ICellEditorImperativeHandle => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any cell that has an error
        return !!error;
      },

      columnField: () => field,
      validateField: (error) => setError(error),
    };
  });

  return (
    <S.DateEditor cellHeight={offsetHeight}>
      <DateTimePicker
        value={value}
        ampm={false}
        onChange={(newValue: any) => {
          setValue(newValue);
        }}
        renderInput={(params: any) => (
          <TextField
            style={{ height: offsetHeight }}
            onClick={(e) => {}}
            {...params}
          />
        )}
      />
    </S.DateEditor>
  );
});

export default DateTimeEditor;
