import { Button } from '@mui/material';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { PowerResource } from 'api/models/PowerResource.model';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import BreadcrumbsBar from '../BreadcrumbsBar';
import {
  APP_BASE_URL,
  SETUP_RESOURCES_URL,
  SETUP_URL,
} from '../Sidebar/menu.config';
import style from './AddResources.module.scss';
import { BACK } from './constant';
import { IGenUnitValues } from './Forms/GeneratingUnit/IGenUnitValues';

interface IAddResourcesContext {
  resourceToAdd: string;
  setResourceToAdd?: React.Dispatch<React.SetStateAction<string>>;
  children: React.ReactNode[];
  back: Function;
  addResource: Function;
}

const AddResourcesContext = createContext<Partial<IAddResourcesContext>>({});

export const useAddResourcesContext = () => useContext(AddResourcesContext);

type Props = {
  children?: React.ReactNode[];
};

const AddResourcesContextProvider = ({ children }: Props) => {
  const [resourceToAdd, setResourceToAdd] = useState('');
  const [steper, setStep] = useState<number>(0);
  const { path } = useRouteMatch();
  const history = useHistory();
  const { addResources, wizzardButtons, negative } = style;

  const back = () => {
    if (steper > 0) {
      setStep(steper - 1);
      setResourceToAdd('');
    }
  };

  // const next = () => {
  //   if (children && steper < children.length - 1) {
  //     setStep(steper + 1);
  //   }
  // };

  const addResource = async (resource: IGenUnitValues) => {
    const resourceObj: PowerResource = tranform(resource);
    resourceObj.resourceType = resourceToAdd;
    AutoTraderApi.postResources(resourceObj).then((response) => {
      history.push({
        pathname: `${APP_BASE_URL}${SETUP_URL}${SETUP_RESOURCES_URL}`,
      });
    });
  };

  const tranform = (resource: IGenUnitValues) => {
    let transformed: Record<string, any> = {};
    Object.keys(resource).forEach((key) => {
      const keyName = key as keyof IGenUnitValues;
      transformed[keyName] = resource[keyName]?.value;
    });
    return transformed as PowerResource;
  };
  const returnBack = () => history.goBack();

  useEffect(() => {
    if (resourceToAdd !== '') {
      if (children && steper < children.length - 1) {
        setStep(steper + 1);
      }
    }
  }, [resourceToAdd, steper, children]);
  return (
    <AddResourcesContext.Provider
      value={{ resourceToAdd, setResourceToAdd, back, addResource }}
    >
      <div className={addResources}>
        <BreadcrumbsBar path={`${path}`} />
        {children && children[steper]}
        {resourceToAdd === '' && (
          <div className={wizzardButtons}>
            <Button
              className={negative}
              variant='contained'
              size='medium'
              onClick={() => (steper === 0 ? returnBack() : back())}
            >
              {BACK}
            </Button>
            {/* <Button
            disabled={resourceToAdd === ''}
            className={positive}
            variant='contained'
            size='medium'
            onClick={() => next()}
          >
            {children && steper < children.length - 1 ? CONTINUE : ADD}
          </Button> */}
          </div>
        )}
      </div>
    </AddResourcesContext.Provider>
  );
};

export { AddResourcesContextProvider };
