import Layout from 'App/layout/Layout';
import { Route } from 'react-router-dom';
import SetupPages from './Setup';
import TradePages from './Trade';
import ReportsPages from './Reports';
import AdminPages from './Admin';
import PlanPages from './Plan';
import SupportPages from './Support';
import { sideMenu } from 'components/admin/Sidebar/menu.config';

const index = () => {
  return (
    <Layout sideMenu={sideMenu}>
      <Route path='*/administration' component={AdminPages} />
      <Route path='*/setup' component={SetupPages} />
      <Route path='*/plan' component={PlanPages} />
      <Route path='*/trade' component={TradePages} />
      <Route path='*/reports' component={ReportsPages} />
      <Route path='*/support' component={SupportPages} />
    </Layout>
  );
};

export default index;
