import { ErrorOutlineOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import React from 'react';
import * as S from './BidsOffersStatus.styles';

export interface ImageCellRendererParams extends ICellRendererParams {
  rendererStatus: string;
  rendererMessage: string;
}

interface TradeMessages {
  status: string;
  messages: string[];
}

const BidsOffersStatusRender = (props: ImageCellRendererParams) => {
  const { status, messages }: TradeMessages = props.data;
  const tooltipStyleProps = {
    tooltip: {
      sx: {
        color: '#0C1725',
        backgroundColor: '#FFFFFF',
        borderRadius: '8px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.2px',
        padding: '16px',
        filter:
          'drop-shadow(0px 0px 4px rgba(13, 26, 42, 0.1)) drop-shadow(0px 2px 12px rgba(63, 76, 130, 0.1))',
      },
    },
    arrow: {
      sx: {
        color: '#FFFFFF',
      },
    },
  };

  return (
    <span>
      {new Array(props.value).fill('').map((index) => (
        <S.StatusWrapper key={index}>
          <S.StatusBox>
            <span
              className={`bidsoffersStatusLabel_${status
                .toLowerCase()
                .replace(' ', '')}`}
            >
              {status.replace('_', ' ').toLowerCase()}
            </span>
          </S.StatusBox>
          {messages.length >= 1 && (
            <S.StatusBox>
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography color='inherit'>Error details:</Typography>
                    <ul>
                      {messages.map((message: string, i) => {
                        return (
                          <li key={`message-${index}-${i}`}>${message}</li>
                        );
                      })}
                    </ul>
                  </React.Fragment>
                }
                arrow
                placement='top'
                componentsProps={tooltipStyleProps}
              >
                <ErrorOutlineOutlined
                  className={`bidsoffersStatusLabel_${status
                    .toLowerCase()
                    .replace(' ', '')}_icon`}
                />
              </Tooltip>
            </S.StatusBox>
          )}
        </S.StatusWrapper>
      ))}
    </span>
  );
};

export default BidsOffersStatusRender;
