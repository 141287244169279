import React from 'react';

const PageNotPermissions = () => {
  return (
    <div
      id='wrapper'
      style={{
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div>
        <h1 style={{ fontSize: '8rem' }}>401</h1>
      </div>
      <div id='info'>
        <h3>
          Sorry, your account does not have any permissions, please contact your
          Auth0 Administrator.
        </h3>
      </div>
    </div>
  );
};

export default PageNotPermissions;
