import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import { SourceSink } from 'api/models/SourceSink.model';
import { AppStore } from 'redux/store';
import { useSelector } from 'react-redux';

type TRef = {};

type TProps = {
  [field: string]: any;
};

const SourceSinkRender = React.forwardRef<TRef, TProps>((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [field, setField] = useState(props.colDef.field);
  const [error, setError] = useState<string | null>();
  const [optionList, setOptionList] = useState<any[]>();

  const refInput = useRef<React.RefObject<HTMLInputElement>>(null);

  const { sources, sinks, sourcesSinks } = useSelector(
    (store: AppStore) => store.sourcesandsink
  );

  useEffect(() => {
    if (sourcesSinks && sources && sinks) {
      const sands = sourcesSinks.map((item: SourceSink) => {
        return { value: item.value, label: item.value };
      });
      const extras =
        field === 'source'
          ? sources.map((item: SourceSink) => {
              return { value: item.value, label: item.value };
            })
          : sinks.map((item: SourceSink) => {
              return { value: item.value, label: item.value };
            });

      setOptionList([
        { value: 'Default', label: 'Default' },
        ...extras,
        ...sands,
      ]);
    } else {
      setOptionList([{ value: 'Default', label: 'Default' }]);
    }
  }, [sourcesSinks, sources, sinks, field]);

  useEffect(() => {
    if (typeof props.value === 'string') {
      setValue(props.value);
    }

    if (typeof props.value === 'object') {
      setValue(props.value.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.current?.focus();
    }
    setField(props.colDef.field);
  }, [props.colDef.field]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, (): ICellEditorImperativeHandle => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any cell that has an error
        return !!error;
      },

      columnField: () => field,
      validateField: (error) => setError(error),
    };
  });

  const sourceSinksOptions = optionList?.filter(
    (item) => item.value === value
  )[0];
  return <div>{sourceSinksOptions?.label}</div>;
});

export default SourceSinkRender;
