import { Component } from 'react';
import {
  GetTokenViaAuthorizationCodeGrantError,
  RefreshTokenError,
} from '../../utils/auth';
import { LOGIN } from 'constants/routes';

interface IProps {}

interface IState {
  errorMessage: string | undefined;
  tryLogInAgain: boolean;
}

/**
 * See https://reactjs.org/docs/error-boundaries.html
 * Does not handle async errors by default.
 * See https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/
 * for workaround for async errors.
 */
export class TokenFetchErrorBoundary extends Component<IProps, IState> {
  /**
   * Promise rejection handler is from
   * https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/
   */
  private readonly promiseRejectionHandler = (event: PromiseRejectionEvent) => {
    this.setState(
      TokenFetchErrorBoundary.getDerivedStateFromError(event.reason)
    );
  };

  /**
   * Error boundary cannot be done in functonal style.
   */
  constructor(props: IProps) {
    super(props);
    this.state = { errorMessage: undefined, tryLogInAgain: true };
  }

  /**
   * A React component is an error boundary iff it implements
   * this static method or componentDidCatch(error, errorInfo)
   * or both.
   * @param error the caught error
   */
  static getDerivedStateFromError(error: any) {
    if (error instanceof RefreshTokenError) {
      return {
        errorMessage: 'Could not refresh credentials.',
        tryLogInAgain: true,
      };
    }
    if (error instanceof GetTokenViaAuthorizationCodeGrantError) {
      return {
        errorMessage:
          'Something went wrong getting credentials. Please try again later.',
        tryLogInAgain: false,
      };
    }
    return {
      errorMessage: undefined,
      tryLogInAgain: false,
    };
  }

  /**
   * Promise rejection handler is from
   * https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/
   */
  componentDidMount() {
    // Add an event listener to the window to catch unhandled promise rejections & stash the error in the state
    window.addEventListener('unhandledrejection', this.promiseRejectionHandler);
  }

  /**
   * Promise rejection handler is from
   * https://eddiewould.com/2021/28/28/handling-rejected-promises-error-boundary-react/
   */
  componentWillUnmount() {
    window.removeEventListener(
      'unhandledrejection',
      this.promiseRejectionHandler
    );
  }

  render() {
    if (this.state.errorMessage !== undefined) {
      const loc = new URL(window.location.href);
      loc.pathname = LOGIN;
      window.location.replace(loc.toString());
    }
    return this.props.children;
  }
}
