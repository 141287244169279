import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorImperativeHandle } from 'interfaces/dataGrid';
import { ZonedDateTime } from '@pci/pci-ui-library';
import { pickSelectedTimezone } from 'redux/states/miscellaneous.state';

import { useSelector } from 'react-redux';

type TRef = {};

type TProps = {
  [field: string]: any;
};

const CellRender = React.forwardRef<TRef, TProps>((props, ref) => {
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { type } = props;

  const [value, _setValue] = useState('');
  const [maskOn, setMaskOn] = useState(false);
  const [field, setField] = useState(props.colDef.field);
  const [error, setError] = useState<string | null>();

  const refInput = useRef<React.RefObject<HTMLInputElement>>(null);

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.current?.focus();
    }
    setField(props.colDef.field);
  }, [props.colDef.field]);

  useEffect(() => {
    let newValue = '';
    let value;

    if (props?.value?.required) {
      value =
        props?.value?.required === false && !props?.value?.value
          ? ''
          : props?.value?.value || props?.value || '';
    } else {
      if (typeof props.value === 'string') {
        value = props.value;
      } else if (typeof props.value === 'object') {
        if (props.value instanceof Date) {
          value = props.value;
        } else {
          value =
            props?.value?.value === undefined || props?.value?.value === null
              ? ''
              : props?.value?.value;
        }
      } else {
        value = props.value !== undefined ? props.value : '';
      }
    }

    if (type === 'radio') {
      newValue = value ? 'YES' : 'NO';
    } else if (type === 'date') {
      if (typeof value === 'object') {
        const date = new Date(
          value.getFullYear(),
          value.getMonth(),
          value.getDate()
        );
        const [year, month, day] = date.toISOString().split('T')[0].split('-');
        newValue = [month, day, year].join(`/`);
      } else {
        const date = value.split('T')[0];
        const [year, month, day] = date.split('-');
        newValue = [month, day, year].join(`/`);
      }
    } else if (type === 'datetime') {
      let zonedTime;
      if (value !== '') {
        zonedTime = ZonedDateTime.fromDate(
          new Date(value),
          selectedTimeZone ? selectedTimeZone : ZonedDateTime.defaultTimeZone()
        );
        newValue = value !== null ? zonedTime.format('MM/DD/YYYY HH:mm') : '';
      }
    } else if (type === 'dateTimeDay') {
      let zonedTime;
      if (value !== '') {
        zonedTime = ZonedDateTime.fromDate(
          new Date(value),
          selectedTimeZone ? selectedTimeZone : ZonedDateTime.defaultTimeZone()
        );
        newValue = value !== null ? zonedTime.format('MM/DD/YYYY') : '';
      }
    } else if (type === 'dateTimeMonth') {
      let zonedTime;
      if (value !== '') {
        zonedTime = ZonedDateTime.fromDate(
          new Date(value),
          selectedTimeZone ? selectedTimeZone : ZonedDateTime.defaultTimeZone()
        );
        newValue = value !== null ? zonedTime.format('MMM-DD') : '';
      }
    } else if (type === 'epoch') {
      let zonedTime;
      const dateFromEpoch = new Date(0);
      dateFromEpoch.setUTCSeconds(value);
      if (value !== '') {
        zonedTime = ZonedDateTime.fromDate(
          dateFromEpoch,
          selectedTimeZone ? selectedTimeZone : ZonedDateTime.defaultTimeZone()
        );
        newValue = value !== null ? zonedTime.format('MM/DD/YYYY HH:mm') : '';
      }
    } else if (type === 'password') {
      setMaskOn(true);
    } else if (type === 'selector') {
      if (props.options) {
        const options: any[] = props.options;
        const toFilter = value;
        const filtred = options.filter(
          (option) => option.value === toFilter
        )[0];
        newValue = filtred.label;
      }
    } else {
      newValue = value;
    }
    _setValue(newValue);
    // eslint-disable-next-line
  }, [props.value, props.options, type]);

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, (): ICellEditorImperativeHandle => {
    return {
      // the final value to send to the grid, on completion of editing
      getValue() {
        // this simple editor doubles any value entered into the input
        return value;
      },

      // Gets called once before editing starts, to give editor a chance to
      // cancel the editing before it even starts.
      isCancelBeforeStart() {
        return false;
      },

      // Gets called once when editing is finished (eg if Enter is pressed).
      // If you return true, then the result of the edit will be ignored.
      isCancelAfterEnd() {
        // our editor will reject any cell that has an error
        return !!error;
      },

      columnField: () => field,
      validateField: (error) => setError(error),
      mask: (onOrNot) => setMaskOn(onOrNot),
    };
  });

  return maskOn ? <div>•••••••••••</div> : <div>{`${value}`}</div>;
});

export default CellRender;
