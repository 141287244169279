import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { Button } from '@mui/material';

import { useAppContext } from 'App/AppProvider';
import CellRender from 'components/molecules/CellRender/CellRender';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MatchedTrade } from 'api/models/MatchedTrade.model';
import FolioSkeleton from 'components/admin/GridSkeleton/GridSkeleton';
import DateRangeSelector, {
  useDateRangeSelector,
} from 'components/admin/DateRangeSelector';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';
import { keepLocalTimezone } from 'utils/dateTime';

import styles from './MatchedTradesPage.module.scss';
import * as S from '../OperatingPlan/OperatingPlan.styles';
import * as SM from '../OperatingPlan/M15/M15.styles';
import { MVP_TIMEZONES } from 'constants/general';
import {
  EResolution,
  HOUR_FILTER,
  HOURS_4_FILTER,
  HOURS_24_FILTER,
  MONTH_FILTER,
  MONTHS_3_FILTER,
  MONTHS_12_FILTER,
  WEEK_FILTER,
} from 'components/molecules/RangeFilterTab/constants';
import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';
import useRangeFilterTabs from 'components/molecules/RangeFilterTab/useRangeFilterTabs';
import { ITimeInterval } from 'components/molecules/RangeFilterTab/types';
import { getTimeIntervalSetup } from '../Performance/helpers';
import usePreferences from 'hooks/usePreferences';
import { useLocation } from 'react-router-dom';
import {
  pickSelectedParticipant,
  pickSelectedTimezone,
  updateMiscellaneous,
} from 'redux/states/miscellaneous.state';

import { useDispatch, useSelector } from 'react-redux';
import {
  DATA_SOURCE,
  IDataSource,
  ITimeResolution,
  dynamicColumnsDef,
} from './helpers';
import { AppDispatch } from 'redux/store';
import {
  fetchMatchedTrades,
  selectMatchedTrades,
} from 'redux/states/matchedtrades.state';

const INTERVALS: ITimeInterval[] = [
  HOUR_FILTER,
  HOURS_4_FILTER,
  HOURS_24_FILTER,
];

const DAILY_INTERVALS: ITimeInterval[] = [
  HOURS_24_FILTER,
  WEEK_FILTER,
  MONTH_FILTER,
];

const MONTHLY_INTERVALS: ITimeInterval[] = [
  MONTH_FILTER,
  MONTHS_3_FILTER,
  MONTHS_12_FILTER,
];

const MatchedTradesPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const matchedTradesRecords = useSelector(selectMatchedTrades);
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const { logEvent } = useAppContext();
  const [isCustomRangeActive, setIsCustomRangeActive] =
    useState<boolean>(false);
  const {
    handleRangeFilterChange,
    selectedRangeFilterTab,
    setSelectedRangeFilterTab,
  } = useRangeFilterTabs(HOUR_FILTER);
  const [startTime, setStartTime] = useState<string>('');
  const [endTime, setEndTime] = useState<string>('');

  const { intervalPreferences } = usePreferences();
  const { pathname } = useLocation();
  const { fromRangeDateTime, toRangeDateTime, handleDateRangeSelector } =
    useDateRangeSelector(selectedTimeZone as TTimeZone);
  const { matchedtrades, matchedtradesoptions, filterBtn, intervalOptions } =
    styles;
  const { pushNotification } = useAppContext();

  const defaultColDef = useMemo(() => {
    return {
      autoHeight: true,
      wrapText: true,
    };
  }, []);

  const [matchedTradesList, setMatchedTradesList] = useState<MatchedTrade[]>();
  const [loadingMatchedTrades, setLoadingMatchedTrades] =
    useState<boolean>(true);
  const [gridApiMatchedTrades, setGridApiMatchedTrades] = useState<GridApi>();

  const switchFilter = () => {
    setIsCustomRangeActive(!isCustomRangeActive);
  };

  const [selectedDataSource, setSelectedDataSource] = useState<IDataSource>(
    DATA_SOURCE.Interval
  );

  const [timeResolution, setTimeResolution] = useState<ITimeResolution>(
    EResolution.NONE
  );

  const getMatchedTrades = useCallback(
    async (startTime: string, endTime: string, selectedTimeZone: string) => {
      try {
        setLoadingMatchedTrades(true);
        const timezone = MVP_TIMEZONES.filter(
          (timeZone) => timeZone.zone === selectedTimeZone
        )[0];
        const request = {
          marketParticipantId: selectedParticipant && selectedParticipant.id,
          startAt: encodeURIComponent(
            ZonedDateTime.parseIso(
              startTime,
              selectedTimeZone as TTimeZone
            ).toIsoString()
          ),
          stopAt: encodeURIComponent(
            ZonedDateTime.parseIso(
              endTime,
              selectedTimeZone as TTimeZone
            ).toIsoString()
          ),
          timeResolution: timeResolution,
          outputTimeZone: timezone.identifier,
        };

        if (request.marketParticipantId === undefined) {
          setLoadingMatchedTrades(false);
          return;
        }

        dispatch(fetchMatchedTrades(request));

        setLoadingMatchedTrades(false);
      } catch (error: any) {
        setMatchedTradesList([]);
        setLoadingMatchedTrades(false);
      }
    },
    [
      setMatchedTradesList,
      setLoadingMatchedTrades,
      selectedParticipant,
      dispatch,
      timeResolution,
    ]
  );

  const onGridMatchedTradesReadyHandler = (gridEvent: GridReadyEvent) => {
    gridEvent.api.setHeaderHeight(70);
    gridEvent.api.sizeColumnsToFit();
    setGridApiMatchedTrades(gridEvent.api);
  };

  const onRefreshData = useCallback(() => {
    setMatchedTradesList([]);
    getMatchedTrades(startTime, endTime, selectedTimeZone);
    // eslint-disable-next-line
  }, [startTime, endTime, getMatchedTrades]);

  const dateFromFilterTab = useCallback(
    (selectedFilter: ITimeInterval, now: ZonedDateTime) => {
      const timeIntervalSetup = getTimeIntervalSetup(selectedFilter, now);
      const { startAt: fromDate, stopAt: toDate } = timeIntervalSetup;

      setStartTime(fromDate);
      setEndTime(toDate);
    },
    [setStartTime, setEndTime]
  );

  const dateFromRange = useCallback(
    (timeZone: TTimeZone) => {
      const fromDate = ZonedDateTime.parseIso(fromRangeDateTime, timeZone);
      const toDate = ZonedDateTime.parseIso(toRangeDateTime, timeZone);

      const saveDates = (fromDate: string, toDate: string) => {
        setStartTime(fromDate);
        setEndTime(toDate);
      };

      const daysdiff = toDate.diff(fromDate, 'days');
      if (
        timeResolution === EResolution.NONE &&
        daysdiff > 32 &&
        pushNotification
      ) {
        pushNotification(
          'error',
          'You can only request 31 days of 15-minute data.'
        );
      } else if (
        timeResolution === EResolution.DAILY &&
        daysdiff > 366 &&
        pushNotification
      ) {
        pushNotification(
          'error',
          'You can only request 365 days of daily data.'
        );
      } else {
        saveDates(fromDate.toIsoString(), toDate.toIsoString());
      }
    },
    [
      setStartTime,
      setEndTime,
      fromRangeDateTime,
      toRangeDateTime,
      pushNotification,
      timeResolution,
    ]
  );

  useEffect(() => {
    if (logEvent) {
      logEvent({
        eventTime: new Date(),
        eventName: Event.VIEWEDMATCHEDTRADESPAGE,
        category: Category.MATCHED_TRADES_PAGE,
      });
    }
  }, [logEvent]);

  useEffect(() => {
    const intervalPreference = intervalPreferences?.find(
      (interval) => interval.page === pathname
    );
    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'BASIC'
    ) {
      setIsCustomRangeActive(false);
    }

    if (
      intervalPreference?.interval &&
      intervalPreference.intervalType === 'CUSTOM'
    ) {
      setIsCustomRangeActive(true);
    }
  }, [intervalPreferences, pathname]);

  useEffect(() => {
    if (selectedTimeZone) {
      gridApiMatchedTrades?.refreshHeader();
      dateFromRange(selectedTimeZone);
    }
    // eslint-disable-next-line
  }, [fromRangeDateTime, toRangeDateTime, dateFromRange]);

  useEffect(() => {
    if (selectedTimeZone && selectedRangeFilterTab) {
      const now = ZonedDateTime.now(selectedTimeZone as TTimeZone);
      gridApiMatchedTrades?.refreshHeader();
      dateFromFilterTab(selectedRangeFilterTab, now);
    }
  }, [
    gridApiMatchedTrades,
    selectedRangeFilterTab,
    selectedRangeFilterTab.key,
    dateFromFilterTab,
    selectedTimeZone,
  ]);

  useEffect(() => {
    if (startTime && endTime) {
      setMatchedTradesList([]);
      getMatchedTrades(startTime, endTime, selectedTimeZone);
    }
    // eslint-disable-next-line
  }, [startTime, endTime, setMatchedTradesList, getMatchedTrades]);

  useEffect(() => {
    if (matchedTradesRecords) {
      if (matchedTradesRecords.length >= 1) {
        setMatchedTradesList(matchedTradesRecords);
      }
    } else {
      setMatchedTradesList([]);
    }
  }, [matchedTradesRecords]);

  const setDataSource = useCallback(
    (source: string) => {
      if (source) {
        switch (source) {
          case DATA_SOURCE.Interval:
            setTimeResolution(EResolution.NONE);
            break;
          case DATA_SOURCE.Daily:
            setTimeResolution(EResolution.DAILY);
            break;
          case DATA_SOURCE.Monthly:
            setTimeResolution(EResolution.MONTHLY);
        }
      }
      return setSelectedDataSource(source);
    },
    [setSelectedDataSource]
  );

  const dataSourceSwitchRender = useMemo(() => {
    const { Interval, Daily, Monthly } = DATA_SOURCE;
    return [Interval, Daily, Monthly].map((source) => (
      <SM.DataSourceButton
        key={source}
        label={`${source.substring(0, 1).toUpperCase()}${source.substring(1)}`}
        id={`source_${source.toLowerCase()}`}
        isActive={selectedDataSource === source}
        onClick={() => setDataSource(source)}
      />
    ));
    // eslint-disable-next-line
  }, [selectedDataSource, setSelectedDataSource]);

  const handleColumnDefs = useMemo(() => {
    const colDef = dynamicColumnsDef({ timeResolution });
    gridApiMatchedTrades?.setColumnDefs(colDef);
    return colDef;
  }, [gridApiMatchedTrades, timeResolution]);

  const handleRangeIntervals = useMemo(() => {
    switch (timeResolution) {
      case EResolution.NONE: {
        setSelectedRangeFilterTab(HOUR_FILTER);
        dispatch(updateMiscellaneous({ lockedTimezone: false }));
        return INTERVALS;
      }
      case EResolution.DAILY: {
        setSelectedRangeFilterTab(HOURS_24_FILTER);
        dispatch(updateMiscellaneous({ lockedTimezone: true }));

        return DAILY_INTERVALS;
      }
      case EResolution.MONTHLY: {
        setSelectedRangeFilterTab(MONTH_FILTER);
        dispatch(updateMiscellaneous({ lockedTimezone: true }));

        return MONTHLY_INTERVALS;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeResolution]);

  useEffect(() => {
    return () => {
      dispatch(updateMiscellaneous({ lockedTimezone: false }));
    };
  }, [dispatch]);

  return (
    <div className={matchedtrades}>
      <S.Wrapper style={{ height: '90%' }}>
        <S.PageTitle data-testid='title'>Matched Trades</S.PageTitle>
        <FolioSkeleton
          fullHeight
          onClickReset={onRefreshData}
          defaultDefs={defaultColDef}
          components={{ CellRender: CellRender }}
          columnDefs={handleColumnDefs}
          data={matchedTradesList}
          loading={loadingMatchedTrades}
          onGridReady={onGridMatchedTradesReadyHandler}
          canEdit={false}
          canAdd={false}
          canReset
          headerHeight={60}
          toolExtra={
            <div className={matchedtradesoptions}>
              {isCustomRangeActive ? (
                <DateRangeSelector
                  startDate={keepLocalTimezone(
                    fromRangeDateTime,
                    selectedTimeZone as TTimeZone
                  )}
                  endDate={keepLocalTimezone(
                    toRangeDateTime,
                    selectedTimeZone as TTimeZone
                  )}
                  handleDateRangeSelector={handleDateRangeSelector}
                  timezone={selectedTimeZone as TTimeZone}
                />
              ) : (
                <RangeFilterTab
                  tab={selectedRangeFilterTab.key}
                  handleChange={handleRangeFilterChange}
                  timeIntervals={handleRangeIntervals}
                />
              )}
              <div className={intervalOptions}>
                <Button
                  className={filterBtn}
                  variant='contained'
                  size='medium'
                  onClick={switchFilter}
                >
                  {!isCustomRangeActive ? 'Custom Range' : 'Basic Range'}
                </Button>
              </div>
              <SM.DataSourceWrapper>
                {dataSourceSwitchRender}
              </SM.DataSourceWrapper>
            </div>
          }
        />
      </S.Wrapper>
    </div>
  );
};

export default MatchedTradesPage;
