import {
  Dictionary,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { Meter } from 'api/models/Meter.model';
import { MeterStatus } from 'api/models/MeterStatus.model';
import { PowerResource } from 'api/models/PowerResource.model';
import { isEmpty, keyBy } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';
import { AppStore } from 'redux/store';

const STORE_NAME = 'meters';

const metersAdapter = createEntityAdapter<Meter>({
  selectId: (meter) => `${meter.tagId}_${meter.resourceIdentifier}`,
  sortComparer: (a, b) =>
    `${a.tagId}_${a.resourceIdentifier}`.localeCompare(
      `${b.tagId}_${b.resourceIdentifier}`
    ),
});

const initialState = metersAdapter.getInitialState({
  resourcesStatus: IDLE,
  metersStatus: IDLE,
  meterStatusesStatus: IDLE,
  resources: {} as Dictionary<PowerResource>,
  meterStatuses: {} as Dictionary<MeterStatus>,
});

export const fetchMeters = createAsyncThunk(
  `${STORE_NAME}/fetchMeters`,
  async () => {
    const meters = await AutoTraderApi.getMeters();
    if (!isEmpty(meters)) {
      return meters as Meter[];
    } else {
      return [];
    }
  }
);

export const fetchResourcesForMeters = createAsyncThunk(
  `${STORE_NAME}/fetchResourcesForMeters`,
  async () => {
    const { data, status } = await AutoTraderApi.getResources();

    if (status >= 200 && status <= 400) {
      return keyBy<PowerResource>(data, 'resourceIdentifier');
    } else {
      return {};
    }
  }
);

export const fetchMeterStatuses = createAsyncThunk(
  `${STORE_NAME}/fetchMeterStatuses`,
  async () => {
    const meterStatuses = await AutoTraderApi.getMetersStatus();

    if (!isEmpty(meterStatuses)) {
      return keyBy<MeterStatus>(meterStatuses, 'tagId');
    } else {
      return {};
    }
  }
);

export const metersSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    metersUpsert: (state, action) => {
      metersAdapter.upsertOne(state, action.payload);
      state.resourcesStatus = FULFILLED;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMeters.pending, (state) => {
        state.metersStatus = LOADING;
      })
      .addCase(fetchMeters.fulfilled, (state, action) => {
        metersAdapter.upsertMany(state, action.payload);
        state.metersStatus = FULFILLED;
      })
      .addCase(fetchResourcesForMeters.pending, (state) => {
        state.resourcesStatus = LOADING;
      })
      .addCase(fetchResourcesForMeters.fulfilled, (state, action) => {
        state.resources = action.payload;
        state.resourcesStatus = FULFILLED;
      })
      .addCase(fetchMeterStatuses.pending, (state) => {
        state.meterStatusesStatus = LOADING;
      })
      .addCase(fetchMeterStatuses.fulfilled, (state, action) => {
        state.meterStatuses = action.payload;
        state.meterStatusesStatus = FULFILLED;
      });
  },
});

export const { metersUpsert } = metersSlice.actions;

const pickResourcesIdentifiers = (resources: Dictionary<PowerResource>) =>
  Object.keys(resources);

const pickFullMeters = (meters: Meter[], statuses: Dictionary<MeterStatus>) => {
  return meters.map(
    ({
      tagId,
      dataType,
      intervalType,
      unitOfMeasure,
      offThreshold,
      clockTolerance,
      description,
      source,
      resourceIdentifier,
      measurementType,
    }) => {
      const statusOfMeter: MeterStatus | undefined = statuses[tagId];
      return {
        tagId,
        dataType,
        intervalType,
        unitOfMeasure,
        offThreshold: offThreshold?.toString(),
        clockTolerance: clockTolerance?.toString(),
        resourceIdentifier,
        measurementType,
        description,
        source,
        timestamp: statusOfMeter?.timestamp || '',
        status: statusOfMeter?.status || '-',
        timeInStatus: statusOfMeter?.timeInStatus || 0,
        lastUpdateTime: statusOfMeter?.lastUpdateTime || '',
        value: statusOfMeter?.value || 'No data',
      } as Meter;
    }
  );
};

export const selectResourcesIdentifiers = createSelector(
  (state: AppStore) => state.meters.resources,
  pickResourcesIdentifiers
);

export const selectFullMeters = createSelector(
  (state: AppStore) => Object.values(state.meters.entities) as Meter[],
  (state: AppStore) => state.meters.meterStatuses,
  pickFullMeters
);

export default metersSlice.reducer;
