import BidLineChart from 'components/market/organisms/MarketTrader/TraderChart/BidOfferLineChart/BidOfferLineChart';
import { transformMarketBidOfferData } from './helpers';
import { ITransformedMarketBidOfferData } from 'components/market/organisms/MarketTrader/TraderChart/types';
import { IBids, IBidsOffersFiltered, IOffers } from 'interfaces/bidOffer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSessionContext } from 'pages/admin/Trade/context';

import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppStore } from 'redux/store';
import { fetchTradeStack } from 'redux/states/bidsoffers.state';
import { FULFILLED } from 'redux/constants';
import { Skeleton } from '@mui/material';

const BidsOffersChart = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const { tradeStack, tradeStackStatus } = useSelector(
    (store: AppStore) => store.bidsoffers
  );
  const { sessionObj } = useSessionContext();
  const selectedParticipant = useSelector(pickSelectedParticipant);
  const selectedTimeZone = useSelector(pickSelectedTimezone);
  const [bidsOffers, setBidsOffers] = useState<IBidsOffersFiltered>();

  const getBidsOffers = useCallback(() => {
    try {
      if (tradeStack) {
        let bidList = tradeStack[0]?.bids.map((bid) => {
          const newBid: IBids = Object.assign({
            resourceIdentifier: bid.resourceIdentifier,
            planQuantity: bid.planQuantity,
            planCost: bid.planCost,
            tradeQuantity: bid.tradeQuantity,
            tradePrice: bid.tradePrice,
            location: bid.location,
          });
          return newBid;
        });

        let offerList = tradeStack[0]?.offers.map((offer) => {
          const newOffer: IOffers = Object.assign({
            resourceIdentifier: offer.resourceIdentifier,
            planQuantity: offer.planQuantity,
            planCost: offer.planCost,
            tradeQuantity: offer.tradeQuantity,
            tradePrice: offer.tradePrice,
            location: offer.location,
          });
          return newOffer;
        });

        const mergedBidsOffers: IBidsOffersFiltered = Object.assign({
          bids: bidList,
          offers: offerList,
        });

        setBidsOffers(mergedBidsOffers);
      } else {
        const mergedBidsOffers: IBidsOffersFiltered = Object.assign({
          bids: [],
          offers: [],
        });
        setBidsOffers(mergedBidsOffers);
      }
    } catch (error: any) {
      // pushNotification('error', `Error has occured retrieving bids and offers list`);
    }
  }, [tradeStack]);

  useEffect(() => {
    if (
      selectedParticipant &&
      selectedParticipant?.id &&
      document.readyState === 'complete'
    ) {
      const request = {
        marketParticipantId: selectedParticipant.id,
        startAt: sessionObj.toIsoString().replace('+', '-'),
      };
      dispatch(fetchTradeStack(request));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipant?.id, sessionObj, selectedTimeZone]);

  useEffect(() => {
    if (tradeStackStatus === FULFILLED) {
      getBidsOffers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradeStackStatus]);

  const data: ITransformedMarketBidOfferData = useMemo(
    (): ITransformedMarketBidOfferData =>
      transformMarketBidOfferData(
        bidsOffers,
        selectedParticipant?.strategy?.bidStrategy,
        selectedParticipant?.strategy?.offerStrategy
      ),
    [
      bidsOffers,
      selectedParticipant?.strategy?.bidStrategy,
      selectedParticipant?.strategy?.offerStrategy,
    ]
  );
  return bidsOffers ? (
    <BidLineChart
      chartId={`market-bid-line-chart-${selectedParticipant?.id}`}
      data={data}
    />
  ) : (
    <Skeleton variant='rounded' width={'100%'} height={'100%'} />
  );
};

export default BidsOffersChart;
