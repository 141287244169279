import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import Loader from 'components/molecules/Loader/Loader';
import {
  AutoTraderApi,
  AutoTraderApiOauth,
  IAutoTraderApi,
  OAuth2Wrapper,
} from '../api/AutoTraderAPI';
import { useSnackbar, VariantType } from 'notistack';
import useLogger from 'hooks/useLogger';
import { Logger } from 'services/StructuredLogging/logger';
import useApiConfig from 'hooks/useApiConfig';
import { useLocation } from 'react-router-dom';
import {
  SUPPORT_URL_AUTH_GUIDE,
  SUPPORT_URL_RELEASE_NOTES,
} from 'components/admin/Sidebar/menu.config';
import { useDispatch, useSelector } from 'react-redux';
import RootSore, { AppDispatch, AppStore } from 'redux/store';
import { fetchParticipants } from 'redux/states/participants.state';
import {
  pickSelectedParticipant,
  updateMiscellaneous,
} from 'redux/states/miscellaneous.state';
import { fetchPreferences } from 'redux/states/preferences.state';
import { FULFILLED } from 'redux/constants';
import usePreferences from 'hooks/usePreferences';
import { fetchSourcesAndSinks } from 'redux/states/sourcesandsinks.state';
import { updateAuthentication } from 'redux/states/authentication.state';

interface IAppContext {
  isCSMUser: boolean;
  gettingOauthConfig: boolean;
  setIsCSMUser?: React.Dispatch<React.SetStateAction<boolean>>;
  setLoadScreen?: React.Dispatch<React.SetStateAction<boolean>>;
  tokens: {};
  oauth2Wrapper?: OAuth2Wrapper;
  pushNotification: (variant: VariantType, message: string) => void;
  loadParticipants: () => void;
  logEvent: (log: Logger) => Promise<Logger>;
  tenantAuthorization: (tenantId: string | undefined) => Promise<unknown>;
}

export const AppContext = createContext<Partial<IAppContext>>({});

export const useAppContext = () => useContext(AppContext);

const AppContextProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();
  const { setPreference } = usePreferences();
  // REDUX
  const dispatch = useDispatch<AppDispatch>();
  const { status: participantsStatus } = useSelector(
    (store: AppStore) => store.participant
  );
  const {
    status: preferencesStatus,
    selectedMarketParticipant,
    timezone: selectedTimezone,
    lastPageSeen,
    unitTypePreference,
  } = useSelector((store: AppStore) => store.preferences);
  const { tenantId, authenticateduser, sub, username } = useSelector(
    (store: AppStore) => store.authentication
  );

  const [isCSMUser, setIsCSMUser] = useState<boolean>(false);

  const [loadScreen, setLoadScreen] = useState(false);
  const [oauth2Wrapper, setOauth2Wrapper] = useState<OAuth2Wrapper | undefined>(
    undefined
  );
  const [autoTraderApi, setAutoTraderApi] = useState<
    IAutoTraderApi | undefined
  >(undefined);

  const { enqueueSnackbar } = useSnackbar();
  const { logEvent } = useLogger(
    useSelector(pickSelectedParticipant),
    username,
    sub
  );
  const { isValid, OAuth2FromTenant, loaded: configLoaded } = useApiConfig();

  const pushNotification = useCallback(
    (variant: VariantType, message: string) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    },
    [enqueueSnackbar]
  );

  const tenantAuthorization = async (
    tenantId: string | undefined
  ): Promise<void> => {
    setLoadScreen(true);
    if (tenantId && isValid(tenantId)) {
      const oauth2WrapperLocal = await OAuth2FromTenant(tenantId);
      if (oauth2WrapperLocal instanceof OAuth2Wrapper) {
        oauth2WrapperLocal.addOnSignOut(async () => {
          dispatch(
            updateAuthentication({ tenantId: undefined, authenticated: false })
          );
          AutoTraderApi.setOauth2Wrapper(undefined);
          setOauth2Wrapper(undefined);
          setAutoTraderApi(undefined);
        });
        AutoTraderApi.setOauth2Wrapper(oauth2WrapperLocal);
        setAutoTraderApi(new AutoTraderApiOauth(oauth2WrapperLocal));
        setOauth2Wrapper(oauth2WrapperLocal);
        void configure();
      } else {
        // using oauth2 federated sign in wrapper
        dispatch(updateAuthentication({ authenticated: false }));
        await oauth2WrapperLocal.federatedSignIn();
      }
    }
    setLoadScreen(false);
  };

  const configure = async (): Promise<void> => {
    // The auth config type tells us if we are looking at the csm user pool client or
    // the standard user pool client. These clients have different hosted uis
    if (oauth2Wrapper !== undefined) {
      try {
        const currentUser = await oauth2Wrapper.getCurrentAuthenticatedUser();
        const sub = await (
          await oauth2Wrapper.getAuth().currentSession()
        ).getSub();
        dispatch(
          updateAuthentication({
            authenticateduser: currentUser,
            sub,
            authenticated: currentUser !== undefined,
          })
        );
      } catch (e) {
        // amplify throws an exception to indicate the user is not authenticated
        dispatch(
          updateAuthentication({
            authenticateduser: undefined,
            authenticated: false,
          })
        );
      }
    }
    setLoadScreen(false);
  };

  useEffect(() => {
    if (
      ![SUPPORT_URL_RELEASE_NOTES, SUPPORT_URL_AUTH_GUIDE].includes(pathname)
    ) {
      void tenantAuthorization(tenantId);
    }
    // eslint-disable-next-line
  }, [configLoaded]);

  useEffect(() => {
    void configure();
    fetchComplements();
    // eslint-disable-next-line
  }, [oauth2Wrapper]);

  useEffect(() => {
    setPreference({ lastPageSeen: pathname });
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (authenticateduser !== undefined) {
      const roles = authenticateduser.scopes;
      const email = authenticateduser.email;
      dispatch(updateAuthentication({ roles, username: email }));
      if (roles) {
        const csmUser = roles.includes('SYSTEM_ADMIN');
        const pci = tenantId === 'PCI';
        setIsCSMUser(csmUser && pci);
      }
    }
  }, [authenticateduser, setIsCSMUser, tenantId, dispatch]);

  const setMiscellaneous = useCallback(() => {
    const selectedUnitType = unitTypePreference?.find(
      (unit) => unit.page === lastPageSeen
    );
    dispatch(
      updateMiscellaneous({
        lastPageSeen: lastPageSeen,
        selectedParticipant: selectedMarketParticipant,
        selectedTimezone: selectedTimezone,
        selectedUnit: selectedUnitType && selectedUnitType.unitType,
      })
    );
  }, [
    dispatch,
    selectedMarketParticipant,
    selectedTimezone,
    unitTypePreference,
    lastPageSeen,
  ]);

  const fetchComplements = useCallback(() => {
    if (autoTraderApi) {
      if (preferencesStatus !== FULFILLED) dispatch(fetchPreferences());
      if (participantsStatus !== FULFILLED) dispatch(fetchParticipants());
    }
  }, [autoTraderApi, dispatch, participantsStatus, preferencesStatus]);

  useEffect(() => {
    if (preferencesStatus === FULFILLED) {
      setMiscellaneous();
    }
  }, [preferencesStatus, setMiscellaneous]);

  const loadSourceSinks = useCallback(async () => {
    const selectedParticipant = pickSelectedParticipant(RootSore.getState());
    if (selectedParticipant && selectedParticipant.id) {
      dispatch(fetchSourcesAndSinks());
    }
  }, [dispatch]);

  useEffect(() => {
    if (participantsStatus === FULFILLED) void loadSourceSinks();
    // eslint-disable-next-line
  }, [participantsStatus]);

  return (
    <AppContext.Provider
      value={{
        oauth2Wrapper,
        isCSMUser,
        setIsCSMUser,
        setLoadScreen,
        pushNotification,
        logEvent,
        tenantAuthorization,
      }}
    >
      {loadScreen ? <Loader /> : children}
    </AppContext.Provider>
  );
};

export { AppContextProvider };
