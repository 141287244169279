import { useCallback, useEffect, useMemo } from 'react';
import {
  setTimeZonePreserveLocal,
  TTimeZone,
  ZonedDateTime,
} from '@pci/pci-ui-library';

import ResourcesTab from 'components/admin/ResourcesList/ResourcesTab';
import DateRangeSelector from 'components/admin/DateRangeSelector';
import { EDataGridView } from 'enums/dataGrid';
import { useOperatingPlanM15Context } from 'pages/admin/Plan/context';
import { keepLocalTimezone } from 'utils/dateTime';

import { DATA_SOURCE } from './constants';
import * as S from './M15.styles';
import usePreferences from 'hooks/usePreferences';
import RangeFilterTab from 'components/molecules/RangeFilterTab/RangeFilterTab';
import {
  HOURS_24_FILTER,
  HOURS_4_FILTER,
  WEEK_FILTER,
} from 'components/molecules/RangeFilterTab/constants';

const M15Header = (): JSX.Element => {
  const {
    fromRangeDateTime,
    toRangeDateTime,
    handleDateRangeSelector,
    isCustomRangeActive,
    toggleCustomRangeButton,
    selectedTimeZone,
    selectedGridViewType,
    setSelectedGridViewType,
    selectedDataSource,
    setSelectedDataSource,
    handleRangeFilterChange,
    selectedRangeFilterTab,
    selectedPlanTypeTab,
    handleOnChangePlanTypeTab,
  } = useOperatingPlanM15Context();
  const { operatingPlanPreference, setPreference } = usePreferences();

  const gridViewButtonText = useMemo(() => {
    const selectedView =
      selectedGridViewType === EDataGridView.Resources
        ? EDataGridView.Values
        : EDataGridView.Resources;
    return selectedView;
    // eslint-disable-next-line
  }, [selectedGridViewType]);

  const onClickGridViewHandler = useCallback(() => {
    const view =
      selectedGridViewType === EDataGridView.Resources
        ? EDataGridView.Values
        : EDataGridView.Resources;

    if (operatingPlanPreference) {
      const tempOperatingPlanPreferencePreference = {
        ...operatingPlanPreference,
      };
      tempOperatingPlanPreferencePreference.grouping = view;
      setPreference({
        operatingPlanPreference: tempOperatingPlanPreferencePreference,
      });
    }
    setSelectedGridViewType(view);
  }, [
    setSelectedGridViewType,
    selectedGridViewType,
    operatingPlanPreference,
    setPreference,
  ]);

  const dataSourceSwitchRender = useMemo(() => {
    const { Formulated, Effective, Optimized, Original } = DATA_SOURCE;
    return [Original, Effective, Optimized, Formulated].map((source) => (
      <S.DataSourceButton
        key={source}
        label={`${source.substring(0, 1).toUpperCase()}${source.substring(1)}`}
        id={`source_${source.toLowerCase()}`}
        isActive={selectedDataSource === source}
        onClick={() => setDataSource(source)}
      />
    ));
    // eslint-disable-next-line
  }, [selectedDataSource, setSelectedDataSource]);

  const setDataSource = useCallback(
    (source: string) => {
      if (operatingPlanPreference) {
        const tempOperatingPlanPreferencePreference = {
          ...operatingPlanPreference,
          planSelected: source,
        };
        setPreference({
          operatingPlanPreference: tempOperatingPlanPreferencePreference,
        });
      }
      return setSelectedDataSource(source);
    },
    [operatingPlanPreference, setPreference, setSelectedDataSource]
  );

  useEffect(() => {
    if (operatingPlanPreference?.grouping) {
      setSelectedGridViewType(operatingPlanPreference?.grouping);
    }
    if (operatingPlanPreference?.planSelected) {
      setSelectedDataSource(operatingPlanPreference?.planSelected);
    }
    // setSelectedGridViewType
  }, [operatingPlanPreference, setSelectedGridViewType, setSelectedDataSource]);

  return (
    <S.M15Header>
      <S.DataSourceWrapper>{dataSourceSwitchRender}</S.DataSourceWrapper>
      <S.MainWrapper>
        <ResourcesTab
          tab={selectedPlanTypeTab.key}
          handleChange={handleOnChangePlanTypeTab}
        />
        <S.FiltersWrapper style={{ width: 650 }}>
          {isCustomRangeActive ? (
            <DateRangeSelector
              startDate={keepLocalTimezone(
                fromRangeDateTime,
                selectedTimeZone as TTimeZone
              )}
              startMinMaxDateTime={{
                max: setTimeZonePreserveLocal(
                  ZonedDateTime.parseIso(toRangeDateTime, selectedTimeZone),
                  ZonedDateTime.defaultTimeZone()
                )
                  .subtract(15, 'minutes')
                  .asDate(),
              }}
              endDate={keepLocalTimezone(
                toRangeDateTime,
                selectedTimeZone as TTimeZone
              )}
              endMinMaxDateTime={{
                min: setTimeZonePreserveLocal(
                  ZonedDateTime.parseIso(fromRangeDateTime, selectedTimeZone),
                  ZonedDateTime.defaultTimeZone()
                )
                  .add(15, 'minutes')
                  .asDate(),
              }}
              handleDateRangeSelector={handleDateRangeSelector}
              timezone={selectedTimeZone}
            />
          ) : (
            <RangeFilterTab
              tab={selectedRangeFilterTab.key}
              handleChange={handleRangeFilterChange}
              timeIntervals={[HOURS_4_FILTER, HOURS_24_FILTER, WEEK_FILTER]}
            />
          )}
        </S.FiltersWrapper>
        <S.ButtonsWrapper>
          <S.HeaderButton
            variant='outlined'
            size='medium'
            data-testid={`button_interval_${
              !isCustomRangeActive ? 'custom' : 'basic'
            }`}
            onClick={toggleCustomRangeButton}
          >
            {!isCustomRangeActive ? 'Custom Range' : 'Basic Range'}
          </S.HeaderButton>
          <S.HeaderButton
            variant='outlined'
            size='medium'
            data-testid={`group_by_${gridViewButtonText.toLowerCase()}`}
            onClick={onClickGridViewHandler}
          >
            Group by {gridViewButtonText}
          </S.HeaderButton>
        </S.ButtonsWrapper>
      </S.MainWrapper>
    </S.M15Header>
  );
};

export default M15Header;
