import { Preferences } from 'api/models/Preferences.model';
import {
  IFilterPreference,
  IIntervalPreference,
  IOperatingPlanPreference,
  IUnitTypePreference,
} from 'api/models/User.model';
import { AppContext } from 'App/AppProvider';
import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FULFILLED } from 'redux/constants';
import {
  getPreferences,
  savePreferences,
  toPreferenceDto,
  updatePreferences,
} from 'redux/states/preferences.state';
import RootStore, { AppDispatch, AppStore } from 'redux/store';

export interface IUsePreferences {
  tablePreference: IFilterPreference[];
  intervalPreferences: IIntervalPreference[];
  unitTypePreference: IUnitTypePreference[];
  operatingPlanPreference: IOperatingPlanPreference;
  lastPageSeenPreference: string | undefined;
  selectedMarketParticipantPreference: string | undefined;
  timezonePreference: string | undefined;
  preferencesState: Preferences & {
    status: string;
  };
  setPreference: (peferenceToUpdate: Partial<Preferences>) => void;
}
const usePreferences = (): IUsePreferences => {
  const appContext = useContext(AppContext);
  const dispatch = useDispatch<AppDispatch>();
  const preferencesState = useSelector(getPreferences);
  const {
    timezone,
    lastPageSeen,
    selectedMarketParticipant,
    tablePreference,
    intervalPreference,
    unitTypePreference,
    operatingPlanPreference,
  } = useSelector((store: AppStore) => store.preferences);
  if (!appContext) {
    throw new Error('usePreferences must be used within AppContextProvider');
  }

  const setPreference = useCallback(
    (peferenceToUpdate: Partial<Preferences>) => {
      dispatch(updatePreferences({ ...peferenceToUpdate }));
      const transformedPreferencesOld = toPreferenceDto(RootStore.getState());
      if (preferencesState.status === FULFILLED) {
        dispatch(savePreferences(transformedPreferencesOld));
      }
    },
    [preferencesState, dispatch]
  );

  return {
    tablePreference,
    intervalPreferences: intervalPreference,
    unitTypePreference,
    operatingPlanPreference,
    lastPageSeenPreference: lastPageSeen,
    selectedMarketParticipantPreference: selectedMarketParticipant,
    timezonePreference: timezone,
    preferencesState,
    setPreference,
  };
};

export default usePreferences;
