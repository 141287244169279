import { Tab, Tabs } from '@mui/material';
import React from 'react';

import {
  LOAD,
  GENERATING_UNIT,
  POWER_EXPORT,
  POWER_IMPORT,
} from '../AddResources/constant';

interface Props {
  tab?: string;
  handleChange: (
    event: React.SyntheticEvent | undefined,
    newValue: string
  ) => void;
}
function ResourcesTab({ tab, handleChange }: Props) {
  const executeSelector = (event: React.SyntheticEvent, newValue: string) => {
    if (handleChange) handleChange(event, newValue);
  };
  return (
    <div>
      <Tabs
        value={tab}
        onChange={executeSelector}
        TabIndicatorProps={{
          style: {
            background: '#E2F4F6',
            height: '40px',
            opacity: '1',
            bottom: '3px',
            borderRadius: '8px',
          },
        }}
      >
        <Tab
          style={{
            color: tab === GENERATING_UNIT ? '#468388' : '##468388',
            fontWeight: tab === GENERATING_UNIT ? 'bold' : '400',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'none',
            padding: '12px 5px',
            minWidth: '120px',
            marginRight: '24px',
            zIndex: '99',
          }}
          value={GENERATING_UNIT}
          label='Generating Unit'
          data-testid='type_generating_unit'
        />
        <Tab
          style={{
            color: tab === LOAD ? '#468388' : '#468388',
            fontWeight: tab === LOAD ? 'bold' : '400',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'none',
            padding: '12px 5px',
            minWidth: '60px',
            marginRight: '24px',
            zIndex: '99',
          }}
          value={LOAD}
          label='Load'
          data-testid='type_load'
        />
        <Tab
          style={{
            color: tab === POWER_IMPORT ? '#468388' : '#468388',
            fontWeight: tab === POWER_IMPORT ? 'bold' : '400',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'none',
            padding: '12px 5px',
            minWidth: '100px',
            marginRight: '24px',
            zIndex: '99',
          }}
          value={POWER_IMPORT}
          label='Power Import'
          data-testid='type_power_import'
        />
        <Tab
          style={{
            color: tab === POWER_EXPORT ? '#468388' : '#468388',
            fontWeight: tab === POWER_EXPORT ? 'bold' : '400',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'none',
            padding: '12px 5px',
            minWidth: '100px',
            marginRight: '24px',
            zIndex: '99',
          }}
          value={POWER_EXPORT}
          label='Power Export'
          data-testid='type_power_export'
        />
      </Tabs>
    </div>
  );
}

export default ResourcesTab;
