import { AutoTraderApi } from 'api/AutoTraderAPI';
import { Participant } from 'api/models/Participant.model';
import { useState } from 'react';
import { Director } from 'services/StructuredLogging/Director';
import { Logger } from 'services/StructuredLogging/logger';
import { LoggerBuilder } from 'services/StructuredLogging/LoggerBuilder';

const useLogger = (
  participant?: Participant,
  username?: string,
  sub?: string
) => {
  const director = new Director();
  director.setBuilder(new LoggerBuilder());

  const [directorBuild] = useState(director);

  const logEvent = async (log: Logger): Promise<Logger> => {
    directorBuild.buildLog({
      ...log,
      userEmail: username,
      participantId: participant?.id,
      payload: log.payload,
      adpId: sub,
    });
    const result = await AutoTraderApi.postLog(directorBuild.getBuilder());

    return result;
  };

  return { directorBuild, logEvent };
};

export default useLogger;
