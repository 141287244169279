import * as am4core from '@amcharts/amcharts4/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from 'react-router-dom';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Routes from 'App/Main/Routes';
import { AppContextProvider } from './AppProvider';
import { ZonedDateTime } from '@pci/pci-ui-library';
import { SnackbarProvider } from 'notistack';
import styles from './App.module.scss';
import { LicenseManager } from 'ag-grid-enterprise';
import { TokenFetchErrorBoundary } from './Main/TokenFetchErrorBoundary';
import { Provider } from 'react-redux';
import store from 'redux/store';

am4core.addLicense('CH211112185');
LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_LICENSE_KEY}`);

const App = (): JSX.Element => {
  const { successVariant, errorVariant, infoVariant } = styles;
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      dateLibInstance={ZonedDateTime}
    >
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        classes={{
          variantSuccess: successVariant,
          variantError: errorVariant,
          variantInfo: infoVariant,
        }}
      >
        <TokenFetchErrorBoundary>
          <Provider store={store}>
            <BrowserRouter basename={process.env.REACT_APP_BASEDIR}>
              <AppContextProvider>
                <Routes />
              </AppContextProvider>
            </BrowserRouter>
          </Provider>
        </TokenFetchErrorBoundary>
      </SnackbarProvider>
    </LocalizationProvider>
  );
};

export default App;
