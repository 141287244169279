import { IZones } from 'interfaces/general';

export const NO_OP_HANDLER = async () => {};

export const BOOLEAN_TRUE_LABEL = 'Yes';
export const BOOLEAN_FALSE_LABEL = 'No';

export const STRING_ARRAY_SEPARATOR = '|';

export const STRING_ARRAY_SPECIAL_CHARACTERS = [STRING_ARRAY_SEPARATOR];
export const DECIMAL_INPUT_SPECIAL_CHARACTERS = ['e', 'E', '+', '-'];

export const MVP_TIMEZONES: IZones[] = [
  { zone: 'Etc/GMT+6', label: 'UTC-6', identifier: '-0600' },
  { zone: 'Etc/GMT+5', label: 'UTC-5', identifier: '-0500' },
  { zone: 'Etc/GMT+4', label: 'UTC-4', identifier: '-0400' },
  { zone: 'UTC', label: 'UTC', identifier: 'Z' },
];

export const PAGINATION = [20, 100, 500];
