import CellEditor from 'components/molecules/CellEditor/CellEditor';
import DateTimeEditor from 'components/molecules/DateEditor/DateTimeEditor';
import SelectEditor from 'components/molecules/SelectEditor/SelectEditor';
import { EResourceUnitType } from 'enums/resource';
import {
  timeExportHeaderValueGetter,
  timeExportValueGetter,
} from 'utils/general';

export const resourcesColumnDefs = [
  {
    headerName: 'Identifier',
    field: 'resourceIdentifier',
    minWidth: 100,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
    pinned: 'left',
  },
  {
    headerName: 'Name',
    field: 'name',
    minWidth: 200,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Start At',
    field: 'startAt',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: DateTimeEditor,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Stop At',
    field: 'stopAt',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: DateTimeEditor,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
  },
  {
    headerName: 'Area',
    field: 'area',
    minWidth: 100,
    maxWidth: 120,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Category',
    field: 'category',
    minWidth: 100,
    maxWidth: 120,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Owner',
    field: 'owner',
    minWidth: 120,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Fuel \n Type',
    field: 'fuelType',
    minWidth: 130,
    maxWidth: 180,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellEditorParams: {
      options: [
        { value: 'NA', label: 'NA' },
        { value: 'COAL', label: 'Coal' },
        { value: 'NATURAL_GAS', label: 'Natural Gas' },
        { value: 'OIL', label: 'Oil' },
        { value: 'NUCLEAR', label: 'Nuclear' },
        { value: 'SOLAR', label: 'Solar' },
        { value: 'WIND', label: 'Wind' },
        { value: 'HYDRO', label: 'Hydro' },
        { value: 'H2', label: 'H2' },
        { value: 'GEOTHERMAL', label: 'Geothermal' },
        { value: 'BIOMASS', label: 'Biomass' },
        { value: 'TIDAL', label: 'Tidal' },
      ],
    },
  },
  {
    headerName: 'Cycle \n Type',
    field: 'cycleType',
    minWidth: 120,
    maxWidth: 150,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellEditorParams: {
      options: [
        { value: 'NA', label: 'NA' },
        { value: 'BASE_LOAD', label: 'Base Load' },
        { value: 'INTERMEDIATE', label: 'Intermediate' },
        { value: 'CYCLIC', label: 'Cyclic' },
        { value: 'PEAKING', label: 'Peaking' },
        { value: 'FIXED', label: 'Fixed' },
      ],
    },
  },
  {
    headerName: 'Source',
    field: 'source',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Sink',
    field: 'sink',
    minWidth: 80,
    maxWidth: 80,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Plant',
    field: 'plant',
    minWidth: 100,
    maxWidth: 130,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Line',
    field: 'line',
    minWidth: 80,
    maxWidth: 100,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Quick \n Start',
    field: 'quickStart',
    minWidth: 80,
    maxWidth: 80,
    cellRenderer: 'CellRender',
    cellEditor: SelectEditor,
    cellRendererParams: {
      type: 'radio',
    },
    cellEditorParams: {
      options: [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' },
      ],
    },
  },
];

export const initialValues = (selectedUnitTypeKey: string) => {
  return {
    resourceIdentifier: {
      value: 'TREGION_01',
      error: undefined,
      touched: undefined,
    },
    name: { value: 'Resource Name', error: undefined, touched: undefined },
    resourceType: selectedUnitTypeKey,
    startAt: {
      value: '1900-12-25T01:00:00.000Z',
      error: undefined,
      touched: undefined,
    },
    stopAt: {
      value: '2099-11-24T01:15:00.000Z',
      error: undefined,
      touched: undefined,
    },
    source: {
      value:
        selectedUnitTypeKey === EResourceUnitType.GENERATING_UNIT ||
        selectedUnitTypeKey === EResourceUnitType.POWER_IMPORT
          ? 'Default'
          : undefined,
      error: undefined,
      touched: undefined,
    },
    sink: {
      value:
        selectedUnitTypeKey === EResourceUnitType.LOAD ||
        selectedUnitTypeKey === EResourceUnitType.POWER_EXPORT
          ? 'Default'
          : undefined,
      error: undefined,
      touched: undefined,
    },
    fuelType: { value: 'NA', error: undefined, touched: undefined },
    cycleType: { value: 'NA', error: undefined, touched: undefined },
    plant: { value: undefined, error: undefined, touched: undefined },
    area: { value: undefined, error: undefined, touched: undefined },
    line: { value: undefined, error: undefined, touched: undefined },
    category: { value: undefined, error: undefined, touched: undefined },
    owner: { value: undefined, error: undefined, touched: undefined },
    quickStart: { value: false, error: undefined, touched: undefined },
    newRecord: true,
  };
};
