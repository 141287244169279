import { Delete } from '@mui/icons-material';
import { IDataGridActionButtons } from 'interfaces/dataGrid';

import * as S from './ActionButtons.styles';

const ActionBtns: React.FC<IDataGridActionButtons> = (props) => {
  const { onClickRemoveRow, ...cellProps } = props;
  if (onClickRemoveRow)
    return (
      <S.RemoveRowButton onClick={() => onClickRemoveRow(cellProps)}>
        <Delete fontSize='small' />
      </S.RemoveRowButton>
    );
  return <></>;
};

export default ActionBtns;
