import FormValidation from 'utils/FormValidation';
import { IGenUnitValues } from './GeneratingUnit/IGenUnitValues';

export const generatingUtilSchema = (values: IGenUnitValues) => {
  return {
    resourceName: FormValidation(values.resourceName?.value)
      .string()
      .max(26)
      .pattern(new RegExp('^[A-Z_][A-Z0-9_]{0,25}$'))
      .required(),
    fuelType: FormValidation(values.fuelType?.value).string().required(),
    effectiveDate: FormValidation(values.effectiveDate?.value)
      .date()
      .required(),
    cycleType: FormValidation(values.cycleType).string().required(),
    name: FormValidation(values.name?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    area: FormValidation(values.area?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    plant: FormValidation(values.plant?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    category: FormValidation(values.category?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    line: FormValidation(values.line?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      )
      .required(),
    owner: FormValidation(values.owner?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      ),
    quickStart: FormValidation(values.quickStart?.value).boolean().required(),
    retired: FormValidation(values.retired?.value).boolean().required(),
    // incrementalCost: FormValidation(values.incrementalCost?.value)
    //   .boolean()
    //   .required(),
    // startupCost: FormValidation(values.startupCost?.value)
    //   .number()
    //   .min(0)
    //   .max(99999999999.999)
    //   .required(),
    maxCapacity: FormValidation(values.maxCapacity?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxRegulationDown: FormValidation(values.maxRegulationDown?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxRegulationUp: FormValidation(values.maxRegulationUp?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxBalanceDown: FormValidation(values.maxBalanceDown?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxBalanceUp: FormValidation(values.maxBalanceUp?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxOfflineSpin: FormValidation(values.maxOfflineSpin?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxOfflineNonSpin: FormValidation(values.maxOfflineNonSpin?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    maxOfflineBalanceUp: FormValidation(values.maxOfflineBalanceUp?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    rampRateDown: FormValidation(values.rampRateDown?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    rampRateUp: FormValidation(values.rampRateUp?.value)
      .number()
      .min(0)
      .max(99999999999.999)
      .required(),
    minDownTime: FormValidation(values.minDownTime?.value)
      .number()
      .min(0)
      .max(1999999999)
      .required(),
    minUpTime: FormValidation(values.minUpTime?.value)
      .number()
      .min(0)
      .max(1999999999)
      .required(),
  };
};
