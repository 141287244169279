import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/dateTime';
import { ETheme } from 'enums/styles';
import React from 'react';
import { useLayoutEffect, useRef } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { am4themesDark } from 'utils/styles';

const Chart = styled.div`
  height: 427px;
  width: 100%;
`;

export interface IUnitData {
  [key: string]: number | string | Date | string[];
}

export type TAreaStackedChartData = IUnitData[];

interface IAreaStackedChart {
  chartId: string;
  currentDateTime: ZonedDateTime;
  data: TAreaStackedChartData;
  onSelectedDateTimeChange: (dateTime: Date) => void;
  selectedDateTime?: ZonedDateTime;
  timeZone: TTimeZone;
  fields?: string[];
}

interface StackedAreaChart {
  chart?: am4charts.XYChart;
  dateAxis?: am4charts.DateAxis;
  currentAxisItem?: am4charts.DateAxisDataItem;
  selectedAxisItem?: am4charts.DateAxisDataItem;
}

const AreaStackedChart = ({
  chartId,
  data,
  fields = [],
  currentDateTime,
  selectedDateTime,
  onSelectedDateTimeChange,
}: IAreaStackedChart): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const stackedAreaRef = useRef<StackedAreaChart>({
    chart: undefined,
    dateAxis: undefined,
    currentAxisItem: undefined,
    selectedAxisItem: undefined,
  });
  const stackedReference = stackedAreaRef.current;

  useLayoutEffect(() => {
    if (currentTheme === ETheme.Dark) {
      am4core.useTheme(am4themesDark);
    } else {
      am4core.unuseTheme(am4themesDark);
    }
    am4core.options.onlyShowOnViewport = true;
    const chart: am4charts.XYChart = am4core.create(chartId, am4charts.XYChart);
    chart.data = data;
    chart.width = am4core.percent(100);
    chart.dateFormatter.inputDateFormat = 'MM/dd/yyyy HH:mm';
    chart.dateFormatter.dateFormat = 'HH:mm';
    chart.seriesContainer.draggable = false;
    chart.seriesContainer.resizable = false;
    chart.seriesContainer.svgContainer?.measure();

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.baseInterval = { timeUnit: 'minute', count: 1 };
    dateAxis.gridIntervals.setAll([{ timeUnit: 'hour', count: 2 }]);
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.labels.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    if (valueAxis.tooltip) {
      valueAxis.tooltip.disabled = true;
    }

    // Add and configure Series
    fields.forEach((dataField, index) => {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = 'Time';
      series.name = dataField;
      series.dataFields.valueY = dataField;

      series.tooltipHTML =
        "<span style='font-size:12px; color:#000000;'><b>{name}</b>: {valueY.formatNumber('#.')}</span>";
      series.tooltipText = "[#69798c]{valueY.formatNumber('#.')}[/]";
      series.fillOpacity = 0.6;
      series.strokeWidth = 2;
      series.stacked = true;
      series.sequencedInterpolation = false;

      if (series.tooltip) {
        series.tooltip.autoTextColor = false;
        series.tooltip.pointerOrientation = 'horizontal';
      }
    });

    let lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = 'Net Load MW';
    lineSeries.dataFields.dateX = 'Time';
    lineSeries.dataFields.valueY = 'load';
    lineSeries.stroke = am4core.color('#fdd400');
    lineSeries.strokeWidth = 5;
    lineSeries.strokeOpacity = 0;
    lineSeries.sequencedInterpolation = false;

    let bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#fdd400');
    let circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color('#fff');
    circle.strokeWidth = 2;

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'right';
    chart.legend.scrollable = true;
    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 12;
    markerTemplate.height = 12;

    // Ensure Load MW is at top of legend
    chart.legend.data.unshift(chart.legend.data.pop());

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;
    chart.cursor.behavior = 'none';

    const currentDateLine = dateAxis.axisRanges.push(
      new am4charts.DateAxisDataItem()
    );
    currentDateLine.grid.stroke = am4core.color('#00aa00');
    currentDateLine.grid.strokeOpacity = 1;
    currentDateLine.grid.strokeWidth = 3;
    currentDateLine.grid.above = true;

    stackedAreaRef.current.currentAxisItem = currentDateLine;

    const selectedDateLine = dateAxis.axisRanges.push(
      new am4charts.DateAxisDataItem()
    );

    selectedDateLine.grid.stroke = am4core.color('#ff2400');
    selectedDateLine.grid.strokeOpacity = 1;
    selectedDateLine.grid.strokeWidth = 3;
    selectedDateLine.grid.above = true;
    selectedDateLine.label.background.fill = selectedDateLine.grid.stroke;
    selectedDateLine.label.fill = am4core.color('#fff');
    selectedDateLine.label.draggable = true;
    selectedDateLine.label.minX = 0;
    selectedDateLine.label.maxY = 0;
    selectedDateLine.label.minY = 0;
    selectedDateLine.label.inside = true;

    selectedDateLine.label.events.on('dragstop', function () {
      selectedDateLine.label.maxX = dateAxis.renderer.pixelWidth;
      selectedDateLine.value = dateAxis.positionToValue(
        dateAxis.renderer.coordinateToPosition(selectedDateLine.label.pixelX)
      );
      stackedAreaRef.current.selectedAxisItem = selectedDateLine;
      const selectedDragDate = dateAxis.positionToDate(
        dateAxis.renderer.coordinateToPosition(selectedDateLine.label.pixelX)
      );
      onSelectedDateTimeChange(selectedDragDate);
      chart.invalidateDataItems();
    });

    stackedAreaRef.current.selectedAxisItem = selectedDateLine;

    if (
      stackedAreaRef.current.currentAxisItem &&
      stackedAreaRef.current.selectedAxisItem
    ) {
      const current: Date = new Date(currentDateTime.format(DATE_TIME_FORMAT));
      const selected: Date | undefined =
        selectedDateTime === undefined
          ? undefined
          : new Date(selectedDateTime.format(DATE_TIME_FORMAT));
      stackedAreaRef.current.currentAxisItem.date = current;
      stackedAreaRef.current.selectedAxisItem.date =
        selected === undefined ? current : selected;

      selectedDateLine.label.inside = false;
      selectedDateLine.label.text =
        selectedDateTime === undefined
          ? currentDateTime.format(TIME_FORMAT)
          : selectedDateTime.format(TIME_FORMAT);
      selectedDateLine.label.fontSize = '12';
      selectedDateLine.label.fontWeight = 'bold';
      selectedDateLine.label.dy = -360;
    }

    stackedAreaRef.current.chart = chart;
    stackedAreaRef.current.dateAxis = dateAxis;

    return () => {
      stackedReference.chart?.dispose();
      stackedReference.chart = undefined;
      stackedReference.currentAxisItem = undefined;
      stackedReference.dateAxis = undefined;
      stackedReference.selectedAxisItem = undefined;
    };
  }, [
    stackedReference,
    stackedReference.chart,
    stackedReference.currentAxisItem,
    stackedReference.dateAxis,
    stackedReference.selectedAxisItem,
    chartId,
    currentTheme,
    currentDateTime,
    selectedDateTime,
    data,
    fields,
    onSelectedDateTimeChange,
  ]);

  return <Chart id={chartId} />;
};

export default React.memo(AreaStackedChart);
