import { Button, Checkbox } from '@mui/material';
import i18n from 'i18next';
import { useFormik } from 'formik';
import styles from './CreateUser.module.scss';
import TextField from 'components/admin/TextField';
import { handleTouched, isComplete } from 'utils/FormValidation/utils';
import { CREATING, EDITING } from '../../constant';
import { IUserValues } from './IUserValues';
import { userUtilSchema } from '../formsSchema';
import { useAddUserContext } from '../../AddUserProvider';
import { useEffect } from 'react';
import { useAppContext } from 'App/AppProvider';
import { useSelector } from 'react-redux';
import { AppStore } from 'redux/store';

const initialValue = {
  username: { value: '', error: undefined, touched: undefined },
  roles: { value: [''], error: undefined, touched: undefined },
  name: { value: '', error: undefined, touched: undefined },
  givenName: { value: '', error: undefined, touched: undefined },
  familyName: { value: '', error: undefined, touched: undefined },
};

interface IGenUnitProps {
  mode: string;
  data?: IUserValues;
}

const CreateUser = ({ mode, data }: IGenUnitProps) => {
  const { roles } = useSelector((store: AppStore) => store.authentication);
  const { back, addUser } = useAddUserContext();
  const { isCSMUser } = useAppContext();
  const formRoles = [
    { value: 'ADMIN', label: 'Admin' },
    { value: 'ANALYST', label: 'Analyst' },
    { value: 'TRADER', label: 'Trader' },
    { value: 'VIEWER', label: 'Viewer' },
  ];

  const userRoleHandler = (checked: boolean, newRole: string) => {
    if (checked) {
      formik.setFieldValue('roles', {
        ...formik.values.roles,
        value: [
          ...formik.values.roles.value.filter((role) => role !== ''),
          newRole,
        ],
      });
    } else {
      const newRoles = formik.values.roles.value.filter(
        (role) => role !== newRole
      );
      formik.setFieldValue('roles', {
        ...formik.values.roles,
        value: [...newRoles.filter((role) => role !== '')],
      });
    }
  };
  const {
    genUnitForm,
    formRow,
    description,
    formTitle,
    negative,
    positive,
    submitBtn,
  } = styles;

  const formik = useFormik({
    initialValues: initialValue,
    onSubmit: (_) => {},
    validate: (values: IUserValues) => userUtilSchema(values),
  });

  useEffect(() => {
    if (isCSMUser && roles) {
      formik.setFieldValue('roles', {
        ...formik.values.roles,
        value: [...roles],
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={genUnitForm}>
      <div className={formTitle}>
        <h3>Add user:</h3>
      </div>
      <div>
        <div>
          <div className={formRow}>
            <span className={description}>{i18n.t('user.labels.name')}</span>
            {(mode === CREATING || mode === EDITING) && (
              <TextField
                onClick={(e) =>
                  formik.setTouched(
                    handleTouched(e.target as HTMLInputElement, formik.touched)
                  )
                }
                error={
                  formik.touched.name &&
                  formik.errors &&
                  (formik.errors.name?.error !== null ||
                    formik.errors.givenName?.error !== null ||
                    formik.errors.familyName?.error !== null)
                }
                id='name'
                name='name'
                value={formik.values.name && formik.values.name.value}
                onChange={(newValue) => {
                  const name = newValue.target.value;
                  const splitedName = name.split(' ');
                  const fullName =
                    splitedName.length > 2
                      ? [splitedName[0], `${splitedName[1]} ${splitedName[2]}`]
                      : [splitedName[0], splitedName[1]];

                  formik.setFieldValue('name', {
                    ...formik.values.name,
                    value: newValue.target.value,
                  });

                  formik.setFieldValue('givenName', {
                    ...formik.values.givenName,
                    value: fullName[0],
                  });

                  formik.setFieldValue('familyName', {
                    ...formik.values.familyName,
                    value: fullName[1],
                  });
                }}
                helperText={
                  formik.touched.name &&
                  (formik.errors.name !== undefined ||
                    formik.errors.givenName !== undefined ||
                    formik.errors.familyName !== undefined)
                    ? formik.errors.name?.error ||
                      formik.errors.givenName?.error ||
                      formik.errors.familyName?.error
                    : null
                }
              />
            )}
          </div>
        </div>
        <div>
          <div className={formRow}>
            <span className={description}>
              {i18n.t('user.labels.username')}
            </span>
            {(mode === CREATING || mode === EDITING) && (
              <TextField
                onClick={(e) =>
                  formik.setTouched(
                    handleTouched(e.target as HTMLInputElement, formik.touched)
                  )
                }
                error={
                  formik.touched.username &&
                  formik.errors &&
                  formik.errors.username?.error !== null
                }
                id='username'
                name='username'
                value={formik.values.username && formik.values.username.value}
                onChange={(newValue) => {
                  const username = newValue.target.value;

                  formik.setFieldValue('username', {
                    ...formik.values.username,
                    value: username,
                  });
                }}
                helperText={
                  formik.touched.username &&
                  formik.errors.username !== undefined
                    ? formik.errors.username.error
                    : null
                }
              />
            )}
          </div>
        </div>
        {!isCSMUser &&
          formRoles.map(({ value, label }, index) => (
            <div key={index}>
              <div className={formRow}>
                <span className={description}>{label}</span>
                {(mode === CREATING || mode === EDITING) && (
                  <div>
                    <Checkbox
                      sx={{
                        padding: 0,
                        color: '#CDD2D9',
                        '&.Mui-checked': {
                          color: '#69798C',
                        },
                      }}
                      checked={formik.values.roles.value.includes(value)}
                      onChange={(_, checked) => userRoleHandler(checked, value)}
                      name={value}
                    />
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <div className={formTitle}>
        <div className={submitBtn}>
          <Button
            className={negative}
            variant='outlined'
            size='medium'
            onClick={() => (back ? back() : null)}
          >
            Cancel
          </Button>
          <Button
            disabled={!isComplete(formik.values)}
            className={positive}
            variant='contained'
            size='medium'
            onClick={() => (addUser ? addUser(formik.values) : null)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
