import { Tab, Tabs } from '@mui/material';
import React from 'react';
import { ITimeInterval } from './types';

interface IRangeFilterTabProps {
  tab?: string;
  handleChange: (event: React.SyntheticEvent, newValue: string) => void;
  timeIntervals: ITimeInterval[];
}

function RangeFilterTab({
  tab,
  handleChange,
  timeIntervals,
}: IRangeFilterTabProps) {
  const renderTabs = () => {
    return timeIntervals.map((interval) => {
      return (
        <Tab
          key={`tab-${interval.key}`}
          style={{
            color: tab === interval.key ? '#468388' : '##468388',
            fontWeight: tab === interval.key ? 'bold' : '400',
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '21px',
            textTransform: 'none',
            padding: '12px 5px',
            minWidth: '80px',
            marginRight: '24px',
            zIndex: '99',
          }}
          value={interval.key}
          label={interval.description}
          data-testid={`tab-${interval.key}`}
        />
      );
    });
  };

  return (
    <div>
      <Tabs
        value={tab}
        onChange={handleChange}
        TabIndicatorProps={{
          style: {
            background: '#E2F4F6',
            height: '40px',
            opacity: '1',
            bottom: '3px',
            borderRadius: '8px',
          },
        }}
      >
        {renderTabs()}
      </Tabs>
    </div>
  );
}

export default RangeFilterTab;
