import React, { useEffect } from 'react';
import { useAppContext } from '../../App/AppProvider';
import { authDebug } from '../../utils/log';

const LogoutPage: React.FC = () => {
  const { oauth2Wrapper, setLoadScreen } = useAppContext();
  useEffect(() => {
    if (setLoadScreen) setLoadScreen(true);
    const logout = async () => {
      if (oauth2Wrapper !== undefined) {
        authDebug('[LogoutPage] signing out');
        await oauth2Wrapper.signOut();
      }
    };
    void logout();
  }, [oauth2Wrapper, setLoadScreen]);
  return <></>;
};

export default LogoutPage;
