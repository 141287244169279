import FormValidation from 'utils/FormValidation';
import { IResourcesValues } from './IResourcesValues';

export const resourcesSchema = (values: IResourcesValues) => {
  return {
    resourceIdentifier: FormValidation(values.resourceIdentifier?.value)
      .string()
      .max(26)
      .pattern(new RegExp('^[A-Z_][A-Z0-9_]{0,25}$'))
      .required(),
    name: FormValidation(values.name?.value).string().max(256).required(),
    resourceType: FormValidation(values.resourceType).string().required(),
    fuelType: FormValidation(values.fuelType?.value).string(),
    startAt: FormValidation(values.startAt?.value).date(),
    stopAt: FormValidation(values.stopAt?.value).date(),
    cycleType: FormValidation(values.cycleType?.value).string(),
    area: FormValidation(values.area?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      ),
    plant: FormValidation(values.plant?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      ),
    category: FormValidation(values.category?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      ),
    line: FormValidation(values.line?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      ),
    owner: FormValidation(values.owner?.value)
      .string()
      .max(40)
      .pattern(
        new RegExp('^[a-zA-Z0-9_-](?:(?: |[a-zA-Z0-9_-]){0,38}[a-zA-Z0-9_-])?$')
      ),
    quickStart: FormValidation(values.quickStart?.value).boolean(),
  };
};
