import { Button } from '@mui/material';
import { IPCIButtonProps, PCIButton } from '@pci/pci-ui-library';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const M15Header = styled.header`
  border: solid 1px #d6d6d6;
  border-bottom: 0px;
  border-radius: 8px 0 0px 0px;

  position: relative;
`;

export const RangeWrapper = styled.div`
  margin: 0 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

export const DataSourceWrapper = styled.div`
  position: absolute;
  top: -33px;
  right: -1px;

  border: solid 1px #d6d6d6;
  border-bottom: 0;
  border-radius: 8px 8px 0px 0px;
  background: #f2f5f7;
`;

interface IDataSourceButton extends IPCIButtonProps {
  isActive?: boolean;
}

export const DataSourceButton = styled(PCIButton)<IDataSourceButton>`
  outline: 0;
  border: 0;
  padding: 8px 10px;
  font-size: 14px;
  border-radius: 8px 8px 0px 0px;

  font-weight: ${({ isActive }) => (isActive ? 600 : 400)};
  color: ${({ isActive }) => (isActive ? '#468388' : '#69798C')};
  cursor: ${({ isActive }) => (isActive ? 'auto' : 'pointer')};
  background: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
`;

export const MainWrapper = styled.div`
  padding: 5px;

  width: 100%;
  display: flex;
  gap: 25px;
  justify-content: space-between;
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  border-left: solid 1px #cdd2d9;
  padding-left: 30px;
`;

export const DateWrapper = styled.div`
  font-size: 13px;
`;
export const Date = styled.strong`
  font-size: 12px;
  display: block;
  white-space: nowrap;
`;

const commonButton = css`
  color: var(--secondary);
  box-shadow: none;
  text-transform: none;
  background-color: transparent;
  border-radius: 8px;
  max-height: 42px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background: var(--secondary);
    color: #fff;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
      0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`;

export const HeaderButton = styled(Button)`
  ${commonButton}

  padding: 6px 16px;
  margin-top: 3px;
`;

export const FilterButton = styled(Button)`
  ${commonButton}

  padding: 6px 16px;
  margin-top: 3px;
`;

export const OverridesLink = styled(Link)`
  ${commonButton}

  font-family: 'Lato';
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;
  line-height: normal;
  padding: 6px 16px;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const commonEnergyBox = css`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
`;

export const EnergyBox = styled.div`
  && {
    position: realative;
    display: flex;
    width: 100%;
    align-items: center;
    text-align: center;
    .energyColorCodeLabel_ {
      &default,
      &dash {
        ${commonEnergyBox}
      }
      &fixed {
        ${commonEnergyBox}
        background-color: #A6B4FF !important;
      }
      &upper {
        ${commonEnergyBox}
        background-color: #FFC7C7 !important;
      }
      &higher-middle {
        ${commonEnergyBox}
        background-color: #FECB79 !important;
      }
      &middle {
        ${commonEnergyBox}
        background-color: #FFF6C7 !important;
      }
      &lower-middle {
        ${commonEnergyBox}
        background-color: #DAF5DA !important;
      }
      &lower {
        ${commonEnergyBox}
        background-color: #A7DDA9 !important;
      }
    }
    .imbalanceColorCodeLabel_ {
      &default {
        ${commonEnergyBox}
      }
      &upper {
        ${commonEnergyBox}
        color: #fff;
        background-color: #512155;
      }
      &lower {
        ${commonEnergyBox}
        color: #fff;
        background-color: #7cadc9;
      }
    }
  }
`;

interface IEnergyWarningProps {
  show: boolean;
}

export const EnergyWarning = styled.div<IEnergyWarningProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: 2px;
  top: 0;

  width: 0;
  height: 0;
  border-top: 8px solid #b31d3b;
  border-left: 8px solid transparent;

  background: transparent;
`;

export const TooltipContent = styled.div`
  h3 {
    font-weight: 700;
    color: #0c1725;
    font-size: 12px;
  }

  ul {
    width: 100%;
  }

  li {
    width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    tr {
      border-radius: 4px;

      &:nth-child(odd) {
        background: #f2f2f2;
      }
    }

    td {
      padding: 0 10px;

      &:first-of-type {
        font-weight: 700;
      }
    }
  }
`;
