import { format, parseISO } from 'date-fns';
import { AGSort } from 'interfaces/dataGrid';
import { currencyInDollarsFormatter, formatNumber } from 'utils/general';

const MONTH = 'Month';
const TYPE = 'Type';
const TSP = 'TSP';
const COUNTER_PARTY = 'Counter \nParty';
const TRADE_QUANTITY = 'Trade\nQuantity\n(MW)';
const TRADE_VOLUME = 'Trade\nVolume\n(MWh)';
const LOSS_AMOUNT = 'Loss\nAmount\n($)';

export const auditColumnDefs = [
  {
    headerName: MONTH,
    field: 'reportDate',
    minWidth: 100,
    // maxWidth: 150,
    pinned: 'left',
    sort: AGSort.ASC,
    valueFormatter: (params: any) => {
      const parsedData = parseISO(params.value);
      return format(parsedData, 'LLL-yyyy');
    },
  },
  {
    // missing on API
    headerName: TYPE,
    field: 'type',
    minWidth: 150,
    // maxWidth: 280,
    cellRenderer: 'CellRender',
  },
  {
    headerName: TSP,
    field: 'tsp',
    minWidth: 150,
    // maxWidth: 280,
    cellRenderer: 'CellRender',
  },
  {
    headerName: COUNTER_PARTY,
    field: 'counterparty',
    minWidth: 100,
    // maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: TRADE_QUANTITY,
    field: 'tradeQuantity',
    minWidth: 100,
    valueFormatter: (params: any) => formatNumber(params.value),
  },
  {
    headerName: TRADE_VOLUME,
    field: 'tradeMwh',
    minWidth: 100,
    valueFormatter: (params: any) => formatNumber(params.value),
  },
  {
    headerName: LOSS_AMOUNT,
    field: 'lossAmount',
    minWidth: 100,
    maxWidth: 300,
    valueFormatter: currencyInDollarsFormatter,
  },
];
