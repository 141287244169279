import i18n from 'i18next';
import { isAbsent } from './utils';

const stringField = <T>(value: T, error: string | null, touched?: boolean) => {
  // eslint-disable-next-line
  let rEmail = new RegExp(
    "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|'(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x7f])*')@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]\\)\\).\\){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f]|\\[\x7f])+)]\\)"
  );
  // eslint-disable-next-line
  // eslint-disable-no-useless-escape
  let rUrl = new RegExp(
    '(https|ftp)?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}([-a-zA-Z0-9()@:%_+.~#?&//=]*)'
  );
  // eslint-disable-next-line
  let rUUID = new RegExp(
    '^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)'
  );

  return {
    value,
    error,
    touched,
    email: function email(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !rEmail.test(String(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.invalid_email');
      }
      return this;
    },
    url: function url(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !rUrl.test(String(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.invalid_url');
      }
      return this;
    },
    uuid: function uuid(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !rUUID.test(String(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.invalid_uuid');
      }
      return this;
    },
    required: function required(errorMessage: string | null = null) {
      if (this.error) return this;
      if (isAbsent(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.required_field');
      }
      return this;
    },
    max: function max(maxLength: number, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && String(this.value).length > maxLength) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.max_length', { length: maxLength });
      }
      return this;
    },
    min: function min(minLength: number, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && String(this.value).length < minLength) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.min_length', { length: minLength });
      }
      return this;
    },
    startsWith: function startsWith(
      startStr: string,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !String(this.value).startsWith(startStr)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.string_not_start_with', {
              value: startStr,
            });
      }
      return this;
    },
    endsWith: function endsWith(
      endStr: string,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !String(this.value).endsWith(endStr)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.string_not_end_with', {
              value: endStr,
            });
      }
      return this;
    },
    includes: function includes(
      str: string,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !String(this.value).includes(str)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.string_not_include', { value: str });
      }
      return this;
    },
    pattern: function pattern(
      regex: RegExp,
      errorMessage: string | null = null
    ) {
      if (this.error) return this;
      if (!isAbsent(this.value) && !regex.test(String(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.not_match');
      }
      return this;
    },
    custom: function custom(
      fn: Function,
      errorMessage: string | null,
      ...args: any[]
    ) {
      if (this.error) return this;
      if (!fn(this.value, ...args)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.field_no_valid');
      }
      return this;
    },
    validate: function validate() {
      return this.error;
    },
  };
};

export default stringField;
