import { Add, Edit, Refresh } from '@mui/icons-material';
import { Button, Toolbar } from '@mui/material';
import React, { ReactNode } from 'react';
import styles from './GridToolbar.module.scss';

interface Props {
  title?: string | undefined;
  editMode?: boolean | false;
  canSave?: boolean | false;
  canEdit?: boolean | true;
  canAdd?: boolean | true;
  canReset?: boolean | true;
  toolExtra?: ReactNode;
  onClickReset?: () => void;
  onCancel?: () => void;
  onClickEnableEditing?: () => void;
  onClickAddRow?: () => void;
  onClickSaveButton?: () => void;
}

function GridToolbar({
  title,
  toolExtra,
  editMode,
  onClickReset,
  onCancel,
  onClickEnableEditing,
  onClickAddRow,
  onClickSaveButton,
  canSave,
  canEdit,
  canAdd,
  canReset,
}: Props) {
  const { tableToolbar, toolbarWrapper, toolbarbtn, titleWrapper, disabled } =
    styles;

  return (
    <Toolbar className={tableToolbar}>
      <div className={toolbarWrapper}>
        {title && (
          <div className={titleWrapper}>
            <span data-testid='title'>{title}</span>
          </div>
        )}
        {toolExtra && toolExtra}
        {!editMode ? (
          <div>
            {canReset && (
              <Button
                className={toolbarbtn}
                data-testid='button_refresh'
                variant='contained'
                size='medium'
                startIcon={<Refresh fontSize='small' />}
                onClick={onClickReset}
              >
                Reload
              </Button>
            )}
            {canEdit && (
              <Button
                className={toolbarbtn}
                data-testid='button_edit'
                variant='contained'
                size='medium'
                startIcon={<Edit fontSize='small' />}
                onClick={onClickEnableEditing}
              >
                Edit
              </Button>
            )}
            {canAdd && (
              <Button
                className={toolbarbtn}
                data-testid='button_add_new'
                variant='contained'
                size='medium'
                startIcon={<Add fontSize='small' />}
                onClick={onClickAddRow}
              >
                Add new
              </Button>
            )}
          </div>
        ) : (
          <div>
            {canAdd && (
              <Button
                className={toolbarbtn}
                data-testid='button_add_new'
                variant='contained'
                size='medium'
                startIcon={<Add fontSize='small' />}
                onClick={onClickAddRow}
              >
                Add New
              </Button>
            )}
            <Button
              className={toolbarbtn}
              data-testid='button_cancel'
              variant='contained'
              size='medium'
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              disabled={!canSave}
              data-testid='button_save_changes'
              className={`${toolbarbtn} ${canSave ? '' : disabled}`}
              variant='contained'
              size='medium'
              onClick={onClickSaveButton}
            >
              Save changes
            </Button>
          </div>
        )}
      </div>
    </Toolbar>
  );
}

export default GridToolbar;
