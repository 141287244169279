export enum Event {
  ADDEDRESOURCE = 'AddedResource',
  MODIFIEDRESOURCE = 'ModifiedResource',
  TOGGLEDAUTOSUBMIT = 'ToggledAutoSubmit',
  SELECTEDINTERVAL = 'SelectedInterval',
  LOGGEDOUT = 'LoggedOut',
  LOADEDLOGINPAGE = 'LoadedLoginPage',
  VIEWEDRESOURCESPAGE = 'ViewedResourcesPage',
  VIEWEDLOADTAB = 'ViewedLoadTab',
  VIEWEDGENERATINGUNITTAB = 'ViewedGeneratingUnitTab',
  VIEWEDPOWERIMPORTTAB = 'ViewedPowerImportTab',
  VIEWEDPOWEREXPORTTAB = 'ViewedPowerExportTab',
  VIEWEDPARTICIPANTSPAGE = 'ViewedParticipantsPage',
  ADDEDPARTICIPANT = 'AddedParticipant',
  MODIFIEDPARTICIPANT = 'ModifiedParticipant',
  VIEWEDMETERSPAGE = 'ViewedMetersPage',
  ADDEDMETER = 'AddedMeter',
  MODIFIEDMETER = 'ModifiedMeter',
  VIEWEDOPERATINGPLANPAGE = 'ViewedOperatingPlanPage',
  VIEWEDEFFECTIVETAB = 'ViewedEffectiveTab',
  VIEWEDBALANCEDTAB = 'ViewedBalancedTab',
  GROUPEDBYVALUE = 'GroupedByValue',
  GROUPEDBYRESOURCE = 'GroupedByResource',
  VIEWEDOVERRIDESPAGE = 'ViewedOverridesPage',
  ADDEDOVERRIDE = 'AddedOverride',
  REMOVEDOVERRIDE = 'RemovedOverride',
  MODIFIEDOVERRIDE = 'ModifiedOverride',
  VIEWEDTRADEPAGE = 'ViewedTradePage',
  VIEWEDBIDSANDOFFERSPAGE = 'ViewedBidsAndOffersPage',
  VIEWEDMATCHEDTRADESPAGE = 'ViewedMatchedTradesPage',
  VIEWEDSUPPORTPAGE = 'ViewedSupportPage',
  VIEWEDAPIDOCUMENTATION = 'ViewedAPIDocumentation',
  VIEWEDUSERGUIDE = 'ViewedUserGuide',
  MODIFIEDTIMEZONE = 'ModifiedTimeZone',
  VIEWEDTENANTSELECTIONPAGE = 'ViewedTenantSelectionPage',
  SELECTEDTENANT = 'SelectedTenant',
  LOGGEDIN = 'LoggedIn',
  VIEWEDTRADESTRATEGYPAGE = 'ViewedTradeStrategyPage',
  ADDEDTRADESTRATEGY = 'AddedTradeStrategy',
  MODIFIEDTRADESTRATEGY = 'ModifiedTradeStrategy',
  REMOVEDTRADESTRATEGY = 'RemovedTradeStrategy',
}

export enum EventType {
  AUDIT = 'audit',
  AUTOTRADER = 'autotrader',
}
