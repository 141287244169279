import { ReactNode, useCallback, useState, useEffect } from 'react';

import { createContext } from 'contexts/helpers';
import { TTimeZone, ZonedDateTime } from '@pci/pci-ui-library';
import { DATE_TIME_FORMAT } from 'constants/dateTime';
import { AutoTraderApi } from 'api/AutoTraderAPI';

import {
  pickSelectedParticipant,
  pickSelectedTimezone,
} from 'redux/states/miscellaneous.state';

import { useDispatch, useSelector } from 'react-redux';
import { Participant } from 'api/models/Participant.model';
import { AppDispatch } from 'redux/store';
import { validateParticipantAutoSubmitOn } from 'redux/states/participants.state';

interface IBidsOffersProviderProps {
  children: ReactNode;
}

const createSessionContext = () => {
  const [useSessionContext, Provider] = createContext<any>();

  const SessionProvider = ({
    children,
  }: IBidsOffersProviderProps): JSX.Element => {
    createContext<any>();
    const dispatch = useDispatch<AppDispatch>();
    const selectedParticipant: Participant = useSelector(
      pickSelectedParticipant
    );
    const selectedTimeZone = useSelector(pickSelectedTimezone);

    const [timeZone] = useState<TTimeZone>(selectedTimeZone as TTimeZone);

    const [startSession, setStartSession] = useState(() =>
      ZonedDateTime.now(timeZone)
    );
    const [getIntervalsStatus, setGetIntervalsStatus] = useState('idle');
    const [isSessionSynced, setIsSessionSynced] = useState(true);
    const [deadlineSeconds, setDeadlineSeconds] = useState(0);
    const [intervals, setIntervals] = useState();
    const [currentSession, setCurrentSession] =
      useState<ZonedDateTime>(startSession);
    const [selectedSession, setSelectedSession] =
      useState<ZonedDateTime>(currentSession);

    const onChangeSelectedSession = useCallback(
      (date: ZonedDateTime) => {
        if (date) {
          setIsSessionSynced(date.isSame(currentSession));
          setSelectedSession(date);
        }
      },
      // eslint-disable-next-line
      [selectedParticipant?.id]
    );

    const getIntervals = useCallback(async () => {
      try {
        if (selectedParticipant && selectedParticipant.id) {
          setGetIntervalsStatus('pending');
          const times = await AutoTraderApi.getTimes(selectedParticipant.id);
          if (times && selectedTimeZone) {
            const tid = ZonedDateTime.parseIso(
              times[0].tradingIntervalDeadline,
              selectedTimeZone
            );
            const sdl = ZonedDateTime.parseIso(
              times[0].submissionDeadline,
              selectedTimeZone
            );
            const now = ZonedDateTime.parseIso(times[0].now, selectedTimeZone);
            const difference: number = sdl.diff(now, 'seconds');
            setDeadlineSeconds(difference);
            setIntervals(times[0]);
            setCurrentSession(tid);
            setStartSession(tid);

            setGetIntervalsStatus('complete');
          }
        }
      } catch (error: any) {}
      // eslint-disable-next-line
    }, [selectedParticipant?.id, selectedTimeZone]);

    useEffect(() => {
      getIntervals();
      // eslint-disable-next-line
    }, [selectedParticipant?.id, selectedTimeZone]);

    useEffect(() => {
      onValidateParticipantAutoSubmit();
      // eslint-disable-next-line
    }, [selectedParticipant?.id, selectedParticipant?.autoSubmit]);

    const onValidateParticipantAutoSubmit = async () => {
      if (selectedParticipant && selectedParticipant?.id) {
        dispatch(validateParticipantAutoSubmitOn(selectedParticipant?.id));
      }
    };

    return (
      <Provider
        value={{
          pastSession: deadlineSeconds < 0,
          setSession: onChangeSelectedSession,
          session: isSessionSynced
            ? currentSession.format(DATE_TIME_FORMAT)
            : selectedSession.format(DATE_TIME_FORMAT),
          sessionObj: isSessionSynced ? currentSession : selectedSession,
          intervals,
          deadlineSeconds,
          isSessionSynced,
          startSession,
          timeZone: selectedTimeZone,
          updateSession: getIntervals,
          isAutoSubmitOn: selectedParticipant?.autoSubmit ?? false,
          getIntervalsStatus,
          setGetIntervalsStatus,
        }}
      >
        {children}
      </Provider>
    );
  };

  return [useSessionContext, SessionProvider] as const;
};

export default createSessionContext;
