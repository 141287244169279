import {
  ICellEditor,
  ColDef,
  ColGroupDef,
  CellRendererSelectorResult,
  ICellRendererComp,
  ICellRendererFunc,
  ICellRendererParams,
  NewValueParams,
} from 'ag-grid-enterprise';
import React from 'react';

import {
  TDataGridValueGetterFunc,
  IDataGridCalculatedColDef,
} from 'types/dataGrid';
import { TChildren } from 'types/general';

export interface ICellEditorImperativeHandle extends ICellEditor {
  columnField: () => string;
  validateField: (error: string | undefined | null) => void;
  setEditable?: (editable: boolean) => void;
  setValue?: (value: any) => void;
  mask?: (onOrNot: boolean) => void;
}
interface ICellClassFunc {
  (cellClassParams: any): string | string[];
}

export interface IAdditions {
  key: string;
  additionsIds: string[];
}

export interface IDataGridValueGetterParameters {
  data: any;
  api: {
    getColumnDefs: () => (
      | IDataGridCalculatedColDef<ColDef>
      | IDataGridCalculatedColDef<ColGroupDef>
    )[];
  };
  node: any;
  value?: any;
}

export interface IDataGridColumnFilterParams {
  caseSensitive?: boolean;
  buttons?: string[];
  filterOptions?: any[];
  numberParser?: (text: string | null) => number | null;
  comparator?: (
    filterLocalDateAtMidnight: any,
    cellValue: any
  ) => 0 | 1 | -1 | undefined;
  showTooltips?: boolean;
}

export interface IDataGridValueFormatterParameters {
  value: any;
  data?: any;
  node?: any;
}

export interface IDataGridCellStyleParameters {
  data: any;
}

export enum AGSort {
  ASC = 'asc',
  DESC = 'desc',
}

export interface IDataGridColumn extends ColDef {
  sort?: 'asc' | 'desc' | null;
  colId?: string;
  includeInTotalsRow?: boolean;
  chartDataType?: 'category' | 'excluded' | 'series' | 'time';
  cellEditor?: any;
  cellEditorParams?: unknown;
  cellEditorPopup?: boolean;
  cellRenderer?:
    | { new (): ICellRendererComp }
    | ICellRendererFunc
    | React.FC<any>
    | string;
  cellRendererFramework?: (
    cellRendererParams: ICellRendererParams
  ) => JSX.Element;
  children?: IDataGridColumn[];
  editable?: boolean;
  field?: string;
  filter?: string;
  filterParams?: IDataGridColumnFilterParams;
  floatingFilter?: boolean;
  groupId?: number | string;
  keyCreator?: (parameters: any) => string;
  onCellValueChanged?: (event: NewValueParams) => void;
  headerName?: string;
  singleClickEdit?: boolean;
  type?: string | string[];
  valueFormatter?: (parameters: IDataGridValueFormatterParameters) => string;
  valueGetter?: string | TDataGridValueGetterFunc | undefined;
  cellRendererParams?: any;
  width?: number;
  cellStyle?: {};
  cellClass?: string | string[] | ICellClassFunc;
  userProvidedColDef?: IDataGridColumn;
  cellRendererSelector?: (
    params: ICellRendererParams
  ) => CellRendererSelectorResult | undefined;
}

export interface IDataGridToolbarButton {
  disabled?: boolean | false;
  label?: TChildren;
  show?: boolean | false;
  startIcon?: TChildren;
  onClick?: () => void | Promise<void> | undefined;
}

export interface IDataGridToolbar {
  isEditing: boolean;
  children?: TChildren;
  addRowProps?: IDataGridToolbarButton;
  resetGridProps?: IDataGridToolbarButton;
  saveChangesProps?: IDataGridToolbarButton;
  editGridProps?: IDataGridToolbarButton;
}

export interface IDataGridActionButtons extends ICellRendererParams {
  onClickRemoveRow?: (cellEvent: ICellRendererParams) => void;
}
