/* eslint-disable */

import { createContext } from 'contexts/helpers';
import useApiRequest from 'hooks/useApiRequest';
import { AutoTraderApi } from 'api/AutoTraderAPI';
import { TChildren } from 'types/general';

interface IBidsOffersProviderProps {
  children: TChildren;
}

const createBidsOffersContext = () => {
  const [useBidsOffersContext, Provider] = createContext<any>();

  const BidsOffersProvider = ({
    children,
  }: IBidsOffersProviderProps): JSX.Element => {
    createContext<any>();

    const bidsOffersAPI = useApiRequest(
      AutoTraderApi.getBidsOffersNew.bind(AutoTraderApi)
    );

    return (
      <Provider
        value={{
          bidsOffersAPI,
        }}
      >
        {children}
      </Provider>
    );
  };

  return [useBidsOffersContext, BidsOffersProvider] as const;
};

export default createBidsOffersContext;
