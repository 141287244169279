import { useState } from 'react';

export const UPDATE = 'UPDATE';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';

const useQueue = <T, K extends keyof T>(keyName: K) => {
  const [queue, setQueue] = useState<{ data: T; status: string }[]>([]);

  const setInQueue = (data: T, status: string) => {
    setQueue([...queue, { data, status }]);
  };

  const getIndex = (keyId: T[K]) =>
    queue.findIndex((element) => element.data[keyName] === keyId);

  const isInQueue = (keyId: T[K]) => {
    const exist = getIndex(keyId);
    return exist !== -1;
  };

  const changed = (keyId: T[K], data: T) => {
    const elementIndex = getIndex(keyId);
    const element = queue.at(elementIndex);

    return JSON.stringify(element) === JSON.stringify(data);
  };

  const getQueued = (keyId: T[K]) => {
    const elementIndex = getIndex(keyId);
    return queue.at(elementIndex);
  };

  const getLast = () => {
    return queue.at(queue.length - 1);
  };

  const update = (keyId: T[K], data: T, newStatus: string) => {
    const elementIndex = getIndex(keyId);
    const element = queue.at(elementIndex);
    const newQueue = queue.filter((_, index) => index !== elementIndex);

    if (element) setQueue([...newQueue, { data: data, status: newStatus }]);
  };

  const remove = (keyId: T[K]) => {
    const elementIndex = getIndex(keyId);
    const element = queue.at(elementIndex);
    const newQueue = queue.filter((_, index) => index !== elementIndex);

    if (element) setQueue([...newQueue]);
  };

  const updateStatus = (keyId: T[K], newStatus: string) => {
    const elementIndex = getIndex(keyId);
    const element = queue.at(elementIndex);
    const newQueue = queue.filter((_, index) => index !== elementIndex);
    if (element)
      setQueue([...newQueue, { data: element.data, status: newStatus }]);
  };

  const getQueue = () => [...queue];

  const cleanQueue = () => setQueue([]);

  return {
    setInQueue,
    getLast,
    isInQueue,
    updateStatus,
    update,
    getQueue,
    changed,
    cleanQueue,
    getQueued,
    remove,
  };
};

export default useQueue;
