import styled, { css } from 'styled-components';

export const StatusWrapper = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  column-gap: 8px;
  align-items: center;
`;

const commonStatusStyle = css`
  box-sizing: border-box;
  box-shadow: none !important;
  text-transform: capitalize !important;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-radius: 20px;
  height: 22px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 8px;
  gap: 6px;
`;

export const StatusBox = styled.div`
  && {
    display: flex;
    .bidsoffersStatusLabel_ {
      &tooltip {
        backgroundColor: '#f5f5f9' !important,
        color: 'rgba(0, 0, 0, 0.87)' !important,
        border: '1px solid #dadde9' !important,
      }

      &accepted {
        ${commonStatusStyle}
        width: 69px;
        background-color: #d5e3e4 !important;
        color: #468388 !important;
      }

      &unknown {
        ${commonStatusStyle}
        width: 70px;
        background-color: #cdd2d9 !important;
        color: #69798c !important;
      }

      &submitted {
        ${commonStatusStyle}
        width: 73px;
        background-color: #cdd2d9 !important;
        color: #213955 !important;
      }

      &hold {
        ${commonStatusStyle}
        width: 43px;
        background-color: #fff7ce !important;
        color: #bca635 !important;
      }

      &no_submission {
        ${commonStatusStyle}
        width: 98px;
        background-color: #cdd2d9 !important;
        color: #213955 !important;
      }

      &skipped {
        ${commonStatusStyle}
        width: 61px;
        background-color: #cdd2d9 !important;
        color: #69798c !important;
      }

      &matched {
        ${commonStatusStyle}
        width: 61px;
      }

      &rejected {
        ${commonStatusStyle}
        width: 65px;
        background-color: #eeccd3 !important;
        color: #b31d3b !important;

        &_icon {
          color: #b31d3b !important;
          cursor: pointer !important;
        }
      }
    }
  }
`;
