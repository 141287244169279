import { useCallback, useState, useEffect, useRef } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { ZonedDateTime, setTimeZonePreserveLocal } from '@pci/pci-ui-library';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { useSessionContext } from 'pages/admin/Trade/context';
import * as S from './Options.styles';
import { useAppContext } from 'App/AppProvider';
import { Event } from 'services/StructuredLogging/events';
import { Category } from 'services/StructuredLogging/categories';

type THoursOrDateView = 'day' | 'hours';

const SessionSelector = () => {
  const { logEvent } = useAppContext();
  const {
    startSession,
    session,
    sessionObj,
    setSession,
    isSessionSynced,
    timeZone,
  } = useSessionContext();
  const ref: any = useRef(null);

  const [datetime, setDateTime] = useState<any>(session);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [commit, setCommit] = useState<boolean>(false);
  const [lastAccepted, setLastAccepted] = useState<any>(null);
  const [hoursOrDateView, setHoursOrDateView] =
    useState<THoursOrDateView>('day');

  const onChangeDateTimeHandler = (newDateTime: Date | null) => {};

  const onAcceptDateTimeHandler = (newAcceptDateTime: Date | null) => {
    if (
      commit &&
      newAcceptDateTime &&
      newAcceptDateTime?.getTime() !==
        new Date(sessionObj.isoFormat().slice(0, -6)).getTime()
    ) {
      const sessionToSet = setTimeZonePreserveLocal(
        ZonedDateTime.fromDate(
          newAcceptDateTime,
          ZonedDateTime.defaultTimeZone()
        ),
        timeZone
      );
      setLastAccepted(newAcceptDateTime);
      setSession(sessionToSet);
      if (logEvent) {
        logEvent({
          eventTime: new Date(),
          eventName: Event.SELECTEDINTERVAL,
          payload: {
            SelectedInterval: sessionToSet.asDate(),
          },
          category: Category.TRADEDASHBOARD_PAGE,
          tradeInterval: sessionToSet.asDate(),
        });
      }
    }
  };

  const toggleOpenClose = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onChangeView = useCallback(
    (view: THoursOrDateView) => {
      if (!isOpen) {
        setHoursOrDateView(view);
      }
    },
    [isOpen]
  );

  const renderPickerInput = useCallback(
    (params) => {
      const value = params?.inputProps?.value;
      let date: string = '';
      let time: string = '';

      if (value) {
        date = value.substr(0, 10);
        time = value.substr(11);
      }
      return (
        <S.SelectorButton onClick={toggleOpenClose} ref={params.inputRef}>
          <strong
            data-testid='interval_selected_date'
            onClick={() => onChangeView('day')}
          >
            {date}
          </strong>
          <strong
            data-testid='interval_selected_hours'
            onClick={() => onChangeView('hours')}
          >
            {time}
          </strong>
          <CalendarTodayIcon
            data-testid='interval_selected_icon'
            onClick={() => onChangeView('day')}
            fontSize='small'
          />
        </S.SelectorButton>
      );
    },
    [onChangeView, toggleOpenClose]
  );

  useEffect(() => {
    setDateTime(new Date(sessionObj.toIsoString().slice(0, -6)));
    setLastAccepted(new Date(sessionObj.toIsoString().slice(0, -6)));
  }, [session, setDateTime, setLastAccepted, sessionObj]);

  useEffect(() => {
    let changeFound = false;
    function handleClickOutside(event: any) {
      const composedPath = event.composedPath();
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        composedPath.length
      ) {
        for (let index = 0; index < composedPath.length; index++) {
          const path = composedPath[index];
          if (
            path.className &&
            path.className.includes &&
            (path.className.includes('MuiClockPicker-root') ||
              path.className.includes('MuiCalendarPicker-root'))
          ) {
            changeFound = true;
            break;
          } else {
            changeFound = false;
          }
        }
        setCommit(changeFound);
      }
      return;
    }

    function detectsEsc(event: any) {
      if (event.key === 'Escape') {
        changeFound = false;
        toggleOpenClose();
        setCommit(false);
        setIsOpen(false);
        onChangeDateTimeHandler(lastAccepted);
      }
      return;
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, false);
    document.addEventListener('keydown', detectsEsc, false);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', detectsEsc, false);
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const onBackToCurrent = () => {
    setSession(startSession);
  };

  return (
    <S.SessionSelectorContainer ref={ref}>
      <div data-testid='interval_selected'>
        <DateTimePicker
          value={datetime}
          minutesStep={15}
          openTo={hoursOrDateView}
          open={isOpen}
          onChange={onChangeDateTimeHandler}
          onAccept={onAcceptDateTimeHandler}
          onClose={toggleOpenClose}
          onError={console.log}
          renderInput={renderPickerInput}
        />
      </div>
      <S.ResetButton
        disabled={isSessionSynced}
        onClick={onBackToCurrent}
        data-testid='button_reset'
      >
        Reset
      </S.ResetButton>
    </S.SessionSelectorContainer>
  );
};

export default SessionSelector;
