import i18n from 'i18next';
import { isAbsent } from './utils';

const numberField = <T>(value: T, error: string | null) => {
  return {
    value,
    error,
    required: function required(errorMessage: string | null = null) {
      if (this.error) return this;
      if (isAbsent(this.value)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.required_field');
      }
      return this;
    },
    max: function max(maxValue: number, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && Number(this.value) > maxValue) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.max_value', { value: maxValue });
      }
      return this;
    },
    min: function min(minValue: number, errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && Number(this.value) < minValue) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.min_value', { value: minValue });
      }
      return this;
    },
    positive: function positive(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && Number(this.value) < 0) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_positive');
      }
      return this;
    },
    negative: function negative(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!isAbsent(this.value) && Number(this.value) >= 0) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_negative');
      }
      return this;
    },
    integer: function integer(errorMessage: string | null = null) {
      if (this.error) return this;
      if (!Number.isInteger(Number(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_integer');
      }
      return this;
    },
    decimal: function decimal(errorMessage: string | null = null) {
      if (this.error) return this;
      if (Number.isInteger(Number(this.value))) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.must_be_decimal');
      }
      return this;
    },
    custom: function custom(
      fn: Function,
      errorMessage: string | null = null,
      ...args: []
    ) {
      if (this.error) return this;
      if (!fn(this.value, ...args)) {
        this.error = errorMessage
          ? i18n.t(errorMessage)
          : i18n.t('form_validation_util.field_no_valid');
      }
      return this;
    },
    validate: function validate() {
      return this.error;
    },
  };
};

export default numberField;
