import { ZonedDateTime } from '@pci/pci-ui-library';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AutoTraderApi, IOperatingPlanM15Request } from 'api/AutoTraderAPI';
import {
  IGeneratingUnitOperatingPlan,
  IOperatingPlansDefaultPowerResource,
} from 'interfaces/resource';
import { Dictionary, isEmpty, keyBy } from 'lodash';
import { FULFILLED, IDLE, LOADING } from 'redux/constants';

const STORE_NAME = 'operatingplans';

export interface OperatingPlan {
  generatingUnitOperatingPlans: Dictionary<IGeneratingUnitOperatingPlan>;
  loadOperatingPlans: Dictionary<IOperatingPlansDefaultPowerResource>;
  powerExportOperatingPlans: Dictionary<IOperatingPlansDefaultPowerResource>;
  powerImportOperatingPlans: Dictionary<IOperatingPlansDefaultPowerResource>;
}

interface OriginalOperatingPlans {
  original: OperatingPlan;
  // in minutes
  originalRange: number;
  originalStatus: string;
}
interface EffectiveOperatingPlans {
  effective: OperatingPlan;
  effectiveRange: number;
  effectiveStatus: string;
}
interface OptimizedOperatingPlans {
  optimized: OperatingPlan;
  optimizedRange: number;
  optimizedStatus: string;
}
interface FormulatedOperatingPlans {
  formulated: OperatingPlan;
  formulatedRange: number;
  formulatedStatus: string;
}

export type OperatingPlans = OriginalOperatingPlans &
  EffectiveOperatingPlans &
  OptimizedOperatingPlans &
  FormulatedOperatingPlans;

const initialState = {
  formulated: {},
  original: {},
  effective: {},
  optimized: {},
  formulatedStatus: IDLE,
  originalStatus: IDLE,
  effectiveStatus: IDLE,
  optimizedStatus: IDLE,
} as OperatingPlans;

export const fetchOriginalOperatingPlanM15 = createAsyncThunk(
  `${STORE_NAME}/fetchOriginalOperatingPlanM15`,
  async (requestParams: IOperatingPlanM15Request) => {
    const { data, status } = await AutoTraderApi.getOperatingPlanM15(
      requestParams
    );

    if (status >= 200 && status <= 400) {
      const {
        generatingUnitOperatingPlans,
        loadOperatingPlans,
        powerExportOperatingPlans,
        powerImportOperatingPlans,
      } = data;
      return {
        generatingUnitOperatingPlans: keyBy(generatingUnitOperatingPlans, 'id'),
        loadOperatingPlans: keyBy(loadOperatingPlans, 'id'),
        powerExportOperatingPlans: keyBy(powerExportOperatingPlans, 'id'),
        powerImportOperatingPlans: keyBy(powerImportOperatingPlans, 'id'),
      };
    } else {
      return initialState.original;
    }
  }
);

export const fetchOptimizedOperatingPlanM15 = createAsyncThunk(
  `${STORE_NAME}/fetchOptimizedOperatingPlanM15`,
  async (requestParams: IOperatingPlanM15Request) => {
    const { data, status } = await AutoTraderApi.getOptmizedOperatingPlanM15(
      requestParams
    );

    if (status >= 200 && status <= 400) {
      const {
        generatingUnitOperatingPlans,
        loadOperatingPlans,
        powerExportOperatingPlans,
        powerImportOperatingPlans,
      } = data;
      return {
        generatingUnitOperatingPlans: keyBy(generatingUnitOperatingPlans, 'id'),
        loadOperatingPlans: keyBy(loadOperatingPlans, 'id'),
        powerExportOperatingPlans: keyBy(powerExportOperatingPlans, 'id'),
        powerImportOperatingPlans: keyBy(powerImportOperatingPlans, 'id'),
      };
    } else {
      return initialState.optimized;
    }
  }
);

export const fetchEffectiveOperatingPlanM15 = createAsyncThunk(
  `${STORE_NAME}/fetchEffectiveOperatingPlanM15`,
  async (requestParams: IOperatingPlanM15Request) => {
    const { data, status } = await AutoTraderApi.getEffectiveOperatingPlanM15(
      requestParams
    );

    if (status >= 200 && status <= 400) {
      const {
        generatingUnitOperatingPlans,
        loadOperatingPlans,
        powerExportOperatingPlans,
        powerImportOperatingPlans,
      } = data;
      return {
        generatingUnitOperatingPlans: keyBy(generatingUnitOperatingPlans, 'id'),
        loadOperatingPlans: keyBy(loadOperatingPlans, 'id'),
        powerExportOperatingPlans: keyBy(powerExportOperatingPlans, 'id'),
        powerImportOperatingPlans: keyBy(powerImportOperatingPlans, 'id'),
      };
    } else {
      return initialState.effective;
    }
  }
);

export const fetchFormulatedOperatingPlanM15 = createAsyncThunk(
  `${STORE_NAME}/fetchFormulatedOperatingPlanM15`,
  async (requestParams: IOperatingPlanM15Request) => {
    const { data, status } = await AutoTraderApi.getFormulatedOperatingPlanM15(
      requestParams
    );

    if (status >= 200 && status <= 400 && !isEmpty(data)) {
      const {
        generatingUnitOperatingPlans,
        loadOperatingPlans,
        powerExportOperatingPlans,
        powerImportOperatingPlans,
      } = data;
      const formulatedOutput: any = {
        generatingUnitOperatingPlans: keyBy(generatingUnitOperatingPlans, 'id'),
        loadOperatingPlans: keyBy(loadOperatingPlans, 'id'),
        powerExportOperatingPlans: keyBy(powerExportOperatingPlans, 'id'),
        powerImportOperatingPlans: keyBy(powerImportOperatingPlans, 'id'),
      };
      return formulatedOutput;
    } else {
      return initialState.formulated;
    }
  }
);

export const operatingPlansSlice = createSlice({
  name: STORE_NAME,
  initialState,
  reducers: {
    resetStatuses: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOriginalOperatingPlanM15.pending, (state) => {
        state.originalStatus = LOADING;
      })
      .addCase(fetchOriginalOperatingPlanM15.fulfilled, (state, action) => {
        const { startAt, stopAt } = action.meta.arg as IOperatingPlanM15Request;
        state.originalStatus = FULFILLED;
        state.original = action.payload;
        if (startAt && stopAt) {
          const timeZone = ZonedDateTime.defaultTimeZone();
          const rangeInSmallUnit = ZonedDateTime.parseIso(
            stopAt,
            timeZone
          ).diff(ZonedDateTime.parseIso(startAt, timeZone), 'm');
          state.originalRange = rangeInSmallUnit;
        }
      })
      .addCase(fetchEffectiveOperatingPlanM15.pending, (state) => {
        state.effectiveStatus = LOADING;
      })
      .addCase(fetchEffectiveOperatingPlanM15.fulfilled, (state, action) => {
        const { startAt, stopAt } = action.meta.arg as IOperatingPlanM15Request;
        state.effectiveStatus = FULFILLED;
        state.effective = action.payload;
        if (startAt && stopAt) {
          const timeZone = ZonedDateTime.defaultTimeZone();
          const rangeInSmallUnit = ZonedDateTime.parseIso(
            stopAt,
            timeZone
          ).diff(ZonedDateTime.parseIso(startAt, timeZone), 'm');
          state.effectiveRange = rangeInSmallUnit;
        }
      })
      .addCase(fetchOptimizedOperatingPlanM15.pending, (state) => {
        state.optimizedStatus = LOADING;
      })
      .addCase(fetchOptimizedOperatingPlanM15.fulfilled, (state, action) => {
        const { startAt, stopAt } = action.meta.arg as IOperatingPlanM15Request;
        state.optimizedStatus = FULFILLED;
        state.optimized = action.payload;
        if (startAt && stopAt) {
          const timeZone = ZonedDateTime.defaultTimeZone();
          const rangeInSmallUnit = ZonedDateTime.parseIso(
            stopAt,
            timeZone
          ).diff(ZonedDateTime.parseIso(startAt, timeZone), 'm');
          state.optimizedRange = rangeInSmallUnit;
        }
      })
      .addCase(fetchFormulatedOperatingPlanM15.pending, (state) => {
        state.formulatedStatus = LOADING;
      })
      .addCase(fetchFormulatedOperatingPlanM15.fulfilled, (state, action) => {
        const { startAt, stopAt } = action.meta.arg as IOperatingPlanM15Request;
        state.formulatedStatus = FULFILLED;
        state.formulated = action.payload as OperatingPlan;
        if (startAt && stopAt) {
          const timeZone = ZonedDateTime.defaultTimeZone();
          const rangeInSmallUnit = ZonedDateTime.parseIso(
            stopAt,
            timeZone
          ).diff(ZonedDateTime.parseIso(startAt, timeZone), 'm');
          state.formulatedRange = rangeInSmallUnit;
        }
      });
  },
});

export const selectPlan = (plan: OperatingPlan) => {
  return {
    generatingUnitOperatingPlans: Object.values(
      plan.generatingUnitOperatingPlans || {}
    ),
    loadOperatingPlans: Object.values(plan.loadOperatingPlans || {}),
    powerExportOperatingPlans: Object.values(
      plan.powerExportOperatingPlans || {}
    ),
    powerImportOperatingPlans: Object.values(
      plan.powerImportOperatingPlans || {}
    ),
  };
};

export const { resetStatuses } = operatingPlansSlice.actions;

export default operatingPlansSlice.reducer;
