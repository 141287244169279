import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import Logo from 'components/admin/Logo/Logo';
import {
  ExpandLess,
  ExpandMore,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material';
import { sideMenuType } from './menu.config';
import {
  useRouteMatch,
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import styles from './Sidebar.module.scss';
import { AppDispatch, AppStore } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { updateMiscellaneous } from 'redux/states/miscellaneous.state';

type sideBar = {
  sideMenu: sideMenuType[];
};

function Sidebar({ sideMenu }: sideBar) {
  const miscellaneousState = useSelector(
    (store: AppStore) => store.miscellaneous
  );
  const { navigation: navigationState } = miscellaneousState;
  const { sideBarCollapsed, expand } = navigationState;
  const { roles } = useSelector((store: AppStore) => store.authentication);
  const dispatch = useDispatch<AppDispatch>();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const handleExpandableClick = (expanded: string, children: boolean) => {
    const navigation = {
      ...navigationState,
      expand: expand === expanded && children ? '' : expanded,
    };
    dispatch(updateMiscellaneous({ navigation }));
  };
  const collapseHandler = () => {
    if (sideBarCollapsed !== undefined) {
      const navigation = {
        ...navigationState,
        sideBarCollapsed: !sideBarCollapsed,
      };
      dispatch(updateMiscellaneous({ navigation }));
    }
  };

  // float menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popNo, setPopNo] = useState<number>(-1);
  const open = Boolean(anchorEl) && Boolean(sideBarCollapsed);

  const handleCollapsedMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuIconClick = (e: any, _popno: number) => {
    setAnchorEl(e.currentTarget);
    setPopNo(_popno);
  };

  const {
    colapsebutton,
    sidebarMenu,
    selectedMenu,
    selectedTab,
    menuButton,
    full,
    menuTitle,
    tabIcon,
    selectedIconTab,
    subMenuText,
    subTabSelected,
    collapsed,
    itemIcon,
    sideBar,
    fullLink,
    subMenuCollapsedText,
    subMenuCollapsedContainer,
  } = styles;

  useEffect(() => {
    const routes: string[] = pathname
      .split('/')
      .filter((route) => route !== '');
    const navigation = {
      ...navigationState,
      expand: routes[1],
    };
    dispatch(updateMiscellaneous({ navigation }));
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (sideBarCollapsed && expand !== '') {
      const navigation = {
        ...navigationState,
        expand: '',
      };
      dispatch(updateMiscellaneous({ navigation }));
    }
    // eslint-disable-next-line
  }, [sideBarCollapsed, expand]);

  return (
    <Box className={sideBar} data-testid='sidebar_menu'>
      <Logo margin={24} />
      <Divider variant='middle' />
      <List
        id='sidebar-menu'
        className={sidebarMenu}
        component='div'
        disablePadding
      >
        {sideMenu.map(
          ({ Icon, label, children, to: menuTo, menuRoles }, index) => {
            const selected = expand === menuTo.replace('/', '');
            const onMenu = pathname.includes(`${path}${menuTo}`);
            const isVisible = roles?.some((role) => menuRoles.includes(role));

            return (
              <div key={index} className={selected ? selectedMenu : ''}>
                {isVisible && (
                  <ListItemButton
                    id='listItemButton'
                    data-testid={`nav_${label
                      .toLowerCase()
                      .replaceAll(' ', '_')}`}
                    className={`${menuButton} ${onMenu ? selectedTab : ''} ${
                      !children ? full : ''
                    }`}
                    key={`${label}-${index}`}
                    onClick={() =>
                      handleExpandableClick(menuTo.replace('/', ''), !!children)
                    }
                  >
                    <ListItemIcon
                      id='listItemIcon'
                      className={`${itemIcon} ${
                        sideBarCollapsed ? collapsed : ''
                      }`}
                      onClick={(e) => handleSubMenuIconClick(e, index)}
                    >
                      <Icon
                        className={`${tabIcon} ${
                          onMenu ? selectedIconTab : ''
                        }`}
                      />
                    </ListItemIcon>
                    {children ? (
                      <ListItemText
                        id='menuTitle'
                        className={`${menuTitle} ${
                          sideBarCollapsed ? collapsed : ''
                        }`}
                        primary={label}
                      />
                    ) : (
                      <Link
                        className={sideBarCollapsed ? fullLink : ''}
                        key={index}
                        data-testid={`nav_${label
                          .toLowerCase()
                          .replaceAll(' ', '_')}`}
                        component={RouterLink}
                        underline='none'
                        to={`${path}${menuTo}`}
                      >
                        <ListItemText
                          id='menuTitle'
                          className={`${menuTitle} ${
                            sideBarCollapsed ? collapsed : ''
                          }`}
                          primary={label}
                        />
                      </Link>
                    )}

                    {children &&
                      !sideBarCollapsed &&
                      (selected ? <ExpandLess /> : <ExpandMore />)}
                  </ListItemButton>
                )}

                {children && !sideBarCollapsed && (
                  <Collapse in={selected} timeout='auto' unmountOnExit>
                    <List
                      className='submenu-container'
                      component='div'
                      disablePadding
                    >
                      {children.map(({ label, to, menuRoles }, index) => {
                        const route = `${path}${menuTo}${to}`;
                        const selectedSubtab = pathname === route;
                        const isVisible = roles?.some((role) =>
                          menuRoles.includes(role)
                        );
                        if (isVisible) {
                          return (
                            <Link
                              key={index}
                              data-testid={`nav_${label
                                .toLowerCase()
                                .replace('&', '_and_')
                                .replaceAll(' ', '_')}`}
                              component={RouterLink}
                              underline='none'
                              to={route}
                            >
                              <ListItemText
                                id='listItemText'
                                className={`${subMenuText} ${
                                  selectedSubtab ? subTabSelected : ''
                                }`}
                                key={index}
                                primary={label}
                              />
                            </Link>
                          );
                        } else {
                          return <div />;
                        }
                      })}
                    </List>
                  </Collapse>
                )}

                {children && popNo === index && (
                  <Menu
                    key={`subMenu-${index}`}
                    id={'collapsedSubMenu'}
                    className={subMenuCollapsedContainer}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCollapsedMenuClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    {children.map(({ label, to, menuRoles }, index) => {
                      const route = `${path}${menuTo}${to}`;
                      const isVisible = roles?.some((role) =>
                        menuRoles.includes(role)
                      );
                      if (isVisible) {
                        return (
                          <MenuItem
                            onClick={handleCollapsedMenuClose}
                            key={index}
                            data-testid={`nav_${label
                              .toLowerCase()
                              .replaceAll(' ', '_')}`}
                          >
                            <Link
                              component={RouterLink}
                              underline='none'
                              to={route}
                            >
                              <ListItemText
                                id='listItemText'
                                className={subMenuCollapsedText}
                                key={index}
                                primary={label}
                              />
                            </Link>
                          </MenuItem>
                        );
                      } else {
                        return <div />;
                      }
                    })}
                  </Menu>
                )}
              </div>
            );
          }
        )}
      </List>
      <Button
        onClick={collapseHandler}
        data-testid='colapsebutton'
        className={colapsebutton}
        startIcon={
          sideBarCollapsed ? (
            <KeyboardArrowRightRounded className='colpseicon' />
          ) : (
            <KeyboardArrowLeftRounded className='colpseicon' />
          )
        }
      />
    </Box>
  );
}

export default Sidebar;
