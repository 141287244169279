import CellEditor from 'components/molecules/CellEditor/CellEditor';
import { timeOnLineNoUnitsFormatter } from './helpers/helpers';
import SelectEditor from 'components/molecules/SelectEditor/SelectEditor';
import {
  generateToken,
  timeExportHeaderValueGetter,
  timeExportValueGetter,
} from 'utils/general';

// DATA TYPES
export const STRING = 'STRING';
export const LONG = 'LONG';
export const DOUBLE = 'DOUBLE';
export const DATE_TIME = 'DATE_TIME';

// INTERVAL TYPES
export const INSTANT = 'INSTANT';
export const M05 = 'M05';
export const M10 = 'M10';
export const M15 = 'M15';
export const M30 = 'M30';
export const HR = 'HR';

// UNIT OF MEASURE
export const MW = 'MW';
export const STAGE = 'STAGE';
export const MMBTU = 'MMBTU';

// TYPE OF MEASURE
export const MW_LEVEL = 'MW_LEVEL';

export const initialValues = {
  id: { value: generateToken(), error: undefined, touched: undefined },
  tagId: { value: 'Meter_01', error: undefined, touched: undefined },
  resourceIdentifier: {
    value: 'TREGION_01',
    error: undefined,
    touched: undefined,
    required: false,
  },
  dataType: { value: DOUBLE, error: undefined, touched: undefined },
  intervalType: { value: INSTANT, error: undefined, touched: undefined },
  unitOfMeasure: { value: MW, error: undefined, touched: undefined },
  offThreshold: { value: 0, error: undefined, touched: undefined },
  clockTolerance: { value: 120, error: undefined, touched: undefined },
  description: { value: 'Description', error: undefined, touched: undefined },
  measurementType: {
    value: MW_LEVEL,
    error: undefined,
    touched: undefined,
  },
  source: { value: 'Source', error: undefined, touched: undefined },
  newRecord: true,
};

export const METER_COLUMNS = [
  {
    headerName: 'Tag ID',
    field: 'tagId',
    minWidth: 150,
    maxWidth: 300,
    flex: 2,
    cellRenderer: 'CellRender',
    cellEditor: CellEditor,
    pinned: 'left',
  },
  {
    headerName: 'Resource',
    field: 'resourceIdentifier',
    minWidth: 130,
    cellEditor: SelectEditor,
    cellEditorParams: {
      options: [{ value: 'TREGION_01', label: 'TREGION_01' }],
    },
  },
  {
    headerName: 'Interval',
    field: 'intervalType',
    minWidth: 100,
    maxWidth: 200,
    flex: 1,
    cellEditor: SelectEditor,
    cellRenderer: 'CellRender',
    cellRendererParams: {
      type: 'selector',
      options: [
        { value: INSTANT, label: 'Instant' },
        { value: M05, label: 'M05' },
        { value: M10, label: 'M10' },
        { value: M15, label: 'M15' },
        { value: M30, label: 'M30' },
        { value: HR, label: 'HR' },
      ],
    },
    cellEditorParams: {
      options: [
        { value: INSTANT, label: 'Instant' },
        { value: M05, label: 'M05' },
        { value: M10, label: 'M10' },
        { value: M15, label: 'M15' },
        { value: M30, label: 'M30' },
        { value: HR, label: 'HR' },
      ],
    },
  },
  {
    headerName: 'Off \n Threshold',
    field: 'offThreshold',
    minWidth: 70,
    maxWidth: 70,
    flex: 1,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Clock \n Tolerance \n (Seconds)',
    field: 'clockTolerance',
    minWidth: 80,
    maxWidth: 80,
    flex: 1,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },

  {
    headerName: 'Last Update',
    field: 'lastUpdateTime',
    minWidth: 130,
    maxWidth: 130,
    flex: 2,
    headerValueGetter: timeExportHeaderValueGetter,
    cellRendererParams: {
      type: 'datetime',
    },
    valueGetter: timeExportValueGetter,
    editable: false,
  },

  {
    headerName: 'Energy',
    field: 'value',
    minWidth: 90,
    flex: 2,
    editable: false,
  },
  {
    headerName: 'Status',
    field: 'status',
    minWidth: 90,
    flex: 2,
    cellRenderer: 'CellRender',
    editable: false,
  },
  {
    headerName: 'Time in\n Status \n (Minutes)',
    field: 'timeInStatus',
    minWidth: 150,
    flex: 2,
    editable: false,
    valueFormatter: timeOnLineNoUnitsFormatter,
  },
  {
    headerName: 'Source',
    field: 'source',
    minWidth: 200,
    flex: 2,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Description',
    field: 'description',
    minWidth: 200,
    flex: 2,
    cellEditor: CellEditor,
    cellRenderer: 'CellRender',
  },
];
