import { AGSort } from 'interfaces/dataGrid';

export const auditColumnDefs = [
  {
    headerName: 'Date & Time',
    field: 'eventTime',
    minWidth: 100,
    maxWidth: 150,
    pinned: 'left',
    sort: AGSort.ASC,
    cellRendererParams: {
      type: 'epoch',
    },
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Event Name',
    field: 'eventName',
    minWidth: 150,
    maxWidth: 280,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'User Email',
    field: 'userEmail',
    minWidth: 100,
    maxWidth: 300,
    cellRenderer: 'CellRender',
  },
  {
    headerName: 'Event Json',
    field: 'payload',
    cellStyle: { whiteSpace: 'nowrap' },
    valueFormatter: (params: any) => {
      let formated = '';
      if (params.value !== null) {
        const jsonPayload = params.value?.toString();
        formated = JSON.stringify(jsonPayload);
      }
      return formated;
    },
  },
];
